import { Validation } from "../../models/validations/validation";

export const leaseTypeValidations: Array<Validation> = [
    {
        property: "name",
        friendlyName: "Lease type name",
        isRequired: true,
        minLength: 2,
        maxLength: 255,
    }
]