import { Guid } from "guid-typescript";
import { Site } from "../models/sites/site";
import ApiBroker from "./apiBroker";

class SiteBroker {

    relativeSiteUrl = '/api/sites';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostSiteAsync(site: Site) {
        return await this.apiBroker.PostAsync(this.relativeSiteUrl, site)
            .then(result => new Site(result.data));
    }

    async GetAllSitesAsync(queryString: string) {
        var url = this.relativeSiteUrl + queryString;

        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((site: any) => new Site(site)));
    }

    async GetSiteByIdAsync(id: Guid) {
        const url = `${this.relativeSiteUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new Site(result.data));
    }

    async PutSiteAsync(site: Site) {
        return await this.apiBroker.PutAsync(this.relativeSiteUrl, site)
            .then(result => new Site(result.data));
    }

    async DeleteSiteByIdAsync(id: Guid) {
        const url = `${this.relativeSiteUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new Site(result.data));
    }
}

export default SiteBroker;



