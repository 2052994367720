import { Guid } from "guid-typescript";

import { ReviewStage } from "../models/reviewStages/reviewStage";
import ApiBroker from "./apiBroker";

class ReviewStageBroker {

    relativeReviewStageUrl = '/api/reviewStages';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostReviewStageAsync(reviewStage: ReviewStage) {
        return await this.apiBroker.PostAsync(this.relativeReviewStageUrl, reviewStage)
            .then(result => new ReviewStage(result.data));
    }

    async GetAllReviewStagesAsync(queryString: string) {
        var url = this.relativeReviewStageUrl + queryString;
        
        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((reviewStage: any) => new ReviewStage(reviewStage)));
    }

    async GetReviewStageByIdAsync(id: Guid) {
        const url = `${this.relativeReviewStageUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new ReviewStage(result.data));
    }

    async PutReviewStageAsync(reviewStage: ReviewStage) {
        return await this.apiBroker.PutAsync(this.relativeReviewStageUrl, reviewStage)
            .then(result => new ReviewStage(result.data));
    }

    async DeleteReviewStageByIdAsync(id: Guid) {
        const url = `${this.relativeReviewStageUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new ReviewStage(result.data));
    }
}

export default ReviewStageBroker;



