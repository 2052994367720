import { Guid } from 'guid-typescript';

export class SiteView {
    public id: Guid;
    public practiceId: Guid;
    public siteTypeId?: Guid;
    public contractId?: Guid;
    public ownershipId?: Guid;
    public propertyStatusId?: Guid;
    public closureDate?: Date;
    public siteName?: string;
    public addressLine1 ?: string;
    public addressLine2 ?: string;
    public addressLine3 ?: string;
    public postCode ?: string;
    public practiceTelephoneNumber ?: string;
    public practiceEmail?: string
    public leadGpName?: string;
    public leadGpEmail?: string;
    public practiceManagerName?: string;
    public practiceManagerEmail?: string;
    public listSize?: number
    public totalSquareFootage?: number;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;
    public practiceName?: string;
    public practiceCode?: string;
    public siteTypeName?: string;
    public contractName?: string;
    public ownershipName?: string;
    public propertyStatusName?: string; 


    constructor(
        id: Guid,
        practiceId: Guid,
        siteTypeId?: Guid,
        contractId?: Guid,
        ownershipId?: Guid,
        propertyStatusId?: Guid,
        closureDate?: Date,
        siteName?: string,
        addressLine1?: string,
        addressLine2?: string,
        addressLine3?: string,
        postCode?: string,
        listSize?: number,
        totalSquareFootage?: number,
        isActive?: boolean,
        sortOrder?: number,
        createdByUser?: string,
        createdDate?: Date,
        updatedByUser?: string,
        updatedDate?: Date,
        practiceName?: string,
        practiceCode?: string,
        siteTypeName?: string,
        contractName?: string,
        ownershipName?: string,
        propertyStatusName?: string
    ) {
        this.id = id;
        this.practiceId = practiceId;
        this.siteTypeId = siteTypeId;
        this.contractId = contractId;
        this.ownershipId = ownershipId;
        this.propertyStatusId = propertyStatusId;
        this.closureDate = closureDate;
        this.siteName = siteName || "";
        this.addressLine1 = addressLine1 || "";
        this.addressLine2 = addressLine2 || "";
        this.addressLine3 = addressLine3 || "";
        this.postCode = postCode || "";
        this.listSize = listSize || 0;
        this.totalSquareFootage = totalSquareFootage || 0;
        this.isActive = isActive === false ? false : true;
        this.sortOrder = sortOrder || 1000;
        this.createdByUser = createdByUser !== undefined ? createdByUser : '';
        this.createdDate = createdDate;
        this.updatedByUser = updatedByUser !== undefined ? updatedByUser : ''
        this.updatedDate = updatedDate;
        this.practiceName = practiceName || "";
        this.practiceCode = practiceCode || "";
        this.siteTypeName = siteTypeName || "";
        this.contractName = contractName || "";
        this.ownershipName = ownershipName || "";
        this.propertyStatusName = propertyStatusName || "";
    }
}