
import { Guid } from "guid-typescript";
import React, { FunctionComponent } from "react";
import { RentReviewView } from "../../models/views/components/rentReviews/rentReviewView";
import { lookupViewService } from "../../services/views/lookups/lookupViewService";
import { rentReviewViewService } from "../../services/views/rentReviews/rentReviewViewService";
import RentReviewDetailCard from "./rentReviewDetailCard";

interface RentReviewDetailProps {
    leaseId: Guid;
    rentReview: RentReviewView;
    onCancel: Function;
    mode: string;
    children?: React.ReactNode;
}

const RentReviewDetail: FunctionComponent<RentReviewDetailProps> = (props) => {
    const {
        rentReview,
        onCancel,
        mode,
        children
    } = props;

    let { mappedReviewStages: reviewStagesRetrieved } = lookupViewService.useGetReviewStageList();
    let { mappedReviewStatuses: reviewStatusesRetrieved } = lookupViewService.useGetReviewStatusList();

    const addRentReview = rentReviewViewService.useCreateRentReview();
    const handleAdd = (rentReview: RentReviewView) => {
        return addRentReview.mutate(rentReview, {
            onSuccess: () => {
                onCancel();
            }
        });;
    }

    const updateRentReview = rentReviewViewService.useUpdateRentReview();
    const handleUpdate = (rentReview: RentReviewView) => {
        return updateRentReview.mutateAsync(rentReview,{
            onSuccess: () => {
                onCancel();
        }});
    }

    const handleDelete = async (rentReview: RentReviewView) => {
        rentReview.isActive = false;
        return updateRentReview.mutateAsync(rentReview);
    }

    const handleUnDelete = async (rentReview: RentReviewView) => {
        rentReview.isActive = true;
        return updateRentReview.mutateAsync(rentReview);
    }

    const handleCancel = () => {
        onCancel();
    }

    return (
        <>
            {
                reviewStagesRetrieved !== undefined
                && reviewStatusesRetrieved !== undefined
                && (
                    <RentReviewDetailCard
                       // key={mode !== 'ADD' ? rentReviewRetrieved?.id.toString() : rentReview?.id.toString()}
                        rentReview={ rentReview}
                        reviewStages={reviewStagesRetrieved}
                        reviewStatuses={reviewStatusesRetrieved}
                        mode={mode}
                        onModeChange={() => {}}
                        onAdd={handleAdd}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        onUnDelete={handleUnDelete}
                        onCancel={handleCancel}
                    >
                        {children}
                    </RentReviewDetailCard>
                )}
        </>
    );
}

export default RentReviewDetail;