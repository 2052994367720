import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";

interface ContactRowNewProps {
    onAdd: (value: boolean) => void;
}

const ContactRowNew: FunctionComponent<ContactRowNewProps> = (props) => {
    const {
        onAdd
    } = props;

    return (
        <SecuredComponents allowedRoles={securityPoints.contacts.add}>
            <TableBaseRow>
                <TableBaseData></TableBaseData>
                <TableBaseData classes="text-end">
                    <ButtonBase id="contactsAdd" onClick={() => onAdd(true)} add> <FontAwesomeIcon icon={faCirclePlus} size="lg" />&nbsp; New</ButtonBase>
                </TableBaseData>
            </TableBaseRow>
        </SecuredComponents>
    );
}

export default ContactRowNew;
