import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { ContactType } from "../../../models/contactTypes/contactType";
import { ContactTypeView } from "../../../models/views/components/contactTypes/contactTypeView";
import { contactTypeService } from "../../foundations/contactTypeService";

export const contactTypeViewService = {

    useCreateContactType: () => {
        return contactTypeService.useCreateContactType();
    },

    useGetAllContactTypes: (searchTerm?: string) => {
        try {
            let query = '?$orderby=sortOrder,name';

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`;
            }

            const response = contactTypeService.useGetAllContactTypes(query);
            const [mappedContactTypes, setMappedContactTypes] = useState<Array<ContactTypeView>>([]);

            useEffect(() => {
                if (response.data) {
                    const contactTypes = response.data.map((contactType: ContactType) =>
                        new ContactTypeView(
                            contactType.id,
                            contactType.name,
                            contactType.sortOrder,
                            contactType.isActive ?? true,
                            contactType.createdByUser,
                            contactType.createdDate,
                            contactType.updatedByUser,
                            contactType.updatedDate
                        ));

                    setMappedContactTypes(contactTypes);
                }
            }, [response.data]);

            return {
                mappedContactTypes, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetContactTypeById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = contactTypeService.useGetAllContactTypes(query);
            const [mappedContactType, setMappedContactType] = useState<ContactTypeView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const contactType = new ContactTypeView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate
                    );

                    setMappedContactType(contactType);
                }
            }, [response.data]);

            return {
                mappedContactType, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateContactType: () => {
        return contactTypeService.useUpdateContactType();
    },

    useRemoveContactType: () => {
        return contactTypeService.useDeleteContactType();
    },
}