import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import ButtonBase from "../bases/buttons/ButtonBase";
import { DiscussionView } from "../../models/views/components/discussions/discussionView";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { discussionErrors } from "./discussionErrors";
import { discussionValidation } from "./discussionValidation";

interface DiscussionRowEditProps {
    discussion: DiscussionView;
    onCancel: () => void;
    onEdit: (discussionType: DiscussionView) => void;
    apiError?: any;
}

const DiscussionRowEdit: FunctionComponent<DiscussionRowEditProps> = (props) => {
    const {
        discussion,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editDiscussion, setEditDiscussion] = useState<DiscussionView>({ ...discussion });

    const { errors, processApiErrors, enableValidationMessages, validate } = useValidation(discussionErrors, discussionValidation, discussion);

    const handleChange = (
        event: ChangeEvent<HTMLInputElement>
            | ChangeEvent<HTMLTextAreaElement>
            | ChangeEvent<HTMLSelectElement>) => {
        const updatedDiscussion = {
            ...editDiscussion,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditDiscussion(updatedDiscussion);
    };

    const handleUpdate = () => {
        if (!validate(editDiscussion)) {
            onEdit(editDiscussion);
        } else {
            enableValidationMessages();
        }
    }

    const handleCancel = () => {
        setEditDiscussion({ ...discussion });
        onCancel();
    };

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData cols={2}>
                <TextAreaInputBase
                    id="comment"
                    name="comment"
                    label="Comment"
                    placeholder="Comment"
                    value={editDiscussion.comment}
                    error={errors.comment}
                    onChange={handleChange}
                    required={true}
                    rows={5}
                    maxCharacters={4000}
                    showCount={false}
                    showRemaingCount={true}
                />
                <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleUpdate()} edit>Update</ButtonBase>
            </TableBaseData>
           
        </TableBaseRow>
    );
}

export default DiscussionRowEdit;
