import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import SiteTypeBroker from "../../brokers/apiBroker.siteTypes"
import { SiteType } from "../../models/siteTypes/siteType";

export const siteTypeService = {
    useCreateSiteType: () => {
        const siteTypeBroker = new SiteTypeBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((siteType: SiteType) => {
            const date = new Date();
            siteType.createdDate = siteType.updatedDate = date;
            siteType.createdByUser = siteType.updatedByUser = msal.accounts[0].username;

            return siteTypeBroker.PostSiteTypeAsync(siteType);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("SiteTypeGetAll");
                    queryClient.invalidateQueries(["SiteTypeGetById", { id: data.id }]);
                }
            });
    },

    useGetAllSiteTypes: (query: string) => {
        const siteTypeBroker = new SiteTypeBroker();

        return useQuery(
            ["SiteTypeGetAll", { query: query }],
            () => siteTypeBroker.GetAllSiteTypesAsync(query),
            { staleTime: Infinity });
    },

    useGetSiteTypeById: (id: Guid) => {
        const siteTypeBroker = new SiteTypeBroker();

        return useQuery(
            ["SiteTypeGetById", { id: id }],
            () => siteTypeBroker.GetSiteTypeByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateSiteType: () => {
        const siteTypeBroker = new SiteTypeBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((siteType: SiteType) => {
            const date = new Date();
            siteType.updatedDate = date;
            siteType.updatedByUser = msal.accounts[0].username;

            return siteTypeBroker.PutSiteTypeAsync(siteType);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("SiteTypeGetAll");
                    queryClient.invalidateQueries(["SiteTypeGetById", { id: data.id }]);
                }
            });
    },

    useDeleteSiteType: () => {
        const siteTypeBroker = new SiteTypeBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return siteTypeBroker.DeleteSiteTypeByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("SiteTypeGetAll");
                    queryClient.invalidateQueries(["SiteTypeGetById", { id: data.id }]);
                }
            });
    },
}