import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { DiscussionView } from "../../models/views/components/discussions/discussionView";
import { Guid } from "guid-typescript";
import ButtonBase from "../bases/buttons/ButtonBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";
import { useValidation } from "../../hooks/useValidation";
import { discussionErrors } from "./discussionErrors";
import { discussionValidation } from "./discussionValidation";

interface DiscussionRowAddProps {
    siteId: Guid;
    onCancel: () => void;
    onAdd: (discussion: DiscussionView) => void;
    apiError?: any;
}

const DiscussionRowAdd: FunctionComponent<DiscussionRowAddProps> = (props) => {
    const {
        siteId,
        onAdd,
        apiError
    } = props;

    const [discussion, setDiscussion] = useState<DiscussionView>(new DiscussionView(Guid.create(), siteId));

    const { errors, processApiErrors, enableValidationMessages, validate } = useValidation(discussionErrors, discussionValidation, discussion);

    const handleChange = (
        event: ChangeEvent<HTMLInputElement>
            | ChangeEvent<HTMLTextAreaElement>
            | ChangeEvent<HTMLSelectElement>) => {
        const addDiscussion = {
            ...discussion,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setDiscussion(addDiscussion);
    };

    const handleCancel = async () => {
        setDiscussion(new DiscussionView(Guid.create(), siteId));
    };

    const handleSave = async (discussion: DiscussionView) => {
        if (!validate(discussion)) {
            await onAdd(discussion);
            setDiscussion(new DiscussionView(Guid.create(), siteId))
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <SecuredComponents allowedRoles={securityPoints.discussions.add}>
            <TableBaseRow>
                <TableBaseData cols={2}>
                    <TextAreaInputBase
                        id="comment"
                        name="comment"
                        label=""
                        placeholder="Comment"
                        required={true}
                        value={discussion.comment}
                        error={errors.comment}
                        onChange={handleChange}
                        rows={3}
                        maxCharacters={4000}
                        showCount={false}
                        showRemaingCount={true} />

                    <ButtonBase id="discussionsClear" onClick={() => handleCancel()} cancel>Clear</ButtonBase>&nbsp;
                    <ButtonBase id="discussionsAdd" onClick={() => handleSave(discussion)} add>Post</ButtonBase>
                </TableBaseData>
            </TableBaseRow>
        </SecuredComponents>
    );
}

export default DiscussionRowAdd;
