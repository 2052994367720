import { Validation } from "../../models/validations/validation";

export const discussionValidation: Array<Validation> = [
    {
        property: "comment",
        friendlyName: "comment",
        isRequired: true,
        minLength: 3,
        maxLength: 4000,
    }
]