import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import TextInputBase from "../bases/inputs/TextInputBase";
import SelectInputBase from "../bases/inputs/SelectInputBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import { PracticeView } from "../../models/views/components/practices/practiceView";
import { LookupView } from "../../models/views/components/lookups/lookupView";
import { Option } from "../../models/options/option";
import SummaryListBase from "../bases/components/SummaryList/SummaryListBase";
import SummaryListBaseRow from "../bases/components/SummaryList/SummaryListBase.Row";
import SummaryListBaseKey from "../bases/components/SummaryList/SummaryListBase.Key";
import SummaryListBaseValue from "../bases/components/SummaryList/SummaryListBase.Value";
import SummaryListBaseAction from "../bases/components/SummaryList/SummaryListBase.Action";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";
import { useValidation } from "../../hooks/useValidation";
import { practiceErrors } from "./practiceErrors";
import { practiceValidation } from "./practiceValidation";
import { Link } from "react-router-dom";
import DateInputBase from "../bases/inputs/DateInputBase";
import moment from "moment";
import PracticeDetailCloseModel from "./practiceDetailCloseModel";

interface PracticeDetailCardEditProps {
    practice: PracticeView;
    boroughs: Array<LookupView>;
    providerStatus: Array<LookupView>;    
    onAdd: (practice: PracticeView) => void;
    onUpdate: (practice: PracticeView) => void;
    onCancel: () => void;
    mode: string;
    onModeChange: (value: string) => void;
    apiError?: any;
}

const PracticeDetailCardEdit: FunctionComponent<PracticeDetailCardEditProps> = (props) => {
    const {
        practice,
        boroughs,
        providerStatus,        
        onAdd,
        onUpdate,
        onCancel,
        mode,
        onModeChange,
        apiError
    } = props;

    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const [editPractice, setEditPractice] = useState<PracticeView>({ ...practice });

    const { errors, enableValidationMessages, processApiErrors, validate } = useValidation(practiceErrors, practiceValidation, editPractice)

    const confirmClosePractice = () => {
        setEditPractice({ ...editPractice, isActive: false });
        setConfirmModalVisible(false);
    }

    const cancelClosePractice = () => {
        setEditPractice({ ...editPractice, dateOfClosure: undefined });
        setConfirmModalVisible(false);
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
        let value: any;
        switch (event.target.type) {
            case "checkbox":
                value = (event.target as HTMLInputElement).checked
                break;
            case "date":
                value = event.target.value !== '' ? event.target.value : null;
                break
            default:
                value = event.target.value;
                break;
        }

        const updatedPractice = {
            ...editPractice,
            [event.target.name]: value,
        };

        if (event.target.name === "isActive") {
            if (event.target.value === "false") {
                setConfirmModalVisible(true);
                updatedPractice.isActive = true;
            } else {
                updatedPractice.isActive = event.target.value === "true" ? true : false;
                updatedPractice.dateOfClosure = undefined;
            }
        }

        setEditPractice(updatedPractice);
    };

    const boroughOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...boroughs.map((borough) => {
            return { id: borough.id.toString() || 0, name: borough.name || "" };
        }),
    ];

    const providerStatusOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...providerStatus.map((providerStatus) => {
            return { id: providerStatus.id.toString() || 0, name: providerStatus.name || "" };
        }),
    ];

    const handleCancel = () => {
        setEditPractice({ ...practice });
        onModeChange('VIEW')
        onCancel();
    };

    const handleSave = () => {
        if (!validate(editPractice)) {
            onAdd(editPractice)
        } else {
            enableValidationMessages();
        }
    }

    const handleUpdate = () => {
        if (!validate(editPractice)) {
            onUpdate(editPractice)
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError)
    }, [apiError, processApiErrors])

    return (
        <>
        { confirmModalVisible && <PracticeDetailCloseModel editPractice={editPractice} cancelClosePractice={cancelClosePractice} confirmClosePractice={confirmClosePractice} handleChange={handleChange} /> }
            <SummaryListBase>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Place Name</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <SelectInputBase
                            id="boroughId"
                            name="boroughId"
                            value={editPractice.boroughId?.toString()}
                            options={boroughOptions}
                            error={errors.boroughId}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction> </SummaryListBaseAction>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Practice Name</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="practiceName"
                            name="practiceName"
                            placeholder="Practice Name"
                            value={editPractice.practiceName}
                            error={errors.practiceName}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction> </SummaryListBaseAction>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Practice Code</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="practiceCode"
                            name="practiceCode"
                            placeholder="Practice Code"
                            value={editPractice.practiceCode}
                            error={errors.practiceCode}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Practice Phone</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="practicePhone"
                            name="practicePhone"
                            placeholder="Practice Phone"
                            value={editPractice.practicePhone}
                            error={errors.practicePhone}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Practice Phone Bypass</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="practicePhoneBypass"
                            name="practicePhoneBypass"
                            placeholder="Practice Phone Bypass"
                            value={editPractice.practicePhoneBypass}
                            error={errors.practicePhoneBypass}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Practice Generic Email</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="practiceGenericEmail"
                            name="practiceGenericEmail"
                            placeholder="Practice Generic Email"
                            value={editPractice.practiceGenericEmail}
                            error={errors.practiceGenericEmail}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Provider Status</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <SelectInputBase
                            id="providerStatusId"
                            name="providerStatusId"
                            value={editPractice.providerStatusId?.toString()}
                            options={providerStatusOptions}
                            error={errors.providerStatusId}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Group Provider Name</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="groupName"
                            name="groupName"
                            placeholder="Group Provider Name"
                            value={editPractice.groupName}
                            error={errors.groupName}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Place Code</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="odsCode"
                            name="odsCode"
                            placeholder="Place Code"
                            value={editPractice.odsCode}
                            error={errors.odsCode}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Primary Care Network (PCN)</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="pcn"
                            name="pcn"
                            placeholder="Primary Care Network (PCN)"
                            value={editPractice.pcn}
                            error={errors.pcn}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>PCN Code</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="pcnCode"
                            name="pcnCode"
                            placeholder="PCN Code"
                            value={editPractice.pcnCode}
                            error={errors.pcnCode}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Is Contract Active?</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <SelectInputBase id="isActive"
                            name="isActive"
                            onChange={handleChange}
                            value={editPractice.isActive ? "true" : "false"}
                            options={[
                                { id: "true", name: "Yes" },
                                { id: "false", name: "No" },
                            ]}
                        />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>
                {editPractice.isActive === false && <SummaryListBaseRow>
                    <SummaryListBaseKey>Date of Closure</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <DateInputBase
                            id="dateOfClosure"
                            name="dateOfClosure"
                            value={editPractice.dateOfClosure ? moment(editPractice?.dateOfClosure).format("YYYY-MM-DD") : ""}
                            required={editPractice.isActive ? false : true}
                            type="date"
                            error={errors.dateOfClosure}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                    <SummaryListBaseAction></SummaryListBaseAction>
                </SummaryListBaseRow>
                }

            </SummaryListBase>

            {mode === "ADD" && (
                <div>
                    <Link to={'/practices/'}>
                        <ButtonBase onClick={() => { }} add>Cancel</ButtonBase>
                    </Link>
                    <SecuredComponents allowedRoles={securityPoints.practices.add}>
                        <ButtonBase onClick={handleSave} add>Add</ButtonBase>
                    </SecuredComponents>
                </div>
            )}

            {mode !== "ADD" && (
                <div>
                    <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>
                    <SecuredComponents allowedRoles={securityPoints.practices.edit}>
                        <ButtonBase onClick={handleUpdate} edit>Update</ButtonBase>
                    </SecuredComponents>
                </div>
            )}

        </>
    );
}

export default PracticeDetailCardEdit;
