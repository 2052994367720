import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { PropertyStatusView } from "../../models/views/components/propertyStatuses/propertyStatusView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { toastSuccess } from "../../brokers/toastBroker";

interface PropertyStatusRowDeleteProps {
    propertyStatus: PropertyStatusView;
    onCancel: (value: string) => void;
    onDelete: (propertyStatus: PropertyStatusView) => void;
}

const PropertyStatusRowDelete: FunctionComponent<PropertyStatusRowDeleteProps> = (props) => {
    const {
        propertyStatus,
        onCancel,
        onDelete
    } = props;

    const handleDelete = (propertyStatus: PropertyStatusView) => {
        onDelete(propertyStatus);
        toastSuccess(`${propertyStatus.name} Deleted`);
    }

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{propertyStatus.name}</b><br />
                {propertyStatus.description}<br />
            </TableBaseData>
            <TableBaseData classes="text-end">
                {propertyStatus.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="grey" title="Inactive" /> }
                {propertyStatus.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel('VIEW')} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleDelete(propertyStatus)} remove>Yes, Delete</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default PropertyStatusRowDelete;
