import { ErrorBase } from "../../types/ErrorBase";

export interface IRentReviewErrors extends ErrorBase {
    reviewStageId: string;
    reviewStatusId: string;
    rentReviewDueDate: string;
    agreedRentAmount: string;
    nextRentReview: string;
    nextRentAmount: string;
    repairAllowance: string;
    pcifImprovementGrantAmountApplicable: string;
    valuationDecision: string;
    districtValuerName: string;
    districtValuerEmail: string;
    districtValuerPoNumber: string;
    districtValuerRefNo: string;
    districtValuerDeadlineDate: string;
    isSilentRentReview: string;
    isActive: string;
}

export const rentReviewErrors: IRentReviewErrors = {
    hasErrors: false,
    reviewStageId: "",
    reviewStatusId: "",
    rentReviewDueDate: "",
    agreedRentAmount: "",
    nextRentReview: "",
    nextRentAmount: "",
    repairAllowance: "",
    pcifImprovementGrantAmountApplicable: "",
    valuationDecision: "",
    districtValuerName: "",
    districtValuerEmail: "",
    districtValuerPoNumber: "",
    districtValuerRefNo: "",
    districtValuerDeadlineDate: "",
    isSilentRentReview: "",
    isActive: ""
};