import { Guid } from "guid-typescript";

import { SiteType } from "../models/siteTypes/siteType";
import ApiBroker from "./apiBroker";

class SiteTypeBroker {

    relativeSiteTypeUrl = '/api/siteTypes';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostSiteTypeAsync(siteType: SiteType) {
        return await this.apiBroker.PostAsync(this.relativeSiteTypeUrl, siteType)
            .then(result => new SiteType(result.data));
    }

    async GetAllSiteTypesAsync(queryString: string) {
        var url = this.relativeSiteTypeUrl + queryString;
        
        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((siteType: any) => new SiteType(siteType)));
    }

    async GetSiteTypeByIdAsync(id: Guid) {
        const url = `${this.relativeSiteTypeUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new SiteType(result.data));
    }

    async PutSiteTypeAsync(siteType: SiteType) {
        return await this.apiBroker.PutAsync(this.relativeSiteTypeUrl, siteType)
            .then(result => new SiteType(result.data));
    }

    async DeleteSiteTypeByIdAsync(id: Guid) {
        const url = `${this.relativeSiteTypeUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new SiteType(result.data));
    }
}

export default SiteTypeBroker;



