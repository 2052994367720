import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { LeaseTypeView } from "../../models/views/components/leaseTypes/leaseTypeView";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { leaseTypeErrors } from "./leaseTypeErrors";
import { leaseTypeValidations } from "./leaseTypeValidations";

interface LeaseTypeRowEditProps {
    leaseType: LeaseTypeView;
    onCancel: () => void;
    onEdit: (leaseType: LeaseTypeView) => void;
    apiError?: any;
}

const LeaseTypeRowEdit: FunctionComponent<LeaseTypeRowEditProps> = (props) => {
    const {
        leaseType,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editLeaseType, setEditLeaseType] = useState<LeaseTypeView>({ ...leaseType });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(leaseTypeErrors, leaseTypeValidations, editLeaseType);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const SpdatedleaseType = {
            ...editLeaseType,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditLeaseType(SpdatedleaseType);
    };

    const handleSave = () => {
        if (!validate(editLeaseType)) {
            onEdit(editLeaseType);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Lease Type Name"
                    placeholder="Lease Type Name"
                    value={editLeaseType.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Lease Type Description"
                    placeholder="LeaseType Description"
                    value={editLeaseType.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={editLeaseType.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={editLeaseType.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default LeaseTypeRowEdit;
