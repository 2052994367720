import { Guid } from 'guid-typescript';

export class LeaseDocument {
    public id: Guid;
    public leaseId?: Guid | null;
    public rentReviewId?: Guid | null;
    public label?: string;
    public description?: string;
    public contentType?: string;
    public fileName?: string;
    public file?: File;
    public storageUrl?: string;
    public isActive?: boolean;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(document: any) {
        this.id = document.id ? Guid.parse(document.id) : Guid.parse(Guid.EMPTY);
        this.leaseId = document.leaseId ? Guid.parse(document.leaseId) : null;
        this.rentReviewId = document.rentReviewId ? Guid.parse(document.rentReviewId) : null;
        this.label = document.label || "";
        this.description = document.description || "";
        this.contentType = document.contentType;
        this.fileName = document.fileName;
        this.storageUrl = document.storageUrl;
        this.isActive = document.isActive;
        this.createdByUser = document.createdByUser
        this.createdDate = new Date(document.createdDate)
        this.updatedByUser = document.updatedByUser
        this.updatedDate = new Date(document.updatedDate)
    }
}