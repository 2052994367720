import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { ProviderStatus } from "../../../models/providerStatuses/providerStatus";
import { ProviderStatusView } from "../../../models/views/components/providerStatuses/providerStatusView";
import { providerStatusService } from "../../foundations/providerStatusService";

export const providerStatusViewService = {

    useCreateProviderStatus: () => {
        return providerStatusService.useCreateProviderStatus();
    },

    useGetAllProviderStatuses: (searchTerm?: string) => {
        try {
            let query = '?$orderby=sortOrder,name';

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`;
            }

            const response = providerStatusService.useGetAllProviderStatuses(query);
            const [mappedProviderStatuses, setMappedProviderStatuses] = useState<Array<ProviderStatusView>>();

            useEffect(() => {
                if (response.data) {
                    const providerStatuses = response.data.map((providerStatus: ProviderStatus) =>
                        new ProviderStatusView(
                            providerStatus.id,
                            providerStatus.name,
                            providerStatus.description,
                            providerStatus.isActive,
                            providerStatus.sortOrder,
                            providerStatus.createdByUser,
                            providerStatus.createdDate,
                            providerStatus.updatedByUser,
                            providerStatus.updatedDate));

                    setMappedProviderStatuses(providerStatuses);
                }
            }, [response.data]);

            return {
                mappedProviderStatuses, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetProviderStatusById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = providerStatusService.useGetAllProviderStatuses(query);
            const [mappedProviderStatus, setMappedProviderStatus] = useState<ProviderStatusView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const providerStatus = new ProviderStatusView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].description,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate);

                    setMappedProviderStatus(providerStatus);
                }
            }, [response.data]);

            return {
                mappedProviderStatus, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateProviderStatus: () => {
        return providerStatusService.useUpdateProviderStatus();
    },

    useRemoveProviderStatus: () => {
        return providerStatusService.useDeleteProviderStatus();
    },
}