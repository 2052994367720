import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { Site } from "../../../models/sites/site";
import { SiteView } from "../../../models/views/components/site/siteView";
import { siteService } from "../../foundations/siteService";

export const siteViewService = {

    useCreateSite: () => {
        return siteService.useCreateSite();
    },

    useGetAllSites: (searchTerm?: string) => {
        try {
            let query = `?$orderby=siteName`;

            if (searchTerm) {
                query = query + `&$filter=contains(siteName,'${searchTerm}') or contains(siteCode,'${searchTerm}')`
            }

            const response = siteService.useGetAllSites(query);
            const [mappedSites, setMappedSites] = useState<Array<SiteView>>();

            useEffect(() => {
                if (response.data) {
                    const sites = response.data.map((site: Site) => new SiteView(
                        site.id,
                        site.practiceId,
                        site.siteTypeId,
                        site.contractId,
                        site.ownershipId,
                        site.propertyStatusId,
                        site.closureDate,
                        site.siteName,
                        site.addressLine1,
                        site.addressLine2,
                        site.addressLine3,
                        site.postCode,
                        site.listSize,
                        site.totalSquareFootage,
                        site.isActive,
                        site.sortOrder,
                        site.createdByUser,
                        site.createdDate,
                        site.updatedByUser,
                        site.updatedDate,
                        site.practiceName,
                        site.siteTypeName,
                        site.contractName,
                        site.ownershipName,
                        site.propertyStatusName
                    ));

                    setMappedSites(sites);
                }
            }, [response.data]);

            return {
                mappedSites, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetSiteById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}` +
                `&$expand=practice($select=id,practiceName,practiceCode)` +
                `&$expand=siteType($select=id,name)` +
                `&$expand=contract($select=id,name)` +
                `&$expand=ownership($select=id,name)` +
                `&$expand=propertyStatus($select=id,name)`;

            const response = siteService.useGetAllSites(query);
            const [mappedSite, setMappedSite] = useState<SiteView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const site = new SiteView(
                        response.data[0].id,
                        response.data[0].practiceId,
                        response.data[0].siteTypeId,
                        response.data[0].contractId,
                        response.data[0].ownershipId,
                        response.data[0].propertyStatusId,
                        response.data[0].closureDate,
                        response.data[0].siteName,
                        response.data[0].addressLine1,
                        response.data[0].addressLine2,
                        response.data[0].addressLine3,
                        response.data[0].postCode,
                        response.data[0].listSize,
                        response.data[0].totalSquareFootage,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate,
                        response.data[0].practiceName,
                        response.data[0].practiceCode,
                        response.data[0].siteTypeName,
                        response.data[0].contractName,
                        response.data[0].ownershipName,
                        response.data[0].propertyStatusName);

                        setMappedSite(site)
                }
            }, [response.data]);

            return {
                mappedSite, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateSite: () => {
        return siteService.useUpdateSite();
    },

    useRemoveSite: () => {
        return siteService.useDeleteSite();
    },
}