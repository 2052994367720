import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-typescript";
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react"
import { useValidation } from "../../hooks/useValidation";
import { LeaseDocument } from "../../models/leaseDocuments/leaseDocument";
import securityPoints from "../../SecurityMatrix";
import { documentService } from "../../services/foundations/documentService";
import { documentViewService } from "../../services/views/documents/documentViewService";
import { SecuredComponents } from "../Links";
import DocumentDetailsCard from "./documentDetailsCard";
import DocumentDetailsCardNew from "./documentDetailsCardNew";
import { documentErrors } from "./documentsErrors";
import { documentValidations } from "./documentsValidations";
import { Button } from "react-bootstrap";

type DocumentDetailsProps = {
    leaseId?: Guid | undefined,
    rentReviewId?: Guid | undefined,
}

const DocumentDetails: FunctionComponent<DocumentDetailsProps> = ({ leaseId, rentReviewId }) => {

    const [attaching, setAttaching] = useState(false);

    const [newDocument, setNewDocument] = useState<LeaseDocument>(
        new LeaseDocument(
            {
                leaseId: leaseId?.toString(),
                rentReviewId: rentReviewId?.toString(), name: '', description: ''
            }));

    const { mappedDocuments } = documentViewService.useGetAllDocumentsForLease(leaseId, rentReviewId);
    const { mutate: deleteDocument } = documentService.useDeleteDocument();
    const [openDocument, setOpenDocument] = useState(Guid.createEmpty());
    const { data: documentUrl } = documentService.useGetDocumentUrl(openDocument);
    const mutation = documentService.useCreateDocument();
    const { errors, enableValidationMessages, validate } = useValidation(documentErrors, documentValidations, newDocument);

    const handleChange = (name: string, value: string) => {
        const nextLeaseDocument = {
            ...newDocument,
            [name]: value
        };

        setNewDocument(nextLeaseDocument);
        //validate(nextLeaseDocument);
    }

    const saveDocument = () => {
        //var errors = formIsValid(newDocument);
        if (!validate(newDocument)) {
            mutation.mutate(newDocument);

            setNewDocument(new LeaseDocument(
                {
                    leaseId: leaseId?.toString(),
                    rentReviewId: rentReviewId?.toString(),
                    name: '',
                    description: ''
                }));

            setAttaching(false);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        if (documentUrl) {
            var link = document.createElement("a");
            link.setAttribute('download', '');
            link.href = documentUrl?.data;
            document.body.appendChild(link);
            link.click();
            link.remove();
            setOpenDocument(Guid.createEmpty());
        }
    }, [documentUrl])

    const handleOpenDocument = (leaseDocument: LeaseDocument) => {
        setOpenDocument(leaseDocument.id);
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.files) {
            let nextDocument = {
                ...newDocument,
                label: newDocument.label || event.target.files[0].name,

            };

            if (event?.target?.files.length) {
                nextDocument = {
                    ...nextDocument,
                    fileName: event.target.files[0].name,
                    contentType: event.target.files[0].type || "unknown",
                    file: event.target.files[0]
                }
            } else {
                nextDocument = {
                    ...nextDocument,
                    fileName: undefined,
                    contentType: undefined,
                    file: undefined
                }
            }

            //validate(nextDocument);
            setNewDocument(nextDocument);
        }
    }

    const handleCancel = () => {
        setNewDocument(new LeaseDocument(
            {
                leaseId: leaseId?.toString(),
                rentReviewId: rentReviewId?.toString(),
                name: '',
                description: ''
            }));

        setAttaching(false)
    }

    const handleDelete = (id: Guid) => {
        deleteDocument(id);
    }

    return <>
        <div className="mt-3">
            <SecuredComponents allowedRoles={securityPoints.documents.add}>
                <>
                    <Button id="attachDocument" onClick={() => { setAttaching(true) }} size="sm">
                        <FontAwesomeIcon icon={faPaperclip} />&nbsp;Attach Document
                    </Button>
                </>
            </SecuredComponents>

            {attaching && <DocumentDetailsCardNew
                documentToAdd={newDocument}
                handleChange={handleChange}
                handleCancel={handleCancel}
                handleUpload={saveDocument}
                handleFileChange={handleFileChange}
                errors={errors}
            />}
            <DocumentDetailsCard documents={mappedDocuments} handleDeleteDocument={handleDelete} handleOpenDocument={handleOpenDocument} />
        </div>
    </>
}

export default DocumentDetails;