import { ErrorBase } from "../../types/ErrorBase";

interface IReferenceValueErrors extends ErrorBase {
    hasErrors: boolean;
    name: string;
    referenceValueType: string;
    workFlowAction: string;
    description: string;
    isActive: string;
    sortOrder: string;
}

export const referenceValueErrors: IReferenceValueErrors = {
    hasErrors: false,
    name: "",
    referenceValueType: "",
    workFlowAction: "",
    description: "",
    isActive: "",
    sortOrder: ""
};