import React, { FunctionComponent, useState } from "react";
import { OwnershipView } from "../../models/views/components/ownerships/ownershipView";
import OwnershipRowView from "./ownershipRowView";
import OwnershipRowEdit from "./ownershipRowEdit";
import OwnershipRowDelete from "./ownershipRowDelete";

type OwnershipRowProps = {
    ownership: OwnershipView;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
    onUpdate: (ownership: OwnershipView) => void;
    onDelete: (ownership: OwnershipView) => void;
}

const OwnershipRow: FunctionComponent<OwnershipRowProps> = (props) => {
    const {
        ownership,
        allowedToEdit,
        allowedToDelete,
        onUpdate,
        onDelete,
    } = props;

    const [mode, setMode] = useState<string>('VIEW');
    const [apiError, setApiError] = useState<any>({});


    const handleMode = (value: string) => {
        setMode(value);
    };

    const handleUpdate = async (ownership: OwnershipView) => {
        try {
            await onUpdate(ownership);
            setMode('VIEW');
        } catch (error) {
            setApiError(error);
            setMode('EDIT');
        }
    };

    const handleDelete = (ownership: OwnershipView) => {
        onDelete(ownership);
        setMode('VIEW');
    };

    const handleCancel = () => {
        setMode('VIEW');
    };

    return (
        <>
            {mode !== 'EDIT' && mode !== 'DELETE' && (
                <OwnershipRowView
                    key={ownership.id.toString()}
                    ownership={ownership}
                    onEdit={handleMode}
                    onDelete={handleMode}
                    allowedToEdit={allowedToEdit}
                    allowedToDelete={allowedToDelete} />
            )}

            {mode === 'EDIT' && (
                <OwnershipRowEdit
                    key={ownership.id.toString()}
                    ownership={ownership}
                    onCancel={handleCancel}
                    onEdit={handleUpdate}
                    apiError={apiError}
                />
            )}

            {mode === 'DELETE' && (
                <OwnershipRowDelete
                    key={ownership.id.toString()}
                    ownership={ownership}
                    onCancel={handleCancel}
                    onDelete={handleDelete} />
            )}
        </>
    );
}

OwnershipRow.defaultProps = {
    allowedToEdit: false,
    allowedToDelete: false
};

export default OwnershipRow;