import { LeaseActions } from "../../../../models/leaseActions/leaseActions";
import { RentReviewActions } from "../../../../models/rentReviewActions/rentReviewActions";
import { ActionView } from "../../../../models/views/components/actions/actionView";
import { getRagStatus, getRagStatusForState } from "./getRAGColour";

export const getLeasesExpiring = (leaseExpiryActions: any, next: ActionView[]) => {

    const leaseActions = leaseExpiryActions.map((lease: LeaseActions) =>
        new ActionView(
            lease.id,
            lease.leaseEndDate,
            "Lease",
            "Lease Expiry Date",
            getRagStatus(lease.leaseEndDate),
            lease.site?.practice.id,
            lease.site?.practice.practiceName,
            lease.site?.practice.practiceCode,
            lease.site?.id,
            lease.site?.siteName,
            lease.site?.practice.borough.name,
        ));

    return [...next, ...leaseActions]
}

export const getRentReviewDue = (rentReviewDueActions: any, next: ActionView[]) => {
    const RentRevActions = rentReviewDueActions.map((rentReview: RentReviewActions) =>
        new ActionView(
            rentReview.id,
            rentReview.rentReviewDueDate,
            "RentReview",
            "Rent Review Due",
            getRagStatus(rentReview.rentReviewDueDate),
            rentReview.lease.site?.practice.id,
            rentReview.lease.site?.practice.practiceName,
            rentReview.lease.site?.practice.practiceCode,
            rentReview.lease.site?.id,
            rentReview.lease.site?.siteName,
            rentReview.lease.site?.practice.borough.name
        ));

    return [...next, ...RentRevActions]
}

export const getRentReviewDV = (rentReviewDVActions: any, next: ActionView[]) => {
    const rentReviewDV = rentReviewDVActions.map((rentReviewDv: RentReviewActions) =>
        new ActionView(
            rentReviewDv.id,
            rentReviewDv.reviewStageChangedDate,
            "RentReviewDV",
            "Rent Review DV",
            getRagStatusForState(rentReviewDv.reviewStageChangedDate,30),
            rentReviewDv.lease.site?.practice.id,
            rentReviewDv.lease.site?.practice.practiceName,
            rentReviewDv.lease.site?.practice.practiceCode,
            rentReviewDv.lease.site?.id,
            rentReviewDv.lease.site?.siteName,
            rentReviewDv.lease.site?.practice.borough.name
        ));

    return [...next, ...rentReviewDV]
}

export const getRentReviewPendingVal = (rentReviewPendingValActions: any, next: ActionView[]) => {
    const rentReviewPendingVal = rentReviewPendingValActions.map((rentReviewPendingVal: RentReviewActions) =>
        new ActionView(
            rentReviewPendingVal.id,
            rentReviewPendingVal.reviewStageChangedDate,
            "RentReviewPending",
            "Rent Review Pending Valuation",
            getRagStatusForState(rentReviewPendingVal.reviewStageChangedDate, 1),
            rentReviewPendingVal.lease.site?.practice.id,
            rentReviewPendingVal.lease.site?.practice.practiceName,
            rentReviewPendingVal.lease.site?.practice.practiceCode,
            rentReviewPendingVal.lease.site?.id,
            rentReviewPendingVal.lease.site?.siteName,
            rentReviewPendingVal.lease.site?.practice.borough.name
        ));

    return [...next, ...rentReviewPendingVal]
}

export const getRentReviewAgreedVal = (rentReviewAgreedValActions: any, next: ActionView[]) => {
    const rentReviewAgreedVal = rentReviewAgreedValActions.map((rentReviewAgreedVal: RentReviewActions) =>
        new ActionView(
            rentReviewAgreedVal.id,
            rentReviewAgreedVal.reviewStageChangedDate,
            "RentReviewAgreed",
            "Rent Review Agreed",
            getRagStatusForState(rentReviewAgreedVal.reviewStageChangedDate, 35),
            rentReviewAgreedVal.lease.site?.practice.id,
            rentReviewAgreedVal.lease.site?.practice.practiceName,
            rentReviewAgreedVal.lease.site?.practice.practiceCode,
            rentReviewAgreedVal.lease.site?.id,
            rentReviewAgreedVal.lease.site?.siteName,
            rentReviewAgreedVal.lease.site?.practice.borough.name
        ));

    return [...next, ...rentReviewAgreedVal]
}

export const getRentReviewDisputeVal = (rentReviewDisputeValActions: any, next: ActionView[]) => {
    const rentReviewDisputeVal = rentReviewDisputeValActions.map((rentReviewDisputeVal: RentReviewActions) =>
        new ActionView(
            rentReviewDisputeVal.id,
            rentReviewDisputeVal.reviewStageChangedDate,
            "RentReviewDispute",
            "Rent Review Dispute",
            getRagStatusForState(rentReviewDisputeVal.reviewStageChangedDate, 56),
            rentReviewDisputeVal.lease.site?.practice.id,
            rentReviewDisputeVal.lease.site?.practice.practiceName,
            rentReviewDisputeVal.lease.site?.practice.practiceCode,
            rentReviewDisputeVal.lease.site?.id,
            rentReviewDisputeVal.lease.site?.siteName,
            rentReviewDisputeVal.lease.site?.practice.borough.name
        ));

    return [...next, ...rentReviewDisputeVal]
}