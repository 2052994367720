import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { OwnershipView } from "../../models/views/components/ownerships/ownershipView";
import { Guid } from "guid-typescript";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { ownershipErrors } from "./ownershipErrors";
import { ownershipValidations } from "./ownershipValidations";

interface OwnershipRowAddProps {
    onCancel: () => void;
    onAdd: (ownership: OwnershipView) => void;
    apiError?: any;
}

const OwnershipRowAdd: FunctionComponent<OwnershipRowAddProps> = (props) => {
    const {
        onCancel,
        onAdd,
        apiError
    } = props;

    const [ownership, setOwnership] = useState<OwnershipView>(new OwnershipView(Guid.create()));

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(ownershipErrors, ownershipValidations, ownership);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const addOwnership = {
            ...ownership,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setOwnership(addOwnership);
    };

    const handleSave = () => {
        if (!validate(ownership)) {
            onAdd(ownership);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Ownership Name"
                    placeholder="Ownership Name"
                    value={ownership.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Ownership Description"
                    placeholder="Ownership Description"
                    value={ownership.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={ownership.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={ownership.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} add>Add</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default OwnershipRowAdd;
