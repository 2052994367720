import { ErrorBase } from "../../types/ErrorBase";

interface IContractErrors extends ErrorBase
{
    hasErrors: boolean;
    name: string;
    description: string;
    isActive: string;
    sortOrder: string;
}

export const contractErrors: IContractErrors = {
    hasErrors: false,
    name: "",
    description: "",
    isActive: "",
    sortOrder: ""
};