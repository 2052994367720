import { Guid } from 'guid-typescript';

export class Ownership {
    public id: Guid;
    public name?: string;
    public description?: string;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(ownership: any) {
        this.id = ownership.id ? Guid.parse(ownership.id) : Guid.parse(Guid.EMPTY);
        this.name = ownership.name;
        this.description = ownership.description;
        this.isActive = ownership.isActive === true ? true : false;
        this.sortOrder = ownership.sortOrder;
        this.createdByUser = ownership.createdByUser
        this.createdDate = new Date(ownership.createdDate)
        this.updatedByUser = ownership.updatedByUser
        this.updatedDate = new Date(ownership.updatedDate)
    }
}