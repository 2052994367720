import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { ReviewStageView } from "../../models/views/components/reviewStages/reviewStageView";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { reviewStageErrors } from "./reviewStageErrors";
import { reviewStageValidations } from "./reviewStageValidations";

interface ReviewStageRowEditProps {
    reviewStage: ReviewStageView;
    onCancel: () => void;
    onEdit: (reviewStage: ReviewStageView) => void;
    apiError?: any;
}

const ReviewStageRowEdit: FunctionComponent<ReviewStageRowEditProps> = (props) => {
    const {
        reviewStage,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editReviewStage, setEditReviewStage] = useState<ReviewStageView>({ ...reviewStage });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(reviewStageErrors, reviewStageValidations, editReviewStage);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const UpdatedReviewStage = {
            ...editReviewStage,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditReviewStage(UpdatedReviewStage);
    };

    const handleCancel = () => {
        setEditReviewStage({ ...reviewStage });
        onCancel();
    };

    const handleSave = () => {
        if (!validate(editReviewStage)) {
            onEdit(editReviewStage);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Review Stage Name"
                    placeholder="Review Stage Name"
                    value={editReviewStage.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Review Stage Description"
                    placeholder="Review Stage Description"
                    value={editReviewStage.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={editReviewStage.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={editReviewStage.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReviewStageRowEdit;
