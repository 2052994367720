import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { BoroughView } from "../../models/views/components/boroughs/boroughView";
import { Guid } from "guid-typescript";
import TextInputBase from "../bases/inputs/TextInputBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import { useValidation } from "../../hooks/useValidation";
import { boroughErrors } from "./boroughErrors";
import { boroughValidations } from "./boroughValidations";

interface BoroughRowAddProps {
    onCancel: () => void;
    onAdd: (borough: BoroughView) => void;
    apiError?: any;
}

const BoroughRowAdd: FunctionComponent<BoroughRowAddProps> = (props) => {
    const {
        onCancel,
        onAdd,
        apiError
    } = props;

    const [borough, setBorough] = useState<BoroughView>(new BoroughView(Guid.create()));

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(boroughErrors, boroughValidations, borough);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const addBorough = {
            ...borough,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setBorough(addBorough);
    };

    const handleAdd = () => {
        if (!validate(borough)) {
            onAdd(borough);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Borough Name"
                    placeholder="Borough Name"
                    value={borough.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
            </TableBaseData>

            <TableBaseData>
                 <TextInputBase
                    id="financeManagerName"
                    name="financeManagerName"
                    label="Finance Manager Name"
                    placeholder="Manager Name"
                    value={borough.financeManagerName}
                    error={errors.financeManagerName}
                    onChange={handleChange} />
            </TableBaseData>

            <TableBaseData>
                 <TextInputBase
                    id="financeManagerEmail"
                    name="financeManagerEmail"
                    label="Finance Manager Email"
                    placeholder="Manager Email"
                    value={borough.financeManagerEmail}
                    error={errors.financeManagerEmail}
                    onChange={handleChange} />
            </TableBaseData>


            <TableBaseData >
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleAdd()} disabled={errors.hasErrors} add>Add</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default BoroughRowAdd;
