import { Guid } from "guid-typescript";
import { ReferenceValue } from "../models/referenceValues/referenceValue";
import ApiBroker from "./apiBroker";
import { AxiosResponse } from "axios";

class ReferenceValueBroker {

    relativeReferenceValueUrl = '/api/referenceValues';

    private apiBroker: ApiBroker = new ApiBroker();

    private processOdataResult = (result: AxiosResponse) => {
        const data = result.data.value.map((referenceValue: any) => new ReferenceValue(referenceValue));

        //const nextPage = result.data['@odata.nextLink'];
        return { data }
    }

    async PostReferenceValueAsync(referenceValue: ReferenceValue) {
        return await this.apiBroker.PostAsync(this.relativeReferenceValueUrl, referenceValue)
            .then(result => new ReferenceValue(result.data));
    }

    async GetAllReferenceValuesAsync(queryString: string) {
        var url = this.relativeReferenceValueUrl + queryString;
        
        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((referenceValue: any) => new ReferenceValue(referenceValue)));
    }

    async GetReferenceValueByIdAsync(id: Guid) {
        const url = `${this.relativeReferenceValueUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new ReferenceValue(result.data));
    }

    async PutReferenceValueAsync(referenceValue: ReferenceValue) {
        return await this.apiBroker.PutAsync(this.relativeReferenceValueUrl, referenceValue)
            .then(result => new ReferenceValue(result.data));
    }

    async DeleteReferenceValueByIdAsync(id: Guid) {
        const url = `${this.relativeReferenceValueUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new ReferenceValue(result.data));
    }

    async LookUpOdataReferenceValue(query: string) {
        var url = this.relativeReferenceValueUrl + query;
        return this.processOdataResult(await this.apiBroker.GetAsync(url));
    }
}

export default ReferenceValueBroker;



