import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ReviewStageView } from "../../models/views/components/reviewStages/reviewStageView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import securityPoints from "../../SecurityMatrix";
import { SecuredComponents } from "../Links";

interface ReviewStageRowViewProps {
    reviewStage: ReviewStageView;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
    onEdit: (value: string) => void;
    onDelete: (value: string) => void;
}

const ReviewStageRowView: FunctionComponent<ReviewStageRowViewProps> = (props) => {
    const {
        reviewStage,
        allowedToEdit,
        allowedToDelete,
        onEdit,
        onDelete
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{reviewStage.name}</b><br />
                {reviewStage.description}<br />
                {reviewStage.workflowAction && <> 
                    <i>Workflow Action: {reviewStage.workflowAction}</i>
                </>}
            </TableBaseData>
            <TableBaseData>
                {reviewStage.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="red" title="Inactive" />}
                {reviewStage.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                {allowedToEdit && (
                    <SecuredComponents allowedRoles={securityPoints.reviewStages.edit}>
                        <ButtonBase onClick={() => onEdit('EDIT')} edit>Edit</ButtonBase>
                    </SecuredComponents>
                )}
                &nbsp;
                {allowedToDelete && (
                    <SecuredComponents allowedRoles={securityPoints.reviewStages.delete}>
                        <ButtonBase onClick={() => onDelete('DELETE')} remove>Delete</ButtonBase>
                    </SecuredComponents>
                )}
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReviewStageRowView;
