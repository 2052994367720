import { Guid } from 'guid-typescript';
import { useEffect, useState } from 'react';
import { LeaseDocument } from '../../../models/leaseDocuments/leaseDocument';
import { DocumentView } from "../../../models/views/components/documents/documentView";
import { documentService } from "../../foundations/documentService";

export const documentViewService = {

    useCreateDocument: () => {
        return documentService.useCreateDocument();
    },

    useGetAllDocumentsForLease: (leaseId?: Guid, rentReviewId?: Guid) => {

        let query = `?$filter=`;

        if (leaseId !== undefined)
        {
            query += `leaseId eq ${leaseId}`;
        }
        else
        {
            query += `rentReviewId eq ${rentReviewId}`;
        }

        const response = documentService.useGetAllDocuments(query);
        const [mappedDocuments, setMappedDocuments] = useState<DocumentView[]>([]);

        useEffect(() => {
            if (response.data) {
                const documents = response.data.map((document: LeaseDocument) =>
                    new DocumentView(
                        document.id,
                        document.leaseId,
                        document.rentReviewId,
                        document.label,
                        document.description,
                        document.contentType,
                        document.fileName,
                        document.storageUrl,
                        document.isActive,
                        document.createdByUser,
                        document.createdDate,
                        document.updatedByUser,
                        document.updatedDate
                    ));

                setMappedDocuments(documents);
            }
        }, [response.data]);

        return {
            mappedDocuments, ...response
        }
    },

    useGetAllDocuments: (searchTerm?: string) => {
        try {
            let query = `?`;

            if (searchTerm) {
                query = query + `&$filter=contains(documentName,'${searchTerm}')`;
            }

            const response = documentService.useGetAllDocuments(query);
            const [mappedDocuments, setMappedDocuments] = useState<DocumentView>();

            useEffect(() => {
                if (response.data) {
                    const documents = response.data.map((document: LeaseDocument) =>
                        new DocumentView(
                            document.id,
                            document.leaseId,
                            document.rentReviewId,
                            document.label,
                            document.description,
                            document.contentType,
                            document.fileName,
                            document.storageUrl,
                            document.isActive,
                            document.createdByUser,
                            document.createdDate,
                            document.updatedByUser,
                            document.updatedDate
                        ));

                    setMappedDocuments(documents);
                }
            }, [response.data]);

            return {
                mappedDocuments, ...response
            }

        } catch (err) {
            throw err;
        }
    },

    useGetDocumentById: (id: Guid) => {
        try {
            const query = `?$expand=borough&$expand=sites&$filter=id eq ${id}`
            const response = documentService.useGetAllDocuments(query);
            const [mappedDocument, setMappedDocument] = useState<DocumentView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const document = new DocumentView(
                        response.data[0].id,
                        response.data[0].leaseId,
                        response.data[0].rentReviewId,
                        response.data[0].label,
                        response.data[0].description,
                        response.data[0].contentType,
                        response.data[0].fileName,
                        response.data[0].storageUrl,
                        response.data[0].isActive,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate);

                    setMappedDocument(document);
                }
            }, [response.data]);

            return {
                mappedDocument, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useRemoveDocument: () => {
        return documentService.useDeleteDocument();
    },
}