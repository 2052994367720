import { ErrorBase } from "../../types/ErrorBase";

interface IContactTypeErrors extends ErrorBase {
    hasErrors: boolean;
    name: string;
    description: string;
    isActive: string;
    sortOrder: string;
}

export const contactTypeErrors: IContactTypeErrors = {
    hasErrors: false,
    name: "",
    description: "",
    isActive: "",
    sortOrder: "",
}