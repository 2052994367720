import { Guid } from 'guid-typescript';
import { Borough } from '../boroughs/borough';
import { ProviderStatus } from '../providerStatuses/providerStatus';
import { Site } from '../sites/site';

export class Practice {
    public id: Guid;
    public boroughId?: Guid;
    public practiceName?: string;
    public practiceCode?: string;
    public odsCode?: string;
    public pcn?: string;
    public pcnCode?: string;
    public groupName?: string;
    public practicePhone?: string;
    public practicePhoneBypass?: string;
    public practiceGenericEmail?: string;
    public providerStatusId?: Guid;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;
    public borough?: Borough;
    public sites?: Array<Site>;
    public providerStatus?: ProviderStatus;
    public dateOfClosure?: Date;


    constructor(practice: any) {
        this.id = practice.id ? Guid.parse(practice.id) : Guid.parse(Guid.EMPTY);
        this.boroughId = practice.boroughId ? Guid.parse(practice.boroughId) : Guid.parse(Guid.EMPTY);
        this.practiceName = practice.practiceName || "";
        this.practiceCode = practice.practiceCode || "";
        this.odsCode = practice.odsCode || "";
        this.pcn = practice.pcn || "";
        this.pcnCode = practice.pcnCode || "";
        this.groupName = practice.groupName || "";
        this.practicePhone = practice.practicePhone || "";
        this.practicePhoneBypass = practice.practicePhoneBypass || "";
        this.practiceGenericEmail = practice.practiceGenericEmail || "";
        this.providerStatusId = practice.providerStatusId?Guid.parse(practice.providerStatusId) : Guid.parse(Guid.EMPTY);
        this.isActive = practice.isActive;
        this.sortOrder = practice.sortOrder;
        this.createdByUser = practice.createdByUser || ""
        this.createdDate = practice.createdDate ? new Date(practice.createdDate) : new Date();
        this.updatedByUser = practice.updatedByUser || ""
        this.updatedDate = practice.updatedDate ? new Date(practice.updatedDate) : new Date();
        this.dateOfClosure = practice.dateOfClosure ? new Date(practice.dateOfClosure) : undefined;

        if (practice.borough !== undefined && practice.borough !== null) {
            this.borough = new Borough(practice.borough);
        }

        if (practice.sites !== undefined && practice.sites !== null) {
            this.sites = practice?.sites.map((site: Site) => new Site(site));
        }

        if (practice.providerStatus !== undefined && practice.providerStatus !== null) {
            this.providerStatus = new ProviderStatus(practice.providerStatus);
        }
    }
}