import { Guid } from 'guid-typescript';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Lease } from "../../../models/leases/lease";
import { LeaseView } from "../../../models/views/components/leases/leaseView";
import { leaseService } from "../../foundations/leaseService";

export const leaseViewService = {

    useCreateLease: () => {
        return leaseService.useCreateLease();
    },

    useGetAllLeases: (searchTerm?: string) => {
        try {
            let query = `?$expand=site&$orderby=leaseEndDate asc`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = leaseService.useGetAllLeases(query);
            const [mappedLeases, setMappedLeases] = useState<Array<LeaseView>>([]);

            useEffect(() => {
                if (response.data) {
                    const leases = response.data.map((lease: Lease) =>
                        new LeaseView(
                            lease.id,
                            lease.siteId,
                            lease.leaseTypeId,
                            lease.landlordDetails,
                            lease.agreedLeaseAmount,
                            lease.leaseStartDate,
                            lease.leaseEndDate,
                            lease.isActive,
                            lease.createdByUser,
                            lease.createdDate,
                            lease.updatedByUser,
                            lease.updatedDate,
                            lease.site?.practiceId,
                            lease.leaseTypeName,
                            lease.repairAllowanceTypeId,
                            lease.repairAllowanceTypeName,
                            lease.landlordTypeId,
                            lease.landlordTypeName,
                            lease.tenancyTypeId,
                            lease.tenancyTypeName,
                            lease.isSignedUpToS52Id,
                            lease.isSignedUpToS52Name,
                            lease.leaseProgressTypeId,
                            lease.leaseProgressTypeName,
                            lease.leaseStatusTypeId,
                            lease.leaseStatusTypeName,
                            lease.dvDeadlineDate
                        ));

                    setMappedLeases(leases);
                }
            }, [response.data]);

            return {
                mappedLeases, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetLeaseById: (leaseId: Guid, enabled: boolean = true) => {
        try {
            let query = `?$filter=id eq ${leaseId}`
                + `&$orderby=leaseEndDate asc`
                + `&$expand=leaseType($select=id,name)`
                + `&$expand=repairAllowanceType($select=id,name)`
                + `&$expand=landlordType($select=id,name)`
                + `&$expand=tenancyType($select=id,name)`
                + `&$expand=IsSignedUpToS52Type($select=id,name)`
                + `&$expand=leaseProgressType($select=id,name)`
                + `&$expand=leaseStatusType($select=id,name)`;

            const response = leaseService.useGetAllLeases(query, enabled);
            const [mappedLease, setMappedLease] = useState<LeaseView>();

            useEffect(() => {
                if (response.data) {
                    const lease = new LeaseView(
                        response.data[0].id,
                        response.data[0].siteId,
                        response.data[0].leaseTypeId,
                        response.data[0].landlordDetails,
                        response.data[0].agreedLeaseAmount,
                        response.data[0].leaseStartDate,
                        response.data[0].leaseEndDate,
                        response.data[0].isActive,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate,
                        response.data[0].practiceId,
                        response.data[0].leaseTypeName,
                        response.data[0].repairAllowanceTypeId,
                        response.data[0].repairAllowanceTypeName,
                        response.data[0].landlordTypeId,
                        response.data[0].landlordTypeName,
                        response.data[0].tenancyTypeId,
                        response.data[0].tenancyTypeName,
                        response.data[0].isSignedUpToS52Id,
                        response.data[0].isSignedUpToS52Name,
                        response.data[0].leaseProgressTypeId,
                        response.data[0].leaseProgressTypeName,
                        response.data[0].leaseStatusTypeId,
                        response.data[0].leaseStatusTypeName,
                        response.data[0].dvDeadlineDate);

                    setMappedLease(lease);
                }
            }, [response.data]);

            return {
                mappedLease, ...response
            }

        } catch (err) {
            throw err;
        }
    },

    useGetLeasesBySiteId: (siteId: Guid, searchTerm?: string) => {
        try {
            let query = `?$orderby=leaseEndDate asc`;
            if (siteId) {
                query = query + `&$filter=siteId eq ` + siteId;
            }

            if (searchTerm) {
                query = query + ` and contains(name,'${searchTerm}')`
            }

            query += `&$expand=tenancyType($select=id,name)`

            const response = leaseService.useGetAllLeases(query);
            const [mappedLeases, setMappedLeases] = useState<Array<LeaseView>>([]);

            useEffect(() => {
                if (response.data) {
                    const leases = response.data.map((lease: Lease) =>
                        new LeaseView(
                            lease.id,
                            lease.siteId,
                            lease.leaseTypeId,
                            lease.landlordDetails,
                            lease.agreedLeaseAmount,
                            lease.leaseStartDate,
                            lease.leaseEndDate,
                            lease.isActive,
                            lease.createdByUser,
                            lease.createdDate,
                            lease.updatedByUser,
                            lease.updatedDate,
                            lease.site?.practiceId,
                            lease.leaseTypeName,
                            lease.repairAllowanceTypeId,
                            lease.repairAllowanceTypeName,
                            lease.landlordTypeId,
                            lease.landlordTypeName,
                            lease.tenancyTypeId,
                            lease.tenancyTypeName,
                            lease.isSignedUpToS52Id,
                            lease.isSignedUpToS52Name,
                            lease.leaseProgressTypeId,
                            lease.leaseProgressTypeName,
                            lease.leaseStatusTypeId,
                            lease.leaseStatusTypeName,
                            lease.dvDeadlineDate
                        ));

                    setMappedLeases(leases);
                }
            }, [response.data]);

            return {
                mappedLeases, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetRecentLease: () => {
        try {
            const query = `?$top=1&$orderby=updatedDate`
            const response = leaseService.useGetAllLeases(query);
            const [mappedLease, setMappedLease] = useState<LeaseView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const lease = new LeaseView(
                        response.data[0].id,
                        response.data[0].siteId,
                        response.data[0].leaseTypeId,
                        response.data[0].landlordDetails,
                        response.data[0].agreedLeaseAmount,
                        response.data[0].leaseStartDate,
                        response.data[0].leaseEndDate,
                        response.data[0].isActive,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate,
                        response.data[0].practiceId,
                        response.data[0].leaseTypeName,
                        response.data[0].repairAllowanceTypeId,
                        response.data[0].repairAllowanceTypeName,
                        response.data[0].landlordTypeId,
                        response.data[0].landlordTypeName,
                        response.data[0].tenancyTypeId,
                        response.data[0].tenancyTypeName,
                        response.data[0].isSignedUpToS52Id,
                        response.data[0].isSignedUpToS52Name,
                        response.data[0].leaseProgressTypeId,
                        response.data[0].leaseProgressTypeName,
                        response.data[0].leaseStatusTypeId,
                        response.data[0].leaseStatusTypeName,
                        response.data[0].dvDeadlineDate);

                    setMappedLease(lease);
                }
            }, [response.data]);

            return {
                mappedLease, ...response
            }

        } catch (err) {
            throw err;
        }
    },

    useGetExpiringLeasesCount: () => {
        try {
            var futureMonth = moment().add(3, 'M').format('YYYY-MM-DD');
            let query = `?$filter=leaseEndDate lt ` + futureMonth;

            const response = leaseService.useGetAllLeases(query);
            const [mappedLeases, setMappedLeases] = useState<number>();

            useEffect(() => {
                if (response.data) {
                    var count = response.data.length;

                    setMappedLeases(count);
                }
            }, [response.data]);

            return {
                mappedLeases, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateLease: () => {
        return leaseService.useUpdateLease();
    },

    useRemoveLease: () => {
        return leaseService.useDeleteLease();
    },
}