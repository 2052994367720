import React, { FunctionComponent, useMemo, useState } from "react";
import SearchBase from "../bases/inputs/SearchBase";
import { debounce } from "lodash";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import ProviderStatusRowAdd from "./providerStatusRowAdd";
import ProviderStatusRow from "./providerStatusRow";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import { ProviderStatusView } from "../../models/views/components/providerStatuses/providerStatusView";
import { providerStatusViewService } from "../../services/views/providerStatuses/providerStatusViewService";
import ProviderStatusRowNew from "./providerStatusRowNew";
import TableBase from "../bases/components/Table/TableBase";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";
import TableBaseThead from "../bases/components/Table/TableBase.Thead";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseHeader from "../bases/components/Table/TableBase.Header";

type ProviderStatusTableProps = {
    allowedToAdd: boolean;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
}

const ProviderStatusTable: FunctionComponent<ProviderStatusTableProps> = (props) => {
    const {
        allowedToAdd,
        allowedToEdit,
        allowedToDelete,
    } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedProviderStatuses: providerStatusesRetrieved, isLoading } =
        providerStatusViewService.useGetAllProviderStatuses(debouncedTerm);

    const addProviderStatus = providerStatusViewService.useCreateProviderStatus();
    const updateProviderStatus = providerStatusViewService.useUpdateProviderStatus();
    const removeProviderStatus = providerStatusViewService.useRemoveProviderStatus();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [addApiError, setAddApiError] = useState<any>({});

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleAddState = () => {
        setAddMode(!addMode);
    };

    const handleAddNew = (providerStatus: ProviderStatusView) => {
        return addProviderStatus.mutate(providerStatus, {
            onSuccess: () => {
                setAddMode(false);
            },
            onError: (error: any) => {
                setAddApiError(error?.response?.data?.errors);
            }
        });
    };

    const handleUpdate = (providerStatus: ProviderStatusView) => {
        return updateProviderStatus.mutateAsync(providerStatus);
    }

    const handleDelete = (providerStatus: ProviderStatusView) => {
        return removeProviderStatus.mutateAsync(providerStatus.id);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , [])

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>
                        Provider Statuses
                    </CardBaseTitle>

                    <CardBaseContent>

                        <SearchBase id="search" label="Search provider statuses" value={searchTerm}
                            onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />

                            {isLoading && <> <SpinnerBase />.</>}

                            <TableBase>
                            <TableBaseThead>
                                <TableBaseRow>
                                    <TableBaseHeader>Provider Statuses</TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                </TableBaseRow>
                            </TableBaseThead>
                            <TableBaseTbody>
                                    {
                                        allowedToAdd &&
                                        <>
                                            {addMode === false && (<ProviderStatusRowNew onAdd={handleAddState} />)}

                                            {addMode === true && (
                                                <ProviderStatusRowAdd
                                                    onCancel={handleAddState}
                                                    onAdd={handleAddNew}
                                                    apiError={addApiError} />)}
                                        </>
                                    }

                                    {providerStatusesRetrieved?.map((providerStatus: ProviderStatusView) =>
                                        <ProviderStatusRow
                                            key={providerStatus.id?.toString()}
                                            providerStatus={providerStatus}
                                            allowedToEdit={allowedToEdit}
                                            allowedToDelete={allowedToDelete}
                                            onSave={handleUpdate}
                                            onDelete={handleDelete}
                                        />)}
                            </TableBaseTbody>
                            </TableBase>
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </>
    );
}

ProviderStatusTable.defaultProps = {
    allowedToAdd: true,
    allowedToEdit: true,
    allowedToDelete: true,
};

export default ProviderStatusTable;