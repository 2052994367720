import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ReviewStageView } from "../../models/views/components/reviewStages/reviewStageView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { toastSuccess } from "../../brokers/toastBroker";

interface ReviewStageRowDeleteProps {
    reviewStage: ReviewStageView;
    onCancel: (value: string) => void;
    onDelete: (reviewStage: ReviewStageView) => void;
}

const ReviewStageRowDelete: FunctionComponent<ReviewStageRowDeleteProps> = (props) => {
    const {
        reviewStage,
        onCancel,
        onDelete
    } = props;

    const handleDelete = (reviewStage: ReviewStageView) => {
        onDelete(reviewStage);
        toastSuccess(`${reviewStage.name} Deleted`);
    }

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{reviewStage.name}</b><br />
                {reviewStage.description}
            </TableBaseData>
            <TableBaseData>
                {reviewStage.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="grey" title="Inactive" /> }
                {reviewStage.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel('VIEW')} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleDelete(reviewStage)} remove>Yes, Delete</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReviewStageRowDelete;
