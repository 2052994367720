import { Validation } from "../../models/validations/validation";

export const practiceValidation: Array<Validation> = [{
    property: "practiceName",
    friendlyName: "Practice name",
    isRequired: true,
    minLength: 3,
    maxLength: 255
}, {
    property: "practiceCode",
    friendlyName: "Practice code",
    isRequired: true,
    minLength: 6,
    maxLength: 6
}, {
    property: "odsCode",
    friendlyName: "ODS code",
    isRequired: true,
    minLength: 3,
    maxLength: 10
}, {
    property: "pcn",
    friendlyName: "PCN",
    isRequired: true,
    minLength: 3,
    maxLength: 255
}, {
    property: "pcnCode",
    friendlyName: "PCN code",
    isRequired: true,
    minLength: 3,
    maxLength: 10
}, {
    property: "groupName",
    friendlyName: "Group name",
    maxLength: 255
}, {
    property: "practicePhone",
    friendlyName: "Practice phone",
    isRequired: true,
    minLength: 3,
    maxLength: 255
}, {
    property: "practicePhoneBypass",
    friendlyName: "Practice phone bypass",
    isRequired: true,
    minLength: 3,
    maxLength: 255
}, {
    property: "practiceGenericEmail",
    friendlyName: "Practice generic email",
    isRequired: true,
    minLength: 3,
    maxLength: 255
}, {
    property: "providerStatusId",
    friendlyName: "Provider status",
    isRequired: true
}, {
    property: "boroughId",
    friendlyName: "Borough",
    isRequired: true
}, {
    property: "dateOfClosure",
    friendlyName: "Date of closure",
    isRequired: true,
    predicate: (value: any) => { return value.isActive === false }
}]