import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { ReferenceValue } from "../../../models/referenceValues/referenceValue";
import { ReferenceValueView } from "../../../models/views/components/referenceValues/referenceValueView";
import { referenceValueService } from "../../foundations/referenceValueService";

export const referenceValueViewService = {

    useCreateReferenceValue: () => {
        return referenceValueService.useCreateReferenceValue();
    },

    useGetAllReferenceValues: (referenceValueType: string, searchTerm?: string) => {
        try {
            let query = '?$orderby=sortOrder,name&$filter=';

            if (searchTerm) {
                query += `contains(name,'${searchTerm}') and `;
            } 

            query += `referenceValueType eq '${referenceValueType}'`;

            const response = referenceValueService.useGetAllReferenceValues(query);
            const [mappedReferenceValues, setMappedReferenceValues] = useState<Array<ReferenceValueView>>();

            useEffect(() => {
                if (response.data) {
                    const referenceValues = response.data.map((referenceValue: ReferenceValue) =>
                        new ReferenceValueView(
                            referenceValue.id,
                            referenceValue.name,
                            referenceValue.referenceValueType,
                            referenceValue.description,
                            referenceValue.workFlowAction,
                            referenceValue.isActive,
                            referenceValue.sortOrder,
                            referenceValue.createdByUser,
                            referenceValue.createdDate,
                            referenceValue.updatedByUser,
                            referenceValue.updatedDate
                        ));

                    setMappedReferenceValues(referenceValues);
                }
            }, [response.data]);

            return {
                mappedReferenceValues, ...response
            }
        } catch (err) {
            throw err;
        }
    },
    useGetReferenceValueById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = referenceValueService.useGetAllReferenceValues(query);
            const [mappedReferenceValue, setMappedReferenceValue] = useState<ReferenceValueView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const referenceValue = new ReferenceValueView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].referenceValueType,
                        response.data[0].description,
                        response.data[0].workFlowAction,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate
                    );

                    setMappedReferenceValue(referenceValue);
                }
            }, [response.data]);

            return {
                mappedReferenceValue, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateReferenceValue: () => {
        return referenceValueService.useUpdateReferenceValue();
    },

    useRemoveReferenceValue: () => {
        return referenceValueService.useDeleteReferenceValue();
    },
}