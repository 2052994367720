import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { ReactElement } from 'react';
import { NavItem, NavLink } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'

type PublicLinkParameters = {
    children: string,
    to: string,
    className?: string
}

type SecuredLinkParameters = {
    to: string,
    children: string,
    allowedRoles?: Array<string>,
    deniedRoles?: Array<string>
}

type SecuredComponentsParameters = {
    children: ReactElement,
    allowedRoles?: Array<string>,
    deniedRoles?: Array<string>
}

export const PublicLink = ({ to, children, className }: PublicLinkParameters): ReactElement => {
    return (
        <NavItem>
            <LinkContainer to={to} >
                <NavLink className={className}>
                    {children}
                </NavLink>
            </LinkContainer>
        </NavItem>
    )
}

export const SecuredLink = ({ to, children, allowedRoles = [], deniedRoles = [] }: SecuredLinkParameters): ReactElement => {
    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const userRoles = (): Array<string> => {
        if (accounts.length && accounts[0].idTokenClaims && accounts[0].idTokenClaims.roles) {
             return accounts[0].idTokenClaims.roles;
        }

        return []
    }

    const userIsInRole = (roles: Array<string>): Boolean => {
        let found = false;
        roles.forEach(r => {
            if (userRoles().indexOf(r) > -1) {
                found = true;
            }
        });
        return found;
    }

    if (isAuthenticated && userIsInRole(deniedRoles)) {
        return <></>
    }

    if (isAuthenticated && (allowedRoles.length === 0 || userIsInRole(allowedRoles))) {
        return <NavItem>
            <LinkContainer to={to} >
                <NavLink className="text-white">
                    {children}
                </NavLink>
            </LinkContainer>
        </NavItem>
    }

    return <></>;

}


export const SecuredComponents = ({ children, allowedRoles = [], deniedRoles = [] }: SecuredComponentsParameters): ReactElement => {
    const { accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const userRoles = (): Array<string> => {
        if (accounts.length && accounts[0].idTokenClaims && accounts[0].idTokenClaims.roles) {
            return accounts[0].idTokenClaims.roles;
        }

        return []
    }

    const userIsInRole = (roles: Array<string>): Boolean => {
        let found = false;
        roles.forEach(r => {
            if (userRoles().indexOf(r) > -1) {
                found = true;
            }
        });
        return found;
    }

    if (isAuthenticated && userIsInRole(deniedRoles)) {
        return <></>
    }

    if (isAuthenticated && (allowedRoles.length === 0 || userIsInRole(allowedRoles))) {
        return <>
            {children}
        </>
    }

    return <></>;
}