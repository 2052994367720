import { Guid } from "guid-typescript";
import { RentReview } from "../models/rentReviews/rentReview";

import ApiBroker from "./apiBroker";

class RentReviewBroker {
    relativeRentReviewUrl = '/api/rentReviews';

    private apiBroker = new ApiBroker();

    async PostRentReviewAsync(rentReview: RentReview) {
        return await this.apiBroker.PostAsync(this.relativeRentReviewUrl, rentReview)
            .then(result => new RentReview(result.data));
    }

    async GetAllRentReviewAsync(queryString: string) {
        var url = this.relativeRentReviewUrl + queryString;

        return await this.apiBroker.GetAsync(url)
            .then(result => {
                return result.data.map((rentReview: any) => { return new RentReview(rentReview)})
            });
    }

    async GetRentReviewByIdAsync(id: Guid) {
        const url = `${this.relativeRentReviewUrl}/${id}`;

        return this.apiBroker.GetAsync(url)
            .then(result => new RentReview(result.data));
    }

    async PutRentReviewAsync(rentReview: RentReview) {
        return await this.apiBroker.PutAsync(this.relativeRentReviewUrl, rentReview)
            .then(result =>  new RentReview(result.data));
    }

    async DeleteRentReviewByIdAsync(id: Guid) {
        const url = `${this.relativeRentReviewUrl}/${id}`;

        return this.apiBroker.DeleteAsync(url)
            .then(result => new RentReview(result.data));
    }
}

export default RentReviewBroker;