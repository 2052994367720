import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import SiteBroker from "../../brokers/apiBroker.sites";
import { Site } from "../../models/sites/site";
import { Guid } from "guid-typescript";

export const siteService = {
    useCreateSite: () => {
        const siteBroker = new SiteBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((site: Site) => {
            const date = new Date();
            site.createdDate = site.updatedDate = date;
            site.createdByUser = site.updatedByUser = msal.accounts[0].username;

            return siteBroker.PostSiteAsync(site);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("SiteGetAll");
                    queryClient.invalidateQueries("PracticeGetAll");
                    queryClient.invalidateQueries(["SiteGetById", { id: data.id }]);
                }
            });
    },

    useGetAllSites: (query: string) => {
        const siteBroker = new SiteBroker();

        return useQuery(
            ["SiteGetAll", { query: query }],
            () => siteBroker.GetAllSitesAsync(query),
            { staleTime: Infinity });
    },

    useGetSiteById: (id: Guid) => {
        const siteBroker = new SiteBroker();

        return useQuery(
            ["SiteGetById", { id: id }],
            () => siteBroker.GetSiteByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateSite: () => {
        const siteBroker = new SiteBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((site: Site) => {
            const date = new Date();
            site.updatedDate = date;
            site.updatedByUser = msal.accounts[0].username;

            return siteBroker.PutSiteAsync(site)
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("SiteGetAll");
                    queryClient.invalidateQueries(["SiteGetById", { id: data.id }]);
                }
            });
    },

    useDeleteSite: () => {
        const siteBroker = new SiteBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return siteBroker.DeleteSiteByIdAsync(id)
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("SiteGetAll");
                    queryClient.invalidateQueries(["SiteGetById", { id: data.id }]);
                }
            });
    },
}
