import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { ReviewStatusView } from "../../models/views/components/reviewStatuses/reviewStatusView";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import { reviewStatusValidations } from "./reviewStatusValidations";
import { reviewStatusErrors } from "./reviewStatusErrors";
import { useValidation } from "../../hooks/useValidation";

interface ReviewStatusRowEditProps {
    reviewStatus: ReviewStatusView;
    onCancel: () => void;
    onEdit: (reviewStatus: ReviewStatusView) => void;
    apiError?: any;
}

const ReviewStatusRowEdit: FunctionComponent<ReviewStatusRowEditProps> = (props) => {
    const {
        reviewStatus,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editReviewStatus, setEditReviewStatus] = useState<ReviewStatusView>({ ...reviewStatus });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(reviewStatusErrors, reviewStatusValidations, editReviewStatus);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const updatedreviewStatus = {
            ...editReviewStatus,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditReviewStatus(updatedreviewStatus);
    };

    const handleCancel = () => {
        setEditReviewStatus({ ...reviewStatus });
        onCancel();
    };

    const handleSave = () => {
        if (!validate(editReviewStatus)) {
            onEdit(editReviewStatus);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Review Status Name"
                    placeholder="Review Status Name"
                    value={editReviewStatus.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Review Status Description"
                    placeholder="Review Status Description"
                    value={editReviewStatus.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={editReviewStatus.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={editReviewStatus.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReviewStatusRowEdit;
