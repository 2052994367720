import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ReviewStatusView } from "../../models/views/components/reviewStatuses/reviewStatusView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";

interface ReviewStatusRowViewProps {
    reviewStatus: ReviewStatusView;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
    onEdit: (value: string) => void;
    onDelete: (value: string) => void;
}

const ReviewStatusRowView: FunctionComponent<ReviewStatusRowViewProps> = (props) => {
    const {
        reviewStatus,
        allowedToEdit,
        allowedToDelete,
        onEdit,
        onDelete
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{reviewStatus.name}</b><br />
                {reviewStatus.description}<br />
            </TableBaseData>
            <TableBaseData>
                {reviewStatus.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="red" title="Inactive" />}
                {reviewStatus.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                {allowedToEdit && (
                    <SecuredComponents allowedRoles={securityPoints.reviewStatuses.edit}>
                        <ButtonBase onClick={() => onEdit('EDIT')} edit>Edit</ButtonBase>
                    </SecuredComponents>
                )}
                &nbsp;
                {allowedToDelete && (
                    <SecuredComponents allowedRoles={securityPoints.reviewStatuses.delete}>
                        <ButtonBase onClick={() => onDelete('DELETE')} remove>Delete</ButtonBase>
                    </SecuredComponents>
                )}
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReviewStatusRowView;
