import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import { RentReview } from "../../models/rentReviews/rentReview";
import RentReviewBroker from "../../brokers/apiBroker.rentReviews";
import moment from "moment";


export const rentReviewService = {
    useCreateRentReview: () => {
        const rentReviewBroker = new RentReviewBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((rentReview: RentReview) => {
            const date = new Date();
            rentReview.createdDate = rentReview.updatedDate = date;
            rentReview.createdByUser = rentReview.updatedByUser = msal.accounts[0].username;

            if(rentReview.id.equals(Guid.createEmpty()))
            {
                rentReview.id = Guid.create();
            }

            return rentReviewBroker.PostRentReviewAsync(rentReview);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("RentReviewGetAll");
                    queryClient.invalidateQueries(["RentReviewGetById", { id: data.id }]);
                }
            });
    },

    useGetAllRentReviews: (query: string, enabled: boolean = true) => {
        const rentReviewBroker = new RentReviewBroker();

        return useQuery(
            ["RentReviewGetAll", { query: query }],
            () => rentReviewBroker.GetAllRentReviewAsync(query),
            {
                staleTime: Infinity,
                enabled: enabled
            });
    },

    useGetLeaseById: (id: Guid, enabled: boolean = true) => {
        const rentReviewBroker = new RentReviewBroker();

        return useQuery(
            ["RentReviewGetById", { id: id }],
            () => rentReviewBroker.GetRentReviewByIdAsync(id),
            {
                staleTime: Infinity,
                enabled: enabled
            });
    },

    useGetRentReviewDueActions: (searchTerm: string, enabled: boolean = true, practiceId?: string, siteId?: string) =>
    {
        let queryDate = moment().add(90, 'days').format("YYYY-MM-DD");
        let ignoreStage = 'Completed'

        let query = `?$select=id,rentReviewDueDate`
            + `&$expand=lease($expand=site($expand=practice($select=id,practiceName,PracticeCode)))`
            + `&$expand=lease($select=site)&$expand=lease($expand=site($select=id,siteName))`
            + `&$expand=lease($expand=site($expand=practice($expand=borough($select=id,name))))`
            + `&$filter=isActive eq true and rentReviewDueDate lt ${queryDate} `
            + `and ReviewStage/name ne '${ignoreStage}'`;

        if (practiceId) {
            query = query + ` and lease/site/practiceId eq ${practiceId}`;
        }

        if (siteId) {
            query = query + ` and lease/site/id eq ${siteId}`;
        }

        if (searchTerm) {
            query = query + ` and (contains(lease/site/practice/practiceName,'${searchTerm}')`
                + ` or contains(lease/site/practice/practiceCode,'${searchTerm}')`
                + ` or contains(lease/site/practice/borough/name,'${searchTerm}'))`;
        }

        const rentReviewBroker = new RentReviewBroker();

        return useQuery(
            ["RentReviewGetAll", { query: query }],
            () => rentReviewBroker.GetAllRentReviewAsync(query),
            {
                staleTime: Infinity,
                enabled: enabled
            });
    },


    useGetRentReviewDvActions: (searchTerm: string, enabled: boolean = true, practiceId?: string, siteId?: string) =>
    {
        let queryDate = moment().add(90, 'days').format("YYYY-MM-DD");
        let stage = 'Engage DV'

        let query = `?$select=id,reviewStageChangedDate`
            + `&$expand=lease($expand=site($expand=practice($select=id,practiceName,PracticeCode)))`
            + `&$expand=lease($select=site)&$expand=lease($expand=site($select=id,siteName))`
            + `&$expand=lease($expand=site($expand=practice($expand=borough($select=id,name))))`
            + `&$filter=isActive eq true and reviewStageChangedDate lt ${queryDate} `
            + `and ReviewStage/name eq '${stage}'`;

        if (practiceId) {
            query = query + ` and lease/site/practiceId eq ${practiceId}`;
        }

        if (siteId) {
            query = query + ` and lease/site/id eq ${siteId}`;
        }

        if (searchTerm) {
            query = query + ` and (contains(lease/site/practice/practiceName,'${searchTerm}')`
                + ` or contains(lease/site/practice/practiceCode,'${searchTerm}')`
                + ` or contains(lease/site/practice/borough/name,'${searchTerm}'))`
        }

        const rentReviewBroker = new RentReviewBroker();

        return useQuery(
            ["RentReviewGetAll", { query: query }],
            () => rentReviewBroker.GetAllRentReviewAsync(query),
            {
                staleTime: Infinity,
                enabled: enabled
            });
    },



    useGetRentReviewPendingValuationActions:
        (searchTerm: string, enabled: boolean = true, practiceId?: string, siteId?: string) =>
        {
            let queryDate = moment().add(90, 'days').format("YYYY-MM-DD");
            let stage = 'Valuation'

            let query = `?$select=id,reviewStageChangedDate`
                + `&$expand=lease($expand=site($expand=practice($select=id,practiceName,PracticeCode)))`
                + `&$expand=lease($select=site)&$expand=lease($expand=site($select=id,siteName))`
                + `&$expand=lease($expand=site($expand=practice($expand=borough($select=id,name))))`
                + `&$filter=isActive eq true and reviewStageChangedDate lt ${queryDate} `
                + `and ReviewStage/name eq '${stage}'`;

            if (practiceId) {
                query = query + ` and lease/site/practiceId eq ${practiceId}`;
            }

            if (siteId) {
                query = query + ` and lease/site/id eq ${siteId}`;
            }

            if (searchTerm) {
                query = query + ` and (contains(lease/site/practice/practiceName,'${searchTerm}')`
                    + ` or contains(lease/site/practice/practiceCode,'${searchTerm}')`
                    + ` or contains(lease/site/practice/borough/name,'${searchTerm}'))`
            }

            const rentReviewBroker = new RentReviewBroker();

            return useQuery(
                ["RentReviewGetAll", { query: query }],
                () => rentReviewBroker.GetAllRentReviewAsync(query),
                {
                    staleTime: Infinity,
                    enabled: enabled
                });
        },


    useGetRentReviewAgreedValuationActions:
        (searchTerm: string, enabled: boolean = true, practiceId?: string, siteId?: string) =>
    {
        let queryDate = moment().add(90, 'days').format("YYYY-MM-DD");
        let stage = 'Valuation Acceptance'

        let query = `?$select=id,reviewStageChangedDate`
            + `&$expand=lease($expand=site($expand=practice($select=id,practiceName,PracticeCode)))`
            + `&$expand=lease($select=site)&$expand=lease($expand=site($select=id,siteName))`
            + `&$expand=lease($expand=site($expand=practice($expand=borough($select=id,name))))`
            + `&$filter=isActive eq true and reviewStageChangedDate lt ${queryDate} `
                + `and ReviewStage/name eq '${stage}'`;

        if (practiceId) {
            query = query + ` and lease/site/practiceId eq ${practiceId}`;
        }

        if (siteId) {
            query = query + ` and lease/site/id eq ${siteId}`;
        }

        if (searchTerm) {
            query = query + ` and (contains(lease/site/practice/practiceName,'${searchTerm}')`
                + ` or contains(lease/site/practice/practiceCode,'${searchTerm}')`
                + ` or contains(lease/site/practice/borough/name,'${searchTerm}'))`
        }

        const rentReviewBroker = new RentReviewBroker();

        return useQuery(
            ["RentReviewGetAll", { query: query }],
            () => rentReviewBroker.GetAllRentReviewAsync(query),
            {
                staleTime: Infinity,
                enabled: enabled
            });
    },


    useGetRentReviewDisputeResolutionActions:
        (searchTerm: string, enabled: boolean = true, practiceId?: string, siteId?: string) =>
        {
            let queryDate = moment().add(90, 'days').format("YYYY-MM-DD");
            let stage = 'Dispute Resolution'

            let query = `?$select=id,reviewStageChangedDate`
                + `&$expand=lease($expand=site($expand=practice($select=id,practiceName,PracticeCode)))`
                + `&$expand=lease($select=site)&$expand=lease($expand=site($select=id,siteName))`
                + `&$expand=lease($expand=site($expand=practice($expand=borough($select=id,name))))`
                + `&$filter=isActive eq true and reviewStageChangedDate lt ${queryDate} `
                + `and ReviewStage/name eq '${stage}'`;

            if (practiceId) {
                query = query + ` and lease/site/practiceId eq ${practiceId}`;
            }

            if (siteId) {
                query = query + ` and lease/site/id eq ${siteId}`;
            }

            if (searchTerm) {
                query = query + ` and (contains(lease/site/practice/practiceName,'${searchTerm}')`
                    + ` or contains(lease/site/practice/practiceCode,'${searchTerm}')`
                    + ` or contains(lease/site/practice/borough/name,'${searchTerm}'))`
            }

            const rentReviewBroker = new RentReviewBroker();

            return useQuery(
                ["RentReviewGetAll", { query: query }],
                () => rentReviewBroker.GetAllRentReviewAsync(query),
                {
                    staleTime: Infinity,
                    enabled: enabled
                });
        },


    useGetRentReviewBySiteId: (siteId: Guid) => {
        const rentReviewBroker = new RentReviewBroker();

        return useQuery(
            ["RentReviewGetById", { siteId: siteId }],
            () => rentReviewBroker.GetRentReviewByIdAsync(siteId),
            {
                staleTime: Infinity
            });
    },

    useUpdateRentReview: () => {
        const rentReviewBroker = new RentReviewBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((rentReview: RentReview) => {
            const date = new Date();
            rentReview.updatedDate = date;
            rentReview.updatedByUser = msal.accounts[0].username;

            return rentReviewBroker.PutRentReviewAsync(rentReview);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("RentReviewGetAll");
                    queryClient.invalidateQueries(["RentReviewGetById", { id: data.id }]);
                    queryClient.invalidateQueries(["RentReviewDeleteById", { id: data.id }]);
                }
            });
    },

    useDeleteRentReview: () => {
        const rentReviewBroker = new RentReviewBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return rentReviewBroker.DeleteRentReviewByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("RentReviewGetAll");
                    queryClient.invalidateQueries(["RentReviewGetById", { id: data.id }]);
                }
            });
    },
}
