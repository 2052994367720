import React, { FunctionComponent, useState, useEffect } from "react";
import { ContactTypeView } from "../../models/views/components/contactTypes/contactTypeView";
import { Guid } from "guid-typescript";
import TextInputBase from "../bases/inputs/TextInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { contactTypeValidations } from "./contactTypesValidations";
import { contactTypeErrors } from "./contactTypesErrors";
import { useValidation } from "../../hooks/useValidation";

interface ContactTypeRowAddProps {
    onCancel: () => void;
    onAdd: (contactType: ContactTypeView) => void;
    apiError?: any;
}

const ContactTypeRowAdd: FunctionComponent<ContactTypeRowAddProps> = (props) => {
    const {
        onCancel,
        onAdd,
        apiError
    } = props;

    const [contactType, setContactType] = useState<ContactTypeView>(new ContactTypeView(Guid.create()));

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(contactTypeErrors, contactTypeValidations, contactType);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const addContactType = {
            ...contactType,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };
        
        setContactType(addContactType);
    };

    const handleSave = () => {
        if (!validate(contactType)) {
            onAdd(contactType);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Contact Type Name"
                    placeholder="Contact Type Name"
                    value={contactType.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={contactType.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={contactType.isActive === false ? false : true}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} add>Add</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ContactTypeRowAdd;
