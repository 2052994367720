import { Validation } from "../../models/validations/validation";

export const rentReviewValidations: Array<Validation> = [
    {
        property: "reviewStatusId",
        friendlyName: "Review status",
        isRequired: true
    },
    {
        property: "reviewStageId",
        friendlyName: "Review stage",
        isRequired: true
    },
    {
        property: "rentReviewDueDate",
        friendlyName: "Rent review due date",
        isRequired: true
    },
    {
        property: "repairAllowance",
        friendlyName: "Repair allowance",
    },
    {
        property: "pcifImprovementGrantAmountApplicable",
        friendlyName: "PCIF improvement grant amount applicable",
    },
    {
        property: "agreedRentAmount",
        friendlyName: "Agreed rent amount",
    },
    {
        property: "nextRentReview",
        friendlyName: "Next rent review",
    },
    {
        property: "nextRentAmount",
        friendlyName: "Next rent amount",
    },
    {
        property: "districtValuerName",
        friendlyName: "District valuers name",
        maxLength: 255,
    },
    {
        property: "districtValuerEmail",
        friendlyName: "District valuer email",
        maxLength: 255,
    },
    {
        property: "districtValuerPoNumber",
        friendlyName: "District valuers PO number",
        maxLength: 50,
    } ,
    {
        property: "districtValuerRefNo",
        friendlyName: "District valuers Ref number",
        maxLength: 50,
    }
]