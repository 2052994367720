import { AxiosResponse } from "axios";
import { Guid } from "guid-typescript";
import { Practice } from "../models/practices/practice";
import ApiBroker from "./apiBroker";

class PracticeBroker {
    relativePracticeUrl = '/api/practices';
    relativePracticeOdataUrl = '/odata/practices';

    private apiBroker = new ApiBroker();

    private processOdataResult = (result: AxiosResponse) => {
        const data = result.data.value.map((practice: any) => new Practice(practice));

        const nextPage = result.data['@odata.nextLink'];
        return { data, nextPage }
}

    async PostPracticeAsync(practice: Practice) {
        return await this.apiBroker.PostAsync(this.relativePracticeUrl, practice)
            .then(result => new Practice(result.data));
    }

    async GetAllPracticeAsync(queryString: string) {
        var url = this.relativePracticeUrl + queryString;

        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((practice: any) => new Practice(practice)));
    }

    async GetPracticeFirstPagesAsync(query: string) {
        var url = this.relativePracticeOdataUrl + query;
        return this.processOdataResult(await this.apiBroker.GetAsync(url));
    }

    async GetPracticesSubsequentPagesAsync(absoluteUri: string) {
        return this.processOdataResult(await this.apiBroker.GetAsyncAbsolute(absoluteUri));
    }

    async GetPracticeByIdAsync(id: Guid) {
        const url = `${this.relativePracticeUrl}/${id}`;

        return this.apiBroker.GetAsync(url)
            .then(result => new Practice(result.data));
    }

    async PutPracticeAsync(practice: Practice) {
        return await this.apiBroker.PutAsync(this.relativePracticeUrl, practice)
            .then(result => new Practice(result.data));
    }

    async DeletePracticeByIdAsync(id: Guid) {
        const url = `${this.relativePracticeUrl}/${id}`;

        return this.apiBroker.DeleteAsync(url)
            .then(result => new Practice(result.data));
    }
}

export default PracticeBroker;