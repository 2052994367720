import { Guid } from "guid-typescript";

import { Contract } from "../models/contracts/contract";
import ApiBroker from "./apiBroker";

class ContractBroker {

    relativeContractUrl = '/api/contracts';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostContractAsync(contract: Contract) {
        return await this.apiBroker.PostAsync(this.relativeContractUrl, contract)
            .then(result => new Contract(result.data));
    }

    async GetAllContractsAsync(queryString: string) {
        var url = this.relativeContractUrl + queryString;
        
        return await this.apiBroker.GetAsync(url)
            .then(r => r.data.map((d: any) => new Contract(d)));
    }

    async GetContractByIdAsync(id: Guid) {
        const url = `${this.relativeContractUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new Contract(result.data));
    }

    async PutContractAsync(contract: Contract) {
        return await this.apiBroker.PutAsync(this.relativeContractUrl, contract)
            .then(result => new Contract(result.data));
    }

    async DeleteContractByIdAsync(id: Guid) {
        const url = `${this.relativeContractUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new Contract(result.data));
    }
}

export default ContractBroker;



