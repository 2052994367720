import React, { FunctionComponent } from "react";
import { Guid } from 'guid-typescript';
import { SiteListItemView } from "../../models/views/components/siteList/siteListItemView";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import ButtonBase from "../bases/buttons/ButtonBase";
import { siteListViewService } from "../../services/views/siteList/siteListViewService";
import { Link } from "react-router-dom";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import SummaryListBase from "../bases/components/SummaryList/SummaryListBase";
import SummaryListBaseRow from "../bases/components/SummaryList/SummaryListBase.Row";
import SummaryListBaseKey from "../bases/components/SummaryList/SummaryListBase.Key";
import SummaryListBaseValue from "../bases/components/SummaryList/SummaryListBase.Value";
import SummaryListBaseAction from "../bases/components/SummaryList/SummaryListBase.Action";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";

interface SiteListProps {
    practiceId: Guid;
    onSelect: (practiceId: Guid, siteId: Guid) => void;
    children?: React.ReactNode;
}

const SiteList: FunctionComponent<SiteListProps> = (props) => {
    const {
        practiceId,
        onSelect,
        children
    } = props;

    const { mappedSites: sitesRetrieved } = siteListViewService.useGetSiteList(practiceId);

    const getClassTextColor = (propertyStatus: any) => {
        if (propertyStatus === "Closed") {
            return "text-danger valign-middle";
        }
        if (propertyStatus === "Pending") {
            return "text-warning valign-middle";
        }
        if (propertyStatus === "Open") {
            return "text-success valign-middle";
        }
    }

    const getClassRowColor = (active?: boolean) => {
        if (active) {
            return "";
        }
        else {
            return "text-danger";
        }
    }

    return (
        <div>
            {
                <>
                    {sitesRetrieved && (
                        <CardBase>
                            <CardBaseBody>
                                <CardBaseTitle>Sites</CardBaseTitle>
                                <CardBaseContent>

                                    <SummaryListBase>
                                        <SummaryListBaseRow>
                                            <SummaryListBaseKey>
                                                <SecuredComponents allowedRoles={securityPoints.sites.add}>
                                                    <Link to={'/site/' + practiceId + '/' + Guid.create()}>
                                                        <ButtonBase onClick={() => { }} add>
                                                            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
                                                            &nbsp; Site
                                                        </ButtonBase>
                                                    </Link>
                                                </SecuredComponents>
                                            </SummaryListBaseKey>
                                            <SummaryListBaseValue> </SummaryListBaseValue>
                                            <SummaryListBaseAction></SummaryListBaseAction>
                                        </SummaryListBaseRow>
                                    </SummaryListBase>

                                    {sitesRetrieved.map((site: SiteListItemView) =>
                                    (
                                        <span key={site.id.toString()}>
                                            <SummaryListBase>
                                                <SummaryListBaseRow classes={getClassRowColor(site.isActive)}>
                                                    <SummaryListBaseKey >
                                                        {site.isActive
                                                            ? <>{site.siteName} (<strong>{site.siteTypeName}</strong>)</>
                                                            : <s>{site.siteName} (<strong>{site.siteTypeName}</strong>)</s>}
                                                        <br />

                                                        {site.isActive
                                                            ? <>
                                                                {site.propertyStatus === "Closed" && (
                                                                    <span className="PropertyStatusText">
                                                                        Premises Status : <strong style={{ "textTransform": "uppercase" }} className={getClassTextColor(site.propertyStatus)}> {site.propertyStatus}</strong>
                                                                    </span>
                                                                )}

                                                                {site.propertyStatus !== "Closed" && (
                                                                    <span className="PropertyStatusText">
                                                                        Premises Status : <strong style={{ "textTransform": "uppercase" }} className={getClassTextColor(site.propertyStatus)}> {site.propertyStatus}</strong>
                                                                    </span>
                                                                )}
                                                            </> :
                                                            //Strikethrough copy
                                                            <s>
                                                                {site.propertyStatus === "Closed" && (
                                                                    <span className="PropertyStatusText">
                                                                        Premises Status : <strong style={{ "textTransform": "uppercase" }}> {site.propertyStatus}</strong>
                                                                    </span>
                                                                )}

                                                                {site.propertyStatus !== "Closed" && (
                                                                    <span className="PropertyStatusText">
                                                                        Premises Status : <strong style={{ "textTransform": "uppercase" }}> {site.propertyStatus}</strong>
                                                                    </span>
                                                                )}
                                                            </s>
                                                        }
                                                    </SummaryListBaseKey>
                                                    <SummaryListBaseValue> </SummaryListBaseValue>
                                                    <SummaryListBaseAction>

                                                        <Link to={'/site/' + practiceId + '/' + site.id.toString() + '/leases'}>
                                                            <ButtonBase
                                                                id={site.id.toString()}
                                                                key={site.id.toString()}
                                                                title={site.siteName}
                                                                onClick={() => onSelect(practiceId, site.id)}
                                                                view>
                                                                View Details
                                                            </ButtonBase>
                                                        </Link>

                                                    </SummaryListBaseAction>
                                                </SummaryListBaseRow>
                                            </SummaryListBase>
                                        </span>))}

                                    {children !== undefined && (
                                        <>
                                            <br />
                                            {children}
                                        </>
                                    )}
                                </CardBaseContent>
                            </CardBaseBody>
                        </CardBase>
                    )}
                </>
            }
        </div>
    );
}

export default SiteList;