import { Guid } from "guid-typescript";

export class LookupView {
    public id: string | number | Guid;
    public name?: string;
    public isActive: boolean;
    public workflowAction?: string;

    constructor(id: string | number | Guid, name?: string, isActive?: boolean, workflowAction?:string) {
        this.id = id;
        this.name = name || "";
        this.isActive = isActive === false ? false : true;
        this.workflowAction = workflowAction;
    }
}