import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import ProviderStatusBroker from "../../brokers/apiBroker.providerStatuses"
import { ProviderStatus } from "../../models/providerStatuses/providerStatus";

export const providerStatusService = {
    useCreateProviderStatus: () => {
        const providerStatusBroker = new ProviderStatusBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((providerStatus: ProviderStatus) => {
            const date = new Date();
            providerStatus.createdDate = providerStatus.updatedDate = date;
            providerStatus.createdByUser = providerStatus.updatedByUser = msal.accounts[0].username;

            return providerStatusBroker.PostProviderStatusAsync(providerStatus);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ProviderStatusGetAll");
                    queryClient.invalidateQueries(["ProviderStatusGetById", { id: data.id }]);
                }
            });
    },

    useGetAllProviderStatuses: (query: string) => {
        const providerStatusBroker = new ProviderStatusBroker();

        return useQuery(
            ["ProviderStatusGetAll", { query: query }],
            () => providerStatusBroker.GetAllProviderStatusesAsync(query),
            { staleTime: Infinity });
    },

    useGetProviderStatusById: (id: Guid) => {
        const providerStatusBroker = new ProviderStatusBroker();

        return useQuery(
            ["ProviderStatusGetById", { id: id }],
            () => providerStatusBroker.GetProviderStatusByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateProviderStatus: () => {
        const providerStatusBroker = new ProviderStatusBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((providerStatus: ProviderStatus) => {
            const date = new Date();
            providerStatus.updatedDate = date;
            providerStatus.updatedByUser = msal.accounts[0].username;

            return providerStatusBroker.PutProviderStatusAsync(providerStatus);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ProviderStatusGetAll");
                    queryClient.invalidateQueries(["ProviderStatusGetById", { id: data.id }]);
                }
            });
    },

    useDeleteProviderStatus: () => {
        const providerStatusBroker = new ProviderStatusBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return providerStatusBroker.DeleteProviderStatusByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ProviderStatusGetAll");
                    queryClient.invalidateQueries(["ProviderStatusGetById", { id: data.id }]);
                }
            });
    },
}