import { Guid } from 'guid-typescript';

export class ReviewStatus {
    public id: Guid;
    public name?: string;
    public description?: string;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(reviewStatus: any) {
        this.id = reviewStatus.id ? Guid.parse(reviewStatus.id) : Guid.parse(Guid.EMPTY);
        this.name = reviewStatus.name;
        this.description = reviewStatus.description;
        this.isActive = reviewStatus.isActive;
        this.sortOrder = reviewStatus.sortOrder;
        this.createdByUser = reviewStatus.createdByUser
        this.createdDate = new Date(reviewStatus.createdDate)
        this.updatedByUser = reviewStatus.updatedByUser
        this.updatedDate = new Date(reviewStatus.updatedDate)
    }
}