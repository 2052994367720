import { Guid } from "guid-typescript";
import { Lease } from "../models/leases/lease";
import ApiBroker from "./apiBroker";

class LeaseBroker {
    relativeLeaseUrl = '/api/leases';

    private apiBroker = new ApiBroker();

    async PostLeaseAsync(lease: Lease) {
        return await this.apiBroker.PostAsync(this.relativeLeaseUrl, lease)
            .then(result => new Lease(result.data));
    }

    async GetAllLeaseAsync(queryString: string) {
        var url = this.relativeLeaseUrl + queryString;

        return await this.apiBroker.GetAsync(url)
            .then(result => {
                return result.data.map((lease: any) => {return new Lease(lease)})
            });
    }

    async GetLeaseByIdAsync(id: Guid) {
        const url = `${this.relativeLeaseUrl}/${id}`;

        return this.apiBroker.GetAsync(url)
            .then(result => new Lease(result.data));
    }

    async PutLeaseAsync(lease: Lease) {
        return await this.apiBroker.PutAsync(this.relativeLeaseUrl, lease)
            .then(result => new Lease(result.data));
    }

    async DeleteLeaseByIdAsync(id: Guid) {
        const url = `${this.relativeLeaseUrl}/${id}`;

        return this.apiBroker.DeleteAsync(url)
            .then(result => new Lease(result.data));
    }
}

export default LeaseBroker;