import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import { SiteTypeView } from "../../models/views/components/siteTypes/siteTypeView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { toastSuccess } from "../../brokers/toastBroker";

interface SiteTypeRowDeleteProps {
    siteType: SiteTypeView;
    onCancel: (value: string) => void;
    onDelete: (siteType: SiteTypeView) => void;
}

const SiteTypeRowDelete: FunctionComponent<SiteTypeRowDeleteProps> = (props) => {
    const {
        siteType,
        onCancel,
        onDelete
    } = props;

    const handleDelete = (siteType: SiteTypeView) => {
        onDelete(siteType);
        toastSuccess(`${siteType.name} Deleted`);
    }

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{siteType.name}</b><br />
                {siteType.description}<br />
            </TableBaseData>
            <TableBaseData>
                {siteType.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="grey" title="Inactive" /> }
                {siteType.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel('VIEW')} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleDelete(siteType)} remove>Yes, Delete</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default SiteTypeRowDelete;
