import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { PropertyStatusView } from "../../models/views/components/propertyStatuses/propertyStatusView";
import { Guid } from "guid-typescript";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import { useValidation } from "../../hooks/useValidation";
import { propertyStatusErrors } from "./propertyStatusErrors";
import { propertyStatusValidations } from "./propertyStatusValidations";

interface PropertyStatusRowAddProps {
    onCancel: () => void;
    onAdd: (propertyStatus: PropertyStatusView) => void;
    apiError: any;
}

const PropertyStatusRowAdd: FunctionComponent<PropertyStatusRowAddProps> = (props) => {
    const {
        onCancel,
        onAdd,
        apiError
    } = props;

    const [propertyStatus, setPropertyStatus] = useState<PropertyStatusView>(new PropertyStatusView(Guid.create()));

    const { errors, processApiErrors, enableValidationMessages, validate } =
    useValidation(propertyStatusErrors, propertyStatusValidations, propertyStatus);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const addPropertyStatus = {
            ...propertyStatus,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setPropertyStatus(addPropertyStatus);
    };

    const handleSave = () => {
        if (!validate(propertyStatus)) {
            onAdd(propertyStatus);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Property Status Name"
                    placeholder="Property Status Name"
                    value={propertyStatus.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Property Status Description"
                    placeholder="Property Status Description"
                    value={propertyStatus.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={propertyStatus.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={propertyStatus.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} add>Add</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default PropertyStatusRowAdd;
