import React, { FunctionComponent } from "react";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";
import TableBase from "../bases/components/Table/TableBase";
import ActionsRow from "./actionsRow";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseThead from "../bases/components/Table/TableBase.Thead";
import TableBaseHeader from "../bases/components/Table/TableBase.Header";
import { ActionView } from "../../models/views/components/actions/actionView";

type ActionsTableProps = {
    actionsRetrieved: ActionView[];
    showGoTo? : boolean
}

const ActionTable: FunctionComponent<ActionsTableProps> = (props) => {

    const {
        actionsRetrieved,
        showGoTo
    } = props;

    return (
        <div className="infiniteScollContainer">

            {props.actionsRetrieved &&
                <TableBase>
                    <TableBaseThead>
                        <TableBaseRow>
                            <TableBaseHeader>Practice</TableBaseHeader>
                            <TableBaseHeader>Site</TableBaseHeader>
                            <TableBaseHeader>Status</TableBaseHeader>
                            <TableBaseHeader classes="text-center">RAG</TableBaseHeader>
                            {showGoTo &&
                                <TableBaseHeader classes="text-end">Action(s)</TableBaseHeader>
                            }
                        </TableBaseRow>
                    </TableBaseThead>
                    <TableBaseTbody>
                        {actionsRetrieved?.map((leaseActionList: ActionView) =>
                            <ActionsRow
                                key={leaseActionList.id?.toString() + leaseActionList.type?.toString()}
                                leaseAction={leaseActionList}
                                showGoTo={showGoTo}
                            />
                        )}
                    </TableBaseTbody>
                </TableBase>
            }
            {actionsRetrieved.length === 0 &&
                <span className="bg-info">
                    <h2>Nothing to action.</h2>
                </span>
            }

        </div >
    );
}

export default ActionTable;