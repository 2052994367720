import { leaseService } from "../../foundations/leaseService";
import { useEffect, useState } from "react";
import { rentReviewService } from "../../foundations/rentReviewService";
import { ActionView } from "../../../models/views/components/actions/actionView";
import { getLeasesExpiring, getRentReviewAgreedVal, getRentReviewDisputeVal, getRentReviewDue, getRentReviewDV, getRentReviewPendingVal } from "./functions/getActionData";

export const actionViewService = {
    useGetAllActions: (searchTerm?: string, practiceId?: string, siteId?: string) => {
        try {

            if (searchTerm === undefined) { searchTerm = "" }

            const [mappedActions, setMappedActions] = useState<Array<ActionView>>([]);

            const leaseExpiryActions = leaseService
                .useGetLeaseExpireActions(searchTerm, true, practiceId, siteId);

            const rentReviewDueActions = rentReviewService
                .useGetRentReviewDueActions(searchTerm, true, practiceId, siteId);

            const rentReviewDVActions = rentReviewService
                .useGetRentReviewDvActions(searchTerm, true, practiceId, siteId);

            const rentReviewPendingValActions = rentReviewService
                .useGetRentReviewPendingValuationActions(searchTerm, true, practiceId, siteId);

            const rentReviewAgreedValActions = rentReviewService
                .useGetRentReviewAgreedValuationActions(searchTerm, true, practiceId, siteId);

            const rentReviewDisputeValActions = rentReviewService
                .useGetRentReviewDisputeResolutionActions(searchTerm, true, practiceId, siteId);

            useEffect(() => {
                let next: ActionView[] = []

                if (leaseExpiryActions.data) {
                    next = getLeasesExpiring(leaseExpiryActions.data, next);
                }
                if (rentReviewDueActions.data) {
                    next = getRentReviewDue(rentReviewDueActions.data, next);
                }

                if (rentReviewDVActions.data) {
                    next = getRentReviewDV(rentReviewDVActions.data, next);
                }

                if (rentReviewPendingValActions.data) {
                    next = getRentReviewPendingVal(rentReviewPendingValActions.data, next);
                }

                if (rentReviewAgreedValActions.data) {
                    next = getRentReviewAgreedVal(rentReviewAgreedValActions.data, next);
                }

                if (rentReviewDisputeValActions.data) {
                    next = getRentReviewDisputeVal(rentReviewDisputeValActions.data, next);
                }

                setMappedActions(next.sort((a, b) => {
                    return b.due.getTime() - a.due.getTime() // Order by due date
                }));
            }, [
                leaseExpiryActions.data,
                rentReviewDueActions.data,
                rentReviewDVActions.data,
                rentReviewPendingValActions.data,
                rentReviewAgreedValActions.data,
                rentReviewDisputeValActions.data,
            ]);

            return {
                mappedActions,
                ...leaseExpiryActions,
                ...rentReviewDueActions,
                ...rentReviewDVActions,
                ...rentReviewPendingValActions,
                ...rentReviewAgreedValActions,
                ...rentReviewDisputeValActions
            }

        } catch (err) {
            throw err;
        }
    },
}