import { Validation } from "../../models/validations/validation";

export const contactValidations: Array<Validation> = [
    {
        property: "name",
        friendlyName: "name",
        isRequired: true,
        minLength: 3,
        maxLength: 255,
    },
    {
        property: "contactTypeId",
        friendlyName: "contactTypeId",
        isRequired: true
    }
]