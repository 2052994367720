import { Guid } from "guid-typescript";

export class LeaseType {
    public id: Guid;
    public name?: string;
    public description?: string;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(leaseType: any) {
        this.id = leaseType.id ? Guid.parse(leaseType.id) : Guid.parse(Guid.EMPTY);
        this.name = leaseType.name;
        this.description = leaseType.description;
        this.isActive = leaseType.isActive;
        this.sortOrder = leaseType.sortOrder;
        this.createdByUser = leaseType.createdByUser
        this.createdDate = new Date(leaseType.createdDate)
        this.updatedByUser = leaseType.updatedByUser
        this.updatedDate = new Date(leaseType.updatedDate)
    }
}