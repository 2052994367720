import { ErrorBase } from "../../types/ErrorBase";

export interface IDiscussionErrors extends ErrorBase {
    hasErrors: boolean;
    comment: string;
}

export const discussionErrors: IDiscussionErrors = {
    hasErrors: false,
    comment: ""
};
