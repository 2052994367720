/// <reference path="../../models/options/option.ts" />
import React, { FunctionComponent, useEffect, useState } from "react";
import { Guid } from 'guid-typescript';
import LeaseDetailCard from "./leaseDetailCard";
import { lookupViewService } from "../../services/views/lookups/lookupViewService";
import { leaseViewService } from "../../services/views/leases/leaseViewService";
import { LeaseView } from "../../models/views/components/leases/leaseView";
import { Row, Col } from 'nhsuk-react-components'
import ButtonBase from "../bases/buttons/ButtonBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";
import { Alert, ListGroup, ListGroupItem } from "react-bootstrap";
import moment from "moment";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";

interface LeaseDetailProps {
    siteId: Guid;
    children?: React.ReactNode;
}

const LeaseDetail: FunctionComponent<LeaseDetailProps> = (props) => {
    const {
        siteId,
        children
    } = props;

    let { mappedLeaseTypes: leaseTypesRetrieved } = lookupViewService.useGetLeaseTypeList("");
    let { mappedReferenceValues: repairAllowanceNameRetrieved } = lookupViewService.useGetReferenceValueList("repairAllowanceTypeId");
    let { mappedLeases, isSuccess, isLoading } = leaseViewService.useGetLeasesBySiteId(siteId);
    let { mappedReferenceValues: landlordTypeNameRetrieved } = lookupViewService.useGetReferenceValueList("landlordTypeId");
    let { mappedReferenceValues: tenancyTypeNameRetrieved } = lookupViewService.useGetReferenceValueList("tenancyTypeId");
    let { mappedReferenceValues: section52TypeNameRetrieved } = lookupViewService.useGetReferenceValueList("isSignedUpToS52Id");
    let { mappedReferenceValues: leaseProgressTypeNameRetrieved } = lookupViewService.useGetReferenceValueList("leaseProgressTypeId");
    let { mappedReferenceValues: leaseStatusTypeNameRetrieved } = lookupViewService.useGetReferenceValueList("leaseStatusTypeId");

    const [sortedLeases, setSortedLeases] = useState<Array<LeaseView>>([]);

    useEffect(() => {
        setSortedLeases(mappedLeases.sort((a, b) => {
            if (!a.leaseStartDate) return 1;
            if (!b.leaseStartDate) return -1;
            if (a.leaseStartDate > b.leaseStartDate) return -1;
            if (a.leaseStartDate < b.leaseStartDate) return 1;
            if (a.isActive > b.isActive) return -1;
            return 0;
        }));
    }, [mappedLeases])

    useEffect(() => {
        const currentLeases = sortedLeases.filter(lease => lease.leaseIsCurrent);
        if (currentLeases.length > 0) {
            setLeaseId(currentLeases[0].id);
        }
    }, [sortedLeases])


    const [leaseId, setLeaseId] = useState<Guid>(Guid.createEmpty());
    const { mappedLease: leaseRetrieved } = leaseViewService.useGetLeaseById(
        leaseId,
        !leaseId.equals(Guid.createEmpty())
    )

    const [lease, setLease] = useState<LeaseView>(new LeaseView(Guid.create(), siteId));
    const [mode, setMode] = useState<string>("VIEW");
    const [onModeChange, setOnModeChange] = useState<string>("VIEW");
    const addLease = leaseViewService.useCreateLease();

    const handleAdd = (lease: LeaseView) => {
        return addLease.mutateAsync(lease, {
            onSuccess: () => {
                setMode("VIEW")
                setOnModeChange("VIEW");
                setLeaseId(lease.id);
            }
        });
    }



    const updateLease = leaseViewService.useUpdateLease();

    const handleUpdate = (lease: LeaseView) => {
        return updateLease.mutateAsync(lease);
    }

    const handleDelete = async (lease: LeaseView) => {
        lease.isActive = false;
        return updateLease.mutateAsync(lease);
    }

    const handleUnDelete = async (lease: LeaseView) => {
        lease.isActive = true;
        return updateLease.mutateAsync(lease);
    }

    const handleLeaseNew = () => {
        if (siteId !== undefined) {
            setLease(new LeaseView(Guid.create(), siteId));
            setMode("ADD");
            setOnModeChange("EDIT");
        }
    }

    const UpdateModeChange = (value: string) => {
        setOnModeChange(value);
    }

    const onCancel = () => {
        setMode("VIEW")
        setOnModeChange("VIEW");
    }

    return (
        <>
            <Row>
                {onModeChange !== "EDIT" &&
                    <Col width="one-quarter">
                        <div className="mb-2">
                            {
                                isLoading && <SpinnerBase />
                            }
                            {!isLoading && mappedLeases && mappedLeases.length > 0 && <ListGroup>
                                {sortedLeases.map((lease) => <ListGroupItem onClick={() => setLeaseId(lease.id)} active={lease.id === leaseId}>
                                    <div className={`${lease.isActive === false ? "text-decoration-line-through" : ""}`} >
                                        <small>
                                            {lease.tenancyTypeName === "Notional" ? <>Notional</> : <>
                                                <>{moment(lease.leaseStartDate).format("MMMYYYY")}</>
                                                -
                                                <>{moment(lease.leaseEndDate).format("MMMYYYY")}</>
                                            </>}
                                            {
                                                lease.leaseIsCurrent &&
                                                <span className="float-end"><FontAwesomeIcon icon={faSquareCheck} size="sm" /></span>
                                            }
                                        </small>
                                    </div>
                                </ListGroupItem>)}
                            </ListGroup>
                        }
                        </div>
                        {

                            !isLoading && mappedLeases.length === 0 &&
                            <p>Please add lease.</p>
                        }
                        {
                            !isLoading && sortedLeases.filter(x => x.leaseIsCurrent).length === 0 &&
                            <Alert variant="warning">No current lease</Alert>

                        }

                        <div className="mt-1">
                            <SecuredComponents allowedRoles={securityPoints.leases.add} >
                                <ButtonBase id="AddNewLeaseBtn" onClick={handleLeaseNew} add><FontAwesomeIcon icon={faCirclePlus} size="lg" />&nbsp; Lease</ButtonBase>
                            </SecuredComponents>
                        </div>
                    </Col>
                }
                <Col width={onModeChange !== "EDIT" ? "three-quarters" : "full"}>
                    {
                        isSuccess
                        && ((leaseRetrieved && leaseRetrieved !== undefined && mode !== 'ADD') || (lease && lease !== undefined && mode === 'ADD'))
                        && leaseTypesRetrieved
                        && repairAllowanceNameRetrieved
                        && leaseProgressTypeNameRetrieved
                        && leaseStatusTypeNameRetrieved
                        && (
                            <LeaseDetailCard
                                key={mode !== 'ADD' ? leaseRetrieved?.id.toString() : lease?.id.toString()}
                                lease={mode === 'ADD' ? lease : leaseRetrieved}
                                leaseTypes={leaseTypesRetrieved}
                                repairAllowanceTypes={repairAllowanceNameRetrieved}
                                landlordTypes={landlordTypeNameRetrieved}
                                tenancyTypes={tenancyTypeNameRetrieved}
                                IsSignedUpToS52Types={section52TypeNameRetrieved}
                                leaseProgressTypes={leaseProgressTypeNameRetrieved}
                                leaseStatusTypes={leaseStatusTypeNameRetrieved}
                                mode={mode}
                                onCancel={onCancel}
                                onModeChange={UpdateModeChange}
                                onAdd={handleAdd}
                                onUpdate={handleUpdate}
                                onDelete={handleDelete}
                                onUnDelete={handleUnDelete}>
                                {children}
                            </LeaseDetailCard>
                        )}
                </Col>
            </Row>
        </>
    );
}

export default LeaseDetail;