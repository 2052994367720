import React, { FunctionComponent, useState } from "react";
import { DiscussionView } from "../../models/views/components/discussions/discussionView";
import DiscussionRowView from "./discussionRowView";
import DiscussionRowEdit from "./discussionRowEdit";
import DiscussionRowDelete from "./discussionRowDelete";

type DiscussionRowProps = {
    discussion: DiscussionView;
    onSave: (discussion: DiscussionView) => void;
    onDelete: (discussion: DiscussionView) => void;
}

const DiscussionRow: FunctionComponent<DiscussionRowProps> = (props) => {
    const {
        discussion,
        onSave,
        onDelete,
    } = props;

    const [mode, setMode] = useState<string>('VIEW');
    const [apiError, setApiError] = useState<any>({});

    const handleMode = (value: string) => {
        setMode(value);
    };

    const handleSave = async (discussion: DiscussionView) => {
        try {
            await onSave(discussion);
            setMode('VIEW');
        } catch (error) {
            setApiError(error);
            setMode('EDIT');
        }
    };

    const handleDelete = (discussion: DiscussionView) => {
        onDelete(discussion);
        setMode('VIEW');
    };

    const handleCancel = () => {
        setMode('VIEW');
    };

    return (
        <>
            {mode !== 'EDIT' && mode !== 'DELETE' && (
                <DiscussionRowView
                    key={discussion.id.toString()}
                    discussion={discussion}
                    onEdit={handleMode}
                    onDelete={handleMode} />
            )}

            {mode === 'EDIT' && (
                <DiscussionRowEdit
                    key={discussion.id.toString()}
                    discussion={discussion}
                    onCancel={handleCancel}
                    onEdit={handleSave}
                    apiError={apiError}
                />
            )}

            {mode === 'DELETE' && (
                <DiscussionRowDelete
                    key={discussion.id.toString()}
                    discussion={discussion}
                    onCancel={handleCancel}
                    onDelete={handleDelete} />
            )}
        </>
    );
}

export default DiscussionRow;