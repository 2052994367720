import { Guid } from 'guid-typescript';

export class ReviewStage {
    public id: Guid;
    public name?: string;
    public description?: string;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;
    public workflowAction?: string;

    constructor(reviewStage: any) {
        this.id = reviewStage.id ? Guid.parse(reviewStage.id) : Guid.parse(Guid.EMPTY);
        this.name = reviewStage.name;
        this.description = reviewStage.description;
        this.isActive = reviewStage.isActive;
        this.sortOrder = reviewStage.sortOrder;
        this.createdByUser = reviewStage.createdByUser;
        this.createdDate = new Date(reviewStage.createdDate);
        this.updatedByUser = reviewStage.updatedByUser;
        this.updatedDate = new Date(reviewStage.updatedDate);
        this.workflowAction = reviewStage.workflowAction;
    }
}