import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { ReviewStageView } from "../../models/views/components/reviewStages/reviewStageView";
import { Guid } from "guid-typescript";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { reviewStageErrors } from "./reviewStageErrors";
import { reviewStageValidations } from "./reviewStageValidations";

interface ReviewStageRowAddProps {
    onCancel: () => void;
    onAdd: (reviewStage: ReviewStageView) => void;
    apiError?: any;
}

const ReviewStageRowAdd: FunctionComponent<ReviewStageRowAddProps> = (props) => {
    const {
        onCancel,
        onAdd,
        apiError
    } = props;

    const [reviewStage, setReviewStage] = useState<ReviewStageView>(new ReviewStageView(Guid.create()));

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(reviewStageErrors, reviewStageValidations, reviewStage);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const addReviewStage = {
            ...reviewStage,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setReviewStage(addReviewStage);
    };

    const handleSave = () => {
        if (!validate(reviewStage)) {
            onAdd(reviewStage);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Review Stage Name"
                    placeholder="Review Stage Name"
                    value={reviewStage.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Review Stage Description"
                    placeholder="Review Stage Description"
                    value={reviewStage.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={reviewStage.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={reviewStage.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} add>Add</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReviewStageRowAdd;
