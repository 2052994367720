import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { OwnershipView } from "../../models/views/components/ownerships/ownershipView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import securityPoints from "../../SecurityMatrix";
import { SecuredComponents } from "../Links";

interface OwnershipRowViewProps {
    ownership: OwnershipView;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
    onEdit: (value: string) => void;
    onDelete: (value: string) => void;
}

const OwnershipRowView: FunctionComponent<OwnershipRowViewProps> = (props) => {
    const {
        ownership,
        allowedToEdit,
        allowedToDelete,
        onEdit,
        onDelete
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{ownership.name}</b><br />
                {ownership.description}<br />
            </TableBaseData>
            <TableBaseData>
                {ownership.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="red" title="Inactive" /> }
                {ownership.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                {allowedToEdit && (
                    <SecuredComponents allowedRoles={securityPoints.ownerships.edit}>
                        <ButtonBase onClick={() => onEdit('EDIT')} edit>Edit</ButtonBase>
                        </SecuredComponents>
                )}
                &nbsp;
                {allowedToDelete && (
                    <SecuredComponents allowedRoles={securityPoints.ownerships.delete}>
                        <ButtonBase onClick={() => onDelete('DELETE')} remove>Delete</ButtonBase>
                    </SecuredComponents>
                )}
            </TableBaseData>
        </TableBaseRow>
    );
}

export default OwnershipRowView;
