import { ErrorBase } from "../../types/ErrorBase";

export interface SiteErrors extends ErrorBase {
    practiceId: string;
    siteTypeId: string;
    contractId: string;
    ownershipId: string;
    propertyStatusId: string;
    closureDate: string;
    siteName: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postCode: string;
    listSize: string;
    totalSquareFootage: string;
}

export const siteErrors: SiteErrors = {
    hasErrors: false,
    practiceId: "",
    siteTypeId: "",
    contractId: "",
    ownershipId: "",
    propertyStatusId: "",
    closureDate: "",
    siteName: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    postCode: "",
    listSize: "",
    totalSquareFootage: "",
};
