import { Guid } from 'guid-typescript';
import { ContactType } from '../contactTypes/contactType';
import { Site } from '../sites/site';

export class Contact {
    public id: Guid;
    public siteId: Guid;
    public contactTypeId?: Guid;
    public name?: string;
    public contactNumber?: string;
    public contactEmail?: string;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;
    public site?: Site;
    public contactType?: ContactType;

    constructor(contact: any) {
        this.id = Guid.parse(contact.id);
        this.siteId = Guid.parse(contact.siteId);
        this.contactTypeId = contact.contactTypeId ? Guid.parse(contact.contactTypeId) : Guid.parse(Guid.EMPTY);
        this.name = contact.name;
        this.contactNumber = contact.contactNumber;
        this.contactEmail = contact.contactEmail;
        this.createdByUser = contact.createdByUser
        this.createdDate = new Date(contact.createdDate)
        this.updatedByUser = contact.updatedByUser
        this.updatedDate = new Date(contact.updatedDate)
        this.site = contact.site;
        this.contactType = contact.contactType;
    }
}