export const referenceValueConfig = [
    {
        'name': 'Repair Allowance',
        'key': 'repairAllowanceTypeId'
    },

    {
        'name': 'Landord Type',
        'key': 'landlordTypeId'
    },

    {
        'name': 'Tenancy Type',
        'key': 'tenancyTypeId'
    },

    {
        'name': 'Section 52 Type',
        'key': 'isSignedUpToS52Id'
    },

    {
        'name': 'Lease Progress Type',
        'key': 'leaseProgressTypeId'
    },

    {
        'name': 'Lease Status Type',
        'key': 'leaseStatusTypeId'
    }
]
