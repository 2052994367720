import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { ProviderStatusView } from "../../models/views/components/providerStatuses/providerStatusView";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import { providerStatusValidations } from "./providerStatusValidations";
import { providerStatusErrors } from "./providerStatusErrors";
import { useValidation } from "../../hooks/useValidation";

interface ProviderStatusRowEditProps {
    providerStatus: ProviderStatusView;
    onCancel: () => void;
    onEdit: (providerStatus: ProviderStatusView) => void;
    apiError?: any;
}

const ProviderStatusRowEdit: FunctionComponent<ProviderStatusRowEditProps> = (props) => {
    const {
        providerStatus,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editProviderStatus, setEditProviderStatus] = useState<ProviderStatusView>({ ...providerStatus });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(providerStatusErrors, providerStatusValidations, editProviderStatus);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const UpdatedproviderStatus = {
            ...editProviderStatus,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditProviderStatus(UpdatedproviderStatus);
    };

    const handleCancel = () => {
        setEditProviderStatus({ ...providerStatus });
        onCancel();
    };

    const handleSave = () => {
        if (!validate(editProviderStatus)) {
            onEdit(editProviderStatus);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Property Status Name"
                    placeholder="Property Status Name"
                    value={editProviderStatus.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Premises Status Description"
                    placeholder="Property Status Description"
                    value={editProviderStatus.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={editProviderStatus.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={editProviderStatus.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ProviderStatusRowEdit;
