import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { OwnershipView } from "../../models/views/components/ownerships/ownershipView";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import { useValidation } from "../../hooks/useValidation";
import { ownershipErrors } from "./ownershipErrors";
import { ownershipValidations } from "./ownershipValidations";

interface OwnershipRowEditProps {
    ownership: OwnershipView;
    onCancel: () => void;
    onEdit: (ownership: OwnershipView) => void;
    apiError?: any;
}

const OwnershipRowEdit: FunctionComponent<OwnershipRowEditProps> = (props) => {
    const {
        ownership,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editOwnership, setEditOwnership] = useState<OwnershipView>({ ...ownership });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(ownershipErrors, ownershipValidations, editOwnership);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const updatedOwnership = {
            ...editOwnership,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditOwnership(updatedOwnership);
    };

    const handleCancel = () => {
        setEditOwnership({ ...ownership });
        onCancel();
    };

    const handleUpdate = () => {
        if (!validate(editOwnership)) {
            onEdit(editOwnership);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Ownership Name"
                    placeholder="Ownership Name"
                    value={editOwnership.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Ownership Description"
                    placeholder="Ownership Description"
                    value={editOwnership.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={editOwnership.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={editOwnership.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleUpdate()} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default OwnershipRowEdit;
