import { Guid } from 'guid-typescript';

export class DocumentView {
    public id: Guid;
    public leaseId?: Guid | null;
    public rentReviewId?: Guid | null;
    public label?: string;
    public description?: string;
    public contentType?: string;
    public fileName?: string;
    public storageUrl?: string;
    public isActive?: boolean;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(
        id: Guid,
        leaseId?: Guid | null,
        rentReviewId?: Guid | null,
        label?: string,
        description?: string,
        contentType?: string,
        fileName?: string,
        storageUrl?: string,
        isActive?: boolean,
        createdByUser?: string,
        createdDate?: Date,
        updatedByUser?: string,
        updatedDate?: Date)
    {
        this.id = id;
        this.leaseId = leaseId;
        this.rentReviewId = rentReviewId;
        this.label = label;
        this.description = description;
        this.contentType = contentType;
        this.fileName = fileName;
        this.storageUrl = storageUrl;
        this.isActive = isActive === false ? false : true;
        this.createdByUser = createdByUser !== undefined ? createdByUser : '';
        this.createdDate = createdDate;
        this.updatedByUser = updatedByUser !== undefined ? updatedByUser : ''
        this.updatedDate = updatedDate;
    }
}