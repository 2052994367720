import { Guid } from "guid-typescript";
import { SiteListItemView } from "../views/components/siteList/siteListItemView";

export class PracticeHomeView {
    public id: Guid;
    public boroughId?: Guid;
    public practiceName?: string;
    public practiceCode?: string;
    public odsCode?: string;
    public pcn?: string;
    public pcnCode?: string;
    public groupName?: string;
    public practicePhone?: string;
    public practicePhoneBypass?: string;
    public practiceGenericEmail?: string;
    public providerStatusId?: Guid;
    public isActive: boolean;
    public boroughName?: string;
    public sites?: Array<SiteListItemView>;

    constructor(
        id: Guid,
        boroughId?: Guid,
        practiceName?: string,
        practiceCode?: string,
        odsCode?: string,
        pcn?: string,
        pcnCode?: string,
        groupName?: string,
        practicePhone?: string,
        practicePhoneBypass?: string,
        practiceGenericEmail?: string,
        providerStatusId?: Guid,
        isActive?: boolean,
        boroughName?: string,
        sites?: Array<SiteListItemView>
    ) {
        this.id = id;
        this.boroughId = boroughId;
        this.practiceName = practiceName;
        this.practiceCode = practiceCode;
        this.odsCode = odsCode;
        this.pcn = pcn;
        this.pcnCode = pcnCode;
        this.groupName = groupName;
        this.practicePhone = practicePhone;
        this.practicePhoneBypass = practicePhoneBypass;
        this.practiceGenericEmail = practiceGenericEmail;
        this.providerStatusId = providerStatusId
        this.isActive = isActive === true ? true : false;
        this.boroughName = boroughName;
        this.sites = sites;
    }
}