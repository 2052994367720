import React, { FunctionComponent, useState } from "react";
import { ContactView } from "../../models/views/components/contacts/contactView";
import ContactRowView from "./contactRowView";
import ContactRowEdit from "./contactRowEdit";
import ContactRowDelete from "./contactRowDelete";
import { LookupView } from "../../models/views/components/lookups/lookupView";

type ContactRowProps = {
    contact: ContactView;
    contactTypes: Array<LookupView>;
    onSave: (contact: ContactView) => void;
    onDelete: (contact: ContactView) => void;
}

const ContactRow: FunctionComponent<ContactRowProps> = (props) => {
    const {
        contact,
        contactTypes,
        onSave,
        onDelete,
    } = props;

    const [mode, setMode] = useState<string>('VIEW');
    const [apiError, setApiError] = useState<any>({});

    const handleMode = (value: string) => {
        setMode(value);
    };

    const handleSave = async (contact: ContactView) => {
        try {
            await onSave(contact);
            setMode('VIEW');
        } catch (error) {
            setApiError(error);
            setMode('EDIT');
        }
    };

    const handleDelete = (contact: ContactView) => {
        onDelete(contact);
        setMode('VIEW');
    };

    const handleCancel = () => {
        setMode('VIEW');
    };

    return (
        <>
            {mode !== 'EDIT' && mode !== 'DELETE' && (
                <ContactRowView
                    key={contact.id.toString()}
                    contact={contact}
                    onEdit={handleMode}
                    onDelete={handleMode}/>
            )}

            {mode === 'EDIT' && (
                <ContactRowEdit
                    key={contact.id.toString()}
                    contact={contact}
                    contactTypes={contactTypes}
                    onCancel={handleCancel}
                    onEdit={handleSave}
                    apiError={apiError}
                />
            )}

            {mode === 'DELETE' && (
                <ContactRowDelete
                    key={contact.id.toString()}
                    contact={contact}
                    onCancel={handleCancel}
                    onDelete={handleDelete} />
            )}
        </>
    );
}

export default ContactRow;