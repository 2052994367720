import React, { FunctionComponent, useMemo, useState } from "react";
import SearchBase from "../bases/inputs/SearchBase";
import { debounce } from "lodash";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import BoroughRowAdd from "./boroughRowAdd";
import { BoroughView } from "../../models/views/components/boroughs/boroughView";
import { boroughViewService } from "../../services/views/boroughs/boroughViewService";
import BoroughRow from "./boroughRow";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import BoroughRowNew from "./boroughRowNew";
import TableBase from "../bases/components/Table/TableBase";
import TableBaseThead from "../bases/components/Table/TableBase.Thead";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseHeader from "../bases/components/Table/TableBase.Header";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";
import InfiniteScroll from "../bases/pagers/InfiniteScroll";
import InfiniteScrollLoader from "../bases/pagers/InfiniteScroll.Loader";

type BoroughTableProps = {
    allowedToAdd: boolean;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
}

const BoroughTable: FunctionComponent<BoroughTableProps> = (props) => {
    const {
        allowedToAdd,
        allowedToEdit,
        allowedToDelete,
    } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedBoroughs: boroughsRetrieved,
        isLoading,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        data
    } = boroughViewService.useGetAllBoroughs(debouncedTerm);

    const addBorough = boroughViewService.useCreateBorough();
    const updateBorough = boroughViewService.useUpdateBorough();
    const deleteBorough = boroughViewService.useRemoveBorough();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [addApiError, setAddApiError] = useState<any>({});

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleAddState = () => {
        setAddMode(!addMode);
    };

    const handleAddNew = (borough: BoroughView) => {
        return addBorough.mutate(borough, {
            onSuccess: () => {
                setAddMode(false);
            },
            onError: (error: any) => {
                setAddApiError(error?.response?.data?.errors);
            }
        });
    };

    const handleUpdate = (borough: BoroughView) => {
        return updateBorough.mutateAsync(borough);
    }

    const handleDelete = (borough: BoroughView) => {
        return deleteBorough.mutateAsync(borough.id);
    }

    const hasNoMorePages = () => {
        return !isLoading && data?.pages.at(-1)?.nextPage === undefined;
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , [])

    return (
        <>
            <div className="infiniteScollContainer">
                <CardBase>
                    <CardBaseBody>
                        <CardBaseTitle>
                            Places
                        </CardBaseTitle>
                        <CardBaseContent>
                            <InfiniteScroll loading={isLoading} hasNextPage={hasNextPage || false} loadMore={fetchNextPage}>
                                <SearchBase id="search" label="Search places" value={searchTerm}
                                    onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />
                                {isLoading && <> <SpinnerBase /></>}
                                <TableBase>
                                    <TableBaseThead>
                                        <TableBaseRow>
                                            <TableBaseHeader>Places</TableBaseHeader>
                                            <TableBaseHeader classes="text-center">Finance Manager Name</TableBaseHeader>
                                            <TableBaseHeader classes="text-center">Finance Manager Email</TableBaseHeader>
                                        </TableBaseRow>
                                    </TableBaseThead>
                                    <TableBaseTbody>
                                        {
                                            allowedToAdd &&
                                            <>
                                                {addMode === false && (<BoroughRowNew onAdd={handleAddState} />)}

                                                {addMode === true && (
                                                    <BoroughRowAdd
                                                        onCancel={handleAddState}
                                                        onAdd={handleAddNew}
                                                        apiError={addApiError} />)}
                                            </>
                                        }

                                        {boroughsRetrieved?.map((borough: BoroughView) =>
                                            <BoroughRow
                                                key={borough.id?.toString()}
                                                borough={borough}
                                                allowedToEdit={allowedToEdit}
                                                allowedToDelete={allowedToDelete}
                                                onSave={handleUpdate}
                                                onDelete={handleDelete}
                                            />)
                                        }
                                        <tr>
                                            <td colSpan={3} className="text-center">
                                                <InfiniteScrollLoader
                                                    loading={isLoading || isFetchingNextPage}
                                                    spinner={<SpinnerBase />}
                                                    noMorePages={hasNoMorePages()}
                                                    noMorePagesMessage={<>---No more places---</>} />
                                            </td>
                                        </tr>
                                    </TableBaseTbody>
                                </TableBase>
                            </InfiniteScroll>
                        </CardBaseContent>
                    </CardBaseBody>
                </CardBase>
            </div>
        </>
    );
}

BoroughTable.defaultProps = {
    allowedToAdd: true,
    allowedToEdit: true,
    allowedToDelete: true,
};

export default BoroughTable;