import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { PracticeHomeView } from "../../models/practices/practiceHomeView";
import { LookupView } from "../../models/views/components/lookups/lookupView";
import { Guid } from "guid-typescript";
import TextInputBase from "../bases/inputs/TextInputBase";
import SelectInputBase from "../bases/inputs/SelectInputBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import { Option } from "../../models/options/option";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";

interface PracticeRowEditProps {
    practice: PracticeHomeView;
    boroughs: Array<LookupView>;
    providerStatus: Array<LookupView>;
    onCancel: (value: string) => void;
    onEdit: (practice: PracticeHomeView) => void;
    apiError?: any;
}

interface PracticeErrors {
    hasErrors: boolean;
    practiceName: string;
    practiceCode: string;
    odsCode: string;
    pcn: string;
    pcnCode: string;
    groupName: string;
    practicePhone: string;
    practicePhoneByPass: string;
    practiceGenericEmail: string;
    providerStatusId: string;
    boroughId: string;
}

const PracticeRowEdit: FunctionComponent<PracticeRowEditProps> = (props) => {
    const {
        practice,
        boroughs,
        onCancel,
        onEdit,
    } = props;

    const boroughOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...boroughs.map((borough) => {
            return { id: borough.id.toString() || 0, name: borough.name || "" };
        }),
    ];

    const providerStatusOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...boroughs.map((providerStatus) => {
            return { id: providerStatus.id.toString() || 0, name: providerStatus.name || "" };
        }),
    ];

    const [editPractice, setEditPractice] = useState<PracticeHomeView>(practice);

    const [errors, setErrors] = useState<PracticeErrors>({
        hasErrors: false,
        practiceName: "",
        practiceCode: "",
        odsCode: "",
        pcn: "",
        pcnCode: "",
        groupName: "",
        practicePhone: "",
        practicePhoneByPass: "",
        practiceGenericEmail: "",
        providerStatusId: "",
        boroughId: "",
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
        const updatedPractice = {
            ...editPractice,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        formIsValid(updatedPractice);
        setEditPractice(updatedPractice);
    };

    const formIsValid = (_practice: PracticeHomeView) => {
        const _errors: PracticeErrors = {
            hasErrors: false,
            practiceName: "",
            practiceCode: "",
            odsCode: "",
            pcn: "",
            pcnCode: "",
            groupName: "",
            practicePhone: "",
            practicePhoneByPass: "",
            practiceGenericEmail: "",
            providerStatusId: "",
            boroughId: ""
        };

        if (!_practice.practiceName || _practice.practiceName.length < 3 || _practice.practiceName.length > 255) {
            _errors.practiceName = "Practice! name is required (Min character is 3 Max characters is 255)";
            _errors.hasErrors = true;
        }

        if (!_practice.practiceCode || _practice.practiceCode.length < 6 || _practice.practiceCode.length > 6) {
            _errors.practiceCode = "Practice code is required (6 character code)";
            _errors.hasErrors = true;
        }

        if (!_practice.odsCode || _practice.odsCode.length < 3 || _practice.odsCode.length > 10) {
            _errors.odsCode = "ODS code is required (Min character is 3 Max characters is 10)";
            _errors.hasErrors = true;
        }

        if (!_practice.pcn || _practice.pcn.length < 3 || _practice.pcn.length > 255) {
            _errors.groupName = "PCN is required (Min character is 3 Max characters is 255)";
            _errors.hasErrors = true;
        }

        if (!_practice.pcnCode || _practice.pcnCode.length < 3 || _practice.pcnCode.length > 10) {
            _errors.pcnCode = "PCN code is required (Min character is 3 Max characters is 10)";
            _errors.hasErrors = true;
        }

        if (!_practice.groupName || _practice.groupName.length < 3 || _practice.groupName.length > 255) {
            _errors.groupName = "Group name is required (Min character is 3 Max characters is 255)";
            _errors.hasErrors = true;
        }

        if (!_practice.practicePhoneBypass || _practice.practicePhoneBypass.length < 3 || _practice.practicePhoneBypass.length > 255) {
            _errors.practicePhone = "Practice phone bypass is required (Min character is 3 Max characters is 255)";
            _errors.hasErrors = true;
        }

        if (!_practice.practiceGenericEmail || _practice.practiceGenericEmail.length < 3 || _practice.practiceGenericEmail.length > 255) {
            _errors.groupName = "Practice generic email is required (Min character is 3 Max characters is 255)";
            _errors.hasErrors = true;
        }

        if (!_practice.providerStatusId || _practice.providerStatusId === Guid.parse(Guid.EMPTY)) {
            _errors.providerStatusId = "Provider status is required";
            _errors.hasErrors = true;
        }

        if (!_practice.boroughId || _practice.boroughId === Guid.parse(Guid.EMPTY)) {
            _errors.boroughId = "Borough is required";
            _errors.hasErrors = true;
        }

        setErrors(_errors);

        return _errors;
    }

    return <TableBaseRow>
        <TableBaseData>
            <TextInputBase
                id="practiceName"
                name="practiceName"
                label="Practice Name"
                placeholder="Practice Name"
                value={editPractice.practiceName}
                error={errors.practiceName}
                onChange={handleChange}
            />
            <TextInputBase
                id="practiceCode"
                name="practiceCode"
                label="Practice Code"
                placeholder="Practice Code"
                value={editPractice.practiceCode}
                error={errors.practiceCode}
                onChange={handleChange}
            />
            <TextInputBase
                id="odsCode"
                name="odsCode"
                label="ODS Code"
                placeholder="ODS Code"
                value={editPractice.odsCode}
                error={errors.odsCode}
                onChange={handleChange}
            />
            <TextInputBase
                id="pcnCode"
                name="pcnCode"
                label="PCN Code"
                placeholder="PCN Code"
                value={editPractice.pcnCode}
                error={errors.pcnCode}
                onChange={handleChange}
            />
            <TextInputBase
                id="groupName"
                name="groupName"
                label="Group Name"
                placeholder="Group Name"
                value={editPractice.groupName}
                error={errors.groupName}
                onChange={handleChange}
            />
            <TextInputBase
                id="practicePhone"
                name="practicePhone"
                label="Practice Phone"
                placeholder="Practice Phone"
                value={editPractice.practicePhone}
                error={errors.practicePhone}
                onChange={handleChange}
            />
            <SelectInputBase
                id="providerStatusId"
                name="providerStatusId"
                label="Provider Status Id"
                value={editPractice.providerStatusId?.toString()}
                options={providerStatusOptions}
                error={errors.providerStatusId}
                onChange={handleChange}
            />
            <SelectInputBase
                id="boroughId"
                name="boroughId"
                label="Borough"
                value={editPractice.boroughId?.toString()}
                options={boroughOptions}
                error={errors.boroughId}
                onChange={handleChange}
            />
        </TableBaseData>
        <TableBaseData></TableBaseData>
        <TableBaseData classes="text-end">
            <>
                <ButtonBase onClick={() => onCancel('VIEW')} cancel>Cancel</ButtonBase> & nbsp;
                <ButtonBase onClick={() => onEdit(editPractice)} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </>
        </TableBaseData>
    </TableBaseRow>
}

export default PracticeRowEdit;