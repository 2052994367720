import React, { FunctionComponent, useMemo, useState } from "react";
import SearchBase from "../bases/inputs/SearchBase";
import { debounce } from "lodash";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import DiscussionRowAdd from "./discussionRowAdd";
import { DiscussionView } from "../../models/views/components/discussions/discussionView";
import { discussionViewService } from "../../services/views/discussions/discussionViewService";
import DiscussionRow from "./discussionRow";
import { Guid } from "guid-typescript";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import TableBase from "../bases/components/Table/TableBase";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";

type DiscussionTableProps = {
    siteId: Guid;
}

const DiscussionTable: FunctionComponent<DiscussionTableProps> = (props) => {
    const {
        siteId
    } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedDiscussions: discussionsRetrieved, isLoading } =
        discussionViewService.useGetAllDiscussions(debouncedTerm, siteId);

    const addDiscussion = discussionViewService.useCreateDiscussion();
    const updateDiscussion = discussionViewService.useUpdateDiscussion();
    const removeDiscussion = discussionViewService.useRemoveDiscussion();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [addApiError, setAddApiError] = useState<any>({});

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleAddState = () => {
        setAddMode(!addMode);
    };

    const handleAddNew = (discussion: DiscussionView) => {
        return addDiscussion.mutate(discussion, {
            onSuccess: () => {
                setAddMode(false);
            },
            onError: (error: any) => {
                setAddApiError(error?.response?.data?.errors);
            }
        });
    };

    const handleUpdate = (discussion: DiscussionView) => {
        return updateDiscussion.mutateAsync(discussion);
    }

    const handleDelete = (discussion: DiscussionView) => {
        return removeDiscussion.mutateAsync(discussion.id);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , [])

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>
                        Comments
                    </CardBaseTitle>

                    <CardBaseContent>

                        <SearchBase id="search" label="Search comments" value={searchTerm}
                            onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />

                        {isLoading && <> <SpinnerBase />.</>}
                        <TableBase>
                            <TableBaseTbody>
                               
                                {discussionsRetrieved?.map((discussion: DiscussionView) =>
                                    <DiscussionRow
                                        key={discussion.id?.toString()}
                                        discussion={discussion}
                                        onSave={handleUpdate}
                                        onDelete={handleDelete}
                                    />)}

{
                                    <>
                                        <DiscussionRowAdd
                                            siteId={siteId}
                                            onCancel={handleAddState}
                                            onAdd={handleAddNew}
                                            apiError={addApiError} />
                                    </>
                                }
                            </TableBaseTbody>
                        </TableBase>
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </>
    );
}

export default DiscussionTable;