import React, { FunctionComponent } from "react";
import { LeaseView } from "../../models/views/components/leases/leaseView";
import DocumentDetails from "../documents/documentDetails";
import moment from "moment";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import RentReviewList from "../rentReview/rentReviewList";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";

interface LeaseDetailCardViewProps {
    lease: LeaseView;
    onDelete: (lease: LeaseView) => void;
    onUnDelete: (lease: LeaseView) => void;
    mode: string;
    onModeChange: (value: string) => void;
}

const LeaseDetailCardView: FunctionComponent<LeaseDetailCardViewProps> = (props) => {
    const {
        lease,
        onDelete,
        onUnDelete,
        mode,
        onModeChange,
    } = props;

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>Lease Details</CardBaseTitle>
                    <CardBaseContent>
                        {lease.tenancyTypeName === "Notional" ?
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <div className={`${lease.isActive === false ? "text-decoration-line-through" : ""}`} >
                                        <h4>--- Not Applicable ---</h4>
                                    </div>
                                </Col>
                            </Row> : <>                                
                                <div className={`${lease.isActive === false ? "text-decoration-line-through" : ""}`} >
                                    <Row>
                                        <Col>
                                            <div style={{ color: "#666" }}>Lease Start Date</div>
                                            <b>{moment(lease.leaseStartDate?.toString()).format("Do-MMM-yyyy")}</b>
                                        </Col>
                                        <Col>
                                            <div style={{ color: "#666" }}>Lease End Date</div>
                                            <b>{moment(lease.leaseEndDate?.toString()).format("Do-MMM-yyyy")}</b>
                                        </Col>
                                        <Col>
                                            <div style={{ color: "#666" }}>DV Deadline Date</div>
                                            <b>{lease.dvDeadlineDate !== undefined ? moment(lease.dvDeadlineDate?.toString()).format("Do-MMM-yyyy") : "-"}</b>
                                        </Col>
                                    </Row>
                                    <div style={{ borderBottom: "solid 1px #CCC", display: 'block' }} className="mt-2 mb-2"></div>
                                    <Row>
                                        <Col>
                                            <div style={{ color: "#666" }}>Tenancy Type</div>
                                            <b>{lease.tenancyTypeName}</b>
                                        </Col>
                                        <Col>
                                            <div style={{ color: "#666" }}>Lease Available</div>
                                            <b>{lease.leaseTypeName}</b>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div style={{ color: "#666" }}>Lease Progress</div>
                                            <b>{lease.leaseProgressTypeName}</b>
                                        </Col>
                                        <Col>
                                            <div style={{ color: "#666" }}>Lease Status Type</div>
                                            <b>{lease.leaseStatusTypeName}</b>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div style={{ color: "#666" }}>Landlord Type</div>
                                            <b>{lease.landlordTypeName}</b>
                                        </Col>
                                        <Col>
                                            <div style={{ color: "#666" }}>Landlord Details</div>
                                            <b>{lease.landlordDetails}</b>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {(lease.landlordTypeName === "CHP" || lease.landlordTypeName === "NHSPS") && <Col>
                                            <div style={{ color: "#666" }}>Is signed up to Section 52?</div>
                                            <b>{lease.isSignedUpToS52Name}</b>
                                        </Col>
                                        }
                                        {lease.tenancyTypeName !== "Notional" && <Col>
                                            <div style={{ color: "#666" }}>Repair Allowance Type</div>
                                            <b>{lease.repairAllowanceTypeName}</b>
                                        </Col>}
                                    </Row>
                                </div>
                            </>
                        }
                        <div style={{ borderBottom: "solid 1px #CCC", display: 'block' }} className="mt-2 mb-2"></div>
                        <Row className="mt-2">
                            <Col sm={4}>
                                <ButtonGroup>
                                    {mode === 'VIEW' &&
                                        <SecuredComponents allowedRoles={securityPoints.leases.edit}>
                                            <Button id="leaseEdit" onClick={() => onModeChange('EDIT')}>Edit</Button>
                                        </SecuredComponents>
                                    } <>
                                        {mode === 'VIEW' && lease.isActive === true && 
                                            <SecuredComponents allowedRoles={securityPoints.leases.delete}>
                                                <Button id="leaseDelete" onClick={() => onModeChange('CONFIRMDELETE')} variant="danger">Delete</Button>
                                            </SecuredComponents>
                                        }
                                        {mode === 'CONFIRMDELETE' &&
                                            <>
                                                <Button onClick={() => onModeChange('VIEW')} variant="secondary">Cancel</Button>
                                                <Button id="leaseDeleteConfirm" onClick={() => onDelete(lease)} variant="danger">Yes, Delete</Button>
                                            </>
                                        }
                                        {mode === 'VIEW' && lease.isActive === false &&
                                            <SecuredComponents allowedRoles={securityPoints.leases.delete}>
                                                <Button id="leaseUndoDelete" onClick={() => onModeChange('CONFIRMUNDODELETE')} variant="danger">Undo Delete</Button>
                                            </SecuredComponents>
                                        }
                                        {mode === 'CONFIRMUNDODELETE' &&
                                            <SecuredComponents allowedRoles={securityPoints.leases.delete}>
                                                <Button id="leaseUndoDeleteConfirm" onClick={() => onUnDelete(lease)} variant="danger">Yes, Undo Delete</Button>
                                            </SecuredComponents>
                                        }

                                    </>

                                </ButtonGroup>
                            </Col>
                        </Row>
                        <div style={{ borderBottom: "solid 1px #CCC", display: 'block' }} className="mt-2 mb-2"></div>
                        <Row>
                            <DocumentDetails leaseId={lease.id} />
                        </Row>
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>

            <div>
                <RentReviewList leaseId={lease.id} />
            </div>


        </>
    );
}

export default LeaseDetailCardView;
