import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import TextInputBase from "../bases/inputs/TextInputBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import { LeaseView } from "../../models/views/components/leases/leaseView";
import SummaryListBase from "../bases/components/SummaryList/SummaryListBase";
import SummaryListBaseRow from "../bases/components/SummaryList/SummaryListBase.Row";
import SummaryListBaseKey from "../bases/components/SummaryList/SummaryListBase.Key";
import SummaryListBaseValue from "../bases/components/SummaryList/SummaryListBase.Value";
import securityPoints from "../../SecurityMatrix";
import { SecuredComponents } from "../Links";
import { leaseValidations } from "./leaseValidations";
import { leaseErrors } from "./leaseErrors";
import { useValidation } from "../../hooks/useValidation";
import moment from "moment";
import DateInputBase from "../bases/inputs/DateInputBase";
import SelectInputBase from "../bases/inputs/SelectInputBase";
import { LookupView } from "../../models/views/components/lookups/lookupView";
import { Option } from "../../models/options/option";
import { Guid } from "guid-typescript";
import ProcessLeaseWorkflow from "./leaseWorkflows"

interface LeaseDetailCardEditProps {
    lease: LeaseView;
    leaseTypes: Array<LookupView>;
    repairAllowanceTypes: Array<LookupView>;
    landlordTypes: Array<LookupView>;
    tenancyTypes: Array<LookupView>;
    IsSignedUpToS52Types: Array<LookupView>;
    leaseProgressTypes: Array<LookupView>;
    leaseStatusTypes: Array<LookupView>;
    onAdd: (lease: LeaseView) => void;
    onUpdate: (lease: LeaseView) => void;
    onCancel: () => void;
    mode: string;
    onModeChange: (value: string) => void;
    apiError?: any;
}

const LeaseDetailCardEdit: FunctionComponent<LeaseDetailCardEditProps> = (props) => {
    const {
        lease,
        leaseTypes,
        repairAllowanceTypes,
        landlordTypes,
        tenancyTypes,
        IsSignedUpToS52Types,
        leaseProgressTypes,
        leaseStatusTypes,
        onAdd,
        onUpdate,
        onCancel,
        mode,
        apiError
    } = props;

    const [editLease, setEditLease] = useState<LeaseView>({ ...lease });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(leaseErrors, leaseValidations, editLease);

    const [otherFieldsVisible, setOtherFieldsVisible] = useState(checkTenancyTypeState());

    const [confirmSection52Visible, setSection52Visible] = useState(checkLandordTypeState());

    const [confirmRequiredFields, setRequiredFields] = useState(checkLeaseTypeState());

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
        let value: any;
        let updatedLease: LeaseView = {
            ...editLease
        };

        
        switch (event.target.type) {
            case "checkbox":
                value = (event.target as HTMLInputElement).checked
                break;
            case "date":
                value = event.target.value !== '' ? event.target.value : null;
                break;
            case "select-one":
                value = event.target.value;
                updatedLease = { ...updatedLease, [event.target.name]: value };
                if (event.target.name.endsWith("Id")) {
                    if (Object.keys(updatedLease).includes(event.target.name.replace("Id", "Name"))) {
                        const target = event.target as HTMLSelectElement;
                        const nameValue = target.options[target.selectedIndex].text;
                        updatedLease = { ...updatedLease, [event.target.name.replace("Id", "Name")]: nameValue };
                    }
                }
                break;
            default:
                value = event.target.value;
                break;
        }

        if (event.target.name === "tenancyTypeId") {
            checkTenancyType(event.target.value)
        }

        if (event.target.name === "landlordTypeId") {
            checkLandlordType(event.target.value)
        }

        if (event.target.name === "leaseTypeId") {
            checkLeaseType(event.target.value)
        }
        
        ProcessLeaseWorkflow(updatedLease, lease, [...tenancyTypes, ...leaseStatusTypes]);
        
        setEditLease({
            ...updatedLease,
            [event.target.name]: value
        });
        
    };

    const handleCancel = () => {
        onCancel();
    };

    const handleSave = () => {
        console.log(validate(editLease));
        if (!validate(editLease)) {
            onUpdate(editLease);
        } else {
            enableValidationMessages();
        }
    }

    const handleAdd = () => {
        console.log(validate(editLease));
        if (!validate(editLease)) {
            onAdd(editLease);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    const leaseTypeOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...leaseTypes.map((leaseType) => {
            return { id: leaseType.id.toString() || 0, name: leaseType.name || "" };
        }),
    ];

    const repairAllowanceTypeOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...repairAllowanceTypes.map((referenceValue) => {
            return { id: referenceValue.id.toString() || 0, name: referenceValue.name || "" };
        }),
    ];

    const landlordTypeOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...landlordTypes.map((referenceValue) => {
            return { id: referenceValue.id.toString() || 0, name: referenceValue.name || "" };
        }),
    ];

    const tenancyTypeOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...tenancyTypes.map((referenceValue) => {
            return { id: referenceValue.id.toString() || 0, name: referenceValue.name || "" };
        }),
    ];

    let isSignedUpToS52IdOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...IsSignedUpToS52Types.map((referenceValue) => {
            return { id: referenceValue.id.toString() || 0, name: referenceValue.name || "" };
        }),
    ];

    let leaseProgressTypeOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...leaseProgressTypes.map((referenceValue) => {
            return { id: referenceValue.id.toString() || 0, name: referenceValue.name || "" };
        }),
    ];

    let leaseStatusTypeOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...leaseStatusTypes.map((referenceValue) => {
            return { id: referenceValue.id.toString() || 0, name: referenceValue.name || "" };
        }),
    ];

    function checkTenancyType(id: string) {
        const tenancyType = tenancyTypes.filter(tenancyType =>
            tenancyType.id.toString() === id
        );

        if (tenancyType[0].name === "Notional") {
            setOtherFieldsVisible(false);
            editLease.repairAllowanceTypeId = Guid.createEmpty();
        }
        else {
            setOtherFieldsVisible(true);
        }
    }

    function checkTenancyTypeState() {
        if (editLease.tenancyTypeName === "Notional") {
            return false;
        }
        else {
            return true;
        }
    }

    function checkLandlordType(id: string) {
        const landordType = landlordTypes.filter(landordType =>
            landordType.id.toString() === id
        );

        if (landordType[0].name === "CHP" || landordType[0].name === "NHSPS") {
            setSection52Visible(true);
        }
        else {
            setSection52Visible(false);

            const Section52NA = IsSignedUpToS52Types.filter(Section52NA =>
                Section52NA.name === "N/A"
            );

            editLease.isSignedUpToS52Id = Section52NA[0].id as Guid;
        }
    }

    function checkLandordTypeState() {
        if (editLease.landlordTypeName === "CHP" || editLease.landlordTypeName === "NHSPS") {
            return true;
        }
        else {
            return false;
        }
    }

    function checkLeaseType(id: string) {
        const leaseType = leaseTypes.filter(leaseType =>
            leaseType.id.toString() === id
        );

        if (leaseType[0].name === "Yes") {
            setRequiredFields(true);
        }
        else {
            setRequiredFields(false);
        }
    }

    function checkLeaseTypeState() {
        if (editLease.leaseTypeName === "Yes") {
            return true;
        }
        else {

            return false;
        }
    }

    return (
        <div>

            <SummaryListBase>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Tenancy Type</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <SelectInputBase
                            id="tenancyTypeId"
                            name="tenancyTypeId"
                            value={editLease.tenancyTypeId?.toString()}
                            options={tenancyTypeOptions}
                            required={true}
                            error={errors.tenancyTypeId}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                {otherFieldsVisible && <>                    
                    <SummaryListBaseRow>
                        <SummaryListBaseKey>Lease start Date</SummaryListBaseKey>
                        <SummaryListBaseValue>
                            <DateInputBase
                                id="leaseStartDate"
                                name="leaseStartDate"
                                placeholder="Lease Start Date"
                                value={editLease.leaseStartDate ? moment(editLease?.leaseStartDate).format("YYYY-MM-DD") : ""}
                                required={true}
                                error={errors.leaseStartDate}
                                type="date"
                                onChange={handleChange} />
                        </SummaryListBaseValue>
                    </SummaryListBaseRow>

                    <SummaryListBaseRow>
                        <SummaryListBaseKey>Lease End Date</SummaryListBaseKey>
                        <SummaryListBaseValue>
                            <DateInputBase
                                id="leaseEndDate"
                                name="leaseEndDate"
                                placeholder="Lease end Date"
                                value={editLease.leaseEndDate ? moment(editLease?.leaseEndDate).format("YYYY-MM-DD") : ""}
                                required={true}
                                error={errors.leaseEndDate}
                                type="date"
                                onChange={handleChange} />
                        </SummaryListBaseValue>
                    </SummaryListBaseRow>

                    <SummaryListBaseRow>
                        <SummaryListBaseKey>Lease Available</SummaryListBaseKey>
                        <SummaryListBaseValue>
                            <SelectInputBase
                                id="leaseTypeId"
                                name="leaseTypeId"
                                value={editLease.leaseTypeId?.toString()}
                                options={leaseTypeOptions}
                                required={true}
                                error={errors.leaseTypeId}
                                onChange={handleChange}
                            />
                        </SummaryListBaseValue>
                    </SummaryListBaseRow>

                    <SummaryListBaseRow>
                        <SummaryListBaseKey>Lease Progress</SummaryListBaseKey>
                        <SummaryListBaseValue>
                            <SelectInputBase
                                id="leaseProgressTypeId"
                                name="leaseProgressTypeId"
                                value={editLease.leaseProgressTypeId?.toString()}
                                options={leaseProgressTypeOptions}
                                required={confirmRequiredFields}
                                error={errors.leaseProgressTypeId}
                                onChange={handleChange}
                            />
                        </SummaryListBaseValue>
                    </SummaryListBaseRow>

                    <SummaryListBaseRow>
                        <SummaryListBaseKey>Lease Status Type</SummaryListBaseKey>
                        <SummaryListBaseValue>
                            <SelectInputBase
                                id="leaseStatusTypeId"
                                name="leaseStatusTypeId"
                                value={editLease.leaseStatusTypeId?.toString()}
                                options={leaseStatusTypeOptions}
                                required={confirmRequiredFields}
                                error={errors.leaseStatusTypeId}
                                onChange={handleChange}
                            />
                        </SummaryListBaseValue>
                    </SummaryListBaseRow>


                    <SummaryListBaseRow>
                        <SummaryListBaseKey>Landlord Type</SummaryListBaseKey>
                        <SummaryListBaseValue>
                            <SelectInputBase
                                id="landlordTypeId"
                                name="landlordTypeId"
                                value={editLease.landlordTypeId?.toString()}
                                options={landlordTypeOptions}
                                required={confirmRequiredFields}
                                error={errors.landlordTypeId}
                                onChange={handleChange}
                            />
                        </SummaryListBaseValue>
                    </SummaryListBaseRow>

                    <SummaryListBaseRow>
                        <SummaryListBaseKey>Landlord Details</SummaryListBaseKey>
                        <SummaryListBaseValue>
                            <TextInputBase
                                id="landlordDetails"
                                name="landlordDetails"
                                placeholder="Landlord Details"
                                value={editLease.landlordDetails}
                                required={confirmRequiredFields}
                                error={errors.landlordDetails}
                                onChange={handleChange} />
                        </SummaryListBaseValue>
                    </SummaryListBaseRow>

                    {confirmSection52Visible && <SummaryListBaseRow>
                        <SummaryListBaseKey>Is signed up to Section 52?</SummaryListBaseKey>
                        <SummaryListBaseValue>
                            <SelectInputBase
                                id="isSignedUpToS52Id"
                                name="isSignedUpToS52Id"
                                value={editLease.isSignedUpToS52Id?.toString()}
                                options={isSignedUpToS52IdOptions.filter(e => e.name !== "N/A")}
                                required={confirmRequiredFields}
                                error={errors.isSignedUpToS52Id}
                                onChange={handleChange} />
                        </SummaryListBaseValue>
                    </SummaryListBaseRow>}

                    <SummaryListBaseRow>
                        <SummaryListBaseKey>Repair Allowance Type</SummaryListBaseKey>
                        <SummaryListBaseValue>
                            <SelectInputBase
                                id="repairAllowanceTypeId"
                                name="repairAllowanceTypeId"
                                value={editLease.repairAllowanceTypeId?.toString()}
                                options={repairAllowanceTypeOptions}
                                required={confirmRequiredFields}
                                error={errors.repairAllowanceTypeId}
                                onChange={handleChange}
                            />
                        </SummaryListBaseValue>
                    </SummaryListBaseRow>

                    {editLease.leaseStatusTypeName !== "Complete" &&  <SummaryListBaseRow>
                        <SummaryListBaseKey>DV Deadline Date</SummaryListBaseKey>
                        <SummaryListBaseValue>
                            <DateInputBase
                                id="dvDeadlineDate"
                                name="dvDeadlineDate"
                                placeholder="DV Deadline Date"
                                value={editLease.dvDeadlineDate ? moment(editLease?.dvDeadlineDate).format("YYYY-MM-DD") : ""}
                                required={confirmRequiredFields}
                                error={errors.dvDeadlineDate}
                                type="date"
                                onChange={handleChange} />
                        </SummaryListBaseValue>
                    </SummaryListBaseRow>}
                </>}
            </SummaryListBase>

            {mode === "ADD" && (
                <SecuredComponents allowedRoles={securityPoints.leases.add}>
                    <>
                        <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>
                        <ButtonBase id="AddLeaseBtn" onClick={() => handleAdd()} add>Add</ButtonBase>
                    </>
                </SecuredComponents>
            )}

            {mode !== "ADD" && (
                <SecuredComponents allowedRoles={securityPoints.leases.edit}>
                    <>
                        <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>
                        <ButtonBase onClick={() => handleSave()} edit>Update</ButtonBase>
                    </>
                </SecuredComponents>
            )}
        </div>
    );
}

export default LeaseDetailCardEdit;
