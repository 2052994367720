import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import securityPoints from "../../SecurityMatrix";
import { SecuredComponents } from "../Links";

interface ProviderStatusRowNewProps {
    onAdd: (value: boolean) => void;
}

const ProviderStatusRowNew: FunctionComponent<ProviderStatusRowNewProps> = (props) => {
    const {
        onAdd
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData></TableBaseData>
            <TableBaseData></TableBaseData>
            <TableBaseData classes="text-end">
                <SecuredComponents allowedRoles={securityPoints.providerStatuses.add}>
                    <ButtonBase onClick={() => onAdd(true)} add> <FontAwesomeIcon icon={faCirclePlus} size="lg" />&nbsp; New</ButtonBase>
                </SecuredComponents>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ProviderStatusRowNew;
