import React, { FunctionComponent, useMemo, useState } from "react";
import { debounce } from "lodash";
import SearchBase from "../bases/inputs/SearchBase";
import ActionsTable from "./actionsTable";
import { actionViewService } from "../../services/views/actions/actionViewService";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";

type ActionsAllProps = {}

const ActionsAll: FunctionComponent<ActionsAllProps> = () => {

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedActions: actionsRetrieved, isLoading }
        = actionViewService.useGetAllActions(debouncedTerm);

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , []);

    return (
        <div className="infiniteScollContainer">
            <SearchBase id="search" label="Search practices" value={searchTerm} onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />
            {isLoading && <> <SpinnerBase />.</>}
            {actionsRetrieved &&
                <ActionsTable actionsRetrieved={actionsRetrieved} showGoTo={true} />
            }
        </div >
    );
}

export default ActionsAll;