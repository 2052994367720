import { Validation } from "../../models/validations/validation";

export const boroughValidations: Array<Validation> = [
    {
        property: "name",
        friendlyName: "Borough name",
        isRequired: true,
        minLength: 3,
        maxLength: 255,
    },
    {
        property: "financeManagerName",
        friendlyName: "Finance manager name",
        minLength: 0,
        maxLength: 255,
    },
    {
        property: "financeManagerEmail",
        friendlyName: "Finance manager email",
        minLength: 0,
        maxLength: 255,
    }
]