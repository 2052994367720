import { Guid } from 'guid-typescript';

export class Discussion {
    public id: Guid;
    public siteId: Guid;
    public comment?: string;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(discussion: any) {
        this.id = Guid.parse(discussion.id);
        this.siteId = Guid.parse(discussion.siteId);
        this.comment = discussion.comment;
        this.createdByUser = discussion.createdByUser
        this.createdDate = new Date(discussion.createdDate)
        this.updatedByUser = discussion.updatedByUser
        this.updatedDate = new Date(discussion.updatedDate)
    }
}