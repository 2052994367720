import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import { LeaseTypeView } from "../../models/views/components/leaseTypes/leaseTypeView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { toastSuccess } from "../../brokers/toastBroker";

interface LeaseTypeRowDeleteProps {
    leaseType: LeaseTypeView;
    onCancel: (value: string) => void;
    onDelete: (leaseType: LeaseTypeView) => void;
}

const LeaseTypeRowDelete: FunctionComponent<LeaseTypeRowDeleteProps> = (props) => {
    const {
        leaseType,
        onCancel,
        onDelete
    } = props;

    const handleDelete = (leaseType: LeaseTypeView) => {
        onDelete(leaseType);
        toastSuccess(`${leaseType.name} Deleted`);
    }

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{leaseType.name}</b><br />
                {leaseType.description}<br />
            </TableBaseData>
            <TableBaseData>
                {leaseType.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="grey" title="Inactive" /> }
                {leaseType.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel('VIEW')} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleDelete(leaseType)} remove>Yes, Delete</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default LeaseTypeRowDelete;
