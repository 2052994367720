import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import PropertyStatusBroker from "../../brokers/apiBroker.propertyStatuses"
import { PropertyStatus } from "../../models/propertyStatuses/propertyStatus";

export const propertyStatusService = {
    useCreatePropertyStatus: () => {
        const propertyStatusBroker = new PropertyStatusBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((propertyStatus: PropertyStatus) => {
            const date = new Date();
            propertyStatus.createdDate = propertyStatus.updatedDate = date;
            propertyStatus.createdByUser = propertyStatus.updatedByUser = msal.accounts[0].username;

            return propertyStatusBroker.PostPropertyStatusAsync(propertyStatus);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("PropertyStatusGetAll");
                    queryClient.invalidateQueries(["PropertyStatusGetById", { id: data.id }]);
                }
            });
    },

    useGetAllPropertyStatuses: (query: string) => {
        const propertyStatusBroker = new PropertyStatusBroker();

        return useQuery(
            ["PropertyStatusGetAll", { query: query }],
            () => propertyStatusBroker.GetAllPropertyStatusesAsync(query),
            { staleTime: Infinity });
    },

    useGetPropertyStatusById: (id: Guid) => {
        const propertyStatusBroker = new PropertyStatusBroker();

        return useQuery(
            ["PropertyStatusGetById", { id: id }],
            () => propertyStatusBroker.GetPropertyStatusByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdatePropertyStatus: () => {
        const propertyStatusBroker = new PropertyStatusBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((propertyStatus: PropertyStatus) => {
            const date = new Date();
            propertyStatus.updatedDate = date;
            propertyStatus.updatedByUser = msal.accounts[0].username;

            return propertyStatusBroker.PutPropertyStatusAsync(propertyStatus);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("PropertyStatusGetAll");
                    queryClient.invalidateQueries(["PropertyStatusGetById", { id: data.id }]);
                }
            });
    },

    useDeletePropertyStatus: () => {
        const propertyStatusBroker = new PropertyStatusBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return propertyStatusBroker.DeletePropertyStatusByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("PropertyStatusGetAll");
                    queryClient.invalidateQueries(["PropertyStatusGetById", { id: data.id }]);
                }
            });
    },
}