import { Guid } from "guid-typescript";

import { PropertyStatus } from "../models/propertyStatuses/propertyStatus";
import ApiBroker from "./apiBroker";

class PropertyStatusBroker {

    relativePropertyStatusUrl = '/api/propertyStatuses';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostPropertyStatusAsync(propertyStatus: PropertyStatus) {
        return await this.apiBroker.PostAsync(this.relativePropertyStatusUrl, propertyStatus)
            .then(result => new PropertyStatus(result.data));
    }

    async GetAllPropertyStatusesAsync(queryString: string) {
        var url = this.relativePropertyStatusUrl + queryString;
        
        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((propertyStatus: any) => new PropertyStatus(propertyStatus)));
    }

    async GetPropertyStatusByIdAsync(id: Guid) {
        const url = `${this.relativePropertyStatusUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new PropertyStatus(result.data));
    }

    async PutPropertyStatusAsync(propertyStatus: PropertyStatus) {
        return await this.apiBroker.PutAsync(this.relativePropertyStatusUrl, propertyStatus)
            .then(result => new PropertyStatus(result.data));
    }

    async DeletePropertyStatusByIdAsync(id: Guid) {
        const url = `${this.relativePropertyStatusUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new PropertyStatus(result.data));
    }
}

export default PropertyStatusBroker;



