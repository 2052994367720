import { Guid } from 'guid-typescript';
import { Lease } from '../leases/lease';
import { ReviewStage } from '../reviewStages/reviewStage';
import { ReviewStatus } from '../reviewStatuses/reviewStatus';

export class RentReview {
    public id: Guid;
    public leaseId: Guid;
    public reviewStageId?: Guid;
    public reviewStageChangedDate?: Date;
    public reviewStatusId?: Guid;
    public reviewStatusChangedDate?: Date;
    public rentReviewDueDate?: Date;
    public agreedRentAmount?: number;
    public nextRentReview?: Date;
    public nextRentAmount?: number;
    public repairAllowance?: number;
    public pcifImprovementGrantAmountApplicable?: string;
    public valuationDecision?: string;
    public districtValuerName?: string;
    public districtValuerEmail?: string;
    public districtValuerPoNumber?: string;
    public districtValuerRefNo?: string;
    public districtValuerDeadlineDate?: Date;
    public isSilentRentReview?: boolean;
    public isActive?: boolean;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;
    public lease?: Lease;
    public reviewStage?: ReviewStage;
    public reviewStatus?: ReviewStatus;
    public reviewClosedDate?: Date;

    constructor(rentReview: any) {
        this.id = rentReview.id ? rentReview.id : Guid.parse(Guid.EMPTY);
        this.leaseId = rentReview.leaseId;
        this.reviewStageId = rentReview.reviewStageId ? Guid.parse(rentReview.reviewStageId) : undefined;
        this.reviewStageChangedDate = new Date(rentReview.reviewStageChangedDate);
        this.reviewStatusId = rentReview.reviewStatusId ? Guid.parse(rentReview.reviewStatusId) : undefined;
        this.reviewStatusChangedDate = new Date(rentReview.reviewStatusChangedDate);
        this.rentReviewDueDate = new Date(rentReview.rentReviewDueDate);
        this.agreedRentAmount = rentReview.agreedRentAmount;

        if (rentReview.nextRentReview === null || rentReview.nextRentReview === undefined) {
            this.nextRentReview = undefined;
        }
        else {
            this.nextRentReview = new Date(rentReview.nextRentReview);
        }

        this.nextRentAmount = rentReview.nextRentAmount;
        this.repairAllowance = rentReview.repairAllowance;
        this.pcifImprovementGrantAmountApplicable = rentReview.pcifImprovementGrantAmountApplicable;
        this.valuationDecision = rentReview.valuationDecision;
        this.districtValuerName = rentReview.districtValuerName;
        this.districtValuerEmail = rentReview.districtValuerEmail;
        this.districtValuerPoNumber = rentReview.districtValuerPoNumber;
        this.districtValuerRefNo = rentReview.districtValuerRefNo;

        if (rentReview.districtValuerDeadlineDate === null || rentReview.districtValuerDeadlineDate === undefined) {
            this.districtValuerDeadlineDate = undefined;
        }
        else {
            this.districtValuerDeadlineDate = new Date(rentReview.districtValuerDeadlineDate);
        }

        this.isSilentRentReview = false;
        this.isActive = rentReview.isActive;
        this.createdByUser = rentReview.createdByUser
        this.createdDate = new Date(rentReview.createdDate)
        this.updatedByUser = rentReview.updatedByUser
        this.updatedDate = new Date(rentReview.updatedDate)
        this.lease = rentReview?.lease;
        this.reviewStage = rentReview?.reviewStage;
        this.reviewStatus = rentReview?.reviewStatus;
        this.reviewClosedDate = rentReview?.reviewClosedDate;
    }
}