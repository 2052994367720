import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ContactTypeView } from "../../models/views/components/contactTypes/contactTypeView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { toastInfo } from "../../brokers/toastBroker";

interface ContactTypeRowDeleteProps {
    contactType: ContactTypeView;
    onCancel: () => void;
    onDelete: (contactType: ContactTypeView) => void;
}

const ContactTypeRowDelete: FunctionComponent<ContactTypeRowDeleteProps> = (props) => {
    const {
        contactType,
        onCancel,
        onDelete
    } = props;

    const handleDelete = (contactType: ContactTypeView) => {
        onDelete(contactType);
        toastInfo(`${contactType.name} Deleted`);
    }

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{contactType.name}</b>
            </TableBaseData>
            <TableBaseData>
                {contactType.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="grey" title="Inactive" /> }
                {contactType.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleDelete(contactType)} remove>Yes, Delete</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ContactTypeRowDelete;
