import { useMsal } from "@azure/msal-react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import PracticeBroker from "../../brokers/apiBroker.practices";
import { Practice } from "../../models/practices/practice";


export const practiceService = {
    useCreatePractice: () => {
        const practiceBroker = new PracticeBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((practice: Practice) => {
            const date = new Date();
            practice.createdDate = practice.updatedDate = date;
            practice.createdByUser = practice.updatedByUser = msal.accounts[0].username;

            return practiceBroker.PostPracticeAsync(practice);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("PracticeGetAll");
                    queryClient.invalidateQueries(["PracticeGetById", { id: data.id }]);
                }
            });
    },

    useGetAllPractices: (query: string) => {
        const practiceBroker = new PracticeBroker();

        return useQuery(
            ["PracticeGetAll", { query: query }],
            () => practiceBroker.GetAllPracticeAsync(query),
            { staleTime: Infinity });
    },

    useGetPracticePages: (query: string) => {
        const practiceBroker = new PracticeBroker();

        return useInfiniteQuery(
            ["PracticeGetAll", { query: query }],
            ({ pageParam }) => {
                if (!pageParam) {
                    return practiceBroker.GetPracticeFirstPagesAsync(query)
                }
                return practiceBroker.GetPracticesSubsequentPagesAsync(pageParam)
            },
            {
                getNextPageParam: (lastPage) => lastPage.nextPage,
                staleTime: Infinity
            });
    },

    useGetPracticeById: (id: Guid) => {
        const practiceBroker = new PracticeBroker();

        return useQuery(
            ["PracticeGetById", { id: id }],
            () => practiceBroker.GetPracticeByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdatePractice: () => {
        const practiceBroker = new PracticeBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((practice: Practice) => {
            const date = new Date();
            practice.updatedDate = date;
            practice.updatedByUser = msal.accounts[0].username;

            return practiceBroker.PutPracticeAsync(practice);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("PracticeGetAll");
                    queryClient.invalidateQueries(["PracticeGetById", { id: data.id }]);
                }
            });
    },

    useDeletePractice: () => {
        const practiceBroker = new PracticeBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return practiceBroker.DeletePracticeByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("PracticeGetAll");
                    queryClient.invalidateQueries(["PracticeGetById", { id: data.id }]);
                }
            });
    },
}
