import { Validation } from "../../models/validations/validation";

export const siteDetailsValidation: Array<Validation> = [{
    property: "siteName",
    friendlyName: "Site name",
    isRequired: true,
    minLength: 3,
    maxLength: 255,
},
{
    property: "addressLine1",
    friendlyName: "Address Line 1",
    isRequired: true,
    minLength: 3,
    maxLength: 255
},
{
    property: "addressLine2",
    friendlyName: "Address Line 2",
    maxLength: 255
},
{
    property: "addressLine3",
    friendlyName: "Address Line 3",
    maxLength: 255
},
{
    property: "postCode",
    friendlyName: "Postcode",
    isRequired: true,
    minLength: 3,
    maxLength: 255
},
{
    property: "siteTypeId",
    friendlyName: "Site Type",
    isRequired: true,
},
{
    property: "contractId",
    friendlyName: "Contract Type",
    isRequired: true,
},
{
    property: "ownershipId",
    friendlyName: "Ownership Type",
    isRequired: true,
},
{
    property: "propertyStatusId",
    friendlyName: "Property Status",
    isRequired: true,
},
{
    property: "closureDate",
    friendlyName: "Closure Date",
    isRequired: true,
    predicate: (value: any) => { 
        return value.propertyStatusName === "Closed"; 
    }
}
]