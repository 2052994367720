import { LeaseView } from "../../models/views/components/leases/leaseView";
import { LookupView } from "../../models/views/components/lookups/lookupView";

const config = [{
    field: "tenancyTypeId",
    workflow: "SetLeaseStartEndDatesWhenNotional",
    action: (values: LeaseView) => {
        values.leaseStartDate = new Date();
        values.leaseEndDate = new Date();
        values.landlordDetails = "Notional"}
}, {
    field: "leaseStatusTypeId",
        workflow: "ClearDVDeadlineWhenCompleted",
        action: (values: LeaseView) => { values.dvDeadlineDate = undefined },
    }]

const ProcessLeaseWorkflow = (updatedValues: LeaseView, originalValues: LeaseView, lookups: LookupView[]) => {
    Object.keys(originalValues).forEach((key) => {
        const originalValue = originalValues[key as keyof typeof originalValues];
        const updatedValue = updatedValues[key as keyof typeof updatedValues];

        if (originalValue !== updatedValue) {
            if (config.find(x => x.field === key)) {
                const workflowName = lookups.find(x => x.id.toString() === updatedValue)?.workflowAction;

                if (workflowName) {
                    config.filter(x => x.workflow === workflowName).forEach((item) => {
                        item.action(updatedValues);
                    });
                }
            }
        }
    });
}

export default ProcessLeaseWorkflow;