import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { ReviewStage } from "../../../models/reviewStages/reviewStage";
import { ReviewStageView } from "../../../models/views/components/reviewStages/reviewStageView";
import { reviewStageService } from "../../foundations/reviewStageService";

export const reviewStageViewService = {

    useCreateReviewStage: () => {
        return reviewStageService.useCreateReviewStage();
    },

    useGetAllReviewStages: (searchTerm?: string) => {
        try {
            let query = '?$orderby=sortOrder,name';

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`;
            }

            const response = reviewStageService.useGetAllReviewStages(query);
            const [mappedReviewStages, setMappedReviewStages] = useState<Array<ReviewStageView>>();

            useEffect(() => {
                if (response.data) {
                    const reviewStages = response.data.map((reviewStage: ReviewStage) =>
                        new ReviewStageView(
                            reviewStage.id,
                            reviewStage.name,
                            reviewStage.description,
                            reviewStage.isActive,
                            reviewStage.sortOrder,
                            reviewStage.createdByUser,
                            reviewStage.createdDate,
                            reviewStage.updatedByUser,
                            reviewStage.updatedDate,
                            reviewStage.workflowAction
                        ));

                    setMappedReviewStages(reviewStages);
                }
            }, [response.data]);

            return {
                mappedReviewStages, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetReviewStageById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = reviewStageService.useGetAllReviewStages(query);
            const [mappedReviewStage, setMappedReviewStage] = useState<ReviewStageView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const reviewStage = new ReviewStageView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].description,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].upatedDate);

                    setMappedReviewStage(reviewStage);
                }
            }, [response.data]);

            return {
                mappedReviewStage, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateReviewStage: () => {
        return reviewStageService.useUpdateReviewStage();
    },

    useRemoveReviewStage: () => {
        return reviewStageService.useDeleteReviewStage();
    },
}