import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { ContactTypeView } from "../../models/views/components/contactTypes/contactTypeView";
import TextInputBase from "../bases/inputs/TextInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import { useValidation } from "../../hooks/useValidation";
import { contactTypeErrors } from "./contactTypesErrors";
import { contactTypeValidations } from "./contactTypesValidations";

interface ContactTypeRowEditProps {
    contactType: ContactTypeView;
    onCancel: () => void;
    onEdit: (contactType: ContactTypeView) => void;
    apiError?: any;
}

const ContactTypeRowEdit: FunctionComponent<ContactTypeRowEditProps> = (props) => {
    const {
        contactType,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editContactType, setEditContactType] = useState<ContactTypeView>({ ...contactType });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(contactTypeErrors, contactTypeValidations, editContactType);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const updatedContactType = {
            ...editContactType,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditContactType(updatedContactType);
    };

    const handleSave = () => {
        if (!validate(editContactType)) {
            onEdit(editContactType);
        } else {
            enableValidationMessages();
        }
    }

    const handleCancel = () => {
        setEditContactType({ ...contactType });
        onCancel();
    };


    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Contact Type Name"
                    placeholder="Contact Type Name"
                    value={editContactType.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={editContactType.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={editContactType.isActive === false ? false : true}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ContactTypeRowEdit;
