import React, { FunctionComponent } from "react";
import ActionsRowView from "./actionsRowView";
import { ActionView } from "../../models/views/components/actions/actionView";

type ActionsRowProps = {
    leaseAction: ActionView;
    apiError?: any;
    showGoTo?: boolean;
}

const ActionsRow: FunctionComponent<ActionsRowProps> = (props) => {
    const {
        leaseAction,
        showGoTo
    } = props;

    return (
        <>
            {leaseAction &&
                <ActionsRowView
                    key={leaseAction.id?.toString() + leaseAction.type?.toString()}
                    leaseAction={leaseAction}
                    showGoTo={showGoTo}
                />
            }
        </>
    );
}

export default ActionsRow;