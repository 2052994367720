import { LookupView } from "../../../models/views/components/lookups/lookupView";
import { Borough } from "../../../models/boroughs/borough";
import { Contract } from "../../../models/contracts/contract";
import { Lease } from "../../../models/leases/lease";
import { Ownership } from "../../../models/ownerships/ownership";
import { Practice } from "../../../models/practices/practice";
import { PropertyStatus } from "../../../models/propertyStatuses/propertyStatus";
import { ReviewStage } from "../../../models/reviewStages/reviewStage";
import { ReviewStatus } from "../../../models/reviewStatuses/reviewStatus";
import { SiteType } from "../../../models/siteTypes/siteType";
import { boroughService } from "../../foundations/boroughService";
import { contractService } from "../../foundations/contractService";
import { leaseService } from "../../foundations/leaseService";
import { ownershipService } from "../../foundations/ownershipService";
import { practiceService } from "../../foundations/practiceService";
import { propertyStatusService } from "../../foundations/propertyStatusService";
import { reviewStageService } from "../../foundations/reviewStageService";
import { reviewStatusService } from "../../foundations/reviewStatusService";
import { siteTypeService } from "../../foundations/siteTypeService";
import { ContactType } from "../../../models/contactTypes/contactType";
import { contactTypeService } from "../../foundations/contactTypeService";
import { useEffect, useState } from "react";
import moment from "moment";
import { rentReviewService } from "../../foundations/rentReviewService";
import { RentReview } from "../../../models/rentReviews/rentReview";
import { providerStatusService } from "../../foundations/providerStatusService";
import { ProviderStatus } from "../../../models/providerStatuses/providerStatus";
import { LeaseType } from "../../../models/leaseTypes/leaseType";
import { leaseTypeService } from "../../foundations/leaseTypeService";
import { referenceValueService } from "../../foundations/referenceValueService";
import { ReferenceValue } from "../../../models/referenceValues/referenceValue";

export const lookupViewService = {

    useGetBoroughList: (searchTerm?: string) => {
        try {
            let query = `?$select=id,name&$orderby=name`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = boroughService.useGetAllBoroughs(query);
            const [mappedBoroughs, setMappedBoroughs] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const boroughs = response.data.map((borough: Borough) =>
                        new LookupView(borough.id, borough.name));

                    setMappedBoroughs(boroughs);
                }
            }, [response.data]);

            return {
                mappedBoroughs, ...response
            }

        } catch (err) {
            throw err;
        }
    },

    useGetContactType: (searchTerm?: string) => {
        try {
            let query = `?$select=id,name,isActive&$orderby=sortOrder,name`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = contactTypeService.useGetAllContactTypes(query);
            const [mappedContactTypes, setMappedContactTypes] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const contactTypes = response.data.filter((s: LookupView) => s.isActive === true).map((contactType: ContactType) =>
                        new LookupView(contactType.id, contactType.name))

                    setMappedContactTypes(contactTypes);
                }
            }, [response.data]);

            return {
                mappedContactTypes, ...response
            }

        } catch (err) {
            throw err;
        }
    },

    useGetContractList: (searchTerm?: string) => {
        try {
            let query = `?$select=id,name&$orderby=sortOrder,name`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = contractService.useGetAllContracts(query);
            const [mappedContracts, setMappedContracts] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const contracts = response.data.map((contract: Contract) =>
                        new LookupView(contract.id, contract.name));

                    setMappedContracts(contracts);
                }
            }, [response.data]);

            return {
                mappedContracts, ...response
            }

        } catch (err) {
            throw err;
        }
    },

    useGetOwnershipList: (searchTerm?: string) => {
        try {
            let query = `?$select=id,name,isActive&$orderby=sortOrder,name`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = ownershipService.useGetAllOwnerships(query);
            const [mappedOwnerships, setMappedOwnerships] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const ownerships = response.data.filter((s: LookupView) => s.isActive === true).map((ownership: Ownership) =>
                        new LookupView(ownership.id, ownership.name));

                    setMappedOwnerships(ownerships);
                }
            }, [response.data]);

            return {
                mappedOwnerships, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetPracticeList: (searchTerm?: string) => {
        try {
            let query = `?&select=id,practiceName,practiceCode&$orderby=practiceName`;

            if (searchTerm) {
                query = query + `&$filter=contains(practiceName,'${searchTerm}') or contains(practiceCode,'${searchTerm}')`
            }

            const response = practiceService.useGetAllPractices(query);
            const [mappedPractices, setMappedPractices] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const practices = response.data.map((practice: Practice) =>
                        new LookupView(practice.id, practice.practiceName));

                    setMappedPractices(practices);
                }
            }, [response.data]);

            return {
                mappedPractices, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetPropertyStatusList: (searchTerm?: string) => {
        try {
            let query = `?$select=id,name,isActive&$orderby=sortOrder,name`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = propertyStatusService.useGetAllPropertyStatuses(query);
            const [mappedPropertyStatuses, setMappedPropertyStatuses] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const propertyStatuses = response.data.filter((s: LookupView) => s.isActive === true).map((propertyStatus: PropertyStatus) =>
                        new LookupView(propertyStatus.id, propertyStatus.name));

                    setMappedPropertyStatuses(propertyStatuses);
                }
            }, [response.data]);

            return {
                mappedPropertyStatuses, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetReviewStageList: (searchTerm?: string) => {
        try {
            let query = `?$select=id,name,workflowAction,isActive&$orderby=sortOrder,name`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = reviewStageService.useGetAllReviewStages(query);
            const [mappedReviewStages, setMappedReviewStages] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const reviewStages = response.data.filter((s: LookupView) => s.isActive === true).map((reviewStage: ReviewStage) =>
                        new LookupView(reviewStage.id, reviewStage.name, undefined, reviewStage.workflowAction));

                    setMappedReviewStages(reviewStages);
                }
            }, [response.data]);

            return {
                mappedReviewStages, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetReviewStatusList: (searchTerm?: string) => {
        try {
            let query = `?$select=id,name,isActive&$orderby=sortOrder,name`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = reviewStatusService.useGetAllReviewStatuses(query);
            const [mappedReviewStatuses, setMappedReviewStatuses] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const reviewStatuses = response.data.filter((s: LookupView) => s.isActive === true).map((reviewStatus: ReviewStatus) =>
                        new LookupView(reviewStatus.id, reviewStatus.name));

                    setMappedReviewStatuses(reviewStatuses);
                }
            }, [response.data]);

            return {
                mappedReviewStatuses, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetLeaseList: (searchTerm?: string) => {
        try {
            let query = `?$select=*&$orderby=leaseStartDate desc`;

            if (searchTerm) {
                query = query + `&$filter=siteId eq ${searchTerm}`
            }

            const response = leaseService.useGetAllLeases(query);
            const [mappedLeases, setMappedLeases] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const leases = response.data.map((lease: Lease) =>
                        new LookupView(lease.id, moment(lease.leaseStartDate?.toString()).format("Do-MMM-yyyy"), lease.isActive));

                        setMappedLeases(leases);
                    }
            },[response.data])
           
            return {
                mappedLeases, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetSiteTypeList: (searchTerm?: string) => {
        try {
            let query = `?select=id,name,isActive&$orderby=name`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = siteTypeService.useGetAllSiteTypes(query);
            const [mappedSiteTypes, setMappedSiteTypes] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const siteTypes = response.data.filter((s: LookupView) => s.isActive === true).map((siteType: SiteType) =>
                        new LookupView(siteType.id, siteType.name));

                    setMappedSiteTypes(siteTypes);
                }
            }, [response.data]);

            return {
                mappedSiteTypes, ...response
            }

        } catch (err) {
            throw err;
        }
    },

    useGetRentReviewList: (searchTerm?: string) => {
        try {
            let query = `?$select=id,rentReviewDueDate,isActive&$orderby=rentReviewDueDate desc`;

            if (searchTerm) {
                query = query + `&$filter=leaseId eq ${searchTerm}`
            }

            const response = rentReviewService.useGetAllRentReviews(query);
            const [mappedRentReviews, setMappedRentReviews] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const rentReviews = response.data.map((rentReview: RentReview) =>
                        new LookupView(rentReview.id, moment(rentReview.rentReviewDueDate?.toString()).format("Do-MMM-yyyy"), rentReview.isActive));

                    setMappedRentReviews(rentReviews);
                }
            }, [response.data])

            return {
                mappedRentReviews, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetProviderStatusList: (searchTerm?: string) => {
        try {
            let query = `?$select=id,name,isActive&$orderby=name`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = providerStatusService.useGetAllProviderStatuses(query);
            const [mappedProviderStatus, setMappedProviderStatus] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const providerStatus = response.data.filter((s: LookupView) => s.isActive === true).map((providerStatus: ProviderStatus) =>
                        new LookupView(providerStatus.id, providerStatus.name));

                    setMappedProviderStatus(providerStatus);
                }
            }, [response.data]);

            return {
                mappedProviderStatus, ...response
            }

        } catch (err) {
            console.log(err);
            throw err;
        }
    },
    useGetLeaseTypeList: (searchTerm?: string) => {
        try {
            let query = `?select=id,name,isActive&$orderby=name`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = leaseTypeService.useGetAllLeaseTypes(query);
            const [mappedLeaseTypes, setMappedLeaseTypes] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const leaseTypes = response.data.filter((s: LookupView) => s.isActive === true).map((leaseType: LeaseType) =>
                        new LookupView(leaseType.id, leaseType.name));

                    setMappedLeaseTypes(leaseTypes);
                }
            }, [response.data]);

            return {
                mappedLeaseTypes, ...response
            }

        } catch (err) {
            throw err;
        }
    },
    useGetReferenceValueList: (searchTerm?: string) => {
        try {
            let query = `?select=id,name,isActive,referenceValueType,workflowAction&$orderby=name&`;

            if (searchTerm) {
                query = query + `&$filter=contains(referenceValueType,'${searchTerm}')`
            }

            const response = referenceValueService.useGetAllReferenceValues(query);
                
            const [mappedReferenceValues, setMappedReferenceValues] = useState<Array<LookupView>>([]);

            useEffect(() => {
                if (response.data) {
                    const referenceValues = response.data.filter((s: LookupView) => s.isActive === true).map((referenceValue: ReferenceValue) =>
                        new LookupView(referenceValue.id, referenceValue.name, referenceValue.isActive, referenceValue.workFlowAction));

                    setMappedReferenceValues(referenceValues);
                }
            }, [response.data]);

            return {
                mappedReferenceValues, ...response
            }

        } catch (err) {
            throw err;
        }
    },
}