import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import ContractBroker from "../../brokers/apiBroker.contracts"
import { Contract } from "../../models/contracts/contract";

export const contractService = {
    useCreateContract: () => {
        const contractBroker = new ContractBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((contract: Contract) => {
            const date = new Date();
            contract.createdDate = contract.updatedDate = date;
            contract.createdByUser = contract.updatedByUser = msal.accounts[0].username;

            return contractBroker.PostContractAsync(contract)
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ContractGetAll");
                    queryClient.invalidateQueries(["ContractGetById", { id: data.id }]);
                }
            });
    },

    useGetAllContracts: (query: string) => {
        const contractBroker = new ContractBroker();

        return useQuery(
            ["ContractGetAll", { query: query }],
            () => contractBroker.GetAllContractsAsync(query),
            { staleTime: Infinity });
    },

    useGetContractById: (id: Guid) => {
        const contractBroker = new ContractBroker();

        return useQuery(
            ["ContractGetById", { id: id }],
            () => contractBroker.GetContractByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateContract: () => {
        const contractBroker = new ContractBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((contract: Contract) => {
            const date = new Date();
            contract.updatedDate = date;
            contract.updatedByUser = msal.accounts[0].username;

            return contractBroker.PutContractAsync(contract)
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ContractGetAll");
                    queryClient.invalidateQueries(["ContractGetById", { id: data.id }]);
                }
            });
    },

    useDeleteContract: () => {
        const contractBroker = new ContractBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return contractBroker.DeleteContractByIdAsync(id)
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ContractGetAll");
                    queryClient.invalidateQueries(["ContractGetById", { id: data.id }]);
                }
            });
    },
}