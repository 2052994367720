import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import { ReferenceValueView } from "../../models/views/components/referenceValues/referenceValueView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { toastSuccess } from "../../brokers/toastBroker";

interface ReferenceValueRowDeleteProps {
    referenceValue: ReferenceValueView;
    onCancel: (value: string) => void;
    onDelete: (referenceValue: ReferenceValueView) => void;
}

const ReferenceValueRowDelete: FunctionComponent<ReferenceValueRowDeleteProps> = (props) => {
    const {
        referenceValue,
        onCancel,
        onDelete
    } = props;

    const handleDelete = (referenceValue: ReferenceValueView) => {
        onDelete(referenceValue);
        toastSuccess(`${referenceValue.name} Deleted`);
    }

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{referenceValue.name}</b><br />
                {referenceValue.description}<br />
            </TableBaseData>
            <TableBaseData>
                {referenceValue.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="grey" title="Inactive" /> }
                {referenceValue.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel('VIEW')} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleDelete(referenceValue)} remove>Yes, Delete</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReferenceValueRowDelete;
