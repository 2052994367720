import React, { FunctionComponent } from "react";
import { Guid } from "guid-typescript";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import ActionsByPractice from "./actionsByPractice";

type ContactTableProps = {
    practiceId: Guid;
    searchVisible: boolean;
}

const PracticeActions: FunctionComponent<ContactTableProps> = (props) => {
    const {
        practiceId,
        searchVisible
    } = props;

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>Actions</CardBaseTitle>
                    <CardBaseContent>
                        <ActionsByPractice practiceId={practiceId} searchVisible={searchVisible} />
                    </CardBaseContent>
            </CardBaseBody>
            </CardBase>
        </>
    );
}

export default PracticeActions;