import React from 'react';
import { Container, Form, Nav, Navbar } from "react-bootstrap";
import securityPoints from '../SecurityMatrix';
import { SecuredComponents, SecuredLink } from './Links';

export const NavigationBar = () => {
    return (
        <>
            <Navbar style={{ backgroundColor: "#005eb8" }}>
                <Container style={{ maxWidth: "1040px", backgroundColor: "#005eb8" }} >
                    <Navbar.Brand href="#"></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll>
                            <SecuredLink to="/">Home</SecuredLink>
                            <SecuredLink to="/practices">Practices</SecuredLink>
                            <SecuredLink to="/actions">Actions</SecuredLink>
                        </Nav>
                        <Form className="d-flex">
                            <SecuredComponents allowedRoles={securityPoints.configNavigation.view}>
                                <SecuredLink to="/configuration">Config</SecuredLink>
                            </SecuredComponents>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};