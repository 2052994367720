import React, { FunctionComponent } from "react";
import { PracticeView } from "../../models/views/components/practices/practiceView";
import ButtonBase from "../bases/buttons/ButtonBase";
import SummaryListBase from "../bases/components/SummaryList/SummaryListBase";
import SummaryListBaseRow from "../bases/components/SummaryList/SummaryListBase.Row";
import SummaryListBaseKey from "../bases/components/SummaryList/SummaryListBase.Key";
import SummaryListBaseValue from "../bases/components/SummaryList/SummaryListBase.Value";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";
import moment from "moment";

interface PracticeDetailCardViewProps {
    practice: PracticeView;
    onDelete: (practice: PracticeView) => void;
    mode: string;
    onModeChange: (value: string) => void;
}

const PracticeDetailCardView: FunctionComponent<PracticeDetailCardViewProps> = (props) => {
    const {
        practice,
        onDelete,
        mode,
        onModeChange
    } = props;


    return (
        <>
            <SummaryListBase>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Place Name</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.boroughName}</SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Practice Name</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.practiceName}</SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Practice Code</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.practiceCode}</SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Practice Phone</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.practicePhone}</SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Practice Phone Bypass</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.practicePhoneBypass}</SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Practice Generic Email</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.practiceGenericEmail}</SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Group Provider Name</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.groupName}</SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Place Code</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.odsCode}</SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Primary Care Network (PCN)</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.pcn}</SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>PCN Code</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.pcnCode}</SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Provider Status</SummaryListBaseKey>
                    <SummaryListBaseValue>{practice.providerStatusName}</SummaryListBaseValue>
                </SummaryListBaseRow>

                {practice.dateOfClosure &&
                    <SummaryListBaseRow>
                        <SummaryListBaseKey>Date of Closure</SummaryListBaseKey>
                        <SummaryListBaseValue>{moment(practice.dateOfClosure).format("DD-MMM-YYYY")}</SummaryListBaseValue>
                    </SummaryListBaseRow>
                }
            </SummaryListBase>

            {mode === 'VIEW' &&
                <SecuredComponents allowedRoles={securityPoints.practices.edit}>
                    <ButtonBase onClick={() => onModeChange('EDIT')} edit>Edit</ButtonBase>
                </SecuredComponents>
            }
            <>
                {mode === 'VIEW' &&
                    <SecuredComponents allowedRoles={securityPoints.practices.delete}>
                        <ButtonBase onClick={() => onModeChange('CONFIRMDELETE')} remove>Delete</ButtonBase>
                    </SecuredComponents>
                }
                {mode === 'CONFIRMDELETE' &&
                    <>
                        <SecuredComponents allowedRoles={securityPoints.practices.delete}>
                            <>
                                <ButtonBase onClick={() => onModeChange('VIEW')} cancel>Cancel</ButtonBase>
                                <ButtonBase onClick={() => onDelete(practice)} remove>Yes, Delete</ButtonBase>
                            </>
                        </SecuredComponents>
                    </>
                }
            </>
        </>
    );
}

export default PracticeDetailCardView;
