import { Guid } from 'guid-typescript';

export class Site {
    public id: Guid;
    public practiceId: Guid;
    public siteTypeId?: Guid;
    public contractId?: Guid;
    public ownershipId?: Guid;
    public propertyStatusId?: Guid;
    public closureDate?: Date
    public siteName?: string;
    public addressLine1 ?: string;
    public addressLine2 ?: string;
    public addressLine3 ?: string;
    public postCode ?: string;
    public listSize?: number
    public totalSquareFootage?: number;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;
    public practiceName?: string;
    public practiceCode?: string;
    public siteTypeName?: string;
    public contractName?: string;
    public ownershipName?: string;
    public propertyStatusName?: string; 

    constructor(site: any) {
        this.id = site.id ? Guid.parse(site.id) : Guid.parse(Guid.EMPTY);
        this.practiceId = site.practiceId ? Guid.parse(site.practiceId) : Guid.parse(Guid.EMPTY);
        this.siteTypeId = site.siteTypeId ? Guid.parse(site.siteTypeId) : Guid.parse(Guid.EMPTY);
        this.contractId = site.contractId ? Guid.parse(site.contractId) : Guid.parse(Guid.EMPTY);
        this.ownershipId = site.ownershipId ? Guid.parse(site.ownershipId) : Guid.parse(Guid.EMPTY);
        this.propertyStatusId = site.propertyStatusId ? Guid.parse(site.propertyStatusId) : Guid.parse(Guid.EMPTY);
        this.closureDate = site.closureDate ? new Date(site.closureDate) : undefined;
        this.siteName = site.siteName || "";
        this.addressLine1 = site.addressLine1 || "";
        this.addressLine2 = site.addressLine2 || "";
        this.addressLine3 = site.addressLine3 || "";
        this.postCode = site.postCode || "";
        this.listSize = site.listSize;
        this.totalSquareFootage = site.totalSquareFootage;
        this.isActive = site.isActive;
        this.sortOrder = site.sortOrder;
        this.createdByUser = site.createdByUser || ""
        this.createdDate = site.createdDate ? new Date(site.createdDate) : new Date();
        this.updatedByUser = site.updatedByUser || ""
        this.updatedDate = site.updatedDate ? new Date(site.updatedDate) : new Date();

        this.practiceName = site.practice?.practiceName;
        this.practiceCode = site.practice?.practiceCode;
        this.siteTypeName = site.siteType?.name;
        this.contractName = site.contract?.name;
        this.ownershipName = site.ownership?.name;
        this.propertyStatusName = site.propertyStatus?.name;

    }
}