import React, { FunctionComponent } from "react";
import { ContactView } from "../../models/views/components/contacts/contactView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";

interface ContactRowViewProps {
    contact: ContactView;
    onEdit: (value: string) => void;
    onDelete: (value: string) => void;
}

const ContactRowView: FunctionComponent<ContactRowViewProps> = (props) => {
    const {
        contact,
        onEdit,
        onDelete
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData>
                {
                    contact.name &&
                    (<b>{contact.name}</b>)
                }
                {
                    contact.contactTypeName &&
                    <>({contact.contactTypeName})</>
                }
                {
                    contact.contactNumber &&
                    (<><br />{contact.contactNumber}</>)
                }
                {
                    contact.contactEmail && 
                    (<><br />{contact.contactEmail}</>)
                }
            </TableBaseData>
            <TableBaseData classes="text-end">
                <SecuredComponents allowedRoles={securityPoints.contacts.edit}>
                    <ButtonBase id="contactsEdit" onClick={() => onEdit('EDIT')} edit>Edit</ButtonBase>
                </SecuredComponents>
                &nbsp;
                <SecuredComponents allowedRoles={securityPoints.contacts.delete}>
                    <ButtonBase id="contactsDelete" onClick={() => onDelete('DELETE')} remove>Delete</ButtonBase>
                </SecuredComponents>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ContactRowView;
