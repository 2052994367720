import React, { FunctionComponent } from "react";
import { DiscussionView } from "../../models/views/components/discussions/discussionView";
import ButtonBase from "../bases/buttons/ButtonBase";
import moment from "moment";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";

interface DiscussionRowDeleteProps {
    discussion: DiscussionView;
    onCancel: () => void;
    onDelete: (discussion: DiscussionView) => void;
}

const DiscussionRowDelete: FunctionComponent<DiscussionRowDeleteProps> = (props) => {
    const {
        discussion,
        onCancel,
        onDelete
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData>
                <>
                    { discussion.comment}
                    <br /><br />
                    <small className="text-smaller">
                        Created By:  <strong>{discussion.createdByUser} </strong>
                    </small>
                    <br />
                    <small className="text-smaller">
                        last updated:&nbsp;
                        <strong>{moment(discussion?.updatedDate?.toString()).format("do-MMM-yyyy HH:mm")} </strong>
                    </small>
                </>
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => onDelete(discussion)} remove>Yes, Delete</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default DiscussionRowDelete;
