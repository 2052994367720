import { useMsal } from "@azure/msal-react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import BoroughBroker from "../../brokers/apiBroker.boroughs"
import { Borough } from "../../models/boroughs/borough";

export const boroughService = {

    useCreateBorough: () => {
        const boroughBroker = new BoroughBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((borough: Borough) => {
            const date = new Date();
            borough.createdDate = borough.updatedDate = date;
            borough.createdByUser = borough.updatedByUser = msal.accounts[0].username;

            return boroughBroker.PostBoroughAsync(borough);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("BoroughGetAll");
                    queryClient.invalidateQueries(["BoroughGetById", { id: data.id }]);
                }
            });
    },

    useGetAllBoroughs: (query: string) => {
        const boroughBroker = new BoroughBroker();

        return useQuery(
            ["BoroughGetAll", { query: query }],
            () => boroughBroker.GetAllBoroughsAsync(query),
            { staleTime: Infinity });
    },

    useGetAllBoroughPages: (query: string) => {
        const boroughBroker = new BoroughBroker();

        return useInfiniteQuery(
            ["BoroughGetAll", { query: query }],
            ({ pageParam }) => {
                if (!pageParam) {
                    return boroughBroker.GetBoroughFirstPagesAsync(query)
                }
                return boroughBroker.GetBoroughSubsequentPagesAsync(pageParam)
            },
            {
                getNextPageParam: (lastPage) => lastPage.nextPage,
                staleTime: Infinity
            });
    },

    useGetBoroughById: (id: Guid) => {
        const boroughBroker = new BoroughBroker();

        return useQuery("BoroughGetById", () => boroughBroker.GetBoroughByIdAsync(id), { staleTime: Infinity });
    },

    useUpdateBorough: () => {
        const boroughBroker = new BoroughBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((borough: Borough) => {
            const date = new Date();
            borough.updatedDate = date;
            borough.updatedByUser = msal.accounts[0].username;

            return boroughBroker.PutBoroughAsync(borough);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("BoroughGetAll");
                    queryClient.invalidateQueries(["BoroughGetById", { id: data.id }]);
                }
            });
    },

    useDeleteBorough: () => {
        const boroughBroker = new BoroughBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return boroughBroker.DeleteBoroughByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("BoroughGetAll");
                    queryClient.invalidateQueries(["BoroughGetById", { id: data.id }]);
                }
            });
    },
}