import { Guid } from 'guid-typescript';

export class PropertyStatus {
    public id: Guid;
    public name?: string;
    public description?: string;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(propertyStatus: any) {
        this.id = propertyStatus.id ? Guid.parse(propertyStatus.id) : Guid.parse(Guid.EMPTY);
        this.name = propertyStatus.name;
        this.description = propertyStatus.description;
        this.isActive = propertyStatus.isActive;
        this.sortOrder = propertyStatus.sortOrder;
        this.createdByUser = propertyStatus.createdByUser
        this.createdDate = new Date(propertyStatus.createdDate)
        this.updatedByUser = propertyStatus.updatedByUser
        this.updatedDate = new Date(propertyStatus.updatedDate)
    }
}