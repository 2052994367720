import { AxiosResponse } from "axios";
import { Guid } from "guid-typescript";
import { Borough } from "../models/boroughs/borough";
import ApiBroker from "./apiBroker";

class BoroughBroker {

    relativeBoroughUrl = '/api/boroughs';
    relativeBoroughOdataUrl = '/odata/boroughs'

    private apiBroker: ApiBroker = new ApiBroker();

    private processOdataResult = (result: AxiosResponse) => {
        const data = result.data.value.map((borough: any) => new Borough(borough));

        const nextPage = result.data['@odata.nextLink'];
        return { data, nextPage }
    }

    async PostBoroughAsync(borough: Borough) {
        return await this.apiBroker.PostAsync(this.relativeBoroughUrl, borough)
            .then(result => new Borough(result.data));
    }

    async GetAllBoroughsAsync(queryString: string) {
        var url = this.relativeBoroughUrl + queryString;
        
        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((borough: any) => new Borough(borough)));
    }

    async GetBoroughFirstPagesAsync(query: string) {
        var url = this.relativeBoroughOdataUrl + query;
        return this.processOdataResult(await this.apiBroker.GetAsync(url));
    }

    async GetBoroughSubsequentPagesAsync(absoluteUri: string) {
        return this.processOdataResult(await this.apiBroker.GetAsyncAbsolute(absoluteUri));
    }

    async GetBoroughByIdAsync(id: Guid) {
        const url = `${this.relativeBoroughUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new Borough(result.data));
    }

    async PutBoroughAsync(borough: Borough) {
        return await this.apiBroker.PutAsync(this.relativeBoroughUrl, borough)
            .then(result => new Borough(result.data));
    }

    async DeleteBoroughByIdAsync(id: Guid) {
        const url = `${this.relativeBoroughUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new Borough(result.data));
    }
}

export default BoroughBroker;



