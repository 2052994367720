import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react"
import { FunctionComponent } from "react"
import { LeaseDocument } from "../../models/leaseDocuments/leaseDocument";
import securityPoints from "../../SecurityMatrix";
import ButtonBase from "../bases/buttons/ButtonBase";
import SummaryListBase from "../bases/components/SummaryList/SummaryListBase";
import SummaryListBaseAction from "../bases/components/SummaryList/SummaryListBase.Action";
import SummaryListBaseKey from "../bases/components/SummaryList/SummaryListBase.Key";
import SummaryListBaseRow from "../bases/components/SummaryList/SummaryListBase.Row";
import SummaryListBaseValue from "../bases/components/SummaryList/SummaryListBase.Value";
import { SecuredComponents } from "../Links";

interface DocumentDetailsCardViewProps {
    document: LeaseDocument;
    handleDeleteDocument: Function,
    handleOpenDocument: Function
}

const DocumentDetailsCardView: FunctionComponent<DocumentDetailsCardViewProps> = (props) => {

    const { document, handleDeleteDocument, handleOpenDocument } = props;
    const [deleteConfirm, setDeleteConfirm] = useState(false);

    if (deleteConfirm) {
        return <SummaryListBase>
            <SummaryListBaseRow>
                <SummaryListBaseKey>Delete</SummaryListBaseKey>
                <SummaryListBaseValue>
                    {document.label}
                </SummaryListBaseValue>
                <SummaryListBaseAction>
                    <SecuredComponents allowedRoles={securityPoints.documents.delete}>
                        <>
                            <ButtonBase onClick={() => { handleDeleteDocument(props.document.id); setDeleteConfirm(false) }} remove>Yes</ButtonBase>
                            <ButtonBase onClick={() => { setDeleteConfirm(false) }} cancel>No</ButtonBase>
                        </>
                    </SecuredComponents>
                </SummaryListBaseAction>
            </SummaryListBaseRow>
        </SummaryListBase>
    }

    return <>
        <SummaryListBase>
            <SummaryListBaseRow>
                <SummaryListBaseKey>Name</SummaryListBaseKey>
                <SummaryListBaseValue>
                    {document.label}&nbsp;
                    <span onClick={() => { setDeleteConfirm(true) }} >
                        <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                    </span>
                </SummaryListBaseValue>
            </SummaryListBaseRow>
            <SummaryListBaseRow>
                <SummaryListBaseKey>Description</SummaryListBaseKey>
                <SummaryListBaseValue>{document.description}</SummaryListBaseValue>
            </SummaryListBaseRow>
            <SummaryListBaseRow>
                <SummaryListBaseValue>
                </SummaryListBaseValue>
                <SummaryListBaseAction>
                    <ButtonBase onClick={() => { handleOpenDocument(document) }} download>Download</ButtonBase>
                </SummaryListBaseAction>
            </SummaryListBaseRow>
        </SummaryListBase>
    </>
}

export default DocumentDetailsCardView;