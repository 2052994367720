import { Guid } from 'guid-typescript';

export class Borough {
    public id: Guid;
    public name?: string;
    public financeManagerName?: string;
    public financeManagerEmail?: string;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(borough: any) {
        this.id = borough.id ? Guid.parse(borough.id) : Guid.createEmpty();
        this.name = borough.name;
        this.financeManagerName = borough.financeManagerName;
        this.financeManagerEmail = borough.financeManagerEmail;
        this.createdByUser = borough.createdByUser
        this.createdDate = new Date(borough.createdDate)
        this.updatedByUser = borough.updatedByUser
        this.updatedDate = new Date(borough.updatedDate)
    }
}