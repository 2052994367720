import React, { FunctionComponent } from "react";
import { BoroughView } from "../../models/views/components/boroughs/boroughView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import securityPoints from "../../SecurityMatrix";
import { SecuredComponents } from "../Links";

interface BoroughRowViewProps {
    borough: BoroughView;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
    onEdit: (value: string) => void;
    onDelete: (value: string) => void;
}

const BoroughRowView: FunctionComponent<BoroughRowViewProps> = (props) => {
    const {
        borough,
        allowedToEdit,
        allowedToDelete,
        onEdit,
        onDelete
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData>{borough.name}</TableBaseData>
            <TableBaseData>{borough.financeManagerName}</TableBaseData>
            <TableBaseData>{borough.financeManagerEmail}</TableBaseData>
            <TableBaseData classes="text-end">
                {allowedToEdit && (
                    <SecuredComponents allowedRoles={securityPoints.boroughs.edit}>
                        <ButtonBase onClick={() => onEdit('EDIT')} edit>Edit</ButtonBase>
                    </SecuredComponents>
                )}
                &nbsp;
                {allowedToDelete && (
                    <SecuredComponents allowedRoles={securityPoints.boroughs.delete}>
                        <ButtonBase onClick={() => onDelete('DELETE')} remove>Delete</ButtonBase>
                    </SecuredComponents>
                )}
            </TableBaseData>
        </TableBaseRow>
    );
}

export default BoroughRowView;
