import { Guid } from 'guid-typescript';
import { Site } from '../sites/site';

export class Lease {
    public id: Guid;
    public siteId: Guid;
    public leaseTypeId?: Guid;
    public landlordDetails?: string;
    public agreedLeaseAmount?: number;
    public leaseStartDate?: Date
    public leaseEndDate?: Date
    public isActive?: boolean;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;
    public site?: Site;
    public practiceId?: Guid;
    public leaseTypeName?: string;
    public repairAllowanceTypeId?: Guid;
    public repairAllowanceTypeName?: string;
    public landlordTypeId?: Guid;
    public landlordTypeName?: string;
    public tenancyTypeId?: Guid;
    public tenancyTypeName?: string;
    public isSignedUpToS52Id?: Guid;
    public isSignedUpToS52Name?: string;
    public leaseProgressTypeId?: Guid;
    public leaseProgressTypeName?: string;
    public leaseStatusTypeId?: Guid;
    public leaseStatusTypeName?: string;
    public dvDeadlineDate?: Date;

    constructor(lease: any) {
        this.id = lease.id ? Guid.parse(lease.id) : Guid.parse(Guid.EMPTY);
        this.siteId = Guid.parse(lease.siteId);
        this.leaseTypeId = lease.leaseTypeId ? Guid.parse(lease.leaseTypeId) : Guid.parse(Guid.EMPTY);
        this.landlordDetails = lease.landlordDetails;
        this.agreedLeaseAmount = lease.agreedLeaseAmount;
        this.leaseStartDate = lease.leaseStartDate;
        this.leaseEndDate = new Date(lease.leaseEndDate);
        this.isActive = lease.isActive;
        this.createdByUser = lease.createdByUser
        this.createdDate = new Date(lease.createdDate)
        this.updatedByUser = lease.updatedByUser
        this.updatedDate = new Date(lease.updatedDate)
        this.site = lease?.site;
        this.practiceId = lease.practiceId;
        this.leaseTypeName = lease.leaseType?.name;
        this.repairAllowanceTypeId = lease.repairAllowanceTypeId ? Guid.parse(lease.repairAllowanceTypeId) : Guid.parse(Guid.EMPTY);
        this.repairAllowanceTypeName = lease.repairAllowanceType?.name;
        this.landlordTypeId = lease.landlordTypeId ? Guid.parse(lease.landlordTypeId) : Guid.parse(Guid.EMPTY);
        this.landlordTypeName = lease.landlordType?.name;
        this.tenancyTypeId = lease.tenancyTypeId ? Guid.parse(lease.tenancyTypeId) : Guid.parse(Guid.EMPTY);
        this.tenancyTypeName = lease.tenancyType?.name;
        this.isSignedUpToS52Id = lease.isSignedUpToS52Id ? Guid.parse(lease.isSignedUpToS52Id) : Guid.parse(Guid.EMPTY);
        this.isSignedUpToS52Name = lease.isSignedUpToS52Type?.name;
        this.leaseProgressTypeId = lease.leaseProgressTypeId ? Guid.parse(lease.leaseProgressTypeId) : Guid.parse(Guid.EMPTY);
        this.leaseProgressTypeName = lease.leaseProgressType?.name;
        this.leaseStatusTypeId = lease.leaseStatusTypeId ? Guid.parse(lease.leaseStatusTypeId) : Guid.parse(Guid.EMPTY);
        this.leaseStatusTypeName = lease.leaseStatusType?.name;

        if (lease.dvDeadlineDate === null || lease.dvDeadlineDate === undefined) {
            this.dvDeadlineDate = undefined;
        }
        else {
            this.dvDeadlineDate = new Date(lease.dvDeadlineDate);
        }
    
    }
}