import { Guid } from "guid-typescript";

import { ReviewStatus } from "../models/reviewStatuses/reviewStatus";
import ApiBroker from "./apiBroker";

class ReviewStatusBroker {

    relativeReviewStatusUrl = '/api/reviewStatuses';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostReviewStatusAsync(reviewStatus: ReviewStatus) {
        return await this.apiBroker.PostAsync(this.relativeReviewStatusUrl, reviewStatus)
            .then(result => new ReviewStatus(result.data));
    }

    async GetAllReviewStatusesAsync(queryString: string) {
        var url = this.relativeReviewStatusUrl + queryString;

        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((reviewStatus: any) => new ReviewStatus(reviewStatus)));
    }

    async GetReviewStatusByIdAsync(id: Guid) {
        const url = `${this.relativeReviewStatusUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new ReviewStatus(result.data));
    }

    async PutReviewStatusAsync(reviewStatus: ReviewStatus) {
        return await this.apiBroker.PutAsync(this.relativeReviewStatusUrl, reviewStatus)
            .then(result => new ReviewStatus(result.data));
    }

    async DeleteReviewStatusByIdAsync(id: Guid) {
        const url = `${this.relativeReviewStatusUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new ReviewStatus(result.data));
    }
}

export default ReviewStatusBroker;



