import { Validation } from "../../models/validations/validation";

export const documentValidations: Array<Validation> = [
    {
        property: "label",
        friendlyName: "Document name",
        isRequired: true
    },
    {
        property: "description",
        friendlyName: "Document discription",
        isRequired: true
    },
    {
        property: "file",
        friendlyName: "Attachment",
        isRequired: true
        }
]