import React, { FunctionComponent } from "react";
import { Badge } from "react-bootstrap"
import { Link } from 'react-router-dom';
import { PracticeHomeView } from "../../models/practices/practiceHomeView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";

type PracticeRowViewProps = {
    practice: PracticeHomeView;
}

const PracticeRowView: FunctionComponent<PracticeRowViewProps> = (props) => {
    const {
        practice,
    } = props;

    return (
        <TableBaseRow classes={practice.isActive ? "" : "bg-light"}>
            <TableBaseData key={practice.id.toString()}>
                {practice.isActive
                    ? <> {practice.practiceName} <small>({practice.practiceCode})</small></>
                    : <span className="text-danger"><s>{practice.practiceName} <small >({practice.practiceCode})</small></s> (DELETED)</span>}
                <br />
                <small><strong>{practice.boroughName}</strong></small>
                <br />
                {
                    practice?.sites?.map(site => 
                        <span key={site.id.toString()} >
                            {site.isActive
                                ? <Badge pill bg="success text-white" title={site.siteName}>
                                    {site.propertyStatus}
                                    <Link className="pointer badgeLink"
                                        to={'/site/' + practice.id?.toString() + '/' + site.id.toString() + '/leases'}>
                                        {site.siteName} 
                                    </Link>
                                </Badge>
                                : ""
                            }
                        </span>
                    )
                }
            </TableBaseData>
            <TableBaseData classes="text-end valign-middle">
              
            </TableBaseData>
            <TableBaseData classes="text-end">
                <Link to={'/practice/' + practice?.id?.toString()}>
                    <ButtonBase onClick={() => { }} view>View</ButtonBase>
                </Link>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default PracticeRowView;