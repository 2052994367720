import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import securityPoints from "../../SecurityMatrix";
import { SecuredComponents } from "../Links";

interface LeaseTypeRowNewProps {
    onAdd: (value: boolean) => void;
}

const LeaseTypeRowNew: FunctionComponent<LeaseTypeRowNewProps> = (props) => {
    const {
        onAdd
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData></TableBaseData>
            <TableBaseData></TableBaseData>
            <TableBaseData classes="text-end">
                <SecuredComponents allowedRoles={securityPoints.leaseTypes.add}>
                    <ButtonBase onClick={() => onAdd(true)} add> <FontAwesomeIcon icon={faCirclePlus} size="lg" />&nbsp; New</ButtonBase>
                </SecuredComponents>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default LeaseTypeRowNew;
