import { Guid } from "guid-typescript";

export class PropertyStatusView {
    public id: Guid;
    public name?: string;
    public description?: string;
    public isActive: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(
        id: Guid,
        name?: string,
        description?: string,
        isActive?: boolean,
        sortOrder?: number,
        createdByUser?: string,
        createdDate?: Date,
        updatedByUser?: string,
        updatedDate?: Date
    ) {
        this.id = id;
        this.name = name !== undefined ? name : '';
        this.description = description !== undefined ? description : '';
        this.isActive = isActive === false ? false : true;
        this.sortOrder = sortOrder || 1000;
        this.createdByUser = createdByUser !== undefined ? createdByUser : '';
        this.createdDate = createdDate;
        this.updatedByUser = updatedByUser !== undefined ? updatedByUser : ''
        this.updatedDate = updatedDate;
    }
}