import { Guid } from "guid-typescript";

export class ContactView {
    public id: Guid;
    public siteId: Guid;
    public contactTypeId?: Guid;
    public name?: string;
    public contactNumber?: string;
    public contactEmail?: string;
    public siteName?: string;
    public contactTypeName?: string;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(
        id: Guid,
        siteId: Guid,
        contactTypeId?: Guid,
        name?: string,
        contactNumber?: string,
        contactEmail?: string,
        siteName?: string,
        contactTypeName?: string,
        createdByUser?: string,
        createdDate?: Date,
        updatedByUser?: string,
        updatedDate?: Date)
    {
        this.id = id;
        this.siteId = siteId;
        this.contactTypeId = contactTypeId;
        this.name = name !== undefined ? name : '';
        this.contactNumber = contactNumber !== undefined ? contactNumber : '';
        this.contactEmail = contactEmail !== undefined ? contactEmail : '';
        this.siteName = siteName;
        this.contactTypeName = contactTypeName;
        this.createdByUser = createdByUser !== undefined ? createdByUser : '';
        this.createdDate = createdDate;
        this.updatedByUser = updatedByUser !== undefined ? updatedByUser : ''
        this.updatedDate = updatedDate;
    }
}