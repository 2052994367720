import React, { FunctionComponent, useMemo, useState } from "react";
import SearchBase from "../bases/inputs/SearchBase";
import { debounce } from "lodash";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import ContactTypeRowAdd from "./contactTypeRowAdd";
import { ContactTypeView } from "../../models/views/components/contactTypes/contactTypeView";
import ContactTypeRow from "./contactTypeRow";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import { contactTypeViewService } from "../../services/views/contactTypes/contactTypesViewService";
import ContactTypeRowNew from "./contactTypeRowNew";
import TableBase from "../bases/components/Table/TableBase";
import TableBaseThead from "../bases/components/Table/TableBase.Thead";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseHeader from "../bases/components/Table/TableBase.Header";
import { toastError } from "../../brokers/toastBroker";

type ContactTypeTableProps = {
    allowedToAdd: boolean;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
}

const ContactTypeTable: FunctionComponent<ContactTypeTableProps> = (props) => {
    const {
        allowedToAdd,
        allowedToEdit,
        allowedToDelete,
    } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedContactTypes: contactTypesRetrieved, isLoading } =
        contactTypeViewService.useGetAllContactTypes(debouncedTerm);

    const addContactType = contactTypeViewService.useCreateContactType();
    const updateContactType = contactTypeViewService.useUpdateContactType();
    const removeContactType = contactTypeViewService.useRemoveContactType();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [addApiError, setAddApiError] = useState<any>({});

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleAddState = () => {
        setAddMode(!addMode);
    };

    const handleAddNew = (contactType: ContactTypeView) => {
        return addContactType.mutate(contactType, {
            onSuccess: () => {
                setAddMode(false);
            },
            onError: (error: any) => {
                toastError(`${contactType.name} Failed to save.`);
                setAddApiError(error?.response?.data?.errors);
            }
        });
    };

    const handleUpdate = (contactType: ContactTypeView) => {
        return updateContactType.mutateAsync(contactType);
    }

    const handleDelete = (contactType: ContactTypeView) => {
        return removeContactType.mutateAsync(contactType.id);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , [])

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>
                        Contact Types
                    </CardBaseTitle>
                    <CardBaseContent>
                        <SearchBase id="search" label="Search contact types" value={searchTerm}
                            onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />
                        {isLoading && <> <SpinnerBase />.</>}
                        <TableBase>
                            <TableBaseThead>
                                <TableBaseRow>
                                    <TableBaseHeader>Contact Types</TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                </TableBaseRow>
                            </TableBaseThead>
                            <TableBaseTbody>
                                {
                                    allowedToAdd &&
                                    <>
                                        {addMode === false && (<ContactTypeRowNew onAdd={handleAddState} />)}

                                        {addMode === true && (
                                            <ContactTypeRowAdd
                                                onCancel={handleAddState}
                                                onAdd={handleAddNew}
                                                apiError={addApiError} />)}
                                    </>
                                }
                                {contactTypesRetrieved?.map((contactType: ContactTypeView) =>
                                    <ContactTypeRow
                                        key={contactType.id?.toString()}
                                        contactType={contactType}
                                        allowedToEdit={allowedToEdit}
                                        allowedToDelete={allowedToDelete}
                                        onSave={handleUpdate}
                                        onDelete={handleDelete}
                                    />)}
                            </TableBaseTbody>
                        </TableBase>
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </>
    );
}

ContactTypeTable.defaultProps = {
    allowedToAdd: true,
    allowedToEdit: true,
    allowedToDelete: true,
};

export default ContactTypeTable;