import React, { FunctionComponent, useMemo, useState } from "react";
import SearchBase from "../bases/inputs/SearchBase";
import { debounce } from "lodash";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import ContactRowNew from "./contactRowNew";
import ContactRowAdd from "./contactRowAdd";
import { ContactView } from "../../models/views/components/contacts/contactView";
import { contactViewService } from "../../services/views/contacts/contactViewService";
import ContactRow from "./contactRow";
import { Guid } from "guid-typescript";
import { lookupViewService } from "../../services/views/lookups/lookupViewService";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import TableBaseThead from "../bases/components/Table/TableBase.Thead";
import TableBase from "../bases/components/Table/TableBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";

type ContactTableProps = {
    siteId: Guid;
}

const ContactTable: FunctionComponent<ContactTableProps> = (props) => {
    const {
        siteId
    } = props;

    let { mappedContactTypes: contactTypesRetrieved } = lookupViewService.useGetContactType("");

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedContacts: contactsRetrieved, isLoading } =
        contactViewService.useGetAllContacts(debouncedTerm, siteId);

    const addContact = contactViewService.useCreateContact();
    const updateContact = contactViewService.useUpdateContact();
    const deleteContact = contactViewService.useRemoveContact();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [addApiError, setAddApiError] = useState<any>({});

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleAddState = () => {
        setAddMode(!addMode);
    };

    const handleAddNew = (contact: ContactView) => {
        return addContact.mutate(contact, {
            onSuccess: () => {
                setAddMode(false);
            },
            onError: (error: any) => {
                setAddApiError(error?.response?.data?.errors);
            }
        });
    };

    const handleUpdate = (contact: ContactView) => {
        return updateContact.mutateAsync(contact);
    }

    const handleDelete = (contact: ContactView) => {
        return deleteContact.mutateAsync(contact.id);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , [])

    return (
        <>
            <SearchBase id="search" label="Search contacts" value={searchTerm}
                onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>
                        Contacts
                    </CardBaseTitle>
                    <CardBaseContent>
                        {isLoading && <> <SpinnerBase />.</>}
                        <TableBase>
                            <TableBaseThead>
                                <TableBaseRow>
                                    <TableBaseData>Contacts</TableBaseData>
                                    <TableBaseData classes="text-center">Action(s)</TableBaseData>
                                </TableBaseRow>
                            </TableBaseThead>
                            <TableBaseTbody>
                                {
                                    contactTypesRetrieved &&
                                    <>
                                        {addMode === false && (<ContactRowNew onAdd={handleAddState} />)}
                                        {addMode === true && (
                                            <ContactRowAdd
                                                siteId={siteId}
                                                contactTypes={contactTypesRetrieved}
                                                onCancel={handleAddState}
                                                onAdd={handleAddNew}
                                                apiError={addApiError} />)}
                                    </>
                                }
                                {contactTypesRetrieved && contactsRetrieved?.map((contact: ContactView) =>
                                    <ContactRow
                                        key={contact.id?.toString()}
                                        contact={contact}
                                        contactTypes={contactTypesRetrieved}
                                        onSave={handleUpdate}
                                        onDelete={handleDelete}
                                    />)}
                            </TableBaseTbody>
                        </TableBase>
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </>
    );
}

export default ContactTable;