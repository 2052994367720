import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { ProviderStatusView } from "../../models/views/components/providerStatuses/providerStatusView";
import { Guid } from "guid-typescript";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import { useValidation } from "../../hooks/useValidation";
import { providerStatusErrors } from "./providerStatusErrors";
import { providerStatusValidations } from "./providerStatusValidations";

interface ProviderStatusRowAddProps {
    onCancel: () => void;
    onAdd: (providerStatus: ProviderStatusView) => void;
    apiError: any;
}

const ProviderStatusRowAdd: FunctionComponent<ProviderStatusRowAddProps> = (props) => {
    const {
        onCancel,
        onAdd,
        apiError
    } = props;

    const [providerStatus, setProviderStatus] = useState<ProviderStatusView>(new ProviderStatusView(Guid.create()));

    const { errors, processApiErrors, enableValidationMessages, validate } =
    useValidation(providerStatusErrors, providerStatusValidations, providerStatus);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const addProviderStatus = {
            ...providerStatus,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setProviderStatus(addProviderStatus);
    };

    const handleSave = () => {
        if (!validate(providerStatus)) {
            onAdd(providerStatus);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Provider Status Name"
                    placeholder="Provider Status Name"
                    value={providerStatus.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Provider Status Description"
                    placeholder="Provider Status Description"
                    value={providerStatus.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={providerStatus.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={providerStatus.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} add>Add</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ProviderStatusRowAdd;
