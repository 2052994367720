import React from "react";
import 'nhsuk-frontend/dist/nhsuk.min'
import 'nhsuk-frontend/packages/polyfills';
import SiteDetail from "../components/sites/siteDetail";
import { Link } from 'react-router-dom'
import { Guid } from "guid-typescript";
import { useParams } from "react-router-dom";
import { Container } from 'nhsuk-react-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export const SitePage = () => {
    const { practiceId, siteId, activeTab } = useParams();

    return <section>
        <Container fluid>
            <main id="maincontent" className="NELTopPadding" role="main">
                <Link to={'/practice/' + practiceId}>
                    <FontAwesomeIcon icon={faChevronLeft} size="1x"/> Back to Practices Details
                </Link>
                <SiteDetail 
                    practiceId={Guid.parse(practiceId ?? "")}
                    siteId={siteId}
                    activeTab={activeTab}>
                </SiteDetail>
            </main>
        </Container>
    </section>
}