import React, { FunctionComponent, useEffect, useState } from "react";
import { LookupView } from "../../models/views/components/lookups/lookupView";
import { RentReviewView } from "../../models/views/components/rentReviews/rentReviewView";
import RentReviewDetailCardView from "./rentReviewDetailCardView";
import RentReviewDetailCardEdit from "./rentReviewDetailCardEdit";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";

interface RentReviewDetailCardProps {
    rentReview: RentReviewView | undefined;
    reviewStages: Array<LookupView>;
    reviewStatuses: Array<LookupView>;
    mode: string;
    onModeChange: (mode: string) => void;
    onAdd: (rentReview: RentReviewView) => void;
    onUpdate: (rentReview: RentReviewView) => void;
    onDelete: (rentReview: RentReviewView) => void;
    onUnDelete: (rentReview: RentReviewView) => void;
    onCancel: () => void
    children?: React.ReactNode;
}

const RentReviewDetailCard: FunctionComponent<RentReviewDetailCardProps> = (props) => {
    const {
        rentReview,
        reviewStages,
        reviewStatuses,
        mode,
        onModeChange,
        onAdd,
        onUpdate,
        onDelete,
        onUnDelete,
        onCancel,
        children
    } = props;

    const [displayMode, setDisplayMode] = useState<string>(mode);
    const [apiError, setApiError] = useState<any>({});

    const handleModeChange = (value: string) => {
        if (value === "EDIT") {
            onModeChange("EDIT")
        }
        setDisplayMode(value);
    };

    useEffect(() => {
        if (rentReview !== undefined) {
            // setDisplayMode(rentReview.id.toString() === Guid.EMPTY ? "ADD" : "EDIT");
        }
    }, [rentReview])

    const handleAdd = async (rentReview: RentReviewView) => {
        try {
            await onAdd(rentReview);
        } catch (error) {
            setDisplayMode('EDIT');
            onModeChange("VIEW")
        }
    }

    const handleUpdate = async (rentReview: RentReviewView) => {
        try {
            await onUpdate(rentReview);
            setDisplayMode('VIEW');
            onModeChange("VIEW")
        } catch (error) {
            setDisplayMode('EDIT');
        }
    }

    const handleDelete = async (rentReview: RentReviewView) => {
        await onDelete(rentReview);
        setDisplayMode('VIEW');
    }

    const handleUnDelete = async (rentReview: RentReviewView) => {
        await onUnDelete(rentReview);
        setDisplayMode('VIEW');
    }

    const handleCancel = () => {
        setApiError({});
        setDisplayMode('VIEW');
        onCancel();
    }

    return (
        <div>
            {rentReview !== undefined && (displayMode === "VIEW" || displayMode === "CONFIRMDELETE" || displayMode === "CONFIRMUNDODELETE") && (
                <RentReviewDetailCardView
                    onModeChange={handleModeChange}
                    rentReview={rentReview}
                    onDelete={handleDelete}
                    onUnDelete={handleUnDelete}
                    mode={displayMode}
                />
            )}

            {rentReview !== undefined && (displayMode === "EDIT" || displayMode === "ADD") && (
                <Modal show={true} onHide={handleCancel} size="lg">
                    <ModalHeader closeButton>
                        Edit Review
                    </ModalHeader>
                    <ModalBody>
                        <RentReviewDetailCardEdit
                            onModeChange={handleModeChange}
                            reviewStages={reviewStages}
                            reviewStatuses={reviewStatuses}
                            onUpdate={handleUpdate}
                            onAdd={handleAdd}
                            onCancel={handleCancel}
                            rentReview={rentReview}
                            mode={mode}
                            apiError={apiError}
                        />
                    </ModalBody>
                </Modal>
            )}
            {children !== undefined && (
                <>
                    <br />
                    {children}
                </>
            )}
        </div >
    );
};

export default RentReviewDetailCard;