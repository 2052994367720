import { Guid } from 'guid-typescript';
import { useEffect, useState } from 'react';
import { RentReview } from '../../../models/rentReviews/rentReview';
import { RentReviewView } from '../../../models/views/components/rentReviews/rentReviewView';
import { rentReviewService } from '../../foundations/rentReviewService';

export const rentReviewViewService = {

    useCreateRentReview: () => {
        return rentReviewService.useCreateRentReview();
    },

    useGetAllRentReviews: (searchTerm?: string) => {
        try {
            let query = `?$expand=reviewStage&$expand=reviewStatus&$orderby=rentReviewDueDate asc`;

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`
            }

            const response = rentReviewService.useGetAllRentReviews(query);
            const [mappedRentReviews, setMappedRentReviews] = useState<Array<RentReviewView>>([]);

            useEffect(() => {
                if (response.data) {
                    const rentReviews = response.data.map((rentReview : RentReview) =>
                        new RentReviewView(
                            rentReview.id,
                            rentReview.leaseId,
                            rentReview.reviewStageId,
                            rentReview.reviewStatusId,
                            rentReview.rentReviewDueDate,
                            rentReview.agreedRentAmount,
                            rentReview.nextRentReview,
                            rentReview.nextRentAmount,
                            rentReview.repairAllowance,
                            rentReview.pcifImprovementGrantAmountApplicable,
                            rentReview.valuationDecision,
                            rentReview.districtValuerName,
                            rentReview.districtValuerEmail,
                            rentReview.districtValuerPoNumber,
                            rentReview.districtValuerRefNo,
                            rentReview.districtValuerDeadlineDate,
                            rentReview.isSilentRentReview,
                            rentReview.isActive,
                            rentReview.createdByUser,
                            rentReview.createdDate,
                            rentReview.updatedByUser,
                            rentReview.updatedDate,
                            rentReview.reviewStage?.name,
                            rentReview.reviewStatus?.name,
                            rentReview.reviewClosedDate
                        ));

                    setMappedRentReviews(rentReviews);
                }
            }, [response.data]);

            return {
                mappedRentReviews, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetRentReviewById: (rentReviewId: Guid, enabled: boolean = true) => {
        try {
            let query = `?$filter=id eq ${rentReviewId}`
                + `&$expand=reviewStage`
                + `&$expand=reviewStatus`
                + `&$orderby=rentReviewDueDate asc`;

            const response = rentReviewService.useGetAllRentReviews(query, true);
            const [mappedRentReview, setMappedRentReview] = useState<RentReviewView>();

            useEffect(() => {
                if (!response.data) {
                    return;
                }
                if (response.data.length === 0) {
                    setMappedRentReview(undefined);
                    return;
                }
                if (response.data) {
                    const rentReview = new RentReviewView(
                        response.data[0].id,
                        response.data[0].leaseId,
                        response.data[0].reviewStageId,
                        response.data[0].reviewStatusId,
                        response.data[0].rentReviewDueDate,
                        response.data[0].agreedRentAmount,
                        response.data[0].nextRentReview,
                        response.data[0].nextRentAmount,
                        response.data[0].repairAllowance,
                        response.data[0].pcifImprovementGrantAmountApplicable,
                        response.data[0].valuationDecision,
                        response.data[0].districtValuerName,
                        response.data[0].districtValuerEmail,
                        response.data[0].districtValuerPoNumber,
                        response.data[0].districtValuerRefNo,
                        response.data[0].districtValuerDeadlineDate,
                        response.data[0].isSilentRentReview,
                        response.data[0].isActive,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate,
                        response.data[0].reviewStage?.name,
                        response.data[0].reviewStatus?.name,
                        response.data[0].reviewClosedDate
                    );

                    setMappedRentReview(rentReview);
                }
            }, [response.data]);

            return {
                mappedRentReview, ...response
            }

        } catch (err) {
            throw err;
        }
    },

    useGetRentReviewsByLeaseId: (leaseId: Guid, searchTerm?: string) => {
        try {
            let query = `?$expand=reviewStage&$expand=reviewStatus&$orderby=rentReviewDueDate asc`;
            if (leaseId) {
                query = query + `&$filter=leaseId eq ` + leaseId;
            }

            if (searchTerm) {
                query = query + ` and contains(name,'${searchTerm}')`
            }

            const response = rentReviewService.useGetAllRentReviews(query);
            const [mappedRentReviews, setMappedRentReviews] = useState<Array<RentReviewView>>([]);

            useEffect(() => {
                if (response.data) {
                    const rentReviews = response.data.map((rentReview: RentReview) =>
                        new RentReviewView(
                            rentReview.id,
                            rentReview.leaseId,
                            rentReview.reviewStageId,
                            rentReview.reviewStatusId,
                            rentReview.rentReviewDueDate,
                            rentReview.agreedRentAmount,
                            rentReview.nextRentReview,
                            rentReview.nextRentAmount,
                            rentReview.repairAllowance,
                            rentReview.pcifImprovementGrantAmountApplicable,
                            rentReview.valuationDecision,
                            rentReview.districtValuerName,
                            rentReview.districtValuerEmail,
                            rentReview.districtValuerPoNumber,
                            rentReview.districtValuerRefNo,
                            rentReview.districtValuerDeadlineDate,
                            rentReview.isSilentRentReview,
                            rentReview.isActive,
                            rentReview.createdByUser,
                            rentReview.createdDate,
                            rentReview.updatedByUser,
                            rentReview.updatedDate,
                            rentReview.reviewStage?.name,
                            rentReview.reviewStatus?.name,
                            rentReview.reviewClosedDate
                        ));

                    setMappedRentReviews(rentReviews);
                }
            }, [response.data]);

            return {
                mappedRentReviews, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetRecentRentReview: () => {
        try {
            const query = `?$top=1&$orderby=updatedDate`
            const response = rentReviewService.useGetAllRentReviews(query);
            const [mappedRentReview, setMappedRentReview] = useState<RentReviewView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const rentReview = new RentReviewView(
                        response.data[0].id,
                        response.data[0].leaseId,
                        response.data[0].reviewStageId,
                        response.data[0].reviewStatusId,
                        response.data[0].rentReviewDueDate,
                        response.data[0].agreedRentAmount,
                        response.data[0].nextRentReview,
                        response.data[0].nextRentAmount,
                        response.data[0].repairAllowance,
                        response.data[0].pcifImprovementGrantAmountApplicable,
                        response.data[0].valuationDecision,
                        response.data[0].districtValuerName,
                        response.data[0].districtValuerEmail,
                        response.data[0].districtValuerPoNumber,
                        response.data[0].districtValuerRefNo,
                        response.data[0].districtValuerDeadlineDate,
                        response.data[0].isSilentRentReview,
                        response.data[0].isActive,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate,
                        response.data[0].reviewStage?.name,
                        response.data[0].reviewStatus?.name);

                    setMappedRentReview(rentReview);
                }
            }, [response.data]);

            return {
                mappedRentReview, ...response
            }

        } catch (err) {
            throw err;
        }
    },

    useUpdateRentReview: () => {
        return rentReviewService.useUpdateRentReview();
    },

    useRemoveRentReview: () => {
        return rentReviewService.useDeleteRentReview();
    },
}