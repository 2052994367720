import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { LeaseType } from "../../../models/leaseTypes/leaseType";
import { LeaseTypeView } from "../../../models/views/components/leaseTypes/leaseTypeView";
import { leaseTypeService } from "../../foundations/leaseTypeService";

export const leaseTypeViewService = {

    useCreateLeaseType: () => {
        return leaseTypeService.useCreateLeaseType();
    },

    useGetAllLeaseTypes: (searchTerm?: string) => {
        try {
            let query = '?$orderby=sortOrder,name';

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`;
            }

            const response = leaseTypeService.useGetAllLeaseTypes(query);
            const [mappedLeaseTypes, setMappedLeaseTypes] = useState<Array<LeaseTypeView>>();

            useEffect(() => {
                if (response.data) {
                    const leaseTypes = response.data.map((leaseType: LeaseType) =>
                        new LeaseTypeView(
                            leaseType.id,
                            leaseType.name,
                            leaseType.description,
                            leaseType.isActive,
                            leaseType.sortOrder,
                            leaseType.createdByUser,
                            leaseType.createdDate,
                            leaseType.updatedByUser,
                            leaseType.updatedDate
                        ));

                    setMappedLeaseTypes(leaseTypes);
                }
            }, [response.data]);

            return {
                mappedLeaseTypes, ...response
            }
        } catch (err) {
            throw err;
        }
    },
    useGetLeaseTypeById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = leaseTypeService.useGetAllLeaseTypes(query);
            const [mappedLeaseType, setMappedLeaseType] = useState<LeaseTypeView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const leaseType = new LeaseTypeView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].description,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate
                    );

                    setMappedLeaseType(leaseType);
                }
            }, [response.data]);

            return {
                mappedLeaseType, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateLeaseType: () => {
        return leaseTypeService.useUpdateLeaseType();
    },

    useRemoveLeaseType: () => {
        return leaseTypeService.useDeleteLeaseType();
    },
}