import { Guid } from 'guid-typescript';
import moment from 'moment';

export class LeaseView {
    public id: Guid;
    public siteId: Guid;
    public leaseTypeId?: Guid;
    public landlordDetails?: string;
    public agreedLeaseAmount?: number;
    public leaseStartDate?: Date
    public leaseEndDate?: Date
    public isActive: boolean;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;
    public practiceId?: Guid;
    public leaseTypeName?: string;
    public repairAllowanceTypeId?: Guid
    public repairAllowanceTypeName?: string
    public leaseIsCurrent: boolean;
    public landlordTypeId?: Guid;
    public landlordTypeName?: string;
    public tenancyTypeId?: Guid;
    public tenancyTypeName?: string;
    public isSignedUpToS52Id?: Guid;
    public isSignedUpToS52Name?: string;
    public leaseProgressTypeId?: Guid;
    public leaseProgressTypeName?: string;
    public leaseStatusTypeId?: Guid;
    public leaseStatusTypeName?: string;
    public dvDeadlineDate?: Date;

    constructor(
        id: Guid,
        siteId: Guid,
        leaseTypeId?: Guid,
        landlordDetails?: string,
        agreedLeaseAmount?: number,
        leaseStartDate?: Date,
        leaseEndDate?: Date,
        isActive?: boolean,
        createdByUser?: string,
        createdDate?: Date,
        updatedByUser?: string,
        updatedDate?: Date,
        practiceId?: Guid,
        leaseTypeName?: string,
        repairAllowanceTypeId?: Guid,
        repairAllowanceTypeName?: string,
        landlordTypeId?: Guid,
        landlordTypeName?: string,
        tenancyTypeId?: Guid,
        tenancyTypeName?: string,
        isSignedUpToS52Id?: Guid,
        isSignedUpToS52Name?: string,
        leaseProgressTypeId?: Guid,
        leaseProgressTypeName?: string,
        leaseStatusTypeId?: Guid,
        leaseStatusTypeName?: string,
        dvDeadlineDate?: Date,
        )
    {
        this.id = id;
        this.siteId = siteId;
        this.leaseTypeId = leaseTypeId;
        this.landlordDetails = landlordDetails !== undefined ? landlordDetails : '';
        this.agreedLeaseAmount = agreedLeaseAmount !== undefined ? agreedLeaseAmount : 0;
        this.leaseStartDate = leaseStartDate !== undefined ? new Date(leaseStartDate) : undefined;
        this.leaseEndDate = leaseEndDate !== undefined ? new Date(leaseEndDate) : undefined;
        this.isActive = isActive === false ? false : true;
        this.createdByUser = createdByUser !== undefined ? createdByUser : '';
        this.createdDate = createdDate;
        this.updatedByUser = updatedByUser !== undefined ? updatedByUser : ''
        this.updatedDate = updatedDate;
        this.practiceId = practiceId;
        this.leaseTypeName = leaseTypeName || "";
        this.repairAllowanceTypeId = repairAllowanceTypeId;
        this.repairAllowanceTypeName = repairAllowanceTypeName || "";
        
        this.leaseIsCurrent = false;
        if (leaseStartDate && leaseEndDate && isActive === true) {
            this.leaseIsCurrent = moment().isBetween(moment(leaseStartDate).add(-1,"day"), moment(leaseEndDate));
        } 

        if (!leaseEndDate && isActive === true) {
            this.leaseIsCurrent = moment().isAfter(moment(leaseStartDate));
        }

        if (!leaseStartDate && isActive === true) {
            this.leaseIsCurrent = moment().isBefore(moment(leaseEndDate));
        }

        if(tenancyTypeName === "Notional" && isActive === true) {
            this.leaseIsCurrent = true;
        }

        this.landlordTypeId = landlordTypeId;
        this.landlordTypeName = landlordTypeName || "";
        this.tenancyTypeId = tenancyTypeId;
        this.tenancyTypeName = tenancyTypeName || "";
        this.isSignedUpToS52Id = isSignedUpToS52Id;
        this.isSignedUpToS52Name = isSignedUpToS52Name || "";
        this.leaseProgressTypeId = leaseProgressTypeId;
        this.leaseProgressTypeName = leaseProgressTypeName || "";
        this.leaseStatusTypeId = leaseStatusTypeId;
        this.leaseStatusTypeName = leaseStatusTypeName || "";

        if (dvDeadlineDate === null || dvDeadlineDate === undefined) {
            this.dvDeadlineDate = undefined;
        }
        else {
            this.dvDeadlineDate = new Date(dvDeadlineDate);
        }
    }
}