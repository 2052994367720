import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { ReviewStatus } from "../../../models/reviewStatuses/reviewStatus";
import { ReviewStatusView } from "../../../models/views/components/reviewStatuses/reviewStatusView";
import { reviewStatusService } from "../../foundations/reviewStatusService";

export const reviewStatusViewService = {

    useCreateReviewStatus: () => {
        return reviewStatusService.useCreateReviewStatus();
    },

    useGetAllReviewStatuses: (searchTerm?: string) => {
        try {
            let query = '?$orderby=sortOrder,name';

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`;
            }

            const response = reviewStatusService.useGetAllReviewStatuses(query);
            const [mappedReviewStatuses, setMappedReviewStatuses] = useState<Array<ReviewStatusView>>();

            useEffect(() => {
                if (response.data) {
                    const reviewStatuses = response.data.map((reviewStatus: ReviewStatus) =>
                        new ReviewStatusView(
                            reviewStatus.id,
                            reviewStatus.name,
                            reviewStatus.description,
                            reviewStatus.isActive,
                            reviewStatus.sortOrder,
                            reviewStatus.createdByUser,
                            reviewStatus.createdDate,
                            reviewStatus.updatedByUser,
                            reviewStatus.updatedDate,
                        ));

                    setMappedReviewStatuses(reviewStatuses);
                }
            }, [response.data]);

            return {
                mappedReviewStatuses, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetReviewStatusById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = reviewStatusService.useGetAllReviewStatuses(query);
            const [mappedReviewStatus, setMappedReviewStatus] = useState<ReviewStatusView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const reviewStatus = new ReviewStatusView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].description,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate
                    );

                    setMappedReviewStatus(reviewStatus);
                }
            }, [response.data]);

            return {
                mappedReviewStatus, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateReviewStatus: () => {
        return reviewStatusService.useUpdateReviewStatus();
    },

    useRemoveReviewStatus: () => {
        return reviewStatusService.useDeleteReviewStatus();
    },
}