import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import ContactBroker from "../../brokers/apiBroker.contacts"
import { Contact } from "../../models/contacts/contact";

export const contactService = {
    useCreateContact: () => {
        const contactBroker = new ContactBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((contact: Contact) => {
            const date = new Date();
            contact.createdDate = contact.updatedDate = date;
            contact.createdByUser = contact.updatedByUser = msal.accounts[0].username;

            return contactBroker.PostContactAsync(contact);
        },
            {
                onSuccess: (variables) => {
                    queryClient.invalidateQueries("ContactGetAll");
                    queryClient.invalidateQueries(["ContactGetById", { id: variables.id }]);
                }
            });
    },

    useGetAllContacts: (query: string) => {
        const contactBroker = new ContactBroker();

        return useQuery(
            ["ContactGetAll", { query: query }],
            () => contactBroker.GetAllContactsAsync(query),
            { staleTime: Infinity });
    },

    useGetContactById: (id: Guid) => {
        const contactBroker = new ContactBroker();

        return useQuery(
            ["ContactGetById", { id: id }],
            () => contactBroker.GetContactByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateContact: () => {
        const contactBroker = new ContactBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((contact: Contact) => {
            const date = new Date();
            contact.updatedDate = date;
            contact.updatedByUser = msal.accounts[0].username;

            return contactBroker.PutContactAsync(contact);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ContactGetAll");
                    queryClient.invalidateQueries(["ContactGetById", { id: data.id }]);
                }
            });
    },

    useDeleteContact: () => {
        const contactBroker = new ContactBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return contactBroker.DeleteContactByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ContactGetAll");
                    queryClient.invalidateQueries(["ContactGetById", { id: data.id }]);
                }
            });
    },
}