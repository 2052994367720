import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { PropertyStatus } from "../../../models/propertyStatuses/propertyStatus";
import { PropertyStatusView } from "../../../models/views/components/propertyStatuses/propertyStatusView";
import { propertyStatusService } from "../../foundations/propertyStatusService";

export const propertyStatusViewService = {

    useCreatePropertyStatus: () => {
        return propertyStatusService.useCreatePropertyStatus();
    },

    useGetAllPropertyStatuses: (searchTerm?: string) => {
        try {
            let query = '?$orderby=sortOrder,name';

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`;
            }

            const response = propertyStatusService.useGetAllPropertyStatuses(query);
            const [mappedPropertyStatuses, setMappedPropertyStatuses] = useState<Array<PropertyStatusView>>();

            useEffect(() => {
                if (response.data) {
                    const propertyStatuses = response.data.map((propertyStatus: PropertyStatus) =>
                        new PropertyStatusView(
                            propertyStatus.id,
                            propertyStatus.name,
                            propertyStatus.description,
                            propertyStatus.isActive,
                            propertyStatus.sortOrder,
                            propertyStatus.createdByUser,
                            propertyStatus.createdDate,
                            propertyStatus.updatedByUser,
                            propertyStatus.updatedDate));

                    setMappedPropertyStatuses(propertyStatuses);
                }
            }, [response.data]);

            return {
                mappedPropertyStatuses, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetPropertyStatusById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = propertyStatusService.useGetAllPropertyStatuses(query);
            const [mappedPropertyStatus, setMappedPropertyStatus] = useState<PropertyStatusView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const propertyStatus = new PropertyStatusView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].description,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate);

                    setMappedPropertyStatus(propertyStatus);
                }
            }, [response.data]);

            return {
                mappedPropertyStatus, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdatePropertyStatus: () => {
        return propertyStatusService.useUpdatePropertyStatus();
    },

    useRemovePropertyStatus: () => {
        return propertyStatusService.useDeletePropertyStatus();
    },
}