import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-typescript";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import securityPoints from "../../SecurityMatrix";
import { rentReviewViewService } from "../../services/views/rentReviews/rentReviewViewService";
import ButtonBase from "../bases/buttons/ButtonBase";
import { SecuredComponents } from "../Links";
import moment from "moment";
import RentReviewDetail from "./rentReviewDetail";
import { RentReviewView } from "../../models/views/components/rentReviews/rentReviewView";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";

interface RentReviewListProps {
    leaseId: Guid;
}

const RentReviewList: FunctionComponent<RentReviewListProps> = (props) => {
    const {
        leaseId
    } = props;

    const [rentReview, setRentReview] = useState<RentReviewView>();
    let { mappedRentReviews, isLoading } = rentReviewViewService.useGetRentReviewsByLeaseId(leaseId);
    const [sortedReviews, setSortedReviews] = useState<Array<RentReviewView>>([]);
    const [mode, setMode] = useState<string>("VIEW");

    const handleRentReviewNew = () => {
        setRentReview(new RentReviewView(Guid.createEmpty(), leaseId));
        setMode("ADD");
    }

    useEffect(() => {
        setSortedReviews(mappedRentReviews.sort((a, b) => {
            if (!a.rentReviewDueDate) return 1;
            if (!b.rentReviewDueDate) return -1;
            if (a.rentReviewDueDate > b.rentReviewDueDate) return -1;
            if (a.rentReviewDueDate < b.rentReviewDueDate) return 1;
            return 0;
        }));
    }, [mappedRentReviews])

    if(isLoading) {
        return <SpinnerBase />
    }

    return (
        <CardBase>
            <CardBaseBody>
                <CardBaseTitle>Rent Reviews</CardBaseTitle>
                <CardBaseContent>
                    {
                        sortedReviews.length > 0 &&
                        <>
                            {mappedRentReviews.map((rentReview) =>
                                <Accordion style={{ cursor: "pointer" }} key={rentReview.id.toString()} >
                                    <Accordion.Item eventKey={rentReview.id.toString()} >
                                        <Accordion.Header>
                                            <div className={`${rentReview.isActive === false ? "text-decoration-line-through" : ""}`} >
                                            <span className="fw-bold">{moment(rentReview.rentReviewDueDate).format("DD-MMM-YYYY")}&nbsp;
                                                {rentReview.reviewClosedDate !== undefined ? <>Closed</> : <>: {rentReview.reviewStatusName} : {rentReview.reviewStageName}</>}


                                        </span>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <RentReviewDetail leaseId={leaseId} rentReview={rentReview} onCancel={() => { }} mode={mode} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )}

                        </>
                    }
                    <div className="mt-5">
                        <SecuredComponents allowedRoles={securityPoints.rentReview.add}>
                            <ButtonBase id="AddNewRentReviewBtn" onClick={handleRentReviewNew} add><FontAwesomeIcon icon={faCirclePlus} size="lg" />&nbsp; Rent Review</ButtonBase>
                        </SecuredComponents>
                    </div>
                    {mode === "ADD" && rentReview && <RentReviewDetail leaseId={leaseId} rentReview={rentReview} mode={mode} onCancel={() => { setMode("VIEW"); setRentReview(undefined) }} />


                    }
                </CardBaseContent>
            </CardBaseBody>

        </CardBase>

    );
}

export default RentReviewList;