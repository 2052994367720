import { Guid } from "guid-typescript";

export class SiteType {
    public id: Guid;
    public name?: string;
    public description?: string;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(siteType: any) {
        this.id = siteType.id ? Guid.parse(siteType.id) : Guid.parse(Guid.EMPTY);
        this.name = siteType.name;
        this.description = siteType.description;
        this.isActive = siteType.isActive;
        this.sortOrder = siteType.sortOrder;
        this.createdByUser = siteType.createdByUser
        this.createdDate = new Date(siteType.createdDate)
        this.updatedByUser = siteType.updatedByUser
        this.updatedDate = new Date(siteType.updatedDate)
    }
}