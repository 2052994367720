import { Validation } from "../../models/validations/validation";

export const leaseValidations: Array<Validation> = [
    {
        property: "leaseStartDate",
        friendlyName: "Lease start date",
        isRequired: true,
        predicate: (value: any) => { return value.tenancyTypeName !== "Notional" }
    },
    {
        property: "leaseEndDate",
        friendlyName: "Lease end date",
        isRequired: true,
        predicate: (value: any) => { return value.tenancyTypeName !== "Notional" }
    },
    {
        property: "leaseTypeId",
        friendlyName: "Lease Available Type",
        isRequired: true,
        predicate: (value: any) => { return value.leaseTypeName === "Notional" }
    },
    {
        property: "leaseProgressTypeId",
        friendlyName: "Lease Progress Type",
        isRequired: true,
        predicate: (value: any) => { return value.leaseTypeName === "Yes" }
    },
    {
        property: "leaseStatusTypeId",
        friendlyName: "Lease Status Type",
        isRequired: true,
        predicate: (value: any) => { return value.leaseTypeName === "Yes" }
    },
    {
        property: "landlordTypeId",
        friendlyName: "Landlord Type",
        isRequired: true,
        predicate: (value: any) => { return value.leaseTypeName === "Yes" }
    },
    {
        property: "landlordDetails",
        friendlyName: "Landlord details",
        isRequired: true,
        minLength: 3,
        maxLength: 255,
        predicate: (value: any) => { return value.leaseTypeName === "Yes" }
    },
    {
        property: "repairAllowanceTypeId",
        friendlyName: "Repair Allowance Type",
        isRequired: true,
        predicate: (value: any) => { return value.leaseTypeName === "Yes" }
    },
    {
        property: "dvDeadlineDate",
        friendlyName: "DV Deadline Date",
        isRequired: true,
        predicate: (value: any) => { return value.leaseTypeName === "Yes" && value.leaseStatusTypeName !== "Complete" }
    },
]