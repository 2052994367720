import { Guid } from "guid-typescript";

import { LeaseType } from "../models/leaseTypes/leaseType";
import ApiBroker from "./apiBroker";

class LeaseTypeBroker {

    relativeLeaseTypeUrl = '/api/leaseTypes';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostLeaseTypeAsync(leaseType: LeaseType) {
        return await this.apiBroker.PostAsync(this.relativeLeaseTypeUrl, leaseType)
            .then(result => new LeaseType(result.data));
    }

    async GetAllLeaseTypesAsync(queryString: string) {
        var url = this.relativeLeaseTypeUrl + queryString;
        
        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((leaseType: any) => new LeaseType(leaseType)));
    }

    async GetLeaseTypeByIdAsync(id: Guid) {
        const url = `${this.relativeLeaseTypeUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new LeaseType(result.data));
    }

    async PutLeaseTypeAsync(leaseType: LeaseType) {
        return await this.apiBroker.PutAsync(this.relativeLeaseTypeUrl, leaseType)
            .then(result => new LeaseType(result.data));
    }

    async DeleteLeaseTypeByIdAsync(id: Guid) {
        const url = `${this.relativeLeaseTypeUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new LeaseType(result.data));
    }
}

export default LeaseTypeBroker;



