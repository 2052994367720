import React, { FunctionComponent, useState } from "react";
import { BoroughView } from "../../models/views/components/boroughs/boroughView";
import BoroughRowView from "./boroughRowView";
import BoroughRowEdit from "./boroughRowEdit";
import BoroughRowDelete from "./boroughRowDelete";

type BoroughRowProps = {
    borough: BoroughView;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
    onSave: (borough: BoroughView) => void;
    onDelete: (borough: BoroughView) => void;
}

const BoroughRow: FunctionComponent<BoroughRowProps> = (props) => {
    const {
        borough,
        allowedToEdit,
        allowedToDelete,
        onSave,
        onDelete,
    } = props;

    const [mode, setMode] = useState<string>('VIEW');
    const [apiError, setApiError] = useState<any>({});

    const handleMode = (value: string) => {
        setMode(value);
    };

    const handleSave = async (borough: BoroughView) => {
        try {
            await onSave(borough);
            setMode('VIEW');
        } catch (error) {
            setApiError(error);
            setMode('EDIT');
        }
    };

    const handleDelete = (borough: BoroughView) => {
        onDelete(borough);
        setMode('VIEW');
    };

    const handleCancel = () => {
        setMode('VIEW');
    };

    return (
        <>
            {mode !== 'EDIT' && mode !== 'DELETE' && (
                <BoroughRowView
                    key={borough.id.toString()}
                    borough={borough}
                    onEdit={handleMode}
                    onDelete={handleMode}
                    allowedToEdit={allowedToEdit}
                    allowedToDelete={allowedToDelete} />
            )}                                                                                 

            {mode === 'EDIT' && (
                <BoroughRowEdit
                    key={borough.id.toString()}
                    borough={borough}
                    onCancel={handleCancel}
                    onEdit={handleSave}
                    apiError={apiError}
                />
            )}

            {mode === 'DELETE' && (
                <BoroughRowDelete
                    key={borough.id.toString()}
                    borough={borough}
                    onCancel={handleMode}
                    onDelete={handleDelete} />
            )}
        </>
    );
}

BoroughRow.defaultProps = {
    allowedToEdit: true,
    allowedToDelete: true
};

export default BoroughRow;