import React from 'react';

import './custom.css'
import { MsalProvider } from '@azure/msal-react';
import { ReactQueryDevtools } from 'react-query/devtools'

import "./styles/App.css";

import 'bootstrap/dist/css/bootstrap.min.css';

import { PageLayout } from './components/PageLayout';
import { Route, Routes } from 'react-router-dom';
import { SecuredRoute } from './components/SecuredRoute';
import { Home } from './pages/home';
import { PracticesHomePage } from './pages/practicesHomePage';
import { QueryClientProvider } from 'react-query';
import { PracticePage } from './pages/practicePage';
import { ConfigHomePage } from './pages/configuration/configHomePage';
import { ContactTypesPage } from './pages/configuration/contactTypesPage';
import { ContractsPage } from './pages/configuration/contractsPage';
import { BoroughsPage } from './pages/configuration/boroughsPage';
import { OwnershipsPage } from './pages/configuration/ownershipsPage';
import { PropertyStatusesPage } from './pages/configuration/propertyStatuses';
import { ProviderStatusesPage } from './pages/configuration/providerStatuses';
import { ReferenceValuesPage } from './pages/configuration/refererenceValuesPage';
import { ReviewStatusesPage } from './pages/configuration/reviewStatuses';
import { SiteTypesPage } from './pages/configuration/siteTypes';
import { ReviewStagesPage } from './pages/configuration/reviewStages';
import { SitePage } from './pages/sitePage';
import { ActionsHomePage } from './pages/actionsHomePage';
import { queryClientGlobalOptions } from './brokers/apiBroker.globals';
import securityPoints from './SecurityMatrix';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { LeaseTypesPage } from './pages/configuration/leaseTypesPage';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_AI_CONNECTIONSTRING,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    }
});
appInsights.loadAppInsights();

const App = ({ msalInstance }: any) => {
    return (
        <AppInsightsContext.Provider value={reactPlugin} >
            <MsalProvider instance={msalInstance}>
                <QueryClientProvider client={queryClientGlobalOptions}>
                    <PageLayout>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/practices" element={<SecuredRoute allowedRoles={securityPoints.siteNavigation.view}><PracticesHomePage /></SecuredRoute>} />
                            <Route path="/practices:actions" element={<SecuredRoute allowedRoles={securityPoints.siteNavigation.view}><PracticesHomePage /></SecuredRoute>} />
                            <Route path="/practice" element={<SecuredRoute allowedRoles={securityPoints.siteNavigation.view}><PracticePage /></SecuredRoute>} />
                            <Route path="/practice/:practiceId" element={<SecuredRoute allowedRoles={securityPoints.siteNavigation.view}><PracticePage /></SecuredRoute>} />
                            <Route path="/actions" element={<SecuredRoute allowedRoles={securityPoints.siteNavigation.view}><ActionsHomePage /></SecuredRoute>} />
                            <Route path="/site/:practiceId/:siteId" element={<SecuredRoute allowedRoles={securityPoints.siteNavigation.view}><SitePage /></SecuredRoute>} />
                            <Route path="/site/:practiceId/:siteId/:activeTab" element={<SecuredRoute allowedRoles={securityPoints.siteNavigation.view}><SitePage /></SecuredRoute>} />
                            <Route path="/configuration" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><ConfigHomePage /></SecuredRoute>} />
                            <Route path="/configuration/boroughs" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><BoroughsPage /></SecuredRoute>} />
                            <Route path="/configuration/contactTypes" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><ContactTypesPage /></SecuredRoute>} />
                            <Route path="/configuration/leaseTypes" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><LeaseTypesPage /></SecuredRoute>} />
                            <Route path="/configuration/contracts" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><ContractsPage /></SecuredRoute>} />
                            <Route path="/configuration/ownerships" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><OwnershipsPage /></SecuredRoute>} />
                            <Route path="/configuration/propertystatuses" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><PropertyStatusesPage /></SecuredRoute>} />
                            <Route path="/configuration/providerstatuses" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><ProviderStatusesPage /></SecuredRoute>} />
                            <Route path="/configuration/reviewstages" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><ReviewStagesPage /></SecuredRoute>} />
                            <Route path="/configuration/reviewstatuses" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><ReviewStatusesPage /></SecuredRoute>} />
                            <Route path="/configuration/sitetypes" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><SiteTypesPage /></SecuredRoute>} />
                            <Route path="/referenceValueConfig/:type" element={<SecuredRoute allowedRoles={securityPoints.configNavigation.view}><ReferenceValuesPage /></SecuredRoute>} />
                        </Routes>
                    </PageLayout>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </MsalProvider>
        </AppInsightsContext.Provider>
    );
}
export default App;