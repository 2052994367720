import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { Ownership } from "../../../models/ownerships/ownership";
import { OwnershipView } from "../../../models/views/components/ownerships/ownershipView";
import { ownershipService } from "../../foundations/ownershipService";

export const ownershipViewService = {

    useCreateOwnership: () => {
        return ownershipService.useCreateOwnership();
    },

    useGetAllOwnerships: (searchTerm?: string) => {
        try {
            let query = '?$orderby=sortOrder,name';

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`;
            }

            const response = ownershipService.useGetAllOwnerships(query);
            const [mappedOwnerships, setMappedOwnerships] = useState<Array<OwnershipView>>();

            useEffect(() => {
                if (response.data) {
                    const ownerships = response.data.map((ownership: Ownership) =>
                        new OwnershipView(
                            ownership.id,
                            ownership.name,
                            ownership.description,
                            ownership.isActive,
                            ownership.sortOrder,
                            ownership.createdByUser,
                            ownership.createdDate,
                            ownership.updatedByUser,
                            ownership.updatedDate
                        ));

                    setMappedOwnerships(ownerships);
                }
            }, [response.data]);

            return {
                mappedOwnerships, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetOwnershipById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = ownershipService.useGetAllOwnerships(query);
            const [mappedOwnership, setMappedOwnership] = useState<OwnershipView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const ownership = new OwnershipView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].description,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate);

                    setMappedOwnership(ownership);
                }
            }, [response.data]);

            return {
                mappedOwnership, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateOwnership: () => {
        return ownershipService.useUpdateOwnership();
    },

    useRemoveOwnership: () => {
        return ownershipService.useDeleteOwnership();
    },
}