import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { Discussion } from "../../../models/discussions/discussion";
import { DiscussionView } from "../../../models/views/components/discussions/discussionView";
import { discussionService } from "../../foundations/discussionService";

export const discussionViewService = {

    useCreateDiscussion: () => {
        return discussionService.useCreateDiscussion();
    },

    useGetAllDiscussions: (searchTerm?: string, siteId?: Guid) => {
        try {
            let query = '?$orderby=createdDate desc';

            if (searchTerm || siteId) {
                query = query + `&$filter=contains(comment,'${searchTerm}') and siteId eq ${siteId}`;
            }

            const response = discussionService.useGetAllDiscussions(query);
            const [mappedDiscussions, setMappedDiscussions] = useState<Array<DiscussionView>>([]);

            useEffect(() => {
                if (response.data) {
                    const discussions = response.data.map((discussion: Discussion) =>
                        new DiscussionView(
                            discussion.id,
                            discussion.siteId,
                            discussion.comment,
                            discussion.createdByUser,
                            discussion.createdDate,
                            discussion.updatedByUser,
                            discussion.updatedDate
                        ));

                    setMappedDiscussions(discussions);
                }
            }, [response.data]);

            return {
                mappedDiscussions, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetDiscussionById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = discussionService.useGetAllDiscussions(query);
            const [mappedDiscussion, setMappedDiscussion] = useState<DiscussionView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const discussion = new DiscussionView(
                        response.data[0].id,
                        response.data[0].siteId,
                        response.data[0].comment,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate
                    );

                    setMappedDiscussion(discussion);
                }
            }, [response.data]);
                    
            return {
                mappedDiscussion, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateDiscussion: () => {
        return discussionService.useUpdateDiscussion();
    },

    useRemoveDiscussion: () => {
        return discussionService.useDeleteDiscussion();
    },
}