import React, { FunctionComponent, useEffect, useState } from "react";
import { Guid } from 'guid-typescript';
import { PracticeView } from "../../models/views/components/practices/practiceView";
import { practiceViewService } from "../../services/views/practices/practiceViewService";
import PracticeDetailCard from "./practiceDetailCard";
import { lookupViewService } from "../../services/views/lookups/lookupViewService";
import SiteList from "../siteList/siteList";
import PracticeActions from "../actions/practiceActions";

interface PracticeDetailProps {
    practiceId?: string;
    children?: React.ReactNode;
}

const PracticeDetail: FunctionComponent<PracticeDetailProps> = (props) => {
    const {
        practiceId,
        children
    } = props;

    let practiceRetrieved: PracticeView | undefined
    let { mappedBoroughs: boroughsRetrieved } = lookupViewService.useGetBoroughList();
    let { mappedProviderStatus: providerStatusRetrieved } = lookupViewService.useGetProviderStatusList();

    if (practiceId !== "" && practiceId !== undefined) {
        //TODO : Can we do this differently
        let { mappedPractice } = practiceViewService.useGetPracticeById(Guid.parse(practiceId ?? Guid.EMPTY));
        practiceRetrieved = mappedPractice;
    }

    const [practice, setPractice] = useState<PracticeView>();
    const [mode, setMode] = useState<string>('VIEW');
    const addPractice = practiceViewService.useCreatePractice();

    const handleAdd = (practice: PracticeView) => {
        return addPractice.mutate(practice);
    }

    const updatePractice = practiceViewService.useUpdatePractice();

    const handleUpdate = async (practice: PracticeView) => {
        return updatePractice.mutateAsync(practice);
    }

    const handleDelete = async (practice: PracticeView) => {
        practice.isActive = false;
        return updatePractice.mutateAsync(practice);
    }

    const handleSiteSelection = (practiceId: Guid, siteId: Guid) => { }

    useEffect(() => {
        if (practiceId !== "" && practiceRetrieved !== undefined) {
            setPractice(practiceRetrieved);
            setMode('VIEW');
        }
        if (practiceId === "" || practiceId === undefined) {
            setPractice(new PracticeView(Guid.create()))
            setMode('ADD');
        }
    }, [practiceId, practiceRetrieved]);

    return (
        <div>
            {boroughsRetrieved !== undefined && practice !== undefined && (
                <div>
                    <PracticeDetailCard
                        key={practice.id.toString()}
                        practice={practice}
                        boroughs={boroughsRetrieved}
                        providerStatus={providerStatusRetrieved}
                        mode={mode}
                        onAdd={handleAdd}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                    >
                        {children}
                    </PracticeDetailCard>

                    {mode !== "ADD" && (
                        <>
                            <SiteList practiceId={practice.id} onSelect={handleSiteSelection}></SiteList>
                            <PracticeActions practiceId={practice.id} searchVisible={false} />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default PracticeDetail;