import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { BoroughView } from "../../models/views/components/boroughs/boroughView";
import TextInputBase from "../bases/inputs/TextInputBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { boroughValidations } from "./boroughValidations";
import { useValidation } from "../../hooks/useValidation";
import { boroughErrors } from "./boroughErrors";

interface BoroughRowEditProps {
    borough: BoroughView;
    onCancel: () => void;
    onEdit: (borough: BoroughView) => void;
    apiError?: any;
}

const BoroughRowEdit: FunctionComponent<BoroughRowEditProps> = (props) => {
    const {
        borough,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editBorough, setEditBorough] = useState<BoroughView>({ ...borough });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(boroughErrors, boroughValidations, editBorough);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const updatedBorough = {
            ...editBorough,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditBorough(updatedBorough);
    };

    const handleCancel = () => {
        setEditBorough({ ...borough });
        onCancel();
    };

    const handleSave = () => {
        if (!validate(editBorough)) {
            onEdit(editBorough);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Borough Name"
                    placeholder="Borough Name"
                    value={editBorough.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
            </TableBaseData>
            <TableBaseData>
                <TextInputBase
                    id="financeManagerName"
                    name="financeManagerName"
                    label="Finance Manager Name"
                    placeholder="Manager Name"
                    value={editBorough.financeManagerName}
                    error={errors.financeManagerName}
                    onChange={handleChange} />
            </TableBaseData>
            <TableBaseData>
                <TextInputBase
                    id="financeManagerEmail"
                    name="financeManagerEmail"
                    label="Finance Manager Email"
                    placeholder="Manager Email"
                    value={editBorough.financeManagerEmail}
                    error={errors.financeManagerEmail}
                    onChange={handleChange} />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default BoroughRowEdit;
