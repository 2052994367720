import { ErrorBase } from "../../types/ErrorBase";

export interface PracticeErrors extends ErrorBase {
    practiceName: string;
    practiceCode: string;
    odsCode: string;
    pcn: string;
    pcnCode: string;
    groupName: string;
    practicePhone: string;
    practicePhoneBypass: string;
    practiceGenericEmail: string;
    providerStatusId: string;
    boroughId: string;
    isActive: string;
    dateOfClosure: string;
}

export const practiceErrors: PracticeErrors = {
    hasErrors: false,
    practiceName: "",
    practiceCode: "",
    odsCode: "",
    pcn: "",
    pcnCode: "",
    groupName: "",
    practicePhone: "",
    practicePhoneBypass: "",
    practiceGenericEmail: "",
    providerStatusId: "",
    boroughId: "",
    isActive: "",
    dateOfClosure: ""
}