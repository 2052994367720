import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import OwnershipBroker from "../../brokers/apiBroker.ownerships"
import { Ownership } from "../../models/ownerships/ownership";

export const ownershipService = {
    useCreateOwnership: () => {
        const ownershipBroker = new OwnershipBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((ownership: Ownership) => {
            const date = new Date();
            ownership.createdDate = ownership.updatedDate = date;
            ownership.createdByUser = ownership.updatedByUser = msal.accounts[0].username;

            return ownershipBroker.PostOwnershipAsync(ownership);
        },
            {
                onSuccess: (variables) => {
                    queryClient.invalidateQueries("OwnershipGetAll");
                    queryClient.invalidateQueries(["OwnershipGetById", { id: variables.id }]);
                }
            });
    },

    useGetAllOwnerships: (query: string) => {
        const ownershipBroker = new OwnershipBroker();

        return useQuery(
            ["OwnershipGetAll", { query: query }],
            () => ownershipBroker.GetAllOwnershipsAsync(query),
            { staleTime: Infinity });
    },

    useGetOwnershipById: (id: Guid) => {
        const ownershipBroker = new OwnershipBroker();

        return useQuery(
            ["OwnershipGetById", { id: id }],
            () => ownershipBroker.GetOwnershipByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateOwnership: () => {
        const ownershipBroker = new OwnershipBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((ownership: Ownership) => {
            const date = new Date();
            ownership.updatedDate = date;
            ownership.updatedByUser = msal.accounts[0].username;

            return ownershipBroker.PutOwnershipAsync(ownership);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("OwnershipGetAll");
                    queryClient.invalidateQueries(["OwnershipGetById", { id: data.id }]);
                }
            });
    },

    useDeleteOwnership: () => {
        const ownershipBroker = new OwnershipBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return ownershipBroker.DeleteOwnershipByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("OwnershipGetAll");
                    queryClient.invalidateQueries(["OwnershipGetById", { id: data.id }]);
                }
            });
    },
}