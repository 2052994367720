const securityPoints = {
    siteNavigation: {
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    practices: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    sites: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        delete: ["GpPremiseManagementSpa.Administrators"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    contacts: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    leases: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    leaseTypes: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    rentReview: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    actions: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    discussions: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        delete: ["GpPremiseManagementSpa.Administrators"],
        edit: ["GpPremiseManagementSpa.Administrators"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    documents: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    configNavigation: {
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"]
    },
    boroughs: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
    },
    contactTypes: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
    },
    contracts: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
    },
    ownerships: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
    },
    propertyStatuses: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
    },
    referenceValues: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Users", "GpPremiseManagementSpa.ReadOnly"]
    },
    providerStatuses: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
    },
    reviewStages: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
    },
    reviewStatuses: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
    },
    siteTypes: {
        add: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        delete: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        edit: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
        view: ["GpPremiseManagementSpa.Administrators", "GpPremiseManagementSpa.Configurations"],
    },
}

export default securityPoints