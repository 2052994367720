import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { PropertyStatusView } from "../../models/views/components/propertyStatuses/propertyStatusView";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import { propertyStatusValidations } from "./propertyStatusValidations";
import { propertyStatusErrors } from "./propertyStatusErrors";
import { useValidation } from "../../hooks/useValidation";

interface PropertyStatusRowEditProps {
    propertyStatus: PropertyStatusView;
    onCancel: () => void;
    onEdit: (propertyStatus: PropertyStatusView) => void;
    apiError?: any;
}

const PropertyStatusRowEdit: FunctionComponent<PropertyStatusRowEditProps> = (props) => {
    const {
        propertyStatus,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editPropertyStatus, setEditPropertyStatus] = useState<PropertyStatusView>({ ...propertyStatus });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(propertyStatusErrors, propertyStatusValidations, editPropertyStatus);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const UpdatedpropertyStatus = {
            ...editPropertyStatus,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditPropertyStatus(UpdatedpropertyStatus);
    };

    const handleCancel = () => {
        setEditPropertyStatus({ ...propertyStatus });
        onCancel();
    };

    const handleSave = () => {
        if (!validate(editPropertyStatus)) {
            onEdit(editPropertyStatus);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Property Status Name"
                    placeholder="Property Status Name"
                    value={editPropertyStatus.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Property Status Description"
                    placeholder="Property Status Description"
                    value={editPropertyStatus.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={editPropertyStatus.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={editPropertyStatus.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default PropertyStatusRowEdit;
