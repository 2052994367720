import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import ReviewStatusBroker from "../../brokers/apiBroker.reviewStatuses"
import { ReviewStatus } from "../../models/reviewStatuses/reviewStatus";


export const reviewStatusService = {
    useCreateReviewStatus: () => {
        const reviewStatusBroker = new ReviewStatusBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((reviewStatus: ReviewStatus) => {
            const date = new Date();
            reviewStatus.createdDate = reviewStatus.updatedDate = date;
            reviewStatus.createdByUser = reviewStatus.updatedByUser = msal.accounts[0].username;

            return reviewStatusBroker.PostReviewStatusAsync(reviewStatus);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ReviewStatusGetAll");
                    queryClient.invalidateQueries(["ReviewStatusGetById", { id: data.id }]);
                }
            });
    },

    useGetAllReviewStatuses: (query: string) => {
        const reviewStatusBroker = new ReviewStatusBroker();

        return useQuery(
            ["ReviewStatusGetAll", { query: query }],
            () => reviewStatusBroker.GetAllReviewStatusesAsync(query),
            { staleTime: Infinity });
    },

    useGetReviewStatusById: (id: Guid) => {
        const reviewStatusBroker = new ReviewStatusBroker();

        return useQuery(
            ["ReviewStatusGetById", { id: id }],
            () => reviewStatusBroker.GetReviewStatusByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateReviewStatus: () => {
        const reviewStatusBroker = new ReviewStatusBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((reviewStatus: ReviewStatus) => {
            const date = new Date();
            reviewStatus.updatedDate = date;
            reviewStatus.updatedByUser = msal.accounts[0].username;

            return reviewStatusBroker.PutReviewStatusAsync(reviewStatus);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ReviewStatusGetAll");
                    queryClient.invalidateQueries(["ReviewStatusGetById", { id: data.id }]);
                }
            });
    },

    useDeleteReviewStatus: () => {
        const reviewStatusBroker = new ReviewStatusBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return reviewStatusBroker.DeleteReviewStatusByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ReviewStatusGetAll");
                    queryClient.invalidateQueries(["ReviewStatusGetById", { id: data.id }]);
                }
            });
    },
}