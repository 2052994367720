import React, { FunctionComponent, useState } from "react";
import { ReferenceValueView } from "../../models/views/components/referenceValues/referenceValueView";
import ReferenceValueRowView from "./referenceValueRowView";
import ReferenceValueRowEdit from "./referenceValueRowEdit";
import ReferenceValueRowDelete from "./referenceValueRowDelete";

type ReferenceValueRowProps = {
    referenceValue: ReferenceValueView;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
    onSave: (referenceValue: ReferenceValueView) => void;
    onDelete: (referenceValue: ReferenceValueView) => void;
}

const ReferenceValueRow: FunctionComponent<ReferenceValueRowProps> = (props) => {
    const {
        referenceValue,
        allowedToEdit,
        allowedToDelete,
        onSave,
        onDelete,
    } = props;

    const [mode, setMode] = useState<string>('VIEW');
    const [apiError, setApiError] = useState<any>({});

    const handleMode = (value: string) => {
        setMode(value);
    };

    const handleSave = async (referenceValue: ReferenceValueView) => {
        try {
            await onSave(referenceValue);
            setMode('VIEW');
        } catch (error) {
            setApiError(error);
            setMode('EDIT');
        }
    };

    const handleDelete = (referenceValue: ReferenceValueView) => {
        onDelete(referenceValue);
        setMode('VIEW');
    };

    const handleCancel = () => {
        setMode('VIEW');
    };

    return (
        <>
            {mode !== 'EDIT' && mode !== 'DELETE' && (
                <ReferenceValueRowView
                    key={referenceValue.id.toString()}
                    referenceValue={referenceValue}
                    onEdit={handleMode}
                    onDelete={handleMode}
                    allowedToEdit={allowedToEdit}
                    allowedToDelete={allowedToDelete} />
            )}

            {mode === 'EDIT' && (
                <ReferenceValueRowEdit
                    key={referenceValue.id.toString()}
                    referenceValue={referenceValue}
                    onCancel={handleCancel}
                    onEdit={handleSave}
                    apiError={apiError} />
            )}

            {mode === 'DELETE' && (
                <ReferenceValueRowDelete
                    key={referenceValue.id.toString()}
                    referenceValue={referenceValue}
                    onCancel={handleCancel}
                    onDelete={handleDelete} />
            )}
        </>
    );
}

ReferenceValueRow.defaultProps = {
    allowedToEdit: false,
    allowedToDelete: false
};

export default ReferenceValueRow;