import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import ReviewStageBroker from "../../brokers/apiBroker.reviewStages"
import { ReviewStage } from "../../models/reviewStages/reviewStage";

export const reviewStageService = {

    useCreateReviewStage: () => {
        const reviewStageBroker = new ReviewStageBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((reviewStage: ReviewStage) => {
            const date = new Date();
            reviewStage.createdDate = reviewStage.updatedDate = date;
            reviewStage.createdByUser = reviewStage.updatedByUser = msal.accounts[0].username;

            return reviewStageBroker.PostReviewStageAsync(reviewStage);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ReviewStageGetAll");
                    queryClient.invalidateQueries(["ReviewStageGetById", { id: data.id }]);
                }
            });
    },

    useGetAllReviewStages: (query: string) => {
        const reviewStageBroker = new ReviewStageBroker();

        return useQuery(
            ["ReviewStageGetAll", { query: query }],
            () => reviewStageBroker.GetAllReviewStagesAsync(query),
            { staleTime: Infinity });
    },

    useGetReviewStageById: (id: Guid) => {
        const reviewStageBroker = new ReviewStageBroker();

        return useQuery(
            ["ReviewStageGetById", { id: id }],
            () => reviewStageBroker.GetReviewStageByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateReviewStage: () => {
        const reviewStageBroker = new ReviewStageBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((reviewStage: ReviewStage) => {
            const date = new Date();
            reviewStage.updatedDate = date;
            reviewStage.updatedByUser = msal.accounts[0].username;

            return reviewStageBroker.PutReviewStageAsync(reviewStage);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ReviewStageGetAll");
                    queryClient.invalidateQueries(["ReviewStageGetById", { id: data.id }]);
                }
            });
    },

    useDeleteReviewStage: () => {
        const reviewStageBroker = new ReviewStageBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return reviewStageBroker.DeleteReviewStageByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ReviewStageGetAll");
                    queryClient.invalidateQueries(["ReviewStageGetById", { id: data.id }]);
                }
            });
    },
}