import { FunctionComponent } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import DateInputBase from "../bases/inputs/DateInputBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import React from "react";
import { PracticeView } from "../../models/views/components/practices/practiceView";
import moment from "moment";

interface PracticeDetailCloseModelProps {
    editPractice: PracticeView;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    confirmClosePractice: () => void;
    cancelClosePractice: () => void;
}


const PracticeDetailCloseModel: FunctionComponent<PracticeDetailCloseModelProps> = (props) => {
    const {
        editPractice,
        handleChange,
        confirmClosePractice,
        cancelClosePractice
    } = props;

    return (<Modal show={true}>
        <ModalHeader style={{backgroundColor:"yellow"}}>
            <b>Please confirm</b>
        </ModalHeader>
        <ModalBody>
            <p>Are you sure you want to change this practice to inactive?</p>
            <p>This should only be done if practice contract is closed. This will mark the practice as archived and remove from all reporting and lease/rent renewals.</p>
            <p>Please provide the date the practice closed:</p>
            <DateInputBase
                    id="dateOfClosure"
                    name="dateOfClosure"
                    value={editPractice.dateOfClosure ? moment(editPractice?.dateOfClosure).format("YYYY-MM-DD") : ""}
                    type="date"
                    onChange={handleChange} />
        </ModalBody>
        <ModalFooter>
            <ButtonBase onClick={confirmClosePractice} add disabled={editPractice.dateOfClosure === undefined}>Yes - Close Practice</ButtonBase>
            <ButtonBase onClick={cancelClosePractice} cancel>No - Keep Practice Open</ButtonBase>
        </ModalFooter>
    </Modal>);
}

export default PracticeDetailCloseModel;