import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import DiscussionBroker from "../../brokers/apiBroker.discussions"
import { Discussion } from "../../models/discussions/discussion";

export const discussionService = {
    useCreateDiscussion: () => {
        const discussionBroker = new DiscussionBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((discussion: Discussion) => {
            const date = new Date();
            discussion.createdDate = discussion.updatedDate = date;
            discussion.createdByUser = discussion.updatedByUser = msal.accounts[0].username;

            return discussionBroker.PostDiscussionAsync(discussion);
        },
            {
                onSuccess: (variables) => {
                    queryClient.invalidateQueries("DiscussionGetAll");
                    queryClient.invalidateQueries(["DiscussionGetById", { id: variables.id }]);
                }
            });
    },

    useGetAllDiscussions: (query: string) => {
        const discussionBroker = new DiscussionBroker();

        return useQuery(
            ["DiscussionGetAll", { query: query }],
            () => discussionBroker.GetAllDiscussionsAsync(query),
            { staleTime: Infinity });
    },

    useGetDiscussionById: (id: Guid) => {
        const discussionBroker = new DiscussionBroker();

        return useQuery(
            ["DiscussionGetById", { id: id }],
            () => discussionBroker.GetDiscussionByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateDiscussion: () => {
        const discussionBroker = new DiscussionBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((discussion: Discussion) => {
            const date = new Date();
            discussion.updatedDate = date;
            discussion.updatedByUser = msal.accounts[0].username;

            return discussionBroker.PutDiscussionAsync(discussion);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("DiscussionGetAll");
                    queryClient.invalidateQueries(["DiscussionGetById", { id: data.id }]);
                }
            });
    },

    useDeleteDiscussion: () => {
        const discussionBroker = new DiscussionBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return discussionBroker.DeleteDiscussionByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("DiscussionGetAll");
                    queryClient.invalidateQueries(["DiscussionGetById", { id: data.id }]);
                }
            });
    },
}