import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import TextInputBase from "../bases/inputs/TextInputBase";
import SelectInputBase from "../bases/inputs/SelectInputBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import { LookupView } from "../../models/views/components/lookups/lookupView";
import { Option } from "../../models/options/option";
import SummaryListBase from "../bases/components/SummaryList/SummaryListBase";
import SummaryListBaseRow from "../bases/components/SummaryList/SummaryListBase.Row";
import SummaryListBaseKey from "../bases/components/SummaryList/SummaryListBase.Key";
import SummaryListBaseValue from "../bases/components/SummaryList/SummaryListBase.Value";
import securityPoints from "../../SecurityMatrix";
import { SecuredComponents } from "../Links";
import { useValidation } from "../../hooks/useValidation";
import { RentReviewView } from "../../models/views/components/rentReviews/rentReviewView";
import { rentReviewErrors } from "./rentReviewErrors";
import { rentReviewValidations } from "./rentReviewValidations";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import moment from "moment";
import DateInputBase from "../bases/inputs/DateInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { rentReviewViewService } from "../../services/views/rentReviews/rentReviewViewService";
import { Alert } from "react-bootstrap";
import ProcessRentReviewWorkflow from "./RentReviewWorkflows";
import "./rentReview.css";

interface RentReviewDetailCardEditProps {
    rentReview: RentReviewView;
    reviewStages: Array<LookupView>;
    reviewStatuses: Array<LookupView>;
    onAdd: (rentReviewView: RentReviewView) => void;
    onUpdate: (rentReviewView: RentReviewView) => void;
    onCancel: () => void;
    mode: string;
    onModeChange: (value: string) => void;
    apiError?: any;
}

const RentReviewDetailCardEdit: FunctionComponent<RentReviewDetailCardEditProps> = (props) => {
    const {
        rentReview,
        reviewStages,
        reviewStatuses,
        onAdd,
        onUpdate,
        onCancel,
        mode,
        apiError
    } = props;

    const [editRentReview, setEditRentReview] = useState<RentReviewView>({ ...rentReview });
    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(rentReviewErrors, rentReviewValidations, editRentReview);
    const [showReviewDateWarning, setShowReviewDateWarning] = useState<boolean>(false);
    const {mappedRentReviews} = rentReviewViewService.useGetRentReviewsByLeaseId(rentReview.leaseId);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLSelectElement>) => {
        let value: any;
        switch (event.target.type) {
            case "checkbox":
                value = (event.target as HTMLInputElement).checked
                break;
            case "date":
                value = event.target.value !== '' ? event.target.value : null;
                break
            default:
                value = event.target.value;
                break;
        }

        const updatedRentReview = {
            ...editRentReview,
            [event.target.name]: value
        };

        setEditRentReview(updatedRentReview);
    };

    const handleCancel = () => {
        onCancel();
    };

    const reviewStageOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...reviewStages.map((reviewStage) => {
            return { id: reviewStage.id.toString() || 0, name: reviewStage.name || "" };
        }),
    ];

    const reviewStatusOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...reviewStatuses.map((reviewStatuses) => {
            return { id: reviewStatuses.id.toString() || 0, name: reviewStatuses.name || "" };
        }),
    ];

    const isNewReviewDateInvalid = (): boolean => {
        if(!editRentReview.nextRentReview) {
            // no next review date so no need to check.
            return false;
        }

        if(moment(editRentReview.nextRentReview).isSameOrAfter(moment(), 'day'))
        {
            // next review date is in the future so is valid.
            return false;
        }

        // there is a another rent review in the future so this is valid.
        var futureRentReviews = mappedRentReviews.filter((rentReview) => { return moment(rentReview.rentReviewDueDate).isAfter(moment(), 'day') && rentReview.isActive });
        if(futureRentReviews.length > 0) {
            return false;
        }
            
        // all checks failed so show the warning.
        setShowReviewDateWarning(true);
        return true;
    }


    const handleSave = () => {
        if (!validate(editRentReview)) {
            if (!showReviewDateWarning && isNewReviewDateInvalid()) {
                return;
            }
            ProcessRentReviewWorkflow(editRentReview, rentReview, [...reviewStages, ...reviewStatuses]);
            onUpdate(editRentReview);
        } else {
            enableValidationMessages();
        }
    }

    const handleAdd = () => {
        if (!validate(editRentReview)) {
            if (!showReviewDateWarning && isNewReviewDateInvalid()) {
                return;
            }
            ProcessRentReviewWorkflow(editRentReview, rentReview, [...reviewStages, ...reviewStatuses]);
            onAdd(editRentReview);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <div>
            <Modal show={showReviewDateWarning}>
                <ModalHeader>Next Review Date Warning</ModalHeader>
                <ModalBody>Next Rent Review date is in the past. Please check that the next Rent Review Profile has been created</ModalBody>
                <ModalFooter>
                    {mode === "ADD" && (
                        <ButtonBase onClick={handleAdd} color="primary">Ok - Add Rent Review</ButtonBase>
                    )}
                    {mode !== "ADD" && (
                        <ButtonBase onClick={handleSave} color="primary">Ok - Update Rent Review</ButtonBase>
                    )}
                </ModalFooter>
            </Modal>

            {editRentReview.reviewClosedDate && <>
                <SummaryListBase>
                    <SummaryListBaseRow>
                        <Alert variant="warning" className="full-width text-centre">
                            Review Closed: {moment(editRentReview.reviewClosedDate).format("DD-MMM-YYYY")}
                        </Alert>
                    </SummaryListBaseRow>
                </SummaryListBase>
            </>
            }
            <SummaryListBase>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Review Status</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <SelectInputBase
                            id="reviewStatusId"
                            name="reviewStatusId"
                            value={editRentReview.reviewStatusId?.toString() || ""}
                            options={reviewStatusOptions}
                            error={errors.reviewStatusId}
                            onChange={handleChange}
                            required={true}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Review Progress</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <SelectInputBase
                            id="reviewStageId"
                            name="reviewStageId"
                            value={editRentReview.reviewStageId?.toString() || ""}
                            options={reviewStageOptions}
                            error={errors.reviewStageId}
                            onChange={handleChange}
                            required={true}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Repair Allowance</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="repairAllowance"
                            name="repairAllowance"
                            placeholder="Repair Allowance"
                            value={editRentReview.repairAllowance}
                            required={false}
                            error={errors.repairAllowance}
                            type="number"
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>PCIF Improvement Grant Details (If Applicable)</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextAreaInputBase
                            id="pcifImprovementGrantAmountApplicable"
                            rows={3}
                            maxCharacters={1000}
                            name="pcifImprovementGrantAmountApplicable"
                            placeholder="PCIF Improvement Grant Amount Details"
                            value={editRentReview.pcifImprovementGrantAmountApplicable}
                            error={errors.pcifImprovementGrantAmountApplicable}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Rent Review Due Date</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <DateInputBase
                            id="rentReviewDueDate"
                            name="rentReviewDueDate"
                            value={editRentReview.rentReviewDueDate ? moment(editRentReview?.rentReviewDueDate).format("YYYY-MM-DD") : ""}
                            required={true}
                            error={errors.rentReviewDueDate}
                            type="date"
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Reimbursement Amount</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="agreedRentAmount"
                            name="agreedRentAmount"
                            placeholder="Agreed Rent Amount"
                            value={editRentReview.agreedRentAmount}
                            error={errors.agreedRentAmount}
                            type="number"
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow classes="d-none">
                    <SummaryListBaseKey>Valuation Decision</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="valuationDecision"
                            name="valuationDecision"
                            placeholder="Valuation Decision"
                            value={editRentReview.valuationDecision}
                            error={errors.valuationDecision}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>District Valuer Name</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="districtValuerName"
                            name="districtValuerName"
                            placeholder="District Valuer Name"
                            value={editRentReview.districtValuerName}
                            error={errors.districtValuerName}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>District Valuer Email</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="districtValuerEmail"
                            name="districtValuerEmail"
                            placeholder="District Valuer Email"
                            value={editRentReview.districtValuerEmail}
                            error={errors.districtValuerEmail}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>District Valuer PO Number</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="districtValuerPoNumber"
                            name="districtValuerPoNumber"
                            placeholder="District Valuer PO Number"
                            value={editRentReview.districtValuerPoNumber}
                            error={errors.districtValuerPoNumber}
                            onChange={handleChange} /></SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>District Valuer Ref No</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="districtValuerRefNo"
                            name="districtValuerRefNo"
                            placeholder="District Valuer Ref No"
                            value={editRentReview.districtValuerRefNo}
                            error={errors.districtValuerRefNo}
                            onChange={handleChange} /></SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>District Valuer Deadline Date</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <DateInputBase
                            id="districtValuerDeadlineDate"
                            name="districtValuerDeadlineDate"
                            value={editRentReview.districtValuerDeadlineDate ? moment(editRentReview?.districtValuerDeadlineDate).format("YYYY-MM-DD") : ""}
                            error={errors.districtValuerDeadlineDate}
                            type="date"
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Next Rent Review</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <DateInputBase
                            id="nextRentReview"
                            name="nextRentReview"
                            value={editRentReview.nextRentReview ? moment(editRentReview?.nextRentReview).format("YYYY-MM-DD") : ""}
                            error={errors.nextRentReview}
                            type="date"
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow classes="d-none">
                    <SummaryListBaseKey>Next Rent Amount</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="nextRentAmount"
                            name="nextRentAmount"
                            placeholder="Next Rent Amount"
                            value={editRentReview.nextRentAmount}
                            required={false}
                            error={errors.nextRentAmount}
                            type="number"
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow classes="d-none">
                    <SummaryListBaseKey>Is Silent Rent Review</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <CheckboxBase
                            id="isSilentRentReview"
                            name="isSilentRentReview"
                            label=""
                            checked={editRentReview.isSilentRentReview}
                            error={errors.isSilentRentReview}
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

            </SummaryListBase>

            {mode === "ADD" && (
                <SecuredComponents allowedRoles={securityPoints.rentReview.add}>
                    <>
                        <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>
                        <ButtonBase id="AddRentReviewBtn" onClick={() => handleAdd()} add>Add</ButtonBase>
                    </>
                </SecuredComponents>
            )}

            {mode !== "ADD" && (
                <SecuredComponents allowedRoles={securityPoints.rentReview.edit}>
                    <>
                        <ButtonBase id="rentCancel" onClick={() => handleCancel()} cancel>Cancel</ButtonBase>
                        <ButtonBase id="rentUpdate" onClick={() => handleSave()} edit>Update</ButtonBase>
                    </>
                </SecuredComponents>
            )}
        </div>
    );
}

export default RentReviewDetailCardEdit;
