import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { ReferenceValueView } from "../../models/views/components/referenceValues/referenceValueView";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { referenceValueErrors } from "./referenceValueErrors";
import { referenceValueValidations } from "./referenceValueValidations";

interface ReferenceValueRowEditProps {
    referenceValue: ReferenceValueView;
    onCancel: () => void;
    onEdit: (referenceValue: ReferenceValueView) => void;
    apiError?: any;
}

const ReferenceValueRowEdit: FunctionComponent<ReferenceValueRowEditProps> = (props) => {
    const {
        referenceValue,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editReferenceValue, setEditReferenceValue] = useState<ReferenceValueView>({ ...referenceValue });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(referenceValueErrors, referenceValueValidations, editReferenceValue);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const SpdatedreferenceValue = {
            ...editReferenceValue,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditReferenceValue(SpdatedreferenceValue);
    };

    const handleSave = () => {
        if (!validate(editReferenceValue)) {
            onEdit(editReferenceValue);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Reference Value Name"
                    placeholder="Reference Value Name"
                    value={editReferenceValue.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="referenceValueType"
                    name="referenceValueType"
                    label="Reference Value Type"
                    placeholder="Reference Value Type"
                    value={editReferenceValue.referenceValueType}
                    required={true}
                    error={errors.referenceValueType}
                    onChange={handleChange}
                    rows={3}
                />
                <TextAreaInputBase
                    id="workFlowAction"
                    name="workFlowAction"
                    label="Work Flow Action"
                    placeholder="Work Flow Action"
                    value={editReferenceValue.workFlowAction}
                    error={errors.workFlowAction}
                    onChange={handleChange}
                    rows={3}
                />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Reference Value Description"
                    placeholder="ReferenceValue Description"
                    value={editReferenceValue.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={editReferenceValue.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={editReferenceValue.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReferenceValueRowEdit;
