import React, { ChangeEvent } from "react";
import { FunctionComponent } from "react";
import { LeaseDocument } from "../../models/leaseDocuments/leaseDocument";
import securityPoints from "../../SecurityMatrix";
import ButtonBase from "../bases/buttons/ButtonBase";
import SummaryListBase from "../bases/components/SummaryList/SummaryListBase";
import SummaryListBaseAction from "../bases/components/SummaryList/SummaryListBase.Action";
import SummaryListBaseKey from "../bases/components/SummaryList/SummaryListBase.Key";
import SummaryListBaseRow from "../bases/components/SummaryList/SummaryListBase.Row";
import SummaryListBaseValue from "../bases/components/SummaryList/SummaryListBase.Value";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import TextInputBase from "../bases/inputs/TextInputBase";
import { SecuredComponents } from "../Links";
import { IDocumentErrors } from "./documentsErrors";

interface DocumentDetailsCardNewProps {
    handleCancel: Function,
    documentToAdd: LeaseDocument,
    handleChange: Function,
    handleUpload: Function,
    handleFileChange: Function,
    errors: IDocumentErrors
}

const DocumentDetailsCardNew: FunctionComponent<DocumentDetailsCardNewProps> = (props) => {
    const { handleCancel, documentToAdd, handleChange, handleUpload, handleFileChange, errors } = props;

    return <SummaryListBase>
        <SummaryListBaseRow>
            <SummaryListBaseKey>Name</SummaryListBaseKey>
            <SummaryListBaseValue>
                <TextInputBase
                    name="label"
                    id="newDocumentLabel"
                    value={documentToAdd.label}
                    error={errors.label}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => { handleChange(event.target.name, event.target.value) }} />
            </SummaryListBaseValue>
        </SummaryListBaseRow>
        <SummaryListBaseRow>
            <SummaryListBaseKey>Description</SummaryListBaseKey>
            <SummaryListBaseValue>
                <TextAreaInputBase
                    name="description"
                    id="newDocumentDecription"
                    value={documentToAdd.description}
                    error={errors.description}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) => { handleChange(event.target.name, event.target.value) }}
                    rows={3}
                />
            </SummaryListBaseValue>
        </SummaryListBaseRow>
        <SummaryListBaseRow>
            <SummaryListBaseKey>File</SummaryListBaseKey>
            <SummaryListBaseValue>
                <TextInputBase
                    type="file"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleFileChange(event)
                    }}
                   // value={documentToAdd.fileName}
                    error={errors.file}
                    id="newDocumentFile"
                    name="file"
                />

            </SummaryListBaseValue>
        </SummaryListBaseRow>
        <SummaryListBaseRow>
            <SummaryListBaseKey></SummaryListBaseKey>
            <SummaryListBaseAction>
                <SecuredComponents allowedRoles={securityPoints.documents.add}>
                    <>
                        <ButtonBase onClick={() => { handleUpload() }} disabled={errors.hasErrors} view>Upload</ButtonBase>
                        <ButtonBase onClick={() => { handleCancel() }} cancel>Cancel</ButtonBase>
                    </>
                </SecuredComponents>
            </SummaryListBaseAction>
        </SummaryListBaseRow>
    </SummaryListBase>
}

export default DocumentDetailsCardNew;