import React, { FunctionComponent } from "react";
import { BoroughView } from "../../models/views/components/boroughs/boroughView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import { toastSuccess } from "../../brokers/toastBroker";

interface BoroughRowDeleteProps {
    borough: BoroughView;
    onCancel: (value: string) => void;
    onDelete: (borough: BoroughView) => void;
}

const BoroughRowDelete: FunctionComponent<BoroughRowDeleteProps> = (props) => {
    const {
        borough,
        onCancel,
        onDelete
    } = props;

    const handleDelete = (borough: BoroughView) => {
        onDelete(borough);
        toastSuccess(`${borough.name} Deleted`);
    }

    return (
        <TableBaseRow>
            <TableBaseData>{borough.name}</TableBaseData>
            <TableBaseData>{borough.financeManagerName}</TableBaseData>
            <TableBaseData>{borough.financeManagerEmail}</TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel('VIEW')} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleDelete(borough)} remove>Yes, Delete</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default BoroughRowDelete;
