import React from "react";
import { Container } from 'nhsuk-react-components'
import CardBase from "../components/bases/components/Card/CardBase";
import CardBaseBody from "../components/bases/components/Card/CardBase.Body";
import CardBaseTitle from "../components/bases/components/Card/CardBase.Title";
import CardBaseContent from "../components/bases/components/Card/CardBase.Content";
import ActionsAll from "../components/actions/actionsAll";

export const ActionsHomePage = () => {
    return <div>
        <section>
            <Container>
                <main id="maincontent" className="NELTopPadding" role="main">
                    <>
                        <CardBase>
                            <CardBaseBody>
                                <CardBaseTitle>
                                    Actions
                                </CardBaseTitle>
                                <CardBaseContent>
                                    <ActionsAll />
                                </CardBaseContent>
                            </CardBaseBody>
                        </CardBase>
                    </>
                </main>
            </Container>
        </section>
    </div>
}