import { Guid } from 'guid-typescript';

export class Contract {
    public id: Guid;
    public name?: string;
    public description?: string;
    public sortOrder?: number;
    public isActive?: boolean;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(contract: any) {
        this.id = contract.id ? Guid.parse(contract.id) : Guid.parse(Guid.EMPTY);
        this.name = contract.name;
        this.sortOrder = contract.sortOrder;
        this.description = contract.description;
        this.isActive = contract.isActive === false ? false : true ;
        this.createdByUser = contract.createdByUser
        this.createdDate = new Date(contract.createdDate)
        this.updatedByUser = contract.updatedByUser
        this.updatedDate = new Date(contract.updatedDate)
    }
}