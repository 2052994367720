import React from "react";
import 'nhsuk-frontend/dist/nhsuk.min'
import 'nhsuk-frontend/packages/polyfills';
import { Container } from 'nhsuk-react-components'
import SiteTypeTable from "../../components/siteTypes/siteTypeTable";

export const SiteTypesPage = () => {
    return <div>
        <section >
            <Container>
                <main id="maincontent" className="NELTopPadding" role="main">
                    <>
                        {/*TODO: Add security checks here...*/}
                        <SiteTypeTable allowedToAdd={true} allowedToEdit={true} allowedToDelete={true} />
                    </>
                </main>
            </Container>
        </section>
    </div>
}