import { Validation } from "../../models/validations/validation";

export const reviewStageValidations: Array<Validation> = [
    {
        property: "name",
        friendlyName: "Review stage name",
        isRequired: true,
        minLength: 3,
        maxLength: 255,
    }
]