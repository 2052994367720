import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import LeaseTypeBroker from "../../brokers/apiBroker.leaseTypes"
import { LeaseType } from "../../models/leaseTypes/leaseType";

export const leaseTypeService = {
    useCreateLeaseType: () => {
        const leaseTypeBroker = new LeaseTypeBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((leaseType: LeaseType) => {
            const date = new Date();
            leaseType.createdDate = leaseType.updatedDate = date;
            leaseType.createdByUser = leaseType.updatedByUser = msal.accounts[0].username;

            return leaseTypeBroker.PostLeaseTypeAsync(leaseType);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("LeaseTypeGetAll");
                    queryClient.invalidateQueries(["LeaseTypeGetById", { id: data.id }]);
                }
            });
    },

    useGetAllLeaseTypes: (query: string) => {
        const leaseTypeBroker = new LeaseTypeBroker();

        return useQuery(
            ["LeaseTypeGetAll", { query: query }],
            () => leaseTypeBroker.GetAllLeaseTypesAsync(query),
            { staleTime: Infinity });
    },

    useGetLeaseTypeById: (id: Guid) => {
        const leaseTypeBroker = new LeaseTypeBroker();

        return useQuery(
            ["LeaseTypeGetById", { id: id }],
            () => leaseTypeBroker.GetLeaseTypeByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateLeaseType: () => {
        const leaseTypeBroker = new LeaseTypeBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((leaseType: LeaseType) => {
            const date = new Date();
            leaseType.updatedDate = date;
            leaseType.updatedByUser = msal.accounts[0].username;

            return leaseTypeBroker.PutLeaseTypeAsync(leaseType);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("LeaseTypeGetAll");
                    queryClient.invalidateQueries(["LeaseTypeGetById", { id: data.id }]);
                }
            });
    },

    useDeleteLeaseType: () => {
        const leaseTypeBroker = new LeaseTypeBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return leaseTypeBroker.DeleteLeaseTypeByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("LeaseTypeGetAll");
                    queryClient.invalidateQueries(["LeaseTypeGetById", { id: data.id }]);
                }
            });
    },
}