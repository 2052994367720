import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import TextInputBase from "../bases/inputs/TextInputBase";
import SelectInputBase from "../bases/inputs/SelectInputBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import { Option } from "../../models/options/option";
import { SiteView } from "../../models/views/components/site/siteView";
import { LookupView } from "../../models/views/components/lookups/lookupView";
import SummaryListBase from "../bases/components/SummaryList/SummaryListBase";
import SummaryListBaseRow from "../bases/components/SummaryList/SummaryListBase.Row";
import SummaryListBaseKey from "../bases/components/SummaryList/SummaryListBase.Key";
import SummaryListBaseValue from "../bases/components/SummaryList/SummaryListBase.Value";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";
import { siteDetailsValidation } from "./siteDetailsValidation";
import { siteErrors } from "./siteErrors";
import { useValidation } from "../../hooks/useValidation";
import moment from "moment";
import DateInputBase from "../bases/inputs/DateInputBase";

interface SiteDetailEditProps {
    site: SiteView;
    siteTypes: Array<LookupView>;
    contracts: Array<LookupView>;
    ownerships: Array<LookupView>;
    propertyStatuses: Array<LookupView>;
    onAdd: (site: SiteView) => void;
    onUpdate: (site: SiteView) => void;
    onCancel: () => void;
    mode: string;
    onModeChange: (value: string) => void;
    apiError?: any;
}

const SiteDetailEdit: FunctionComponent<SiteDetailEditProps> = (props) => {
    const {
        site,
        siteTypes,
        contracts,
        ownerships,
        propertyStatuses,
        onAdd,
        onUpdate,
        onCancel,
        mode,
        onModeChange,
        apiError
    } = props;

    const [editSite, setEditSite] = useState<SiteView>({ ...site });

    const [confirmClosureDateVisible, setClosureDateVisible] = useState(checkPropertyTypeState());

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(siteErrors, siteDetailsValidation, editSite);

    const handleChange = (
        event: ChangeEvent<HTMLInputElement>
            | ChangeEvent<HTMLTextAreaElement>
            | ChangeEvent<HTMLSelectElement>) => {

        let updatedSite: SiteView = {
            ...editSite
        };

        let value: any = null;
        switch (event.target.type) {
            case "checkbox":
                updatedSite = { ...updatedSite, [event.target.name]: (event.target as HTMLInputElement).checked };
                break;
            case "date":
                value = event.target.value ? moment(event.target.value).toDate() : null;
                updatedSite = { ...updatedSite, [event.target.name]: value };
                break;
            case "select-one":
                value = event.target.value;
                updatedSite = { ...updatedSite, [event.target.name]: value };
                if (event.target.name.endsWith("Id")) {
                    if (Object.keys(updatedSite).includes(event.target.name.replace("Id", "Name"))) {
                        const target = event.target as HTMLSelectElement;
                        const nameValue = target.options[target.selectedIndex].text;
                        updatedSite = { ...updatedSite, [event.target.name.replace("Id", "Name")]: nameValue };
                    }
                }
                break;
            default:
                value = event.target.value;
                updatedSite = { ...updatedSite, [event.target.name]: value };
                break
        }

        if (event.target.name === "propertyStatusId") {
            if (getPropertyStatus(value) === "Closed") {
                setClosureDateVisible(true);
            } else {
                setClosureDateVisible(false);
                updatedSite = {
                    ...updatedSite,
                    closureDate: undefined
                }
            }
        }

        setEditSite(updatedSite);
    };

    const siteTypeOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...siteTypes.map((siteType) => {
            return { id: siteType.id.toString() || 0, name: siteType.name || "" };
        }),
    ];

    const contractOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...contracts.map((contract) => {
            return { id: contract.id.toString() || 0, name: contract.name || "" };
        }),
    ];

    const ownershipOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...ownerships.map((ownership) => {
            return { id: ownership.id.toString() || 0, name: ownership.name || "" };
        }),
    ];

    const propertyStatusOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...propertyStatuses.map((propertyStatus) => {
            return { id: propertyStatus.id.toString() || 0, name: propertyStatus.name || "" };
        }),
    ];

    function getPropertyStatus(id: string) {
        const propertyStatus = propertyStatuses.filter(propertyStatus =>
            propertyStatus.id.toString() === id
        );

        if (propertyStatus.length > 0) {
            return propertyStatus[0].name;
        }
    }

    function checkPropertyTypeState() {
        if (editSite.propertyStatusName === "Closed") {
            return true;
        }
        else {

            return false;
        }
    }

    const handleUpdate = () => {
        if (!validate(editSite)) {
            onUpdate(editSite);
        } else {
            enableValidationMessages();
        }
    }

    const handleAdd = () => {
        if (!validate(editSite)) {
            onAdd(editSite);
        } else {
            enableValidationMessages();
        }
    }

    const handleCancel = () => {
        setEditSite({ ...site });
        onModeChange('VIEW')
        onCancel();
    };

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <>
            <SummaryListBase>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Site Name</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="siteName"
                            name="siteName"
                            placeholder="Site Name"
                            value={editSite.siteName}
                            required={true}
                            error={errors.siteName}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>

                <SummaryListBaseRow>
                    <SummaryListBaseKey>Site Type</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <SelectInputBase
                            id="siteTypeId"
                            name="siteTypeId"
                            value={editSite.siteTypeId?.toString()}
                            options={siteTypeOptions}
                            required={true}
                            error={errors.siteTypeId}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Contract</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <SelectInputBase
                            id="contractId"
                            name="contractId"
                            value={editSite.contractId?.toString()}
                            options={contractOptions}
                            required={true}
                            error={errors.contractId}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Ownership</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <SelectInputBase
                            id="ownershipId"
                            name="ownershipId"
                            value={editSite.ownershipId?.toString()}
                            options={ownershipOptions}
                            required={true}
                            error={errors.ownershipId}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Premises Status</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <SelectInputBase
                            id="propertyStatusId"
                            name="propertyStatusId"
                            value={editSite.propertyStatusId?.toString()}
                            options={propertyStatusOptions}
                            required={true}
                            error={errors.propertyStatusId}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>
                {confirmClosureDateVisible && <SummaryListBaseRow>
                    <SummaryListBaseKey>Closure Date</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <DateInputBase
                            id="closureDate"
                            name="closureDate"
                            placeholder="Closure Date"
                            value={editSite.closureDate ? moment(editSite.closureDate).format("YYYY-MM-DD") : ""}
                            required={true}
                            error={errors.closureDate}
                            type="date"
                            onChange={handleChange} />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>}
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Address</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="addressLine1"
                            name="addressLine1"
                            label="Address Line 1"
                            placeholder="Address Line 1"
                            required={true}
                            value={editSite.addressLine1}
                            error={errors.addressLine1}
                            onChange={handleChange}
                        />
                        <TextInputBase
                            id="addressLine2"
                            name="addressLine2"
                            label="Address Line 2"
                            placeholder="Address Line 2"
                            value={editSite.addressLine2}
                            error={errors.addressLine2}
                            onChange={handleChange}
                        />
                        <TextInputBase
                            id="addressLine3"
                            name="addressLine3"
                            label="Address Line 3"
                            placeholder="Address Line 3"
                            value={editSite.addressLine3}
                            error={errors.addressLine3}
                            onChange={handleChange}
                        />
                        <TextInputBase
                            id="postCode"
                            name="postCode"
                            label="Post Code"
                            placeholder="Post Code"
                            required={true}
                            value={editSite.postCode}
                            error={errors.postCode}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>List Size</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="listSize"
                            name="listSize"
                            placeholder="List Size"
                            value={editSite.listSize}
                            error={errors.listSize}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>
                <SummaryListBaseRow>
                    <SummaryListBaseKey>Total Square Footage</SummaryListBaseKey>
                    <SummaryListBaseValue>
                        <TextInputBase
                            id="totalSquareFootage"
                            name="totalSquareFootage"
                            placeholder="Total Square Footage"
                            value={editSite.totalSquareFootage}
                            error={errors.totalSquareFootage}
                            onChange={handleChange}
                        />
                    </SummaryListBaseValue>
                </SummaryListBaseRow>
            </SummaryListBase>

            {mode === "ADD" && (
                <SecuredComponents allowedRoles={securityPoints.sites.add}>
                    <div>
                        <ButtonBase onClick={() => handleAdd()} add>Add</ButtonBase>
                    </div>
                </SecuredComponents>
            )}

            {mode !== "ADD" && (
                <SecuredComponents allowedRoles={securityPoints.sites.edit}>
                    <div>
                        <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>
                        <ButtonBase onClick={() => handleUpdate()} edit>Update</ButtonBase>
                    </div>
                </SecuredComponents>
            )}
        </>
    );
}

export default SiteDetailEdit;
