import { Guid } from 'guid-typescript';

export class RentReviewView {
    public id: Guid;
    public leaseId: Guid;
    public reviewStageId?: Guid;
    public reviewStatusId?: Guid;
    public rentReviewDueDate?: Date;
    public agreedRentAmount?: number;
    public nextRentReview?: Date;
    public nextRentAmount?: number;
    public repairAllowance?: number;
    public pcifImprovementGrantAmountApplicable?: string;
    public valuationDecision?: string;
    public districtValuerName?: string;
    public districtValuerEmail?: string;
    public districtValuerPoNumber?: string;
    public districtValuerRefNo?: string;
    public districtValuerDeadlineDate?: Date;
    public isSilentRentReview: boolean;
    public isActive: boolean;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;
    public reviewStageName?: string;
    public reviewStatusName?: string;
    public reviewClosedDate? :Date;

    constructor(
        id: Guid,
        leaseId: Guid,
        reviewStageId?: Guid,
        reviewStatusId?: Guid,
        rentReviewDueDate?: Date,
        agreedRentAmount?: number,
        nextRentReview?: Date,
        nextRentAmount?: number,
        repairAllowance?: number,
        pcifImprovementGrantAmountApplicable?: string,
        valuationDecision?: string,
        districtValuerName?: string,
        districtValuerEmail?: string,
        districtValuerPoNumber?: string,
        districtValuerRefNo?: string,
        districtValuerDeadlineDate?: Date,
        isSilentRentReview?: boolean,
        isActive?: boolean,
        createdByUser?: string,
        createdDate?: Date,
        updatedByUser?: string,
        updatedDate?: Date,
        reviewStageName?: string,
        reviewStatusName?: string,
        reviewClosedDate?: Date
    )
    {
        this.id = id;
        this.leaseId = leaseId;
        this.reviewStageId = reviewStageId;
        this.reviewStatusId = reviewStatusId;
        this.rentReviewDueDate = rentReviewDueDate !== undefined ? new Date(rentReviewDueDate) : undefined;
        this.agreedRentAmount = agreedRentAmount !== undefined ? agreedRentAmount : 0;

        if (nextRentReview === null || nextRentReview === undefined) {
            this.nextRentReview = undefined;
        }
        else {
            this.nextRentReview = new Date(nextRentReview);
        }

        this.nextRentAmount = nextRentAmount !== undefined ? nextRentAmount : 0;
        this.repairAllowance = repairAllowance !== undefined ? repairAllowance : 0;        
        this.pcifImprovementGrantAmountApplicable = pcifImprovementGrantAmountApplicable;
        this.valuationDecision = valuationDecision;
        this.districtValuerName = districtValuerName !== undefined ? districtValuerName : '';
        this.districtValuerEmail = districtValuerEmail !== undefined ? districtValuerEmail : '';
        this.districtValuerPoNumber = districtValuerPoNumber !== undefined ? districtValuerPoNumber : '';
        this.districtValuerRefNo = districtValuerRefNo !== undefined ? districtValuerRefNo : '';

        if (districtValuerDeadlineDate === null || districtValuerDeadlineDate === undefined) {
            this.districtValuerDeadlineDate = undefined;
        }
        else {
            this.districtValuerDeadlineDate = new Date(districtValuerDeadlineDate);
        }

        this.isSilentRentReview = isSilentRentReview === false ? false : true;
        this.isActive = isActive === false ? false : true;
        this.createdByUser = createdByUser !== undefined ? createdByUser : '';
        this.createdDate = createdDate;
        this.updatedByUser = updatedByUser !== undefined ? updatedByUser : ''
        this.updatedDate = updatedDate;
        this.reviewStageName = reviewStageName;
        this.reviewStatusName = reviewStatusName;
        this.reviewClosedDate = reviewClosedDate !== undefined && reviewClosedDate !== null ? new Date(reviewClosedDate) : undefined;
    }
}