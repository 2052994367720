import React, { FunctionComponent, useMemo, useState } from "react";
import { debounce } from "lodash";
import SearchBase from "../bases/inputs/SearchBase";
import ActionsTable from "./actionsTable";
import { Guid } from "guid-typescript";
import { actionViewService } from "../../services/views/actions/actionViewService";

type ActionsBySiteProps = {
    siteId: Guid;
    searchVisible: boolean;
}

const ActionsBySite: FunctionComponent<ActionsBySiteProps> = (props) => {
    const {
        siteId,
        searchVisible
    } = props;

    let practiceId = undefined;
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedActions: actionsRetrieved } = actionViewService
        .useGetAllActions(debouncedTerm, practiceId, siteId.toString());

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , []);

    return (
        <div className="infiniteScollContainer">
            {
                searchVisible &&
                <SearchBase id="search" label="Search practices" value={searchTerm} onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />
            }
            {actionsRetrieved &&
                <ActionsTable actionsRetrieved={actionsRetrieved} showGoTo={false} />
            }
        </div >
    );
}

export default ActionsBySite;