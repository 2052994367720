import React, { FunctionComponent, useState } from "react";
import PracticeDetailCardView from "./practiceDetailCardView";
import PracticeDetailCardEdit from "./practiceDetailCardEdit";
import { LookupView } from "../../models/views/components/lookups/lookupView";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import { PracticeView } from "../../models/views/components/practices/practiceView";
import { useNavigate } from "react-router-dom";

interface PracticeDetailCardProps {
    practice: PracticeView;
    boroughs: Array<LookupView>;
    providerStatus: Array<LookupView>;
    mode: string;
    onAdd: (practice: PracticeView) => void;
    onUpdate: (practice: PracticeView) => void;
    onDelete: (practice: PracticeView) => void;
    children?: React.ReactNode;
}

const PracticeDetailCard: FunctionComponent<PracticeDetailCardProps> = (props) => {
    const {
        practice,
        boroughs,
        providerStatus,
        mode,
        onAdd,
        onUpdate,
        onDelete,
        children
    } = props;

    const [displayMode, setDisplayMode] = useState<string>(mode);
    const [apiError, setApiError] = useState<any>({});

    const handleModeChange = (value: string) => {
        setDisplayMode(value);
    };

    const navigate = useNavigate();

    const handleAdd = async (practice: PracticeView) => {
        try {
            await onAdd(practice);
            navigate('/practices');
        } catch (error) {
            setDisplayMode('EDIT');
        }
    };

    const handleUpdate = async (practice: PracticeView) => {
        try {
            await onUpdate(practice);
            setDisplayMode('VIEW');
        } catch (error) {
            setApiError(error);
            setDisplayMode('EDIT');
        }
    };

    const handleDelete = (practice: PracticeView) => {
        onDelete(practice);
        setDisplayMode('VIEW');
    };

    const handleCancel = () => {
        setApiError({});
    }

    return (
        <div>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>
                        {displayMode === "ADD" ? "New Practice" : practice.practiceName}
                    </CardBaseTitle>

                    <CardBaseContent>
                        {(displayMode === "VIEW" || displayMode === "CONFIRMDELETE") && (
                            <PracticeDetailCardView
                                onModeChange={handleModeChange}
                                practice={practice}
                                onDelete={handleDelete}
                                mode={displayMode}
                            />
                        )}
                        {(displayMode === "EDIT" || displayMode === "ADD") && (
                            <PracticeDetailCardEdit
                                onModeChange={handleModeChange}
                                onAdd={handleAdd}
                                onUpdate={handleUpdate}
                                onCancel={handleCancel}
                                practice={practice}
                                boroughs={boroughs}
                                providerStatus={providerStatus}                                
                                mode={displayMode}
                                apiError={apiError}
                            />
                        )}
                        {children !== undefined && (
                            <>
                                <br />
                                {children}
                            </>
                        )}
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </div >
    );
};

export default PracticeDetailCard;