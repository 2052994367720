import { ErrorBase } from "../../types/ErrorBase";

export interface ILeaseErrors extends ErrorBase {
    leaseStartDate: string;
    leaseEndDate: string;
    leaseTypeId: string;
    landlordDetails: string;
    agreedLeaseAmount: string;
    isActive: string;
    leaseTypeName: string;
    repairAllowanceTypeId: string;
    landlordTypeId: string;
    tenancyTypeId: string;
    isSignedUpToS52Id: string;
    leaseProgressTypeId: string;
    leaseStatusTypeId: string;
    dvDeadlineDate: string
}

export const leaseErrors: ILeaseErrors = {
    hasErrors: false,
    leaseStartDate: "",
    leaseEndDate: "",
    leaseTypeId: "",
    landlordDetails: "",
    agreedLeaseAmount: "",
    isActive: "",
    leaseTypeName: "",
    repairAllowanceTypeId: "",
    landlordTypeId: "",
    tenancyTypeId: "",
    isSignedUpToS52Id: "",
    leaseProgressTypeId: "",
    leaseStatusTypeId: "",
    dvDeadlineDate: "",
};