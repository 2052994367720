import React, { FunctionComponent } from "react";
import { Badge } from "react-bootstrap"
import { PracticeHomeView } from "../../models/practices/practiceHomeView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";

type PracticeRowDeleteProps = {
    practice: PracticeHomeView;
    onCancel: (value: string) => void;
    onDelete: (practice: PracticeHomeView) => void;
}

const PracticeRowDelete: FunctionComponent<PracticeRowDeleteProps> = (props) => {
    const {
        practice,
        onCancel,
        onDelete,
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData>
                {practice.practiceName} <small>({practice.practiceCode})</small>
                <br />
                <small><strong>{practice.boroughName}</strong></small>
                <br />
                {
                    practice?.sites?.map(site =>
                    (
                        <Badge pill bg="secondary" key={site.id.toString()} title={site.siteName}>
                            <a className="pointer"
                                href={'/practice/' + practice.id?.toString() + '/' + site.id.toString()}>
                                {site.siteName}
                            </a>
                        </Badge>

                    ))
                }
            </TableBaseData>
            <TableBaseData></TableBaseData>

            <TableBaseData classes="text-end">
                    <>
                        <ButtonBase onClick={() => onCancel('VIEW')} cancel>Cancel</ButtonBase>
                        &nbsp;
                        <ButtonBase onClick={() => onDelete(practice)} remove>Yes, Delete</ButtonBase>
                    </>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default PracticeRowDelete;