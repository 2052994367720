import React, { FunctionComponent, useMemo, useState } from "react";
import { debounce } from "lodash";
import { PracticeView } from "../../models/views/components/practices/practiceView";
import PracticeRow from "./practiceRow";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import { practiceViewService } from "../../services/views/practices/practiceViewService";
import { lookupViewService } from "../../services/views/lookups/lookupViewService";
import 'nhsuk-frontend/dist/nhsuk.min'
import 'nhsuk-frontend/packages/polyfills';
import SearchBase from "../bases/inputs/SearchBase";
import { Link } from "react-router-dom";
import { practiceHomeViewService } from "../../services/views/practices/practiceHomeViewService";
import { PracticeHomeView } from "../../models/practices/practiceHomeView";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";
import TableBase from "../bases/components/Table/TableBase";
import { LookupView } from "../../models/views/components/lookups/lookupView";
import InfiniteScroll from "../bases/pagers/InfiniteScroll";
import InfiniteScrollLoader from "../bases/pagers/InfiniteScroll.Loader";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";

type PracticeTableProps = {
}

const PracticeTable: FunctionComponent<PracticeTableProps> = (props) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedPractices: practicesRetrieved,
        isLoading,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        data
    }
        = practiceHomeViewService.useGetAllPractices(debouncedTerm);



    const updatePractice = practiceViewService.useUpdatePractice();
    const removePractice = practiceViewService.useRemovePractice();
    let { mappedBoroughs: boroughsRetrieved } = lookupViewService.useGetBoroughList();
    let { mappedProviderStatus: providerStatusRetrieved } = lookupViewService.useGetProviderStatusList();

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , []);

    const handleUpdate = (practice: PracticeView) => {
        return updatePractice.mutateAsync(practice);
    }

    const hasNoMorePages = () => {
        return !isLoading && data?.pages.at(-1)?.nextPage === undefined;
    }

    const handleDelete = (practice: PracticeView) => {
        return removePractice.mutateAsync(practice.id);
    }

    return (
        <div className="infiniteScollContainer">
            <CardBase >
                <CardBaseBody>
                    <CardBaseTitle>
                        GP Practices
                    </CardBaseTitle>
                    <CardBaseContent>
                        <InfiniteScroll loading={isLoading} hasNextPage={hasNextPage || false} loadMore={fetchNextPage}>
                            <SearchBase id="search" label="Search practices" value={searchTerm} onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />

                            <SecuredComponents allowedRoles={securityPoints.practices.add}>
                                <>
                                    <br />
                                    <Link to={'/practice/'}>
                                        <ButtonBase onClick={() => { }} add>&nbsp;New Practice</ButtonBase>
                                    </Link>
                                </>
                            </SecuredComponents>

                            <TableBase>
                                <TableBaseTbody>
                                    {practicesRetrieved?.map((practiceHomeView: PracticeHomeView) =>
                                        <PracticeRow
                                            key={practiceHomeView.id?.toString()}
                                            practice={practiceHomeView}
                                            onSave={handleUpdate}
                                            onDelete={handleDelete}
                                            boroughs={boroughsRetrieved || new Array<LookupView>()}
                                            providerStatus={providerStatusRetrieved || new Array<LookupView>()}
                                        />)
                                    }
                                    <tr>
                                        <td colSpan={3} className="text-center">
                                            <InfiniteScrollLoader
                                                loading={isLoading || isFetchingNextPage}
                                                spinner={<SpinnerBase />}
                                                noMorePages={hasNoMorePages()}
                                                noMorePagesMessage={<>---No more practices---</>} />
                                        </td>
                                    </tr>
                                </TableBaseTbody>
                            </TableBase>
                        </InfiniteScroll>
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </div >
    );
}

export default PracticeTable;