import React, { FunctionComponent, useState } from "react";
import LeaseDetailCardView from "./leaseDetailCardView";
import LeaseDetailCardEdit from "./leaseDetailCardEdit";
import { LeaseView } from "../../models/views/components/leases/leaseView";
import { LookupView } from "../../models/views/components/lookups/lookupView";

interface LeaseDetailCardProps {
    lease: LeaseView | undefined;
    leaseTypes: Array<LookupView>;
    repairAllowanceTypes: Array<LookupView>;
    landlordTypes: Array<LookupView>;
    tenancyTypes: Array<LookupView>;
    IsSignedUpToS52Types: Array<LookupView>;
    leaseProgressTypes: Array<LookupView>;
    leaseStatusTypes: Array<LookupView>;
    mode: string;
    onModeChange: (mode: string) => void;
    onAdd: (lease: LeaseView) => void;
    onUpdate: (lease: LeaseView) => void;
    onDelete: (lease: LeaseView) => void;
    onUnDelete: (lease: LeaseView) => void;
    onCancel: () => void;
    children?: React.ReactNode;
}

const LeaseDetailCard: FunctionComponent<LeaseDetailCardProps> = (props) => {
    const {
        lease,
        leaseTypes,
        repairAllowanceTypes,
        landlordTypes,
        tenancyTypes,
        IsSignedUpToS52Types,
        leaseProgressTypes,
        leaseStatusTypes,
        mode,
        onModeChange,
        onAdd,
        onUpdate,
        onDelete,
        onUnDelete,
        children,
        onCancel
    } = props;

    const [displayMode, setDisplayMode] = useState<string>(mode);
    const [apiError, setApiError] = useState<any>({});

    const handleModeChange = (value: string) => {
        if (value === "EDIT") {
           onModeChange("EDIT")
        }
        setDisplayMode(value);
    };

    const handleAdd = async (lease: LeaseView) => {
        try {
            await onAdd(lease);
        } catch (error) {
            setApiError(error);
            setDisplayMode('EDIT');
            onModeChange("VIEW")
        }
    }

    const handleUpdate = async (lease: LeaseView) => {
        try {
            await onUpdate(lease);
            setDisplayMode('VIEW');
            onModeChange("VIEW")
        } catch (error) {
            setApiError(error);
            setDisplayMode('EDIT');
        }
    }

    const handleDelete = async (lease: LeaseView) => {
            await onDelete(lease);
            setDisplayMode('VIEW');
    }

    const handleUnDelete = async (lease: LeaseView) => {
        await onUnDelete(lease);
        setDisplayMode('VIEW');
    }

    const handleCancel = () => {
        setApiError({});
        setDisplayMode('VIEW');
        onModeChange('VIEW');
        onCancel();
    }

    return (
        <div>
            {lease !== undefined && (displayMode === "VIEW" || displayMode === "CONFIRMDELETE" || displayMode === "CONFIRMUNDODELETE") && (
                <LeaseDetailCardView
                    onModeChange={handleModeChange}
                    lease={lease}
                    onDelete={handleDelete}
                    onUnDelete={handleUnDelete}
                    mode={displayMode}
                />
            )}

            {lease !== undefined && (displayMode === "EDIT" || displayMode === "ADD") && (
                <LeaseDetailCardEdit
                    onModeChange={handleModeChange}
                    onUpdate={handleUpdate}
                    onAdd={handleAdd}
                    onCancel={handleCancel}
                    lease={lease}
                    mode={displayMode}
                    leaseTypes={leaseTypes}
                    repairAllowanceTypes={repairAllowanceTypes}
                    landlordTypes={landlordTypes}
                    tenancyTypes={tenancyTypes}
                    IsSignedUpToS52Types={IsSignedUpToS52Types}
                    leaseProgressTypes={leaseProgressTypes}
                    leaseStatusTypes={leaseStatusTypes}
                    apiError={apiError}
                />
            )}
            {children !== undefined && (
                <>
                    <br />
                    {children}
                </>
            )}
        </div >
    );
};

export default LeaseDetailCard;