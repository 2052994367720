import React, { FunctionComponent } from "react";
import { DiscussionView } from "../../models/views/components/discussions/discussionView";
import moment from 'moment'
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";

interface DiscussionRowViewProps {
    discussion: DiscussionView;
    onEdit: (value: string) => void;
    onDelete: (value: string) => void;
}

const DiscussionRowView: FunctionComponent<DiscussionRowViewProps> = (props) => {
    const {
        discussion,
        onEdit,
        onDelete
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData>
                <>
                    {discussion.comment}
                    <br />
                    <small className="text-smaller">
                        <strong>{discussion.createdByUser} </strong> ({moment(discussion?.updatedDate?.toString()).format("DD-MMM-YYYY HH:mm")})
                    </small>
                </>
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonGroup>
                    <SecuredComponents allowedRoles={securityPoints.discussions.edit}>
                        <Button id="discussionsEdit" size={'sm'} onClick={() => onEdit('EDIT')}><FontAwesomeIcon icon={faEdit} ></FontAwesomeIcon></Button>
                    </SecuredComponents>
                    <SecuredComponents allowedRoles={securityPoints.discussions.delete}>
                        <Button id="discussionsDelete" size={'sm'} onClick={() => onDelete('DELETE')} variant="danger"><FontAwesomeIcon icon={faTimes} /></Button>
                    </SecuredComponents>
                </ButtonGroup>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default DiscussionRowView;
