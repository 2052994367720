import React, { FunctionComponent, useMemo, useState } from "react";
import SearchBase from "../bases/inputs/SearchBase";
import { debounce } from "lodash";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import ReferenceValueRowAdd from "./referenceValueRowAdd";
import { ReferenceValueView } from "../../models/views/components/referenceValues/referenceValueView";
import ReferenceValueRow from "./referenceValueRow";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import { referenceValueViewService } from "../../services/views/referenceValues/referenceValueViewService";
import ReferenceValueRowNew from "./referenceValueRowNew";
import TableBase from "../bases/components/Table/TableBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseHeader from "../bases/components/Table/TableBase.Header";
import TableBaseThead from "../bases/components/Table/TableBase.Thead";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";
import { useParams } from "react-router-dom";
import { referenceValueConfig } from "./referenceValueTypes";

type ReferenceValueTableProps = {
    allowedToAdd: boolean;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
}

const ReferenceValueTable: FunctionComponent<ReferenceValueTableProps> = (props) => {
    const {
        allowedToAdd,
        allowedToEdit,
        allowedToDelete,
    } = props;

    const { type } = useParams();

    if(!type) {
        throw new Error("Reference Value Type is required.");
    }

    const referenceType = referenceValueConfig.find((config) => config.key === type);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedReferenceValues: referenceValuesRetrieved, isLoading } =
        referenceValueViewService.useGetAllReferenceValues(type,debouncedTerm);

    const addReferenceValue = referenceValueViewService.useCreateReferenceValue();
    const updateReferenceValue = referenceValueViewService.useUpdateReferenceValue();
    const removeReferenceValue = referenceValueViewService.useRemoveReferenceValue();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [addApiError, setAddApiError] = useState<any>({});

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleAddState = () => {
        setAddMode(!addMode);
    };

    const handleAddNew = (referenceValue: ReferenceValueView) => {
        return addReferenceValue.mutate(referenceValue, {
            onSuccess: () => {
                setAddMode(false);
            },
            onError: (error: any) => {
                setAddApiError(error?.response?.data?.errors);
            }
        });
    };

    const handleUpdate = (referenceValue: ReferenceValueView) => {
        return updateReferenceValue.mutateAsync(referenceValue);
    }

    const handleDelete = (referenceValue: ReferenceValueView) => {
        return removeReferenceValue.mutateAsync(referenceValue.id);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , [])
        
    if (!referenceType) {
        return <>Unknown Reference Type {type}.</>
    }

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>
                        {referenceType.name}
                    </CardBaseTitle>

                    <CardBaseContent>

                        <SearchBase id="search" label={`Search ${referenceType.name} Values`} value={searchTerm}
                            onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />

                        {isLoading && <> <SpinnerBase />.</>}

                        <TableBase>
                            <TableBaseThead>
                                <TableBaseRow>
                                    <TableBaseHeader>{referenceType.name}</TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                </TableBaseRow>
                            </TableBaseThead>
                            <TableBaseTbody>
                                {
                                    allowedToAdd &&
                                    <>
                                        {addMode === false && (<ReferenceValueRowNew onAdd={handleAddState} />)}

                                        {addMode === true && (
                                            <ReferenceValueRowAdd
                                                onCancel={handleAddState}
                                                onAdd={handleAddNew}
                                                apiError={addApiError} />)}
                                    </>
                                }

                                {referenceValuesRetrieved?.map((referenceValue: ReferenceValueView) =>
                                    <ReferenceValueRow
                                        key={referenceValue.id?.toString()}
                                        referenceValue={referenceValue}
                                        allowedToEdit={allowedToEdit}
                                        allowedToDelete={allowedToDelete}
                                        onSave={handleUpdate}
                                        onDelete={handleDelete}
                                    />)}
                            </TableBaseTbody>
                        </TableBase>
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </>
    );
}

ReferenceValueTable.defaultProps = {
    allowedToAdd: true,
    allowedToEdit: true,
    allowedToDelete: true,
};

export default ReferenceValueTable;