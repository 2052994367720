import { Guid } from "guid-typescript";

export class DiscussionView {
    public id: Guid;
    public siteId: Guid;
    public comment?: string;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(
        id: Guid,
        siteId: Guid,
        comment?: string,
        createdByUser?: string,
        createdDate?: Date,
        updatedByUser?: string,
        updatedDate?: Date)
    {
        this.id = id;
        this.siteId = siteId;
        this.comment = comment !== undefined ? comment : '';
        this.createdByUser = createdByUser !== undefined ? createdByUser : '';
        this.createdDate = createdDate;
        this.updatedByUser = updatedByUser !== undefined ? updatedByUser : ''
        this.updatedDate = updatedDate;
    }
}