import { ErrorBase } from "../../types/ErrorBase";

interface IOwnershipErrors extends ErrorBase
{
    hasErrors: boolean;
    name: string;
    description: string;
    isActive: string;
    sortOrder: string;
}

export const ownershipErrors: IOwnershipErrors = {
    hasErrors: false,
    name: "",
    description: "",
    isActive: "",
    sortOrder: ""
};