import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import ReferenceValueBroker from "../../brokers/apiBroker.referenceValues"
import { ReferenceValue } from "../../models/referenceValues/referenceValue";

export const referenceValueService = {
    useCreateReferenceValue: () => {
        const referenceValueBroker = new ReferenceValueBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((referenceValue: ReferenceValue) => {
            const date = new Date();
            referenceValue.createdDate = referenceValue.updatedDate = date;
            referenceValue.createdByUser = referenceValue.updatedByUser = msal.accounts[0].username;

            return referenceValueBroker.PostReferenceValueAsync(referenceValue);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ReferenceValueGetAll");
                    queryClient.invalidateQueries(["ReferenceValueGetById", { id: data.id }]);
                }
            });
    },

    useGetAllReferenceValues: (query: string) => {
        const referenceValueBroker = new ReferenceValueBroker();

        return useQuery(
            ["ReferenceValueGetAll", { query: query }],
            () => referenceValueBroker.GetAllReferenceValuesAsync(query),
            { staleTime: Infinity });
    },

    useGetReferenceValueById: (id: Guid) => {
        const referenceValueBroker = new ReferenceValueBroker();

        return useQuery(
            ["ReferenceValueGetById", { id: id }],
            () => referenceValueBroker.GetReferenceValueByIdAsync(id),
            { staleTime: Infinity });
    },

    useUpdateReferenceValue: () => {
        const referenceValueBroker = new ReferenceValueBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((referenceValue: ReferenceValue) => {
            const date = new Date();
            referenceValue.updatedDate = date;
            referenceValue.updatedByUser = msal.accounts[0].username;

            return referenceValueBroker.PutReferenceValueAsync(referenceValue);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ReferenceValueGetAll");
                    queryClient.invalidateQueries(["ReferenceValueGetById", { id: data.id }]);
                }
            });
    },

    useDeleteReferenceValue: () => {
        const referenceValueBroker = new ReferenceValueBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return referenceValueBroker.DeleteReferenceValueByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ReferenceValueGetAll");
                    queryClient.invalidateQueries(["ReferenceValueGetById", { id: data.id }]);
                }
            });
    },

    useReferenceValueOdata: (query: string) => {
        const referenceValueBroker = new ReferenceValueBroker();

        return useQuery(
            ["LookupReferenceValue", { query: query }],
            () => referenceValueBroker.LookUpOdataReferenceValue(query),
            { staleTime: Infinity });
    },
}