import React, { FunctionComponent, useState } from "react";
import { SiteView } from "../../models/views/components/site/siteView";
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import ButtonBase from "../bases/buttons/ButtonBase";
import LeaseDetail from "../leases/leaseDetail";
import ContactTable from "../contacts/contactTable";
import SiteActions from "../actions/siteActions";
import DiscussionTable from "../discussions/discussionTable";
import SummaryListBase from "../bases/components/SummaryList/SummaryListBase";
import SummaryListBaseRow from "../bases/components/SummaryList/SummaryListBase.Row";
import SummaryListBaseKey from "../bases/components/SummaryList/SummaryListBase.Key";
import SummaryListBaseValue from "../bases/components/SummaryList/SummaryListBase.Value";
import SummaryListBaseAction from "../bases/components/SummaryList/SummaryListBase.Action";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";
import moment from "moment";

interface SiteDetailCardViewProps {
    site: SiteView;
    onDelete: (site: SiteView) => void;
    mode: string;
    activeTab: string;
    onModeChange: (value: string) => void;
}

const SiteDetailCardView: FunctionComponent<SiteDetailCardViewProps> = (props) => {
    const {
        site,
        onDelete,
        mode,
        activeTab,
        onModeChange
    } = props;

    const [key] = useState(activeTab);

    const handleUrlChange = (key: string | null) => {
        if (key !== null) {
            let currentUrl = window.location.href

            var trimTo = window.location.href.lastIndexOf('/');
            trimTo = trimTo === -1 ? currentUrl.length : trimTo + 1;
            currentUrl = currentUrl.substring(0, trimTo);
            window.history.pushState({ path: currentUrl }, '', currentUrl);

            let newurl = window.location.href + key.toString();
            window.history.pushState({ path: newurl }, '', newurl);
        }
    }

    return (
        <Row>
            <Col lg={12} xl={8}>
                <Tabs
                    defaultActiveKey={key}
                    onSelect={(k) => handleUrlChange(k)}
                    id="siteDetailTabs"
                    className="mb-3">

                    <Tab eventKey="leases" title="Leases and Rent Reviews">
                        <LeaseDetail siteId={site.id} />
                    </Tab>

                    <Tab eventKey="details" title="Details">
                        <SummaryListBase>
                            <SummaryListBaseRow>
                                <SummaryListBaseKey>Site Name</SummaryListBaseKey>
                                <SummaryListBaseValue>{site.siteName}</SummaryListBaseValue>
                                <SummaryListBaseAction> </SummaryListBaseAction>
                            </SummaryListBaseRow>
                            <SummaryListBaseRow>
                                <SummaryListBaseKey>Site Type</SummaryListBaseKey>
                                <SummaryListBaseValue>{site.siteTypeName}</SummaryListBaseValue>
                                <SummaryListBaseAction> </SummaryListBaseAction>
                            </SummaryListBaseRow>
                            <SummaryListBaseRow>
                                <SummaryListBaseKey>Contract</SummaryListBaseKey>
                                <SummaryListBaseValue>{site.contractName}</SummaryListBaseValue>
                                <SummaryListBaseAction> </SummaryListBaseAction>
                            </SummaryListBaseRow>
                            <SummaryListBaseRow>
                                <SummaryListBaseKey>Ownership</SummaryListBaseKey>
                                <SummaryListBaseValue>{site.ownershipName}</SummaryListBaseValue>
                                <SummaryListBaseAction> </SummaryListBaseAction>
                            </SummaryListBaseRow>
                            <SummaryListBaseRow>
                                <SummaryListBaseKey>Premises Status</SummaryListBaseKey>
                                <SummaryListBaseValue>{site.propertyStatusName}</SummaryListBaseValue>
                                <SummaryListBaseAction> </SummaryListBaseAction>
                            </SummaryListBaseRow>
                            {site.propertyStatusName === "Closed" && <SummaryListBaseRow>
                                <SummaryListBaseKey>Closure Date</SummaryListBaseKey>
                                <SummaryListBaseValue>{moment(site.closureDate?.toString()).format("Do-MMM-yyyy")}</SummaryListBaseValue>
                                <SummaryListBaseAction> </SummaryListBaseAction>
                            </SummaryListBaseRow>}
                            <SummaryListBaseRow>
                                <SummaryListBaseKey>Address</SummaryListBaseKey>
                                <SummaryListBaseValue>
                                    {site.addressLine1}
                                    <br />
                                    {site.addressLine2}
                                    <br />
                                    {site.addressLine3}
                                    <br />
                                    {site.postCode}
                                </SummaryListBaseValue>
                                <SummaryListBaseAction> </SummaryListBaseAction>
                            </SummaryListBaseRow>
                            <SummaryListBaseRow>
                                <SummaryListBaseKey>List Size</SummaryListBaseKey>
                                <SummaryListBaseValue>{site.listSize}</SummaryListBaseValue>
                                <SummaryListBaseAction> </SummaryListBaseAction>
                            </SummaryListBaseRow>
                            <SummaryListBaseRow>
                                <SummaryListBaseKey>Total Square Footage</SummaryListBaseKey>
                                <SummaryListBaseValue>{site.totalSquareFootage}</SummaryListBaseValue>
                                <SummaryListBaseAction> </SummaryListBaseAction>
                            </SummaryListBaseRow>
                        </SummaryListBase>

                        {mode === 'VIEW' &&
                            <SecuredComponents allowedRoles={securityPoints.sites.edit}>
                                <ButtonBase id="siteDetailsEdit" onClick={() => onModeChange('EDIT')} edit>Edit</ButtonBase>
                            </SecuredComponents>
                        }

                        <>
                            {mode === 'VIEW' &&
                                <SecuredComponents allowedRoles={securityPoints.sites.delete}>
                                    <ButtonBase id="siteDetailsDelete" onClick={() => onModeChange('CONFIRMDELETE')} remove>Delete</ButtonBase>
                                </SecuredComponents>
                            }
                            {mode === 'CONFIRMDELETE' &&
                                <SecuredComponents allowedRoles={securityPoints.sites.delete}>
                                    <>
                                        <ButtonBase onClick={() => onModeChange('VIEW')} cancel>Cancel</ButtonBase>
                                        <ButtonBase onClick={() => onDelete(site)} remove>Yes, Delete</ButtonBase>
                                    </>
                                </SecuredComponents>
                            }
                        </>
                    </Tab>

                    <Tab eventKey="contacts" title="Contacts">
                        <ContactTable siteId={site.id} />
                    </Tab>

                    <Tab eventKey="actions" title="Actions">
                        <SiteActions siteId={site.id} searchVisible={false} />
                    </Tab>

                </Tabs>
            </Col>
            <Col lg={12} xl={4}>
                <DiscussionTable siteId={site.id} />
            </Col>
        </Row>
    );
}

export default SiteDetailCardView;
