import React, { FunctionComponent } from "react";
import DocumentDetails from "../documents/documentDetails";
import moment from "moment";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";
import { RentReviewView } from "../../models/views/components/rentReviews/rentReviewView";
import { Alert, Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";

interface RentReviewDetailCardViewProps {
    rentReview: RentReviewView;
    onDelete: (rentReview: RentReviewView) => void;
    onUnDelete: (rentReview: RentReviewView) => void;
    mode: string;
    onModeChange: (value: string) => void;
}

const RentReviewDetailCardView: FunctionComponent<RentReviewDetailCardViewProps> = (props) => {
    const {
        rentReview,
        onDelete,
        onUnDelete,
        mode,
        onModeChange
    } = props;

    return (
        <Container className="p-0">
            {rentReview.reviewClosedDate && <Row>
                <Col>
                    <Alert variant="warning" className="full-width text-centre">
                        Review Closed: {moment(rentReview.reviewClosedDate).format("DD-MMM-YYYY")}
                    </Alert>
                </Col>
            </Row>
            }
            <div className={`${rentReview.isActive === false ? "text-decoration-line-through" : ""}`} >
            <Row>
                <Col sm={6}>
                    <div style={{color:"#666"}}>Review Status</div>
                    <b>{rentReview.reviewStatusName}</b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>Review Progress</div>
                    <b>{rentReview.reviewStageName}</b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>Rent Review Due Date</div>
                    <b>{moment(rentReview.rentReviewDueDate?.toString()).format("Do-MMM-yyyy")}</b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>Repair Allowance</div>
                    <b>&#163;&nbsp;{rentReview.repairAllowance}</b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>PCIF Improvement Grant Details</div>
                    <b>{rentReview.pcifImprovementGrantAmountApplicable || "N/A"}</b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>Reimbursement Amount</div>
                    <b>&#163;&nbsp;{rentReview.agreedRentAmount}</b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>District Valuer Name</div>
                    <b>{rentReview.districtValuerName || "-"}</b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>District Valuer Email</div>
                    <b>{rentReview.districtValuerEmail || "-"}</b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>District Valuer PO Number</div>
                    <b>{rentReview.districtValuerPoNumber || "-"}</b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>District Valuer Ref No</div>
                    <b>{rentReview.districtValuerRefNo || "-"}</b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>District Valuer Deadline</div>
                    <b>
                        {rentReview.districtValuerDeadlineDate !== undefined ? moment(rentReview.districtValuerDeadlineDate?.toString()).format("Do-MMM-yyyy") : "-"}
                    </b>
                </Col>
                <Col sm={6}>
                    <div style={{color:"#666"}}>Next Rent Review Date</div>
                    <b>{rentReview.nextRentReview !== undefined ? moment(rentReview.nextRentReview?.toString()).format("Do-MMM-yyyy") : "-"}</b>
                </Col>
                <Col sm={6} classes="d-none">
                    <div style={{color:"#666"}}>Next Rent Amount</div>
                    <b>&#163;&nbsp;{rentReview.nextRentAmount}</b>
                </Col>
                </Row>
            </div>
            <Row className="mt-2 justify-content-end">
                <Col className="align-self-end" sm={4}>
                    <ButtonGroup>
                        {mode === 'VIEW' &&
                            <SecuredComponents allowedRoles={securityPoints.leases.edit}>
                                <Button id="rentEdit" onClick={() => onModeChange('EDIT')} >Edit</Button>
                            </SecuredComponents>
                        }
                        <>
                            {mode === 'VIEW' && rentReview.isActive === true && 
                                <SecuredComponents allowedRoles={securityPoints.leases.delete}>
                                    <Button id="rentDelete" onClick={() => onModeChange('CONFIRMDELETE')} variant="danger">Delete</Button>
                                </SecuredComponents>
                            }
                            {mode === 'CONFIRMDELETE' &&
                                <>
                                    <Button onClick={() => onModeChange('VIEW')} variant="secondary">Cancel</Button>
                                    <Button id="rentDeleteConfirm" onClick={() => onDelete(rentReview)} variant="danger">Yes, Delete</Button>
                                </>
                            }
                            {mode === 'VIEW' && rentReview.isActive === false &&
                                <SecuredComponents allowedRoles={securityPoints.leases.delete}>
                                    <Button id="rentReviewUndoDelete" onClick={() => onModeChange('CONFIRMUNDODELETE')} variant="danger">Undo Delete</Button>
                                </SecuredComponents>
                            }
                            {mode === 'CONFIRMUNDODELETE' &&
                                <SecuredComponents allowedRoles={securityPoints.leases.delete}>
                                    <Button id="rentReviewUndoDeleteConfirm" onClick={() => onUnDelete(rentReview)} variant="danger">Yes, Undo Delete</Button>
                                </SecuredComponents>
                            }
                        </>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DocumentDetails rentReviewId={rentReview.id} />
                </Col>
            </Row>
        </Container>
    );
}

export default RentReviewDetailCardView;
