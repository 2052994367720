import React, { FunctionComponent, useMemo, useState } from "react";
import SearchBase from "../bases/inputs/SearchBase";
import { debounce } from "lodash";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import OwnershipRowAdd from "./ownershipRowAdd";
import { OwnershipView } from "../../models/views/components/ownerships/ownershipView";
import OwnershipRow from "./ownershipRow";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import { ownershipViewService } from "../../services/views/ownerships/ownershipViewService";
import OwnershipRowNew from "./ownershipRowNew";
import TableBase from "../bases/components/Table/TableBase";
import TableBaseThead from "../bases/components/Table/TableBase.Thead";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseHeader from "../bases/components/Table/TableBase.Header";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";

type OwnershipTableProps = {
    allowedToAdd: boolean;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
}

const OwnershipTable: FunctionComponent<OwnershipTableProps> = (props) => {
    const {
        allowedToAdd,
        allowedToEdit,
        allowedToDelete,
    } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedOwnerships: ownershipsRetrieved, isLoading } =
        ownershipViewService.useGetAllOwnerships(debouncedTerm);

    const addOwnership = ownershipViewService.useCreateOwnership();
    const updateOwnership = ownershipViewService.useUpdateOwnership();
    const removeOwnership = ownershipViewService.useRemoveOwnership();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [addApiError, setAddApiError] = useState<any>({});

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleAddState = () => {
        setAddMode(!addMode);
    };

    const handleAddNew = (ownership: OwnershipView) => {
        return addOwnership.mutate(ownership, {
            onSuccess: () => {
                setAddMode(false);
            },
            onError: (error: any) => {
                setAddApiError(error?.response?.data?.errors);
            }
        });
    };

    const handleUpdate = (ownership: OwnershipView) => {
        return updateOwnership.mutateAsync(ownership);
    }

    const handleDelete = (ownership: OwnershipView) => {
        return removeOwnership.mutateAsync(ownership.id);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , [])

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>
                        Ownerships
                    </CardBaseTitle>

                    <CardBaseContent>

                        <SearchBase id="search" label="Search ownerships" value={searchTerm}
                            onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />


                        {isLoading && <> <SpinnerBase />.</>}

                        <TableBase>
                            <TableBaseThead>
                                <TableBaseRow>
                                    <TableBaseHeader>Ownerships</TableBaseHeader>
                                    <TableBaseHeader></TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                </TableBaseRow>
                            </TableBaseThead>
                            <TableBaseTbody>
                                {
                                    allowedToAdd &&
                                    <>
                                        {addMode === false && (<OwnershipRowNew onAdd={handleAddState} />)}

                                        {addMode === true && (
                                            <OwnershipRowAdd
                                                onCancel={handleAddState}
                                                onAdd={handleAddNew}
                                                apiError={addApiError} />)}
                                    </>
                                }

                                {ownershipsRetrieved?.map((ownership: OwnershipView) =>
                                    <OwnershipRow
                                        key={ownership.id?.toString()}
                                        ownership={ownership}
                                        allowedToEdit={allowedToEdit}
                                        allowedToDelete={allowedToDelete}
                                        onUpdate={handleUpdate}
                                        onDelete={handleDelete}
                                    />)}
                            </TableBaseTbody>
                        </TableBase>

                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </>
    );
}

OwnershipTable.defaultProps = {
    allowedToAdd: true,
    allowedToEdit: true,
    allowedToDelete: true,
};

export default OwnershipTable;