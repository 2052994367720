import React, { FunctionComponent } from "react";
import { Guid } from "guid-typescript";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import ActionsBySite from "./actionsBySite";

type ContactTableProps = {
    siteId: Guid;
    searchVisible: boolean;
}

const SiteActions: FunctionComponent<ContactTableProps> = (props) => {
    const {
        siteId,
        searchVisible
    } = props;

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>Actions</CardBaseTitle>
                    <CardBaseContent>
                        <ActionsBySite siteId={siteId} searchVisible={searchVisible} />
                    </CardBaseContent>
            </CardBaseBody>
            </CardBase>
        </>
    );
}

export default SiteActions;