import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { SiteTypeView } from "../../models/views/components/siteTypes/siteTypeView";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { siteTypeErrors } from "./siteTypeErrors";
import { siteTypeValidations } from "./siteTypeValidations";

interface SiteTypeRowEditProps {
    siteType: SiteTypeView;
    onCancel: () => void;
    onEdit: (siteType: SiteTypeView) => void;
    apiError?: any;
}

const SiteTypeRowEdit: FunctionComponent<SiteTypeRowEditProps> = (props) => {
    const {
        siteType,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editSiteType, setEditSiteType] = useState<SiteTypeView>({ ...siteType });

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(siteTypeErrors, siteTypeValidations, editSiteType);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const SpdatedsiteType = {
            ...editSiteType,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditSiteType(SpdatedsiteType);
    };

    const handleSave = () => {
        if (!validate(editSiteType)) {
            onEdit(editSiteType);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Site Type Name"
                    placeholder="Site Type Name"
                    value={editSiteType.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Site Type Description"
                    placeholder="SiteType Description"
                    value={editSiteType.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={editSiteType.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={editSiteType.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default SiteTypeRowEdit;
