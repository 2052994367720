import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ReviewStatusView } from "../../models/views/components/reviewStatuses/reviewStatusView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { toastSuccess } from "../../brokers/toastBroker";

interface ReviewStatusRowDeleteProps {
    reviewStatus: ReviewStatusView;
    onCancel: (value: string) => void;
    onDelete: (reviewStatus: ReviewStatusView) => void;
}

const ReviewStatusRowDelete: FunctionComponent<ReviewStatusRowDeleteProps> = (props) => {
    const {
        reviewStatus,
        onCancel,
        onDelete
    } = props;

    const handleDelete = (reviewStatus: ReviewStatusView) => {
        onDelete(reviewStatus);
        toastSuccess(`${reviewStatus.name} Deleted`);
    }

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{reviewStatus.name}</b><br />
                {reviewStatus.description}<br />
            </TableBaseData>
            <TableBaseData>
                {reviewStatus.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="grey" title="Inactive" /> }
                {reviewStatus.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel('VIEW')} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleDelete(reviewStatus)} remove>Yes, Delete</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReviewStatusRowDelete;
