import { Guid } from "guid-typescript";

import { Contact } from "../models/contacts/contact";
import ApiBroker from "./apiBroker";

class ContactBroker {

    relativeContactUrl = '/api/contacts';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostContactAsync(contact: Contact) {
        return await this.apiBroker.PostAsync(this.relativeContactUrl, contact)
            .then(result => new Contact(result.data));
    }

    async GetAllContactsAsync(queryString: string) {
        var url = this.relativeContactUrl + queryString;
        
        return await this.apiBroker.GetAsync(url)
            .then(r => r.data.map((d: any) => new Contact(d)));
    }

    async GetContactByIdAsync(id: Guid) {
        const url = `${this.relativeContactUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new Contact(result.data));
    }

    async PutContactAsync(contact: Contact) {
        return await this.apiBroker.PutAsync(this.relativeContactUrl, contact)
            .then(result => new Contact(result.data));
    }

    async DeleteContactByIdAsync(id: Guid) {
        const url = `${this.relativeContactUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new Contact(result.data));
    }
}

export default ContactBroker;



