import { useEffect, useState } from "react";
import { Practice } from "../../../models/practices/practice";
import { PracticeHomeView } from "../../../models/practices/practiceHomeView";
import { practiceService } from "../../foundations/practiceService";

export const practiceHomeViewService = {

    useGetAllPractices: (searchTerm?: string) => {
        try {
            let query = `?$expand=borough&$expand=sites($select=*&$expand=propertyStatus;$orderby=siteName desc )&$orderby=isActive desc,practiceName`;

            if (searchTerm) {
                query = query + `&$filter=contains(practiceName,'${searchTerm}')`
                    + ` or contains(practiceCode,'${searchTerm}')`
                    + ` or contains(borough/name,'${searchTerm}')`
            }

            const response = practiceService.useGetPracticePages(query);
            const [mappedPractices, setMappedPractices] = useState<Array<PracticeHomeView>>();
            const [pages, setPages] = useState<any>([]);

            useEffect(() => {
                if (response.data && response.data.pages) {
                    const practices: Array<PracticeHomeView> = [];
                    response.data.pages.forEach(x => {
                        x.data.forEach((practice: Practice) => {
                            practices.push(new PracticeHomeView(
                                practice.id,
                                practice.boroughId,
                                practice.practiceName,
                                practice.practiceCode,
                                practice.odsCode,
                                practice.pcn,
                                practice.pcnCode,
                                practice.groupName,
                                practice.practicePhone,
                                practice.practicePhoneBypass,
                                practice.practiceGenericEmail,
                                practice.providerStatusId,
                                practice.isActive,
                                practice.borough?.name,
                                practice.sites
                            ));
                        })
                        setMappedPractices(practices);
                    });
                    setPages(response.data.pages);
                }
            }, [response.data]);

            return {
                mappedPractices, pages, ...response
            }

        } catch (err) {
            throw err;
        }
    },
}