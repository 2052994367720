import React, { FunctionComponent } from "react";
import { ContactView } from "../../models/views/components/contacts/contactView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { SecuredComponents } from "../Links";
import securityPoints from "../../SecurityMatrix";

interface ContactRowDeleteProps {
    contact: ContactView;
    onCancel: () => void;
    onDelete: (contact: ContactView) => void;
}

const ContactRowDelete: FunctionComponent<ContactRowDeleteProps> = (props) => {
    const {
        contact,
        onCancel,
        onDelete
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{contact.name}</b> ({contact.contactTypeName})
                {
                    contact.contactNumber !== undefined && contact.contactNumber.length > 0 &&
                    (<><br />{contact.contactNumber}</>)
                }
                {
                    contact.contactEmail !== undefined && contact.contactEmail.length > 0 &&
                    (<><br />{contact.contactEmail}</>)
                }
            </TableBaseData>
            <TableBaseData classes="text-end">
                <SecuredComponents allowedRoles={securityPoints.contacts.delete}>
                    <>
                        <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                        <ButtonBase onClick={() => onDelete(contact)} remove>Yes, Delete</ButtonBase>
                    </>
                </SecuredComponents>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ContactRowDelete;
