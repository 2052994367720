import React from "react";
import 'nhsuk-frontend/dist/nhsuk.min'
import 'nhsuk-frontend/packages/polyfills';
import { Container } from 'nhsuk-react-components'
import PracticeTable from "../components/practices/practiceTable";

export const PracticesHomePage = () => {
    return <div>
        <section >
            <Container>
                <main id="maincontent" className="NELTopPadding" role="main">
                    <>
                        <PracticeTable />
                    </>
                </main>
            </Container>
        </section>
    </div>
}