import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import DocumentBroker from "../../brokers/apiBroker.documents";
import { LeaseDocument } from "../../models/leaseDocuments/leaseDocument";


export const documentService = {
    useCreateDocument: () => {
        const documentBroker = new DocumentBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((document: LeaseDocument) => {
            const date = new Date();
            document.createdDate = document.updatedDate = date;
            document.createdByUser = document.updatedByUser = msal.accounts[0].username;
            document.id = Guid.create();

            return documentBroker.PostDocumentAsync(document)
        },
            {
                onSuccess: (data, variables) => {
                    queryClient.invalidateQueries("DocumentGetAll");
                    queryClient.invalidateQueries(["DocumentGetById", { id: variables.id }]);
                }
            });
    },
    useGetDocumentUrl: (id : Guid) => {
        const documentBroker = new DocumentBroker();

        return useQuery(
            ["DocumentUri", id.toString()],
            () => documentBroker.GetDocumentUriById(id),
            {
                staleTime: 60 * 1000,
                enabled: !id.isEmpty()
            });
    },
    useGetAllDocuments: (query: string) => {
        const documentBroker = new DocumentBroker();

        return useQuery(
            ["DocumentGetAll", { query: query }],
            () => documentBroker.GetAllDocumentAsync(query),
            { staleTime: Infinity });
    },
    useGetDocumentById: (id: Guid) => {
        const documentBroker = new DocumentBroker();

        return useQuery(
            ["DocumentGetById", { id: id }],
            () => documentBroker.GetDocumentByIdAsync(id),
            { staleTime: Infinity });
    },
    useDeleteDocument: () => {
        const documentBroker = new DocumentBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return documentBroker.DeleteDocumentByIdAsync(id)
        },
            {
                onSuccess: (data, variables) => {
                    queryClient.invalidateQueries("DocumentGetAll");
                    queryClient.invalidateQueries(["DocumentGetById", { id: variables.toString() }]);
                }
            });
    },
}
