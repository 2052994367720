
import React, { FunctionComponent, useState } from "react";
import { ProviderStatusView } from "../../models/views/components/providerStatuses/providerStatusView";
import ProviderStatusRowView from "./providerStatusRowView";
import ProviderStatusRowEdit from "./providerStatusRowEdit";
import ProviderStatusRowDelete from "./providerStatusRowDelete";

type ProviderStatusRowProps = {
    providerStatus: ProviderStatusView;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
    onSave: (providerStatus: ProviderStatusView) => void;
    onDelete: (providerStatus: ProviderStatusView) => void;
}

const ProviderStatusRow: FunctionComponent<ProviderStatusRowProps> = (props) => {
    const {
        providerStatus,
        allowedToEdit,
        allowedToDelete,
        onSave,
        onDelete,
    } = props;

    const [mode, setMode] = useState<string>('VIEW');
    const [apiError, setApiError] = useState<any>({});

    const handleMode = (value: string) => {
        setMode(value);
    };

    const handleSave = async (providerStatus: ProviderStatusView) => {
        try {
            await onSave(providerStatus);
            setMode('VIEW');
        } catch (error) {
            setApiError(error);
            setMode('EDIT');
        }
    };

    const handleDelete = (providerStatus: ProviderStatusView) => {
        onDelete(providerStatus);
        setMode('VIEW');
    };

    const handleCancel = () => {
        setMode('VIEW');
    };

    return (
        <>
            {mode !== 'EDIT' && mode !== 'DELETE' && (
                <ProviderStatusRowView
                    key={providerStatus.id.toString()}
                    providerStatus={providerStatus}
                    onEdit={handleMode}
                    onDelete={handleMode}
                    allowedToEdit={allowedToEdit}
                    allowedToDelete={allowedToDelete} />
            )}

            {mode === 'EDIT' && (
                <ProviderStatusRowEdit
                    key={providerStatus.id.toString()}
                    providerStatus={providerStatus}
                    onCancel={handleCancel}
                    onEdit={handleSave}
                    apiError={apiError}
                />
            )}

            {mode === 'DELETE' && (
                <ProviderStatusRowDelete
                    key={providerStatus.id.toString()}
                    providerStatus={providerStatus}
                    onCancel={handleCancel}
                    onDelete={handleDelete} />
            )}
        </>
    );
}

ProviderStatusRow.defaultProps = {
    allowedToEdit: false,
    allowedToDelete: false
};

export default ProviderStatusRow;