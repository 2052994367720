import { Guid } from 'guid-typescript';

export class ProviderStatus {
    public id: Guid;
    public name?: string;
    public description?: string;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(providerStatus: any) {
        this.id = providerStatus.id ? Guid.parse(providerStatus.id) : Guid.parse(Guid.EMPTY);
        this.name = providerStatus.name;
        this.description = providerStatus.description;
        this.isActive = providerStatus.isActive;
        this.sortOrder = providerStatus.sortOrder;
        this.createdByUser = providerStatus.createdByUser
        this.createdDate = new Date(providerStatus.createdDate)
        this.updatedByUser = providerStatus.updatedByUser
        this.updatedDate = new Date(providerStatus.updatedDate)
    }
}