import { Guid } from "guid-typescript";
import { LeaseDocument } from "../models/leaseDocuments/leaseDocument";

import ApiBroker from "./apiBroker";

class DocumentBroker {
    relativeDocumentUrl = '/api/documents';

    private apiBroker = new ApiBroker();

    async PostDocumentAsync(document: LeaseDocument) {
        const formData = new FormData();
        formData.append("file", document.file as Blob);
        const documentToPost = { ...document };
        delete documentToPost.file;
        formData.append("data", JSON.stringify(documentToPost));

        return await this.apiBroker.PostFormAsync(this.relativeDocumentUrl, formData);
    }

    async GetDocumentUriById(id: Guid) {
        var url = `${this.relativeDocumentUrl}\\${id.toString()}`;
        return await this.apiBroker.GetAsync(url)
    }

    async GetAllDocumentAsync(queryString: string) {
        var url = this.relativeDocumentUrl + queryString;

        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((document: any) => new LeaseDocument(document)));
    }

    async GetDocumentByIdAsync(id: Guid) {
        const url = `${this.relativeDocumentUrl}/${id}`;

        return this.apiBroker.GetAsync(url)
            .then(result => new LeaseDocument(result.data));
    }

    async DeleteDocumentByIdAsync(id: Guid) {
        const url = `${this.relativeDocumentUrl}/${id}`;

        return this.apiBroker.DeleteAsync(url);
    }
}

export default DocumentBroker;