import { Guid } from 'guid-typescript';
import { useEffect, useState } from 'react';
import { Practice } from "../../../models/practices/practice";
import { PracticeView } from "../../../models/views/components/practices/practiceView";
import { practiceService } from "../../foundations/practiceService";

export const practiceViewService = {

    useCreatePractice: () => {
        return practiceService.useCreatePractice();
    },

    useGetAllPractices: (searchTerm?: string) => {
        try {
            let query = `?$expand=borough&$expand=sites&$orderby=practiceName`;

            if (searchTerm) {
                query = query + `&$filter=contains(practiceName,'${searchTerm}')`
                    + ` or contains(practiceCode,'${searchTerm}')`
                    + ` or contains(borough/name,'${searchTerm}')`;
            }

            const response = practiceService.useGetAllPractices(query);
            const [mappedPractices, setMappedPractices] = useState<Array<PracticeView>>();

            useEffect(() => {
                if (response.data) {
                    const practices = response.data.map((practice: Practice) => new PracticeView(
                        practice.id,
                        practice.boroughId,
                        practice.practiceName,
                        practice.practiceCode,
                        practice.odsCode,
                        practice.pcn,
                        practice.pcnCode,
                        practice.groupName,
                        practice.practicePhone,
                        practice.practicePhoneBypass,
                        practice.practiceGenericEmail,
                        practice.providerStatusId,
                        practice.isActive,
                        practice.sortOrder,
                        practice.createdByUser,
                        practice.createdDate,
                        practice.updatedByUser,
                        practice.updatedDate,
                        practice.borough?.name,
                        practice.providerStatus?.name,
                        practice.dateOfClosure
                    ));

                    setMappedPractices(practices);
                }
            }, [response.data]);

            return {
                mappedPractices, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetPracticeById: (id: Guid) => {
        try {
            let query = `?$expand=borough&$expand=providerstatus&$expand=sites&$filter=id eq ${id}`;
            const response = practiceService.useGetAllPractices(query);
            const [mappedPractice, setMappedPractice] = useState<PracticeView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const practice = new PracticeView(
                        response.data[0].id,
                        response.data[0].boroughId,
                        response.data[0].practiceName,
                        response.data[0].practiceCode,
                        response.data[0].odsCode,
                        response.data[0].pcn,
                        response.data[0].pcnCode,
                        response.data[0].groupName,
                        response.data[0].practicePhone,
                        response.data[0].practicePhoneBypass,
                        response.data[0].practiceGenericEmail,
                        response.data[0].providerStatusId,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate,
                        response.data[0].borough?.name,
                        response.data[0].providerStatus?.name,
                        response.data[0].dateOfClosure
                    );

                    setMappedPractice(practice);
                }
            }, [response.data]);

            return {
                mappedPractice, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdatePractice: () => {
        return practiceService.useUpdatePractice();
    },

    useRemovePractice: () => {
        return practiceService.useDeletePractice();
    },
}