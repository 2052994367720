import { Validation } from "../../models/validations/validation";

export const contactTypeValidations: Array<Validation> = [
    {
        property: "name",
        friendlyName: "Contact type name",
        isRequired: true,
        minLength: 3,
        maxLength: 255,
    }
]