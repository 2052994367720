import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { Site } from "../../../models/sites/site";
import { SiteListItemView } from "../../../models/views/components/siteList/siteListItemView";
import { siteService } from "../../foundations/siteService";

export const siteListViewService = {
    useGetSiteList: (practiceId: Guid) => {
        try {
            let query = `?$expand=propertyStatus($select=id,name)&$expand=siteType($select=id,name)&$select=id,siteName,isActive&$orderby=siteType/sortOrder,siteName`;
           
            if (practiceId) {
                query = query + `&$filter=practiceId eq ${practiceId}`
            }

            const response = siteService.useGetAllSites(query);
            const [mappedSites, setMappedSites] = useState<Array<SiteListItemView>>();

            useEffect(() => {
                if (response.data) {
                    const sites = response.data.map((site: Site) =>
                        new SiteListItemView(
                            site.id,
                            site.siteName,
                            site.isActive,
                            site.propertyStatusName,
                            site.siteTypeName,
                            0 // TODO: await actionService.getAllActionsForSite(siteId)
                        ));

                    setMappedSites(sites);
                }
            }, [response.data]);

            return {
                mappedSites, ...response
            }
        } catch (err) {
            throw err;
        }
    },
}