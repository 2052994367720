import { LookupView } from "../../models/views/components/lookups/lookupView";
import { RentReviewView } from "../../models/views/components/rentReviews/rentReviewView";

const config = [{
    field: "reviewStageId",
    workflow: "CloseReview",
    action: (values: RentReviewView) => { values.reviewClosedDate = new Date(); },
}, {
    field: "reviewStageId",
    workflow: "OpenReview",
    action: (values: RentReviewView) => { values.reviewClosedDate = undefined },
}]

 const ProcessRentReviewWorkflow = (updatedValues: RentReviewView, originalValues: RentReviewView, lookups: LookupView[]) => {
    Object.keys(originalValues).forEach((key) => {
        const originalValue = originalValues[key as keyof typeof originalValues];
        const updatedValue = updatedValues[key as keyof typeof updatedValues];

        if (originalValue !== updatedValue) {
            if (config.find(x => x.field === key)) {
                const workflowName = lookups.find(x => x.id.toString() === updatedValue)?.workflowAction;

                if (workflowName) {
                    config.filter(x => x.workflow === workflowName).forEach((item) => {
                        item.action(updatedValues);
                    });
                }
            }
        }
    });
}

export default ProcessRentReviewWorkflow;