import React from "react"
import { FunctionComponent } from "react"
import { DocumentView } from "../../models/views/components/documents/documentView";
import DocumentDetailsCardView from "./documentDetailsCardView";

interface DocumentDetailsCardProps {
    documents: Array<DocumentView>;
    handleDeleteDocument: Function;
    handleOpenDocument: Function;
}

const DocumentDetailsCard: FunctionComponent<DocumentDetailsCardProps> = (props) => {
    const { documents, handleDeleteDocument, handleOpenDocument } = props;
    
    return <>
        {documents.map((document) => <DocumentDetailsCardView document={document} key={document.id.toString()} handleDeleteDocument={handleDeleteDocument} handleOpenDocument={handleOpenDocument} />)}
    </>
}

export default DocumentDetailsCard;