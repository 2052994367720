import { Guid } from "guid-typescript";

import { ProviderStatus } from "../models/providerStatuses/providerStatus";
import ApiBroker from "./apiBroker";

class ProviderStatusBroker {

    relativeProviderStatusUrl = '/api/providerStatuses';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostProviderStatusAsync(providerStatus: ProviderStatus) {
        return await this.apiBroker.PostAsync(this.relativeProviderStatusUrl, providerStatus)
            .then(result => new ProviderStatus(result.data));
    }

    async GetAllProviderStatusesAsync(queryString: string) {
        var url = this.relativeProviderStatusUrl + queryString;
        
        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((providerStatus: any) => new ProviderStatus(providerStatus)));
    }

    async GetProviderStatusByIdAsync(id: Guid) {
        const url = `${this.relativeProviderStatusUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new ProviderStatus(result.data));
    }

    async PutProviderStatusAsync(providerStatus: ProviderStatus) {
        return await this.apiBroker.PutAsync(this.relativeProviderStatusUrl, providerStatus)
            .then(result => new ProviderStatus(result.data));
    }

    async DeleteProviderStatusByIdAsync(id: Guid) {
        const url = `${this.relativeProviderStatusUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new ProviderStatus(result.data));
    }
}

export default ProviderStatusBroker;



