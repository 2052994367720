import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import ContactTypeBroker from "../../brokers/apiBroker.contactTypes"
import { ContactType } from "../../models/contactTypes/contactType";

export const contactTypeService = {
    useCreateContactType: () => {
        const contactTypeBroker = new ContactTypeBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((contactType: ContactType) => {
            const date = new Date();
            contactType.createdDate = contactType.updatedDate = date;
            contactType.createdByUser = contactType.updatedByUser = msal.accounts[0].username;

            return contactTypeBroker.PostContactTypeAsync(contactType);
        },
            {
                onSuccess: (variables) => {
                    queryClient.invalidateQueries("ContactTypeGetAll");
                    queryClient.invalidateQueries(["ContactTypeGetById", { id: variables.id }]);
                }
            });
    },

    useGetAllContactTypes: (query: string) => {
        try {
            const contactTypeBroker = new ContactTypeBroker();

            return useQuery(
                ["ContactTypeGetAll", { query: query }],
                () => contactTypeBroker.GetAllContactTypesAsync(query),
                { staleTime: Infinity });
        } catch (err) {
            console.error(err);
            throw err;
        }
    },

    useGetContactTypeById: (id: Guid) => {
        try {
            const contactTypeBroker = new ContactTypeBroker();

            return useQuery(
                ["ContactTypeGetById", { id: id }],
                () => contactTypeBroker.GetContactTypeByIdAsync(id),
                { staleTime: Infinity });
        } catch (err) {
            console.error(err);
            throw err;
        }
    },

    useUpdateContactType: () => {
        const contactTypeBroker = new ContactTypeBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((contactType: ContactType) => {
            const date = new Date();
            contactType.updatedDate = date;
            contactType.updatedByUser = msal.accounts[0].username;

            return contactTypeBroker.PutContactTypeAsync(contactType);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ContactTypeGetAll");
                    queryClient.invalidateQueries(["ContactTypeGetById", { id: data.id }]);
                }
            });
    },

    useDeleteContactType: () => {
        const contactTypeBroker = new ContactTypeBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return contactTypeBroker.DeleteContactTypeByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("ContactTypeGetAll");
                    queryClient.invalidateQueries(["ContactTypeGetById", { id: data.id }]);
                }
            });
    },
}
