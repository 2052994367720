import { Guid } from "guid-typescript";

export class ReferenceValue {
    public id: Guid;
    public name?: string;
    public referenceValueType?: string;
    public description?: string;
    public workFlowAction?: string;
    public isActive?: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;

    constructor(referenceValue: any) {
        this.id = referenceValue.id ? Guid.parse(referenceValue.id) : Guid.parse(Guid.EMPTY);
        this.name = referenceValue.name;
        this.referenceValueType = referenceValue.referenceValueType
        this.description = referenceValue.description;
        this.workFlowAction = referenceValue.workFlowAction;
        this.isActive = referenceValue.isActive;
        this.sortOrder = referenceValue.sortOrder;
        this.createdByUser = referenceValue.createdByUser
        this.createdDate = new Date(referenceValue.createdDate)
        this.updatedByUser = referenceValue.updatedByUser
        this.updatedDate = new Date(referenceValue.updatedDate)
    }
}