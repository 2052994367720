import { Guid } from "guid-typescript";

import { ContactType } from "../models/contactTypes/contactType";
import ApiBroker from "./apiBroker";

class ContactTypeBroker {

    relativeContactTypeUrl = '/api/contactTypes';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostContactTypeAsync(contactType: ContactType) {
        return await this.apiBroker.PostAsync(this.relativeContactTypeUrl, contactType)
            .then(result => new ContactType(result.data));
    }

    async GetAllContactTypesAsync(queryString: string) {
        var url = this.relativeContactTypeUrl + queryString;
        
        return await this.apiBroker.GetAsync(url)
            .then(r => r.data.map((d: any) => new ContactType(d)));
    }

    async GetContactTypeByIdAsync(id: Guid) {
        const url = `${this.relativeContactTypeUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new ContactType(result.data));
    }

    async PutContactTypeAsync(contactType: ContactType) {
        return await this.apiBroker.PutAsync(this.relativeContactTypeUrl, contactType)
            .then(result => new ContactType(result.data));
    }

    async DeleteContactTypeByIdAsync(id: Guid) {
        const url = `${this.relativeContactTypeUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new ContactType(result.data));
    }
}

export default ContactTypeBroker;



