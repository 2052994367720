import { ErrorBase } from "../../types/ErrorBase";

export interface IDocumentErrors extends ErrorBase {
    name: string;
    description: string;
    label: string;
    file: string;
}

export const documentErrors: IDocumentErrors = {
    hasErrors: false,
    name: "",
    description: "",
    label: "",
    file:""
}