import React, { FunctionComponent, useMemo, useState } from "react";
import SearchBase from "../bases/inputs/SearchBase";
import { debounce } from "lodash";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import SiteTypeRowAdd from "./siteTypeRowAdd";
import { SiteTypeView } from "../../models/views/components/siteTypes/siteTypeView";
import SiteTypeRow from "./siteTypeRow";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import { siteTypeViewService } from "../../services/views/siteTypes/siteTypeViewService";
import SiteTypeRowNew from "./siteTypeRowNew";
import TableBase from "../bases/components/Table/TableBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseHeader from "../bases/components/Table/TableBase.Header";
import TableBaseThead from "../bases/components/Table/TableBase.Thead";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";

type SiteTypeTableProps = {
    allowedToAdd: boolean;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
}

const SiteTypeTable: FunctionComponent<SiteTypeTableProps> = (props) => {
    const {
        allowedToAdd,
        allowedToEdit,
        allowedToDelete,
    } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedSiteTypes: siteTypesRetrieved, isLoading } =
        siteTypeViewService.useGetAllSiteTypes(debouncedTerm);

    const addSiteType = siteTypeViewService.useCreateSiteType();
    const updateSiteType = siteTypeViewService.useUpdateSiteType();
    const removeSiteType = siteTypeViewService.useRemoveSiteType();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [addApiError, setAddApiError] = useState<any>({});

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleAddState = () => {
        setAddMode(!addMode);
    };

    const handleAddNew = (siteType: SiteTypeView) => {
        return addSiteType.mutate(siteType, {
            onSuccess: () => {
                setAddMode(false);
            },
            onError: (error: any) => {
                setAddApiError(error?.response?.data?.errors);
            }
        });
    };

    const handleUpdate = (siteType: SiteTypeView) => {
        return updateSiteType.mutateAsync(siteType);
    }

    const handleDelete = (siteType: SiteTypeView) => {
        return removeSiteType.mutateAsync(siteType.id);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , [])

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>
                        Site Types
                    </CardBaseTitle>

                    <CardBaseContent>

                        <SearchBase id="search" label="Search site types" value={searchTerm}
                            onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />

                        {isLoading && <> <SpinnerBase />.</>}

                        <TableBase>
                            <TableBaseThead>
                                <TableBaseRow>
                                    <TableBaseHeader>Site Types</TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                </TableBaseRow>
                            </TableBaseThead>
                            <TableBaseTbody>
                                {
                                    allowedToAdd &&
                                    <>
                                        {addMode === false && (<SiteTypeRowNew onAdd={handleAddState} />)}

                                        {addMode === true && (
                                            <SiteTypeRowAdd
                                                onCancel={handleAddState}
                                                onAdd={handleAddNew}
                                                apiError={addApiError} />)}
                                    </>
                                }

                                {siteTypesRetrieved?.map((siteType: SiteTypeView) =>
                                    <SiteTypeRow
                                        key={siteType.id?.toString()}
                                        siteType={siteType}
                                        allowedToEdit={allowedToEdit}
                                        allowedToDelete={allowedToDelete}
                                        onSave={handleUpdate}
                                        onDelete={handleDelete}
                                    />)}
                            </TableBaseTbody>
                        </TableBase>
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </>
    );
}

SiteTypeTable.defaultProps = {
    allowedToAdd: true,
    allowedToEdit: true,
    allowedToDelete: true,
};

export default SiteTypeTable;