import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { Contract } from "../../../models/contracts/contract";
import { ContractView } from "../../../models/views/components/contracts/contractView";
import { contractService } from "../../foundations/contractService";

export const contractViewService = {

    useCreateContract: () => {
        return contractService.useCreateContract();
    },

    useGetAllContracts: (searchTerm?: string) => {
        try {
            let query = '?';

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`;
            }

            const [mappedContracts, setMappedContracts] = useState<Array<ContractView>>([]);
            const response = contractService.useGetAllContracts(query);

            useEffect(() => {
                if (response.data) {
                    const contracts = response.data.map((contract: Contract) =>
                        new ContractView(
                            contract.id,
                            contract.name,
                            contract.description,
                            contract.sortOrder,
                            contract.isActive,
                            contract.createdByUser,
                            contract.createdDate,
                            contract.updatedByUser,
                            contract.updatedDate))

                    setMappedContracts(contracts);
                }
            }, [response.data]);

            return {
                mappedContracts, ...response
            }

        } catch (err) {
            throw err;
        }
    },

    useGetContractById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = contractService.useGetAllContracts(query);
            const [mappedContract, setMappedContract] = useState<ContractView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const contract = new ContractView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].description,
                        response.data[0].sortOrder,
                        response.data[0].isActive,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate);

                    setMappedContract(contract);
                }
            }, [response.data]);

            return {
                mappedContract, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateContract: () => {
        return contractService.useUpdateContract();
    },

    useRemoveContract: () => {
        return contractService.useDeleteContract();
    },
}