import React, { FunctionComponent, useMemo, useState } from "react";
import SearchBase from "../bases/inputs/SearchBase";
import { debounce } from "lodash";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import PropertyStatusRowAdd from "./propertyStatusRowAdd";
import PropertyStatusRow from "./propertyStatusRow";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import { propertyStatusViewService } from "../../services/views/propertyStatuses/propertyStatusViewService";
import { PropertyStatusView } from "../../models/views/components/propertyStatuses/propertyStatusView";
import PropertyStatusRowNew from "./propertyStatusRowNew";
import TableBase from "../bases/components/Table/TableBase";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";
import TableBaseThead from "../bases/components/Table/TableBase.Thead";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseHeader from "../bases/components/Table/TableBase.Header";

type PropertyStatusTableProps = {
    allowedToAdd: boolean;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
}

const PropertyStatusTable: FunctionComponent<PropertyStatusTableProps> = (props) => {
    const {
        allowedToAdd,
        allowedToEdit,
        allowedToDelete,
    } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedPropertyStatuses: propertyStatussRetrieved, isLoading } =
        propertyStatusViewService.useGetAllPropertyStatuses(debouncedTerm);

    const addPropertyStatus = propertyStatusViewService.useCreatePropertyStatus();
    const updatePropertyStatus = propertyStatusViewService.useUpdatePropertyStatus();
    const removePropertyStatus = propertyStatusViewService.useRemovePropertyStatus();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [addApiError, setAddApiError] = useState<any>({});

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleAddState = () => {
        setAddMode(!addMode);
    };

    const handleAddNew = (propertyStatus: PropertyStatusView) => {
        return addPropertyStatus.mutate(propertyStatus, {
            onSuccess: () => {
                setAddMode(false);
            },
            onError: (error: any) => {
                setAddApiError(error?.response?.data?.errors);
            }
        });
    };

    const handleUpdate = (propertyStatus: PropertyStatusView) => {
        return updatePropertyStatus.mutateAsync(propertyStatus);
    }

    const handleDelete = (propertyStatus: PropertyStatusView) => {
        return removePropertyStatus.mutateAsync(propertyStatus.id);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , [])

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>
                        Premises Statuses
                    </CardBaseTitle>

                    <CardBaseContent>

                        <SearchBase id="search" label="Search premises statuses" value={searchTerm}
                            onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />

                            {isLoading && <> <SpinnerBase />.</>}

                            <TableBase>
                            <TableBaseThead>
                                <TableBaseRow>
                                    <TableBaseHeader>Premises Statuses</TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                </TableBaseRow>
                            </TableBaseThead>
                            <TableBaseTbody>
                                    {
                                        allowedToAdd &&
                                        <>
                                            {addMode === false && (<PropertyStatusRowNew onAdd={handleAddState} />)}

                                            {addMode === true && (
                                                <PropertyStatusRowAdd
                                                    onCancel={handleAddState}
                                                    onAdd={handleAddNew}
                                                    apiError={addApiError} />)}
                                        </>
                                    }

                                    {propertyStatussRetrieved?.map((propertyStatus: PropertyStatusView) =>
                                        <PropertyStatusRow
                                            key={propertyStatus.id?.toString()}
                                            propertyStatus={propertyStatus}
                                            allowedToEdit={allowedToEdit}
                                            allowedToDelete={allowedToDelete}
                                            onSave={handleUpdate}
                                            onDelete={handleDelete}
                                        />)}
                            </TableBaseTbody>
                            </TableBase>
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </>
    );
}

PropertyStatusTable.defaultProps = {
    allowedToAdd: true,
    allowedToEdit: true,
    allowedToDelete: true,
};

export default PropertyStatusTable;