import { ErrorBase } from "../../types/ErrorBase";

export interface IContactErrors extends ErrorBase {
    hasErrors: boolean;
    contactTypeId: string;
    name: string;
    contactNumber: string;
    contactEmail: string;
}

export const contactErrors: IContactErrors = {
    hasErrors: false,
    contactTypeId: "",
    name: "",
    contactNumber: "",
    contactEmail: "",
};
