import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ProviderStatusView } from "../../models/views/components/providerStatuses/providerStatusView";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import securityPoints from "../../SecurityMatrix";
import { SecuredComponents } from "../Links";

interface ProviderStatusRowViewProps {
    providerStatus: ProviderStatusView;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
    onEdit: (value: string) => void;
    onDelete: (value: string) => void;
}

const ProviderStatusRowView: FunctionComponent<ProviderStatusRowViewProps> = (props) => {
    const {
        providerStatus,
        allowedToEdit,
        allowedToDelete,
        onEdit,
        onDelete
    } = props;

    return (
        <TableBaseRow>
            <TableBaseData>
                <b>{providerStatus.name}</b><br />
                {providerStatus.description}<br />
            </TableBaseData>
            <TableBaseData classes="text-end">
                {providerStatus.isActive !== true && <FontAwesomeIcon icon={faTimes} size="lg" color="red" title="Inactive" />}
                {providerStatus.isActive === true && <FontAwesomeIcon icon={faCheck} size="lg" color="green" title="Active" />}
            </TableBaseData>
            <TableBaseData classes="text-end">
                {allowedToEdit && (
                    <SecuredComponents allowedRoles={securityPoints.providerStatuses.edit}>
                        <ButtonBase onClick={() => onEdit('EDIT')} edit>Edit</ButtonBase>
                    </SecuredComponents>
                )}
                &nbsp;
                {allowedToDelete && (
                    <SecuredComponents allowedRoles={securityPoints.providerStatuses.delete}>
                        <ButtonBase onClick={() => onDelete('DELETE')} remove>Delete</ButtonBase>
                    </SecuredComponents>
                )}
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ProviderStatusRowView;
