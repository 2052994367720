import { Guid } from "guid-typescript";

import { Ownership } from "../models/ownerships/ownership";
import ApiBroker from "./apiBroker";

class OwnershipBroker {

    relativeOwnershipUrl = '/api/ownerships';

    private apiBroker: ApiBroker = new ApiBroker();

    async PostOwnershipAsync(ownership: Ownership) {
        return await this.apiBroker.PostAsync(this.relativeOwnershipUrl, ownership)
            .then(result => new Ownership(result.data));
    }

    async GetAllOwnershipsAsync(queryString: string) {
        var url = this.relativeOwnershipUrl + queryString;

        return await this.apiBroker.GetAsync(url)
            .then(result => result.data.map((ownership: any) => new Ownership(ownership)));
    }

    async GetOwnershipByIdAsync(id: Guid) {
        const url = `${this.relativeOwnershipUrl}/${id}`;

        return await this.apiBroker.GetAsync(url)
            .then(result => new Ownership(result.data));
    }

    async PutOwnershipAsync(ownership: Ownership) {
        return await this.apiBroker.PutAsync(this.relativeOwnershipUrl, ownership)
            .then(result => new Ownership(result.data));
    }

    async DeleteOwnershipByIdAsync(id: Guid) {
        const url = `${this.relativeOwnershipUrl}/${id}`;

        return await this.apiBroker.DeleteAsync(url)
            .then(result => new Ownership(result.data));
    }
}

export default OwnershipBroker;



