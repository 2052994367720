import { Guid } from 'guid-typescript';

export class SiteListItemView {
    public id: Guid;
    public siteName?: string;
    public isActive?: boolean;
    public propertyStatus?: string;
    public siteTypeName?: string;
    public actionCount?: number;

    constructor(
        id: Guid,
        siteName?: string,
        isActive?: boolean,
        propertyStatus?: string,
        siteTypeName?: string,
        actionCount?: number
    ) {
        this.id = id;
        this.siteName = siteName || "";
        this.isActive = isActive === false ? false : true;
        this.propertyStatus = propertyStatus || "";
        this.siteTypeName = siteTypeName;
        this.actionCount = actionCount || 0;
    }
}