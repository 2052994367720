import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { ReviewStatusView } from "../../models/views/components/reviewStatuses/reviewStatusView";
import { Guid } from "guid-typescript";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { reviewStatusErrors } from "./reviewStatusErrors";
import { reviewStatusValidations } from "./reviewStatusValidations";

interface ReviewStatusRowAddProps {
    onCancel: () => void;
    onAdd: (reviewStatus: ReviewStatusView) => void;
    apiError?: any;
}

const ReviewStatusRowAdd: FunctionComponent<ReviewStatusRowAddProps> = (props) => {
    const {
        onCancel,
        onAdd,
        apiError
    } = props;

    const [reviewStatus, setReviewStatus] = useState<ReviewStatusView>(new ReviewStatusView(Guid.create()));

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(reviewStatusErrors, reviewStatusValidations, reviewStatus);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const addReviewStatus = {
            ...reviewStatus,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setReviewStatus(addReviewStatus);
    };

    const handleSave = () => {
        if (!validate(reviewStatus)) {
            onAdd(reviewStatus);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Review Status Name"
                    placeholder="Review Status Name"
                    value={reviewStatus.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Review Status Description"
                    placeholder="Review Status Description"
                    value={reviewStatus.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={reviewStatus.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={reviewStatus.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} add>Add</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReviewStatusRowAdd;
