import React, { FunctionComponent, useMemo, useState } from "react";
import SearchBase from "../bases/inputs/SearchBase";
import { debounce } from "lodash";
import { SpinnerBase } from "../bases/spinner/SpinnerBase";
import ReviewStageRowAdd from "./reviewStageRowAdd";
import { ReviewStageView } from "../../models/views/components/reviewStages/reviewStageView";
import ReviewStageRow from "./reviewStageRow";
import CardBase from "../bases/components/Card/CardBase";
import CardBaseTitle from "../bases/components/Card/CardBase.Title";
import CardBaseContent from "../bases/components/Card/CardBase.Content";
import CardBaseBody from "../bases/components/Card/CardBase.Body";
import { reviewStageViewService } from "../../services/views/reviewStages/reviewStageViewService";
import ReviewStageRowNew from "./reviewStageRowNew";
import TableBase from "../bases/components/Table/TableBase";
import TableBaseThead from "../bases/components/Table/TableBase.Thead";
import TableBaseTbody from "../bases/components/Table/TableBase.Tbody";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseHeader from "../bases/components/Table/TableBase.Header";

type ReviewStageTableProps = {
    allowedToAdd: boolean;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
}

const ReviewStageTable: FunctionComponent<ReviewStageTableProps> = (props) => {
    const {
        allowedToAdd,
        allowedToEdit,
        allowedToDelete,
    } = props;

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedTerm, setDebouncedTerm] = useState<string>("");

    const { mappedReviewStages: reviewStagesRetrieved, isLoading } =
        reviewStageViewService.useGetAllReviewStages(debouncedTerm);

    const addReviewStage = reviewStageViewService.useCreateReviewStage();
    const updateReviewStage = reviewStageViewService.useUpdateReviewStage();
    const removeReviewStage = reviewStageViewService.useRemoveReviewStage();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [addApiError, setAddApiError] = useState<any>({});

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        handleDebounce(value);
    }

    const handleAddState = () => {
        setAddMode(!addMode);
    };

    const handleAddNew = (reviewStage: ReviewStageView) => {
        return addReviewStage.mutate(reviewStage, {
            onSuccess: () => {
                setAddMode(false);
            },
            onError: (error: any) => {
                setAddApiError(error?.response?.data?.errors);
            }
        });
    };

    const handleUpdate = (reviewStage: ReviewStageView) => {
        return updateReviewStage.mutateAsync(reviewStage);
    }

    const handleDelete = (reviewStage: ReviewStageView) => {
        return removeReviewStage.mutateAsync(reviewStage.id);
    }

    const handleDebounce = useMemo(
        () => debounce((value: string) => {
            setDebouncedTerm(value)
        }, 500)
        , [])

    return (
        <>
            <CardBase>
                <CardBaseBody>
                    <CardBaseTitle>
                        Review Stages
                    </CardBaseTitle>

                    <CardBaseContent>

                        <SearchBase id="search" label="Search review stages" value={searchTerm}
                            onChange={(e) => { handleSearchChange(e.currentTarget.value) }} />

                        {isLoading && <> <SpinnerBase />.</>}

                        <TableBase>
                            <TableBaseThead>
                                <TableBaseRow>
                                    <TableBaseHeader>Review Stages</TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                    <TableBaseHeader classes="text-center"></TableBaseHeader>
                                </TableBaseRow>
                            </TableBaseThead>
                            <TableBaseTbody>
                                {
                                    allowedToAdd &&
                                    <>
                                        {addMode === false && (<ReviewStageRowNew onAdd={handleAddState} />)}

                                        {addMode === true && (
                                            <ReviewStageRowAdd
                                                onCancel={handleAddState}
                                                onAdd={handleAddNew}
                                                apiError={addApiError} />)}
                                    </>
                                }

                                {reviewStagesRetrieved?.map((reviewStage: ReviewStageView) =>
                                    <ReviewStageRow
                                        key={reviewStage.id?.toString()}
                                        reviewStage={reviewStage}
                                        allowedToEdit={allowedToEdit}
                                        allowedToDelete={allowedToDelete}
                                        onSave={handleUpdate}
                                        onDelete={handleDelete}
                                    />)}
                            </TableBaseTbody>
                        </TableBase>
                    </CardBaseContent>
                </CardBaseBody>
            </CardBase>
        </>
    );
}

ReviewStageTable.defaultProps = {
    allowedToAdd: true,
    allowedToEdit: true,
    allowedToDelete: true,
};

export default ReviewStageTable;