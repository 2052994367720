import React, { FunctionComponent, useEffect, useState } from "react";
import { Guid } from 'guid-typescript';
import { SiteView } from "../../models/views/components/site/siteView";
import { lookupViewService } from "../../services/views/lookups/lookupViewService";
import { siteViewService } from "../../services/views/sites/siteViewService";
import SiteDetailCard from "./siteDetailCard";

interface SiteDetailProps {
    siteId?: string;
    practiceId: Guid;
    activeTab?: string;
    children?: React.ReactNode;
}

const SiteDetail: FunctionComponent<SiteDetailProps> = (props) => {
    const {
        siteId,
        practiceId,
        activeTab,
        children
    } = props;

    let { mappedPractices: practicesRetrieved } = lookupViewService.useGetPracticeList("");
    let { mappedSiteTypes: siteTypesRetrieved } = lookupViewService.useGetSiteTypeList("");
    let { mappedContracts: contractsRetrieved } = lookupViewService.useGetContractList("");
    let { mappedOwnerships: ownershipsRetrieved } = lookupViewService.useGetOwnershipList("");
    let { mappedPropertyStatuses: propertyStatusesRetrieved } = lookupViewService.useGetPropertyStatusList("");

    let siteRetrieved: SiteView | undefined

    let { mappedSite, isSuccess } = siteViewService.useGetSiteById(Guid.parse(siteId ?? Guid.EMPTY));
    siteRetrieved = mappedSite;

    const [site, setSite] = useState<SiteView | null>(null);
    const [mode, setMode] = useState<string>('VIEW');
    const [activeTabNav, setActiveTabNav] = useState<string>('');
    const addSite = siteViewService.useCreateSite();

    const handleAdd = (site: SiteView) => {
        return addSite.mutate(site);
    }

    const updateSite = siteViewService.useUpdateSite();

    const handleUpdate = async (site: SiteView) => {
        return updateSite.mutateAsync(site);
    }

    const handleDelete = async (site: SiteView) => {
        site.isActive = false;
        return updateSite.mutateAsync(site);
    }

    useEffect(() => {
        if ((siteId && siteRetrieved !== undefined)) {
            setSite(siteRetrieved);
            setMode('VIEW');
        }
        if (siteRetrieved === undefined) {
            setSite(new SiteView(Guid.create(), practiceId))
            setMode('ADD');
        }
        if (activeTab !== undefined) {
            setActiveTabNav(activeTab);
        }
        if (activeTab === undefined) {
            setActiveTabNav("details");
        }
    }, [siteId, siteRetrieved, practiceId, activeTab]);

    return (
        <div>
            {site !== undefined && isSuccess && site !== null
                && practicesRetrieved
                && siteTypesRetrieved
                && contractsRetrieved
                && ownershipsRetrieved
                && propertyStatusesRetrieved
                && (
                    <SiteDetailCard
                        key={site.id.toString()}
                        site={site}
                        siteTypes={siteTypesRetrieved}
                        contracts={contractsRetrieved}
                        ownerships={ownershipsRetrieved}
                        propertyStatuses={propertyStatusesRetrieved}
                        onAdd={handleAdd}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        mode={mode}
                        activeTab={activeTabNav}>
                        {children}
                    </SiteDetailCard>
                )}
        </div>
    );
}

export default SiteDetail;