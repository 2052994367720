import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { ReferenceValueView } from "../../models/views/components/referenceValues/referenceValueView";
import { Guid } from "guid-typescript";
import TextInputBase from "../bases/inputs/TextInputBase";
import TextAreaInputBase from "../bases/inputs/TextAreaInputBase";
import CheckboxBase from "../bases/inputs/CheckboxBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { referenceValueErrors } from "./referenceValueErrors";
import { referenceValueValidations } from "./referenceValueValidations";
import { useParams } from "react-router-dom";

interface ReferenceValueRowAddProps {
    onCancel: () => void;
    onAdd: (referenceValue: ReferenceValueView) => void;
    apiError?: any;
}

const ReferenceValueRowAdd: FunctionComponent<ReferenceValueRowAddProps> = (props) => {
    const {
        onCancel,
        onAdd,
        apiError
    } = props;

    const {type} = useParams();

    const [referenceValue, setReferenceValue] = useState<ReferenceValueView>(new ReferenceValueView(Guid.create(), undefined, type, undefined, undefined, true, 0, undefined, undefined, undefined, undefined));

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(referenceValueErrors, referenceValueValidations, referenceValue);

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {

        const addReferenceValue = {
            ...referenceValue,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setReferenceValue(addReferenceValue);
    };

    const handleSave = () => {
        if (!validate(referenceValue)) {
            onAdd(referenceValue);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <TextInputBase
                    id="name"
                    name="name"
                    label="Reference Value Name"
                    placeholder="Reference Value Name"
                    value={referenceValue.name}
                    required={true}
                    error={errors.name}
                    onChange={handleChange} />
                <TextAreaInputBase
                    id="workFlowAction"
                    name="workFlowAction"
                    label="Work Flow Action"
                    placeholder="Work Flow Action"
                    value={referenceValue.workFlowAction}
                    error={errors.workFlowAction}
                    onChange={handleChange}
                    rows={3}
                />
                <TextAreaInputBase
                    id="description"
                    name="description"
                    label="Reference Value Description"
                    placeholder="Reference Value Description"
                    value={referenceValue.description}
                    error={errors.description}
                    onChange={handleChange}
                    rows={3}
                />
                <TextInputBase
                    id="sortOrder"
                    name="sortOrder"
                    label="Sort Order"
                    placeholder="Sort Order"
                    value={referenceValue.sortOrder}
                    error={errors.sortOrder}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData>
                <CheckboxBase
                    id="isActive"
                    name="isActive"
                    checked={referenceValue.isActive}
                    error={errors.description}
                    onChange={handleChange}
                />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()} disabled={errors.hasErrors} add>Add</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ReferenceValueRowAdd;
