import { Validation } from "../../models/validations/validation";

export const referenceValueValidations: Array<Validation> = [
    {
        property: "name",
        friendlyName: "Reference Value name",
        isRequired: true,
        minLength: 2,
        maxLength: 255,
    },

    {
        property: "referenceValueType",
        friendlyName: "Reference Value Type",
        isRequired: true,
        minLength: 2,
        maxLength: 255,
    }
]