import { useEffect, useState } from "react";
import { Guid } from "guid-typescript";
import { Borough } from "../../../models/boroughs/borough";
import { BoroughView } from "../../../models/views/components/boroughs/boroughView";
import { boroughService } from "../../foundations/boroughService";

export const boroughViewService = {

    useCreateBorough: () => {
        return boroughService.useCreateBorough();
    },

    useGetAllBoroughs: (searchTerm?: string) => {
        try {

            let query = '?$orderby=name';

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`;
            }
            
            const response = boroughService.useGetAllBoroughPages(query);
            const [mappedBoroughs, setMappedBoroughs] = useState<Array<BoroughView>>();
            const [pages, setPages] = useState<any>([]);

            useEffect(() => {
                if (response.data && response.data.pages) {
                    const boroughs: Array<BoroughView> = [];
                    response.data.pages.forEach(x => {
                        x.data.forEach((borough: Borough) => {
                            boroughs.push(new BoroughView(
                                borough.id,
                                borough.name,
                                borough.financeManagerName,
                                borough.financeManagerEmail,
                                borough.createdByUser,
                                borough.createdDate,
                                borough.updatedByUser,
                                borough.updatedDate
                            ));
                        })
                        setMappedBoroughs(boroughs);
                    });
                    setPages(response.data.pages);
                }
            }, [response.data]);

            return {
                mappedBoroughs, pages, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetBoroughById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = boroughService.useGetAllBoroughs(query);
            const [mappedBorough, setMappedBorough] = useState<BoroughView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const borough = new BoroughView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].financeManagerName,
                        response.data[0].financeManagerEmail,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate);

                    setMappedBorough(borough);
                }
            }, [response.data]);
            
            return {
                mappedBorough, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateBorough: () => {
        return boroughService.useUpdateBorough();
    },

    useRemoveBorough: () => {
        return boroughService.useDeleteBorough();
    },
}