import React, { FunctionComponent, useState } from "react";
import { ContractView } from "../../models/views/components/contracts/contractView";
import ContractRowView from "./contractRowView";
import ContractRowEdit from "./contractRowEdit";
import ContractRowDelete from "./contractRowDelete";

type ContractRowProps = {
    contract: ContractView;
    allowedToEdit: boolean;
    allowedToDelete: boolean;
    onSave: (contract: ContractView) => void;
    onDelete: (contract: ContractView) => void;
}

const ContractRow: FunctionComponent<ContractRowProps> = (props) => {
    const {
        contract,
        allowedToEdit,
        allowedToDelete,
        onSave,
        onDelete,
    } = props;

    const [mode, setMode] = useState<string>('VIEW');
    const [apiError, setApiError] = useState<any>({});

    const handleMode = (value: string) => {
        setMode(value);
    };

    const handleSave = async (contract: ContractView) => {
        try {
            await onSave(contract);
            setMode('VIEW');
        } catch (error) {
            setApiError(error);
            setMode('EDIT');
        }
    };

    const handleDelete = (contract: ContractView) => {
        onDelete(contract);
        setMode('VIEW');
    };

    const handleCancel = () => {
        setMode('VIEW');
    };

    return (
        <>
            {mode !== 'EDIT' && mode !== 'DELETE' && (
                <ContractRowView
                    key={contract.id.toString()}
                    contract={contract}
                    onEdit={handleMode}
                    onDelete={handleMode}
                    allowedToEdit={allowedToEdit}
                    allowedToDelete={allowedToDelete} />
            )}

            {mode === 'EDIT' && (
                <ContractRowEdit
                    key={contract.id.toString()}
                    contract={contract}
                    onCancel={handleCancel}
                    onEdit={handleSave}
                    apiError={apiError}
                />
            )}

            {mode === 'DELETE' && (
                <ContractRowDelete
                    key={contract.id.toString()}
                    contract={contract}
                    onCancel={handleCancel}
                    onDelete={handleDelete} />
            )}
        </>
    );
}

ContractRow.defaultProps = {
    allowedToEdit: false,
    allowedToDelete: false
};

export default ContractRow;