import React from 'react';
import { Container, Row, Col, Card } from 'nhsuk-react-components'
import 'nhsuk-frontend/dist/nhsuk.min'
import 'nhsuk-frontend/packages/polyfills';
import { PublicLink } from '../components/Links';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { Button } from 'react-bootstrap';

export const Home = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    return (
        <>
            <section className="nhsuk-hero">
                <Container>
                    <Row className="nhsuk-grid-row">
                        <Col width="full">
                            <div className="nhsuk-hero__wrapper app-hero__wrapper">
                                <h1 className="nhsuk-u-margin-bottom-4">GP Premises Manager</h1>
                                <p className=" nhsuk-u-margin-bottom-1">
                                    <p className="nhsuk-body-l nhsuk-u-margin-bottom-1">Use the service to setup, monitor and track rent reviews across all managed premises.</p>
                                </p>
                            </div>
                        </Col>
                        <Col width="one-third">
                           
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container className="NELTopPadding">
                    {isAuthenticated ? (
                        <div>
                            <Card.Group>
                                <Card.GroupItem width="one-half">
                                    <Card clickable>
                                        <Card.Content>
                                            <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                                <PublicLink to="/practices">GP Practices</PublicLink>
                                            </Card.Heading>
                                            <Card.Description>
                                                View, add, edit and remove GP Premises.
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                </Card.GroupItem>

                                <Card.GroupItem width="one-half">
                                    <Card clickable>
                                        <Card.Content>
                                            <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                                <PublicLink to="/actions">Actions</PublicLink>
                                            </Card.Heading>
                                            <Card.Description>View, add, edit and remove tasks.</Card.Description>
                                        </Card.Content>
                                    </Card>
                                </Card.GroupItem>
                            </Card.Group>
                        </div>
                    ) : (
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Card.Link href="#" onClick={() => instance.loginRedirect(loginRequest)}>Login to continue.</Card.Link>
                                </Card.Heading>
                                    <Card.Description>
                                        To unlock all the features of this system,
                                        please <Button variant="link" className="linkCustom" onClick={() => instance.loginRedirect(loginRequest)}>Login</Button>.
                                        For access requests, please contact your Manager.
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    )}
                </Container>
            </section>
        </>
    );
};
