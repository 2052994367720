import React from "react"
import { useParams } from "react-router-dom"
import { Link } from 'react-router-dom';
import 'nhsuk-frontend/dist/nhsuk.min'
import 'nhsuk-frontend/packages/polyfills';
import { Container } from 'nhsuk-react-components'
import PracticeDetail from "../components/practices/practiceDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export const PracticePage = () => {

    const { practiceId } = useParams();

    return <div>
        <section >
            <Container>
                <main id="maincontent" className="NELTopPadding" role="main">
                    <Link to={'/practices'}>
                        <FontAwesomeIcon icon={faChevronLeft} size="1x" />Back to Practices
                    </Link>
                    <PracticeDetail practiceId={practiceId} />
                </main>
            </Container>
        </section>
    </div>
}