import { ErrorBase } from "../../types/ErrorBase";

interface IBoroughErrors extends ErrorBase {
    hasErrors: boolean;
    name: string;
    financeManagerName: string;
    financeManagerEmail: string;
}

export const boroughErrors: IBoroughErrors = {
    hasErrors: false,
    name: "",
    financeManagerName: "",
    financeManagerEmail: "",
}