import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { Contact } from "../../../models/contacts/contact";
import { ContactView } from "../../../models/views/components/contacts/contactView";
import { contactService } from "../../foundations/contactService";

export const contactViewService = {

    useCreateContact: () => {
        return contactService.useCreateContact();
    },

    useGetAllContacts: (searchTerm?: string, siteId?: Guid) => {
        try {
            let query = '?$orderby=contactType/sortOrder,name' +
                `&$expand=site($select=id,siteName)` +
                `&$expand=contactType($select=id,name)`;

            if (searchTerm || siteId) {
                query = query + `&$filter=contains(name,'${searchTerm}') and siteId eq ${siteId}`;
            }

            const response = contactService.useGetAllContacts(query);
            const [mappedContacts, setMappedContacts] = useState<Array<ContactView>>([]);

            useEffect(() => {
                if (response.data) {
                    const contacts = response.data.map((contact: Contact) =>
                        new ContactView(
                            contact.id,
                            contact.siteId,
                            contact.contactTypeId,
                            contact.name,
                            contact.contactNumber,
                            contact.contactEmail,
                            contact.site?.siteName,
                            contact.contactType?.name,
                            contact.createdByUser,
                            contact.createdDate,
                            contact.updatedByUser,
                            contact.updatedDate
                        ));

                    setMappedContacts(contacts);
                }
            }, [response.data]);

            return {
                mappedContacts, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useGetContactById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = contactService.useGetAllContacts(query);
            const [mappedContact, setMappedContact] = useState<ContactView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const contact = new ContactView(
                        response.data[0].id,
                        response.data[0].siteId,
                        response.data[0].contactTypeId,
                        response.data[0].name,
                        response.data[0].contactNumber,
                        response.data[0].contactEmail,
                        response.data[0].site?.siteName,
                        response.data[0].contactType?.name,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate
                    );

                    setMappedContact(contact);
                }
            }, [response.data]);
                    
            return {
                mappedContact, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateContact: () => {
        return contactService.useUpdateContact();
    },

    useRemoveContact: () => {
        return contactService.useDeleteContact();
    },
}