import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import TextInputBase from "../bases/inputs/TextInputBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import { ContactView } from "../../models/views/components/contacts/contactView";
import SelectInputBase from "../bases/inputs/SelectInputBase";
import { LookupView } from "../../models/views/components/lookups/lookupView";
import { Option } from "../../models/options/option";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { contactErrors } from "./contactErrors";
import { contactValidations } from "./contactValidations";

interface ContactRowEditProps {
    contact: ContactView;
    contactTypes: Array<LookupView>;
    onCancel: () => void;
    onEdit: (contactType: ContactView) => void;
    apiError?: any;
}

const ContactRowEdit: FunctionComponent<ContactRowEditProps> = (props) => {
    const {
        contact,
        contactTypes,
        onCancel,
        onEdit,
        apiError
    } = props;

    const [editContact, setEditContact] = useState<ContactView>({ ...contact });

    const { errors, processApiErrors, enableValidationMessages, validate } = useValidation(contactErrors, contactValidations, editContact);

    const contactTypeOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...contactTypes.map((contactType) => {
            return { id: contactType.id.toString() || 0, name: contactType.name || "" };
        }),
    ];

    const handleChange = (
        event: ChangeEvent<HTMLInputElement>
            | ChangeEvent<HTMLTextAreaElement>
            | ChangeEvent<HTMLSelectElement>) => {
        const updatedContact = {
            ...editContact,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };

        setEditContact(updatedContact);
    };

    const handleCancel = () => {
        setEditContact({ ...contact });
        onCancel();
    };

    const handleUpdate = () => {
        if (!validate(editContact)) {
            onEdit(editContact);
        } else {
            enableValidationMessages();
        }
    }

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <SelectInputBase
                    id="contactTypeId"
                    name="contactTypeId"
                    value={editContact.contactTypeId?.toString()}
                    required={true}
                    label="Contact Type"
                    options={contactTypeOptions}
                    error={errors.contactTypeId}
                    onChange={handleChange}
                />
                <TextInputBase
                    id="name"
                    name="name"
                    label="Contact Name"
                    placeholder="Contact Name"
                    required={true}
                    value={editContact.name}
                    error={errors.name}
                    onChange={handleChange} />
                <TextInputBase
                    id="contactNumber"
                    name="contactNumber"
                    label="Contact Number"
                    placeholder="Contact Number"
                    value={editContact.contactNumber}
                    error={errors.contactNumber}
                    onChange={handleChange} />
                <TextInputBase
                    id="contactEmail"
                    name="contactEmail"
                    label="Contact Email"
                    placeholder="Contact Email"
                    value={editContact.contactEmail}
                    error={errors.contactEmail}
                    onChange={handleChange} />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => handleCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleUpdate()} edit>Update</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ContactRowEdit;
