import { Guid } from 'guid-typescript';

export class PracticeView {
    public id: Guid;
    public boroughId?: Guid;
    public practiceName?: string;
    public practiceCode?: string;
    public odsCode?: string;
    public pcn?: string;
    public pcnCode?: string;
    public groupName?: string;
    public practicePhone?: string;
    public practicePhoneBypass?: string;
    public practiceGenericEmail?: string;
    public providerStatusId?: Guid;
    public isActive: boolean;
    public sortOrder?: number;
    public createdByUser?: string;
    public createdDate?: Date;
    public updatedByUser?: string;
    public updatedDate?: Date;
    public boroughName?: string;
    public providerStatusName?: string;
    public dateOfClosure?: Date;

    constructor(
        id: Guid,
        boroughId?: Guid,
        practiceName?: string,
        practiceCode?: string,
        odsCode?: string,
        pcn?: string,
        pcnCode?: string,
        groupName?: string,
        practicePhone?: string,
        practicePhoneBypass?: string,
        practiceGenericEmail?: string,
        providerStatusId?: Guid,
        isActive?: boolean,
        sortOrder?: number,
        createdByUser?: string,
        createdDate?: Date,
        updatedByUser?: string,
        updatedDate?: Date,
        boroughName?: string,
        providerStatusName?: string,
        dateOfClosure?: Date)
    {
        this.id = id;
        this.boroughId = boroughId;
        this.practiceName = practiceName || "";
        this.practiceCode = practiceCode || "";
        this.odsCode = odsCode || "";
        this.pcn = pcn || "";
        this.pcnCode = pcnCode || "";
        this.groupName = groupName || "";
        this.practicePhone = practicePhone || "";
        this.practicePhoneBypass = practicePhoneBypass || "";
        this.practiceGenericEmail = practiceGenericEmail || "";
        this.providerStatusId = providerStatusId;
        this.isActive = isActive === false ? false : true;
        this.sortOrder = sortOrder || 1000;
        this.createdByUser = createdByUser !== undefined ? createdByUser : '';
        this.createdDate = createdDate;
        this.updatedByUser = updatedByUser !== undefined ? updatedByUser : ''
        this.updatedDate = updatedDate;
        this.boroughName = boroughName;
        this.providerStatusName = providerStatusName;
        this.dateOfClosure = dateOfClosure;
    }
}