import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { SiteType } from "../../../models/siteTypes/siteType";
import { SiteTypeView } from "../../../models/views/components/siteTypes/siteTypeView";
import { siteTypeService } from "../../foundations/siteTypeService";

export const siteTypeViewService = {

    useCreateSiteType: () => {
        return siteTypeService.useCreateSiteType();
    },

    useGetAllSiteTypes: (searchTerm?: string) => {
        try {
            let query = '?$orderby=sortOrder,name';

            if (searchTerm) {
                query = query + `&$filter=contains(name,'${searchTerm}')`;
            }

            const response = siteTypeService.useGetAllSiteTypes(query);
            const [mappedSiteTypes, setMappedSiteTypes] = useState<Array<SiteTypeView>>();

            useEffect(() => {
                if (response.data) {
                    const siteTypes = response.data.map((siteType: SiteType) =>
                        new SiteTypeView(
                            siteType.id,
                            siteType.name,
                            siteType.description,
                            siteType.isActive,
                            siteType.sortOrder,
                            siteType.createdByUser,
                            siteType.createdDate,
                            siteType.updatedByUser,
                            siteType.updatedDate
                        ));

                    setMappedSiteTypes(siteTypes);
                }
            }, [response.data]);

            return {
                mappedSiteTypes, ...response
            }
        } catch (err) {
            throw err;
        }
    },
    useGetSiteTypeById: (id: Guid) => {
        try {
            const query = `?$filter=id eq ${id}`
            const response = siteTypeService.useGetAllSiteTypes(query);
            const [mappedSiteType, setMappedSiteType] = useState<SiteTypeView>();

            useEffect(() => {
                if (response.data && response.data[0]) {
                    const siteType = new SiteTypeView(
                        response.data[0].id,
                        response.data[0].name,
                        response.data[0].description,
                        response.data[0].isActive,
                        response.data[0].sortOrder,
                        response.data[0].createdByUser,
                        response.data[0].createdDate,
                        response.data[0].updatedByUser,
                        response.data[0].updatedDate
                    );

                    setMappedSiteType(siteType);
                }
            }, [response.data]);

            return {
                mappedSiteType, ...response
            }
        } catch (err) {
            throw err;
        }
    },

    useUpdateSiteType: () => {
        return siteTypeService.useUpdateSiteType();
    },

    useRemoveSiteType: () => {
        return siteTypeService.useDeleteSiteType();
    },
}