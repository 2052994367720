import { Guid } from "guid-typescript";

export class ActionView {
    public id: Guid;
    public due: Date;
    public type?: string;
    public name?: string;
    public ragStatus?: string;
    public practiceId?: Guid;
    public practiceName?: string;
    public practiceCode?: string;
    public siteId?: Guid;
    public siteName?: string;
    public borough?: string;
    public key?: string;

    constructor(
        id: Guid,
        due: Date,
        type?: string,
        name?: string,
        ragStatus?: string,
        practiceId?: Guid,
        practiceName?: string,
        practiceCode?: string,
        siteId?: Guid,
        siteName?: string,
        borough?: string)
    {
        this.id = id;
        this.due = due;
        this.type = type !== undefined ? type : '';
        this.name = name !== undefined ? name : '';
        this.name = name !== undefined ? name : '';
        this.ragStatus = ragStatus !== undefined ? ragStatus : '';
        this.practiceId = practiceId;
        this.practiceName = practiceName !== undefined ? practiceName : '';
        this.practiceCode = practiceCode !== undefined ? practiceCode : ''
        this.siteId = siteId;
        this.siteName = siteName !== undefined ? siteName : '';
        this.borough = borough !== undefined ? borough : '';
        this.key = Guid.create.toString();
    }
}