import React from 'react';
import { Container, Row, Col, Card } from 'nhsuk-react-components'
import { Link } from 'react-router-dom';
import 'nhsuk-frontend/dist/nhsuk.min'
import 'nhsuk-frontend/packages/polyfills';
import { referenceValueConfig } from '../../components/referenceValues/referenceValueTypes';


export const ConfigHomePage = () => {
    return (
        <>
            <section className="nhsuk-hero">
                <Container>
                    <Row className="nhsuk-grid-row">
                        <Col width="two-thirds">
                            <div className="nhsuk-hero__wrapper app-hero__wrapper">
                                <h1 className="nhsuk-u-margin-bottom-4">Configuration</h1>
                                <p className="nhsuk-body-l nhsuk-u-margin-bottom-1">Area to manage configuration items.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container className="NELTopPadding">
                    <Card.Group>
                        <Card.GroupItem width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={'/configuration/boroughs'}>
                                            Places
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove Places.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>

                        <Card.GroupItem width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={'/configuration/contactTypes'}>
                                            Contact Types
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove Contact Types.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>

                        <Card.GroupItem width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={'/configuration/contracts'}>
                                            Contracts
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove Contracts.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>

                        <Card.GroupItem width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={'/configuration/leaseTypes'}>
                                            Lease Available Types
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove Lease Available Types.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>

                        <Card.GroupItem width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={'/configuration/ownerships'}>
                                            Ownerships
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove Ownerships.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>

                        <Card.GroupItem width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={'/configuration/propertystatuses'}>
                                            Premises Statuses
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove Premises Statuses.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>

                        <Card.GroupItem width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={'/configuration/providerstatuses'}>
                                            Provider Statuses
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove Provider Statuses.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>

                        <Card.GroupItem width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={'/configuration/reviewstages'}>
                                            Review Stages
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove Review Stages.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>

                        <Card.GroupItem width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={'/configuration/reviewstatuses'}>
                                            Review Statuses
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove Review Statuses.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>

                        <Card.GroupItem width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={'/configuration/sitetypes'}>
                                            Site Types
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove Site Types.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>

                        {referenceValueConfig.map((item, index) => <Card.GroupItem key={index} width="one-half">
                            <Card clickable>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-card__heading nhsuk-heading-m">
                                        <Link to={`/referenceValueConfig/${item.key}`}>
                                            {item.name}
                                        </Link>
                                    </Card.Heading>
                                    <Card.Description>
                                        View, add, edit and remove {item.name} Types.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </Card.GroupItem>)}
                    </Card.Group>
                </Container>
            </section>
        </>
    );
};
