import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { ContactView } from "../../models/views/components/contacts/contactView";
import { Guid } from "guid-typescript";
import TextInputBase from "../bases/inputs/TextInputBase";
import ButtonBase from "../bases/buttons/ButtonBase";
import SelectInputBase from "../bases/inputs/SelectInputBase";
import { LookupView } from "../../models/views/components/lookups/lookupView";
import { Option } from "../../models/options/option";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import { useValidation } from "../../hooks/useValidation";
import { contactValidations } from "./contactValidations";
import { contactErrors } from "./contactErrors";

interface ContactRowAddProps {
    siteId: Guid;
    contactTypes: Array<LookupView>;
    onCancel: () => void;
    onAdd: (contact: ContactView) => void;
    apiError?: any;
}

const ContactRowAdd: FunctionComponent<ContactRowAddProps> = (props) => {
    const {
        siteId,
        contactTypes,
        onCancel,
        onAdd,
        apiError
    } = props;

    const [contact, setContact] = useState<ContactView>(new ContactView(Guid.create(), siteId));

    const { errors, processApiErrors, enableValidationMessages, validate } =
        useValidation(contactErrors, contactValidations, contact);

    const handleChange = (
        event: ChangeEvent<HTMLInputElement>
            | ChangeEvent<HTMLTextAreaElement>
            | ChangeEvent<HTMLSelectElement>) => {
        const addContact = {
            ...contact,
            [event.target.name]: event.target.type === "checkbox"
                ? (event.target as HTMLInputElement).checked : event.target.value,
        };
        
        setContact(addContact);
    };

    const handleSave = () => {
        if (!validate(contact)) {
            onAdd(contact);
        } else {
            enableValidationMessages();
        }
    }

    const contactTypeOptions: Array<Option> = [
        { id: "", name: "Please select..." },
        ...contactTypes.map((contactType) => {
            return { id: contactType.id.toString() || 0, name: contactType.name || "" };
        }),
    ];

    useEffect(() => {
        processApiErrors(apiError);
    }, [apiError, processApiErrors])

    return (
        <TableBaseRow>
            <TableBaseData>
                <SelectInputBase
                    id="contactTypeId"
                    name="contactTypeId"
                    value={contact.contactTypeId?.toString()}
                    required={true}
                    label="Contact Type"
                    options={contactTypeOptions}
                    error={errors.contactTypeId}
                    onChange={handleChange} />
                <TextInputBase
                    id="name"
                    name="name"
                    label="Contact Name"
                    placeholder="Contact Name"
                    required={true}
                    value={contact.name}
                    error={errors.name}
                    onChange={handleChange} />
                <TextInputBase
                    id="contactNumber"
                    name="contactNumber"
                    label="Contact Number"
                    placeholder="Contact Number"
                    value={contact.contactNumber}
                    error={errors.contactNumber}
                    onChange={handleChange} />
                <TextInputBase
                    id="contactEmail"
                    name="contactEmail"
                    label="Contact Email"
                    placeholder="Contact Email"
                    value={contact.contactEmail}
                    error={errors.contactEmail}
                    onChange={handleChange} />
            </TableBaseData>
            <TableBaseData classes="text-end">
                <ButtonBase onClick={() => onCancel()} cancel>Cancel</ButtonBase>&nbsp;
                <ButtonBase onClick={() => handleSave()}  add>Add</ButtonBase>
            </TableBaseData>
        </TableBaseRow>
    );
}

export default ContactRowAdd;
